import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _isEmpty from 'lodash/isEmpty';
import PropTypes from "prop-types";
import React from "react";
import Tooltip from 'src/components/Tooltip';
import TruncatedTitle from "src/components/TruncatedTitle";
import { renderDynamicContent } from 'src/utils/mlineUtils';
import { getShortUuid } from "src/utils/url";

const LPSCard = ({
  lpsMachine,
  allowedMaterials,
  printerType,
  isPrinterTypeLoading,
  isMaterialsLoading,
  lpsLocationData,
}) => {
  if (!lpsMachine) return null;

  const {
    location,
    isLpsLocationLoading,
    subLocation,
    isLpsSubLocationLoading,
  } = lpsLocationData;

  const allowedMaterialNames = !_isEmpty(allowedMaterials) ? allowedMaterials.map((material) => material.name) : [];

  const renderPrinterType = () => (
    renderDynamicContent(
      isPrinterTypeLoading,
      printerType && (
        <div>
          <FontAwesomeIcon icon={faPrint} className="spacer-right" />
          <span className="font-light">{printerType.name}</span>
        </div>
      )
    )
  );

  const renderLpsLocationData = () => (
    renderDynamicContent(
      isLpsLocationLoading || isLpsSubLocationLoading,
      location && subLocation && (
        <div className="d-flex text-left">
          <FontAwesomeIcon icon={faLocationDot} className="spacer-right mt5" />
          <div>
            <TruncatedTitle
              title={location.name}
              titleClassName='mb0 font-medium font-size-14'
              maxTextLength={25}
              maxTextLengthMobile={20}
          />
            <TruncatedTitle
              title={subLocation.name}
              titleClassName='mb0 font-light font-size-12'
              maxTextLength={35}
              maxTextLengthMobile={30}
          />
          </div>
        </div>
      )
    )
  );

  const renderAllowedMaterials = () =>
    renderDynamicContent(
      isMaterialsLoading,
      !_isEmpty(allowedMaterialNames) && (
        <div>
          Allowed Materials
          <Tooltip
            id="mhs_allowed_materials_tooltip"
            placement="bottom"
            trigger={<FontAwesomeIcon className="spacer-left" icon={faInfoCircle} />}
          >
            <div>{allowedMaterialNames.join(", ")}</div>
          </Tooltip>
        </div>
      ),
    );

  return (
    <div className="lps-card">
      <div className="lps-card-header mb15">
        <TruncatedTitle
          inline
          title={(<h3 className="mb0">{lpsMachine.name}</h3>)}
          titleClassName="font-bold mb0"
          maxTextLength={25}
          maxTextLengthMobile={20}
        />
        <div className="font-light mb10">{getShortUuid(lpsMachine.uri)}</div>
        {renderAllowedMaterials()}
      </div>
      <div className="lps-card-footer">
        {renderPrinterType()}
        {renderLpsLocationData()}
      </div>
    </div>
  );
};

LPSCard.propTypes = {
  lpsMachine: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  allowedMaterials: PropTypes.array.isRequired,
  printerType: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isPrinterTypeLoading: PropTypes.bool.isRequired,
  isMaterialsLoading: PropTypes.bool.isRequired,
  lpsLocationData: PropTypes.shape({
    location: PropTypes.shape({
      name: PropTypes.string,
    }),
    subLocation: PropTypes.shape({
      name: PropTypes.string,
    }),
    isLpsLocationLoading: PropTypes.bool,
    isLpsSubLocationLoading: PropTypes.bool,
  }).isRequired,
};

export default LPSCard;
