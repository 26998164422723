import PropTypes from 'prop-types';
import React from 'react';

const Input = ({
  label,
  type = 'text',
  value,
  onChange,
  suffix,
  style = {},
  inputStyle = {},
  suffixStyle = {},
  labelStyle = {},
  isError = false,
  errorMessage = '',
  ...props
}) => {
  return (
    <div className='custom-input-container' style={style}>
      {label && (
        <label className='custom-input-label' style={labelStyle}>
          {label}
        </label>
      )}

      <div className={`custom-input-wrapper ${isError ? 'custom-input-error' : ''}`}>
        <input
          className='custom-input'
          type={type}
          value={value}
          style={inputStyle}
          onChange={onChange}
          {...props}
        />
        {suffix && (
          <span className='custom-input-suffix' style={suffixStyle}>
            {suffix}
          </span>
        )}
      </div>

      {/* Show error text if isError is true */}
      {isError && errorMessage && <div className='custom-input-error-text'>{errorMessage}</div>}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  suffix: PropTypes.string,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  suffixStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default Input;
