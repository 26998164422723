import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import Loader from 'src/components/loader';
import { API_RESOURCES, LOCALSTORAGE_KEYS } from 'src/utils/constants';
import { getResourceName, isValidUrl } from 'src/utils/url';
import useLocalstorage from 'src/utils/useLocalStorage';

const QrScanner = React.lazy(() => import('react-qr-reader'));

const QrReader = ({ onScan, onError, onUrlError, hidden, children, isLogonScan = false }) => {
  const [isDebugMode] = useLocalstorage(LOCALSTORAGE_KEYS.DEBUG_MODE, false);
  const [fallbackScannedText, setFallbackScannedText] = useState('');
  const qrScannerRef = useRef(null);
  const [cameraKey, setCameraKey] = useState(0);

  const isParsable = string => {
    try {
      if (string) JSON.parse(string);
    } catch (error) {
      return false;
    }
    return true;
  };

  // Function to restart camera without full component unmount
  const restartCamera = () => {
    setCameraKey(prevKey => prevKey + 1);
  };

  function handleScan(data) {
    // Handle QR scan logon, through `/logon-scan` endpoint.
    if (data && isLogonScan) {
      // Parse and format data; replace single (') with double (").
      const formattedString = data.toString().replace(/'/g, '"');
      return isParsable(formattedString) && onScan(JSON.parse(formattedString));
    }

    // Handle QR scan as normal (not logging in).
    try {
      const url = new URL(data);
      const isRunSummaryURI = url?.pathname === `/${API_RESOURCES.SUMMARY}`;

      if (!isValidUrl(url)) {
        onError(new Error('The QR code you scanned was not a valid part.'));
        console.error(`The QR code you scanned was not a valid part ${url.href}`);
        return;
      }

      console.info(`Scanned url ${url.href}`);

      const { searchParams } = url;
      const resourceUri = isRunSummaryURI ? url.href : searchParams.get('resource');
      const resourceName = resourceUri ? getResourceName(resourceUri) : null;

      onScan(url, resourceName, resourceUri);
      // eslint-disable-next-line no-empty
    } catch (_) {}
  }

  function handleError(error) {
    if (onUrlError) return;
    console.error(`${error.name}:`, error);

    if (error.name === 'NotAllowedError') {
      onError(new Error('You must allow camera access in order to scan a QR code.'));
      return;
    }

    if (error.name === 'NotFoundError') {
      onError(new Error('There is no camera available.'));
      return;
    }

    onError(new Error('There was an error activating the camera.'));
  }

  // relaunches the camera when the device wakes up from sleep
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden || isDebugMode) return;

      restartCamera(); // Restart the camera when the page becomes visible
    };

    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [restartCamera]);

  return (
    <>
      {!hidden && isDebugMode && (
        <div className='alert'>
          <label>QR Code URL</label>
          <div className='input-group'>
            <input
              className='form-control'
              type='text'
              value={fallbackScannedText}
              onChange={event => setFallbackScannedText(event.target.value)}
            />
            <button
              type='submit'
              className='btn btn-primary input-group-btn'
              onClick={() => handleScan(fallbackScannedText)}
            >
              Handle Scan
            </button>
          </div>
        </div>
      )}
      {!hidden && !isDebugMode && (
        <Suspense
          fallback={
            <Loader displayName='Lol' text='QR reader' className='qr-reader-content w-100 h-100' />
          }
        >
          <QrScanner
            key={cameraKey}
            ref={qrScannerRef}
            delay={300}
            className='qr-reader-content w-100 h-100'
            onScan={handleScan}
            onError={handleError}
          />
        </Suspense>
      )}
      {hidden && (
        <div className='qr-reader-content w-100 h-100 d-flex align-items-center justify-content-center flex-column bg-success'>
          <FontAwesomeIcon icon={faCheckCircle} size='5x' />
          {children}
        </div>
      )}
    </>
  );
};

QrReader.propTypes = {
  onScan: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onUrlError: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
  hidden: PropTypes.bool,
  children: PropTypes.node,
  isLogonScan: PropTypes.bool,
};

QrReader.defaultProps = {
  hidden: false,
  children: '',
  isLogonScan: false,
};

export default QrReader;
