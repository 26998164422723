import _keyBy from 'lodash/keyBy';
import PropTypes from 'prop-types';
import Collapse, { Panel } from 'rc-collapse';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import ScanButton from 'src/components/scan-button';
import ShipmentDetailsForm from 'src/components/shipment-details-form';
import ViewRunButton from 'src/components/view-run-button';
import { api } from 'src/utils/api';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT, PIECE_STATUS } from 'src/utils/constants';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../utils/routes';

const Badge = ({ badgeStyle, children }) =>
  <span className={`float-right badge badge-${badgeStyle}`}>{children}</span>;

Badge.propTypes = {
  badgeStyle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Badge.defaultProps = {
  badgeStyle: 'default',
};

const Shipment = ({ user }) => {
  const { uuid: shipmentUUID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [shipment, setShipment] = useState(undefined);
  const [pieces, setPieces] = useState([]);
  const [printsByUri, setPrintsByUri] = useState([]);
  const [isPiecesComplete, setIsPiecesComplete] = useState(true)

  const { addToast } = useToasts();

  useEffect(() => void getInitialData(), [shipmentUUID]);

  useEffect(() => {
    const allPiecesComplete = pieces.every(p => p.status === PIECE_STATUS.COMPLETE);
    setIsPiecesComplete(allPiecesComplete);
  }, [pieces]);

  const getInitialData = async () => {
    try {
      const shipment = await api.get(`${API_RESOURCES.SHIPMENT}/${shipmentUUID}/`).json();

      // For regular Run Shipment - just show All Run pieces
      let pieces = [];
      if (shipment.run) {
        const { resources: runPrints } = await api.get(`${API_RESOURCES.PRINT}/`, {
          searchParams: {
            'filter[run]': shipment.run,
            'sort': 'status',
            'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
          },
        }).json();
        let pieceUris = runPrints.map(({ piece: pieceUri }) => pieceUri);
        if (pieceUris.length) {
          const { resources } = await api.get(`${API_RESOURCES.PIECE}/`, {
            searchParams: {
              'filter[uri]': pieceUris.join(','),
              'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
            },
          }).json();
          pieces = resources;
        }
      }

      const pieceUris = pieces.map(({ uri }) => uri);
      let prints = [];
      if (pieceUris.length) {
        const { resources } = await api.get(`${API_RESOURCES.PRINT}/`, {
          searchParams: {
            'filter[piece]': pieceUris.join(','),
            'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
          },
        }).json();
        prints = resources;
      }
      const printsByUri = _keyBy(prints, 'uri');

      setPieces(pieces);
      setShipment(shipment);
      setPrintsByUri(printsByUri);
    } catch (error) {
      setError(error);

    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <Header title="Loading..." back="/scan" user={user} />
        <main role="main" className="text-center">
          <Loader inline text="shipment" className="text-muted" />
        </main>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={shipmentUUID} text={error.text} />
        </main>
      </>
    );
  }

  const getShippingItemStatusBadge = (piece) => {
    if (piece.status === PIECE_STATUS.COMPLETE) {
      return (<Badge badgeStyle="success">Complete</Badge>);
    }
    const currentPrint = printsByUri[piece.current_print];
    if (!currentPrint) {
      return (<Badge>N/A</Badge>);
    }
    if (!currentPrint.run) {
      return (<Badge badgeStyle="warning">Pending</Badge>);
    }
    if (currentPrint.run === shipment.run) {
      return (<Badge badgeStyle="info">Ready to Pack</Badge>);
    }
    return (<Badge badgeStyle="danger">Processing</Badge>);
  };

  const dateFormat = (date) => {
    return !isNaN(date) ? new Date(date).toISOString() : undefined;
  }

  const handleSubmit = async (form) => {
    try {
      await api.put(`${API_RESOURCES.SHIPMENT}/${shipmentUUID}/`, {
        json: {
          name: form.name,
          status: form.status,
          /* eslint-disable camelcase */
          estimated_shipment_date: dateFormat(form.estimatedShipDate),
          estimated_delivery_date: dateFormat(form.estimatedReceiveDate),
          actual_shipment_date: dateFormat(form.actualShipDate),
          actual_delivery_date: dateFormat(form.actualReceiveDate),
          tracking_number: form.trackingNumber || '',
          actual_cost: form.cost || null,
          /* eslint-enable camelcase */
        },
      })

      return addToast('Shipment details saved successfully', { appearance: 'success' });
    } catch (error) {
      return addToast(error.message, { appearance: 'error' });
    }
  }

  return (
    <>
      <Header title="View Packing List" user={user} />
      <main role="main">
        <h2 className="header-margin">
          Shipment: <span className="text-wrap badge badge-secondary">{shipment.name}</span>
        </h2>
        <div className="mb-3">
          {pieces.map((piece, index) => (
            <div key={piece.uri} className="clearfix">
              <span className="float-left">{index + 1}. {piece.name}</span>
              {getShippingItemStatusBadge(piece)}
            </div>
          ))}
          {!pieces.length && (
            <h4>No Pieces for this Shipping yet.</h4>
          )}
        </div>
        <div>
          {
            shipment.run
              ? (
                <Link to={getRouteURI(routes.run, { uuid: getUuid(shipment.run) })}>
                  <button type="button" className="btn btn-info btn-action" disabled={isPiecesComplete}>
                    Pack
                  </button>
                </Link>
              )
              : <span>No Run Scheduled yet</span>
          }
        </div>
        <div className="d-flex justify-content-center">
          <ScanButton />
          <ViewRunButton runUri={shipment.run} />
        </div>
        <Collapse className='mt-4'>
          <Panel
            key="1"
            header="Shipment details"
          >
            <ShipmentDetailsForm shipment={shipment} onSubmit={handleSubmit} />
          </Panel>
        </Collapse>
      </main>
    </>
  );
};

Shipment.propTypes = {
  user: userPropType,
};

Shipment.defaultProps = {
  user: null,
};

export default Shipment;
