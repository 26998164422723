import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Modal from 'src/components/modal';
import RangeInputConversionTooltip from 'src/components/RangeInputConversionTooltip';
import useSelectedAmountHandler from 'src/hooks/useSelectedAmountHandler';
import { api } from 'src/utils/api';
import { API_RESOURCES, MATERIAL_BATCH_ACTIONS } from 'src/utils/constants';
import { convertToUserUnits } from 'src/utils/conversions';
import { formatConvertedUnits } from 'src/utils/ui';
import { getShortUuid } from 'src/utils/url';
import { formatDynamicPercentage, getInputStep } from 'src/utils/validation';

const SieveModal = ({ initialBatch, hideModal, redirectToNextAction, batchUUIDToFetch }) => {
  const [batch, setBatch] = useState(initialBatch);
  const { uuid: batchUUID } = useParams();
  const [error, setError] = useState(undefined);
  const [submitError, setSubmitError] = useState(null);
  const { addToast } = useToasts();

  const { quantity: convertedBatchQuantity, units: convertedBatchUnits, isConverted } =
    useMemo(() => {
      if (batch) {
        return convertToUserUnits(batch.quantity, batch.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [batch]);


  const {
    selectedAmountBase: remainingQuantity,
    selectedAmountConverted,
    handleSelectedAmountChange,
    handleRangeChange,
  } = useSelectedAmountHandler(
    batch?.quantity || 0,
    batch?.units || '',
    convertedBatchQuantity,
    convertedBatchUnits,
    batch?.quantity, // Initial Base Value (for Range)
    convertedBatchQuantity, // Initial Converted Value (for Input)
  );

  const fetchInitialData = async () => {
    if (!initialBatch) {
      const batchUUIDToLoad = batchUUIDToFetch || batchUUID;
      try {
        const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUIDToLoad}/`).json();
        setBatch(batch);
      } catch (error) {
        setError(error.name);
      }
    } else {
      setBatch(initialBatch);
    }
  };

  useEffect(() => void fetchInitialData(), [initialBatch]);

  useEffect(() => {
    if (submitError) {
      addToast(
        `Error: ${submitError}`,
        { appearance: 'error' },
      );
    }
  }, [submitError]);

  const onActionSieve = async (batchUri, remainingQuantity) => {
    try {
      const payload = {
        /* eslint-disable camelcase */
        metadata: {
          remaining_quantity: remainingQuantity,
        },
        action_type: MATERIAL_BATCH_ACTIONS.SIEVE,
        source_batch: batchUri,
        /* eslint-enable camelcase */
      };

      await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
        json: payload,
      });
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      hideModal();
      return;
    }

    redirectToNextAction();

    return new Promise(() => {
    });
  };

  const remainingPercentage = formatDynamicPercentage(remainingQuantity, batch?.quantity);


  return (
    <Modal
      isOpen
      title={(
        <b>Sieve batch {getShortUuid(batch?.uri)}</b>
      )}
      dismissText="Cancel"
      confirmText="Confirm"
      confirmClass="btn-primary"
      onConfirm={() => onActionSieve(batch.uri, remainingQuantity)}
      onDismiss={hideModal}
    >
      <div className="sieve-modal-body">
        <div>
          <label>Remaining quantity:</label>
          &nbsp;<input
            name="remainingQuantity"
            min="0"
            step={getInputStep(convertedBatchQuantity)}
            max={convertedBatchQuantity}
            type="number"
            value={selectedAmountConverted}
            onChange={handleSelectedAmountChange}
        />
          &nbsp;<span>{convertedBatchUnits} ({remainingPercentage || 0}%)</span>
        </div>

        <div className="d-flex align-items-center justify-content-center mt15">
          <div>
            <div className="mb5">0 ({batch?.units})</div>
            <div>{isConverted && formatConvertedUnits(0, convertedBatchUnits)}</div>
          </div>
          <div className="position-relative">
            <RangeInputConversionTooltip
              className="rangeInputAbsolute"
              visible={isConverted} defaultUnits={batch?.units || ''}
              convertedUnits={convertedBatchUnits} />
            &nbsp;
            <input
              name="remainingQuantityRange"
              min="0"
              step={getInputStep(batch?.quantity, true)}
              max={batch?.quantity}
              type="range"
              value={remainingQuantity}
              onChange={(e) => handleRangeChange(e.target.value)}
            /> &nbsp;
          </div>
          <div>
            <div className="mb5">{batch?.quantity} ({batch?.units})</div>
            <div>{isConverted && formatConvertedUnits(convertedBatchQuantity, convertedBatchUnits)}</div>
          </div>
        </div>
      </div>
      {
        error && (
          <div className="alert alert-danger mt15" role="alert">
            {error}
          </div>
        )
      }
    </Modal>
  );
};

export default SieveModal;

SieveModal.propTypes = {
  initialBatch: PropTypes.shape({
    units: PropTypes.string,
    uri: PropTypes.string,
    quantity: PropTypes.number,
  }),
  hideModal: PropTypes.func.isRequired,
  redirectToNextAction: PropTypes.func.isRequired,
  batchUUIDToFetch: PropTypes.string,
};

SieveModal.defaultProps = {
  batchUUIDToFetch: null,
};
