import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from "prop-types";
import React from "react";
import { Spinner } from 'react-bootstrap';
import TruncatedTitle from 'src/components/TruncatedTitle';
import { getShortUuid } from 'src/utils/url';

const LPSBuildModule = ({ module, isLoading }) => {
  if (isLoading) {
    return <Spinner animation="grow" />;
  }

  if (!module) return null;

  return (
    <div className="d-flex align-items-center justify-content-between mt15">
      <div className="mb15 text-left">
        <div className="lps-module-card-resource">
          <TruncatedTitle
            title={module.name}
            titleClassName='mb0'
            maxTextLength={50}
            maxTextLengthMobile={40}
          />
        </div>
        <p className="mb0 font-light">({getShortUuid(module.uri)})</p>
      </div>
      <div className="d-flex align-items-center">
        <FontAwesomeIcon icon={faCheckCircle} size='2x' className="spacer-right" />
        <div className="lps-build-ready">Ready</div>
      </div>
    </div>
  );
};

LPSBuildModule.propTypes = {
  module: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LPSBuildModule;
