import PropTypes from 'prop-types';
import React from 'react';
import Alert from 'src/components/alert';
import Feature from 'src/components/feature';
import Loader from 'src/components/loader';
import ResourceLookup from 'src/components/scan/lookup-resource';
import QrReader from 'src/components/scan/qr-reader';
import { API_RESOURCES } from 'src/utils/constants';
import { FEATURES } from 'src/utils/features';

const ScanQr = ({
  successfulScan,
  handleScan,
  handleError,
  urlError,
  showInstructions,
  instructionText,
  requestedEntity,
  allowedResourcesToScan,
}) => {
  const noRequestEntity = !requestedEntity;
  const batchRequestEntity = requestedEntity === API_RESOURCES.MATERIAL_BATCH;
  const containerRequestEntity = requestedEntity === API_RESOURCES.MATERIAL_CONTAINER;
  const printerRequestEntity = requestedEntity === API_RESOURCES.PRINTER;
  const isRunAction = requestedEntity === API_RESOURCES.RUN;
  const shouldShowResourceLookupField =
    noRequestEntity || batchRequestEntity || containerRequestEntity || printerRequestEntity;

  return (
    <>
      <div className='qr-reader text-white bg-dark rounded-sm overflow-hidden mb-3'>
        <QrReader
          hidden={successfulScan}
          onUrlError={urlError}
          onScan={handleScan}
          onError={handleError}
        >
          <Loader inline text='data' className='mt-3' />
        </QrReader>
      </div>
      {urlError && (
        <Alert name='qr-error' variant='danger' className='text-center'>
          {urlError.message}
        </Alert>
      )}
      {!urlError && showInstructions && (
        <Alert name='qr-instructions' variant='info' className='text-center'>
          <span>{instructionText}</span>
        </Alert>
      )}
      {shouldShowResourceLookupField && !isRunAction && (
        // It's possible to enter batch uuid if material management is enabled
        // and (
        //   exact resource type is not requested (user visited main page)
        //   or
        //   if expected to scan material batch entity
        // )
        <Feature featureName={FEATURES.MATERIAL_MANAGEMENT}>
          <div className='separator'>OR</div>
          <ResourceLookup
            allowedResourcesToScan={allowedResourcesToScan}
            successfulScan={successfulScan}
            onScan={handleScan}
          />
        </Feature>
      )}
    </>
  );
};

ScanQr.propTypes = {
  requestedEntity: PropTypes.string,
  successfulScan: PropTypes.bool.isRequired,
  handleScan: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  urlError: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
  showInstructions: PropTypes.bool.isRequired,
  instructionText: PropTypes.string.isRequired,
  allowedResourcesToScan: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ScanQr.defaultProps = {
  requestedEntity: null,
};

export default ScanQr;
