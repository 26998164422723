import ky from 'ky-universal';
import _isEmpty from 'lodash/isEmpty';
import { API_RESOURCES, MATERIAL_CONTAINER_STATUSES } from 'src/utils/constants';
import { getMachineTypeFromUrl } from 'src/utils/mlineUtils';
import { getUuid } from 'src/utils/url';

import config from './config';
import Sentry from './sentry';

export function getAllByFilterWithApi(api) {
  return async (items, endpoint, options = {}, filter = 'uri') => {
    const chunkedItems = chunkForQueryFilter(items);
    const filterKey = `filter[${filter}]`;

    const requests = chunkedItems.map(chunk =>
      api
        .get(endpoint, {
          ...options,
          searchParams: {
            ...options.searchParams,
            [filterKey]: chunk.filter(Boolean).join(','),
          },
        })
        .json()
    );

    const responses = await Promise.all(requests);
    const data = responses.map(({ resources }) => resources);

    return [].concat(...data);
  };
}

export const chunkForQueryFilter = (items = [], itemsPerChunk = 15) => {
  if (items.length === 0) {
    return [];
  }

  const result = [];

  while (items.length > 0) {
    result.push(items.splice(0, itemsPerChunk));
  }

  return result;
};

export const getCookieFromContext = ({ req } = {}) => {
  if (req && req.headers && req.headers.cookie) {
    return req.headers.cookie;
  }

  return false;
};

export const api = ky.extend({
  credentials: 'include',
  prefixUrl: config.apiHost,
  timeout: 120 * 1000,
  retry: {
    limit: 5, // Maximum number of retries to perform. Default: 2.
    methods: ['get', 'post'], // HTTP methods to automatically retry.
    statusCodes: [408, 413, 429, 500, 502, 503, 504], // HTTP status codes to automatically retry.
    afterStatusCodes: [413, 429, 503], // HTTP status codes that when present in the response, it will wait for the Retry-After header and then retry.
    maxRetryAfter: undefined, // The maximum amount of time in milliseconds to wait before retrying the request. If Retry-After header is greater than maxRetryAfter, it will cancel the request
  },
  hooks: {
    beforeRequest: [
      request => {
        /*
         * We should avoid requests without slash at the end of pathname.
         * nginx returns 308 status code and application makes additional request for
         * api service with slash
         */
        let requestURL = new URL(request.url);
        if (!requestURL.pathname.endsWith('/')) {
          Sentry.captureEvent({
            message: 'The request is executed without the trailing slash in pathname',
            level: 'warning',
            extra: { uri: request.url },
          });
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok && options.onErrorToastCallback) {
          const message = await response.json();
          if (message.errors) {
            options.onErrorToastCallback(message.errors[0].title, { appearance: 'error' });
          }
        }
      },
    ],
  },
});

export const getAllByFilter = getAllByFilterWithApi(api);

export const apiWithContext = ctx => {
  const cookie = getCookieFromContext(ctx);

  const contextApi = api.extend({
    credentials: 'include',
    prefixUrl: config.apiHost,
    hooks: {
      beforeRequest: [
        request => {
          if (cookie) {
            request.headers.append('Cookie', cookie);
          }
        },
      ],
      afterResponse: [
        (request, options, response) => {
          if (ctx && response.headers.has('Set-Cookie')) {
            ctx.res.setHeader('Set-Cookie', response.headers.get('Set-Cookie'));
          }
        },
      ],
    },
  });

  contextApi.getAllByFilter = getAllByFilterWithApi(contextApi);

  return contextApi;
};

// Neede because fetch doesn’t support upload progress.
// WILL NOT WORK SERVER-SIDE
export const upload = (url, options) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('PUT', url);
    xhr.setRequestHeader('content-type', options.contentType);

    xhr.addEventListener('abort', reject);
    xhr.addEventListener('error', reject);

    xhr.upload.addEventListener('progress', ({ loaded, total }) => {
      options.onUploadProgress(loaded / total);
    });

    xhr.addEventListener('load', () => {
      if (xhr.status >= 200 && xhr.status < 400) {
        resolve(xhr.response);
        return;
      }

      let errors = [];
      try {
        errors = JSON.parse(xhr.response).errors;
      } catch (_) {
        /* empty */
      }

      if (errors.length) {
        errors.map(error => {
          options.onErrorToastCallback(error.title, { appearance: 'error' });
        });
      }
      reject(xhr.status);
    });

    xhr.send(options.body);
  });
};

/**
 * Fetches resources by their URIs from the given resource URL and returns a mapping of URIs to resource objects.
 *
 * @param {string} resourceUrl - The base URL of the resource to fetch from.
 * @param {string[]} uris - An array of URIs identifying the resources to fetch.
 * @param {Object} [additionalSearchParams={}] - Optional additional search parameters to include in the request.
 * @returns {Promise<Object|null>} A promise that resolves to an object mapping each URI to its corresponding resource, or `null` if no URIs are provided.
 */
export const fetchDataByUris = async (resourceUrl, uris, additionalSearchParams = {}) => {
  if (_isEmpty(uris)) return null;

  const searchParams = {
    'filter[uri]': uris.join(','),
    ...additionalSearchParams,
  };

  const { resources } = await api
    .get(`${resourceUrl}/`, {
      searchParams,
    })
    .json();

  return Object.fromEntries(resources.map(item => [item.uri, item]));
};

export const fetchContainerOrMhsModuleData = async resourceUri => {
  try {
    const resource = await api
      .get(`${API_RESOURCES.MATERIAL_CONTAINER}/${getUuid(resourceUri)}/`)
      .json();
    return resource || null;
  } catch (error) {
    console.error('Error fetching resource:', error);
    return null;
  }
};

export const fetchContainerRelatedBatch = async batchUri => {
  try {
    const response = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(batchUri)}/`).json();
    return response || null;
  } catch (error) {
    console.error('Error fetching batch:', error);
    return null;
  }
};

export const fetchModuleWorkstation = async workstationUri => {
  try {
    const apiResource = getMachineTypeFromUrl(workstationUri);
    const response = await api.get(`${apiResource}/${getUuid(workstationUri)}/`).json();
    return response || null;
  } catch (error) {
    console.error('Error fetching workstation:', error);
    return null;
  }
};

export const fetchMaterialData = async materialUri => {
  try {
    const response = await api.get(`${API_RESOURCES.MATERIAL}/${getUuid(materialUri)}/`).json();
    return response || null;
  } catch (error) {
    console.error('Error fetching material:', error);
    return null;
  }
};

export const getBuildTransactionsBatchActions = async transactionUris => {
  if (!transactionUris) return null;
  try {
    const transactionMaterialBatchActions = transactionUris
      .map(transaction => transaction.material_batch_action)
      .join(',');
    const response = await api
      .get(API_RESOURCES.MATERIAL_BATCH_ACTION, {
        searchParams: {
          'filter[uri]': transactionMaterialBatchActions,
        },
      })
      .json();
    return response?.resources || null;
  } catch (error) {
    console.error('Error fetching batch actions:', error);
    return null;
  }
};

export const fetchBatchValidContainers = async batch => {
  if (!batch) return null;

  const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;

  try {
    const sourceBatchContainersResponse = await api
      .get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
        searchParams: {
          'filter[current_batch]': batch.uri,
          'filter[status]': Object.values(containerStatusesExceptEmpty).join(','),
        },
      })
      .json();

    return sourceBatchContainersResponse?.resources || [];
  } catch (error) {
    console.error('Error fetching batch containers:', error);
    return null;
  }
};

export const fetchBatchRelatedTransactions = async batchUri => {
  try {
    const response = await api
      .get(API_RESOURCES.MATERIAL_BATCH_ACTION_TRANSACTION, {
        searchParams: {
          'filter[material_batch]': batchUri,
        },
      })
      .json();
    return response?.resources || null;
  } catch (error) {
    console.error('Error fetching batch transactions:', error);
    return null;
  }
};
