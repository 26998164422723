import { faArrowUpShortWide } from '@fortawesome/free-solid-svg-icons';
import { faObjectGroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import React from 'react';
import ConvertedUnits from 'src/components/ConvertedUnits';
import TruncatedItemsList from 'src/components/TruncatedItemsList';
import TruncatedTitle from 'src/components/TruncatedTitle';
import SingleMHSModuleContainer from 'src/pages/mline/sections/mhs/modules/single-mhs-module-container';
import { getShortUuid } from 'src/utils/url';


const CycloneTrustNotification = ({ module, slot, handleCycloneTrust, inputModuleBatch }) => {
  if (!module) return null;

  const moduleQuantity = Number(module?.quantity);
  const moduleBatchUnits = inputModuleBatch?.units;
  const moduleBatchMaterials = inputModuleBatch?.materials || [];
  const batchMaterialNames = _uniq(_map(moduleBatchMaterials, 'name'));

  return (
    <div>
      <SingleMHSModuleContainer
        skipActions
        module={module}
        slot={slot}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="mb10">
            <div>
              <div>
                <TruncatedTitle
                  inline
                  title={module.name}
                  titleClassName='mb0'
                  maxTextLength={35}
                  maxTextLengthMobile={25}
                />
              </div>
              <div className="font-light font-size-14">({getShortUuid(module.uri)})</div>
            </div>
          </div>
          <div>
            {(!isNaN(moduleQuantity) && (moduleQuantity > 0 || !_isEmpty(batchMaterialNames))) && moduleBatchUnits && (
              <div className="mb5">
                <FontAwesomeIcon className='spacer-right' icon={faArrowUpShortWide} />
                <ConvertedUnits quantity={module.quantity} units={moduleBatchUnits} />
              </div>
            )}
            {!_isEmpty(batchMaterialNames) && (
              <div className='d-flex align-items-center'>
                <FontAwesomeIcon className='spacer-right' icon={faObjectGroup} />
                <TruncatedItemsList
                  titleClassName='font-size-17'
                  iconClassName='font-size-17'
                  data={batchMaterialNames}
                  showCount={1}
                  icon={faObjectGroup}
                  showVisibleIcon={false}
                />
              </div>
            )}
          </div>
        </div>

        {/* TODO: GE Temporarily removed */}
        {/*<div>Expected Weight: {expectedWeight} lb</div>*/}
      </SingleMHSModuleContainer>

      <div className="mt15 mb15">How are you weighing the material?</div>

      <button
        // GE Temporarily disable
        disabled
        className='btn btn-lg btn-primary action-btn btn-block mt60'
        type='button'
        onClick={() => handleCycloneTrust(true)}
      >
        Weigh with MHS Load Cells
      </button>
      <button type='button' className='btn btn-lg btn-block' onClick={() => handleCycloneTrust(false)}>
        Offline Weight Measurement
      </button>
    </div>

  )
}

CycloneTrustNotification.propTypes = {
  module: PropTypes.shape({
    name: PropTypes.string,
    quantity: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  slot: PropTypes.string.isRequired,
  handleCycloneTrust: PropTypes.func.isRequired,
  inputModuleBatch: PropTypes.oneOfType(
    [null, PropTypes.shape({
      units: PropTypes.string,
      materials: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
    })]
  ).isRequired,
}

export default CycloneTrustNotification;
