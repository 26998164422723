import { capitalize } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import { getShortUuid } from 'src/utils/url';

export const pluralWord = (baseWordForm, array, character = 's') => {
  if (!array) return baseWordForm;

  const pluralRules = new Intl.PluralRules();

  if (pluralRules.select(array.length) !== 'one') {
    return `${baseWordForm}${character}`;
  }

  return baseWordForm;
};

export const getContainerName = container => {
  if (!container) return '[Container Not Rendered]';

  if (!container.disposable) {
    return container.name;
  }

  return getShortUuid(container.uri);
};

export const getContainerType = (container, capitalizeCase = true) => {
  if (!container) return '[Container Not Rendered]';

  const type = container.disposable ? 'container' : 'permanent container';
  return capitalizeCase ? capitalize(type) : type;
};

/**
 * Returns a text value based on the first true condition in a provided map.
 *
 * This function iterates over a list of condition-text pairs. If a condition evaluates to `true`,
 * its corresponding text is returned. If no conditions are true, a default text is returned.
 *
 * @param {Array.<[boolean, string]>} conditionMap - An array of tuples where each tuple contains:
 *   - A condition (boolean): When `true`, the associated text is returned.
 *   - A text (string): The text to return if the condition is `true`.
 * @param {string} defaultText - The text to return if no conditions are met.
 *
 * @returns {string} - The first text corresponding to a `true` condition, or the default text if no conditions are met.
 *
 * @example
 * const conditions = [
 *   [false, 'Text for condition 1'],
 *   [true, 'Text for condition 2'],
 *   [false, 'Text for condition 3']
 * ];
 * const result = getTextForCondition(conditions, 'Default text');
 * console.info(result); // Output: 'Text for condition 2'
 */
export const getTextForCondition = (conditionMap, defaultText) => {
  for (const [condition, text] of conditionMap) {
    if (condition) return text;
  }
  return defaultText;
};

/**
 * Formats a list of strings using Intl.ListFormat for localization.
 *
 * @param {string[]} items - The array of items to format.
 * @param {Object} [options] - Additional options for formatting.
 * @param {string} [options.locale='en'] - The locale to use.
 * @param {Object} [options.config={ style: 'long', type: 'conjunction' }] - Configuration for Intl.ListFormat.
 * @returns {string} - The formatted list / or empty string if no items provided.
 *
 * @example
 * formatList(['Apple', 'Orange', 'Banana']) // "Apple, Orange, and Banana"
 */
export const formatList = (
  items,
  type = 'conjunction',
  { locale = 'en', config = { style: 'long', type } } = {}
) => {
  if (_isEmpty(items)) return '';
  const formatter = new Intl.ListFormat(locale, config);
  return formatter.format(items);
};
