import { useQuery } from '@tanstack/react-query';
import { fetcher } from 'src/api/fetcher';
import { API_RESOURCES } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

export const usePrinter = printerUri => {
  return useQuery({
    queryKey: ['printer', 'printer-by-uri', printerUri],
    queryFn: () => fetcher(`${API_RESOURCES.PRINTER}/${getUuid(printerUri)}`),
    enabled: !!printerUri,
  });
};

export const usePrinterType = printerTypeUri => {
  return useQuery({
    queryKey: ['printer-type', 'printer-type-by-uri', printerTypeUri],
    queryFn: () => fetcher(`${API_RESOURCES.PRINTER_TYPE}/${getUuid(printerTypeUri)}`),
    enabled: !!printerTypeUri,
  });
};
