import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PermanentContainerPage from 'src/pages/permanent-container/[uuid]';
import PermanentContainerActionPage from 'src/pages/permanent-container/[uuid]/action';
import ActionContainerUnload from 'src/pages/permanent-container/[uuid]/action/action-container-unload';
import ActionUnloadToContainer from 'src/pages/permanent-container/[uuid]/action/action-unload-to-container';
import LoadMaterial from 'src/pages/permanent-container/[uuid]/action/load-material';
import LoadMaterialBatch from 'src/pages/permanent-container/[uuid]/action/load-material-batch';
import RelocatePermanentContainer from 'src/pages/permanent-container/[uuid]/action/relocate';
import ScrapContainer from 'src/pages/permanent-container/[uuid]/action/scrap-container';
import SieveContainer from 'src/pages/permanent-container/[uuid]/action/sieve';
import SplitContainer from 'src/pages/permanent-container/[uuid]/action/split';
import TransferMaterial from 'src/pages/permanent-container/[uuid]/action/transfer-material';
import AlreadyLoadedPage from 'src/pages/permanent-container/[uuid]/already-loaded';
import PermanentContainerSuccessPage from 'src/pages/permanent-container/[uuid]/success';
import PrivateRoute from 'src/routes/PrivateRoute';

import NotFoundPage from '../pages/not-found';

const PermanentContainerRoutes = () => (
  <Routes>
    <Route
      index
      path='/:uuid/action'
      element={<PrivateRoute Component={PermanentContainerActionPage} />}
    />
    <Route index path='/:uuid' element={<PrivateRoute Component={PermanentContainerPage} />} />
    <Route
      path='/:uuid/action/relocate'
      element={<PrivateRoute Component={RelocatePermanentContainer} />}
    />
    <Route
      path='/:uuid/action/scrap-container'
      element={<PrivateRoute Component={ScrapContainer} />}
    />
    <Route path='/:uuid/action/sieve' element={<PrivateRoute Component={SieveContainer} />} />
    <Route path='/:uuid/action/split' element={<PrivateRoute Component={SplitContainer} />} />
    <Route path='/:uuid/already-loaded' element={<PrivateRoute Component={AlreadyLoadedPage} />} />
    <Route path='/:uuid/action/load-material' element={<PrivateRoute Component={LoadMaterial} />} />
    <Route
      path='/:uuid/action/load-material-batch'
      element={<PrivateRoute Component={LoadMaterialBatch} />}
    />
    <Route
      path='/:uuid/action/transfer-material'
      element={<PrivateRoute Component={TransferMaterial} />}
    />
    <Route
      path='/:uuid/action/action-unload-to-container'
      element={<PrivateRoute Component={ActionUnloadToContainer} />}
    />
    <Route
      path='/:uuid/action/action-container-unload'
      element={<PrivateRoute Component={ActionContainerUnload} />}
    />
    <Route
      path='/:uuid/success'
      element={<PrivateRoute Component={PermanentContainerSuccessPage} />}
    />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default PermanentContainerRoutes;
