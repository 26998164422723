import 'react-datepicker/dist/react-datepicker.css';

import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';

const DateField = ({ name, isRequiredAfterValue }) => {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const onChange = date => {
    if (date === null && isRequiredAfterValue) return;
    setValue(date);
  };

  return (
    <DatePicker
      {...field}
      placeholderText='MM/DD/YYYY'
      className='form-control mb-4'
      wrapperClassName='w-100'
      selected={value}
      onChange={date => onChange(date)}
    />
  );
};

DateField.propTypes = {
  name: PropTypes.string,
  isRequiredAfterValue: PropTypes.bool,
};

DateField.defaultProps = {
  name: '',
  isRequiredAfterValue: false,
};

export default DateField;
