import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const RangeInputConversionTooltip = ({ visible, className, defaultUnits, convertedUnits }) => {
  if (!defaultUnits || !convertedUnits || !visible) return null;
  const classes = classNames('d-flex align-items-center mb0 justify-content-center', className);
  return (
    <OverlayTrigger
      placement='bottom'
      overlay={
        <Tooltip id='tooltip'>
          <p className='mb0'>
            The default unit for this material type is <strong>({defaultUnits})</strong> and you are
            currently viewing this material in <strong>({convertedUnits})</strong>. The quantities
            are being converted directly by Flows, which may lead to some values being displayed
            with up to three decimal places due to the conversion calculations between units.
          </p>
        </Tooltip>
      }
    >
      <p className={classes}>
        <FontAwesomeIcon icon={faInfoCircle} color='#FFA221' />
      </p>
    </OverlayTrigger>
  );
};

RangeInputConversionTooltip.defaultProps = {
  className: '',
};

RangeInputConversionTooltip.propTypes = {
  defaultUnits: PropTypes.string.isRequired,
  convertedUnits: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default RangeInputConversionTooltip;
