import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MATERIAL_BATCH_POWDER_QUALITY_STATUS_MAP } from 'src/utils/constants';
import { pluralWord } from 'src/utils/stringUtils';

export const renderBatchPowderQualityIcon = (shouldWarn, batch, isEmptyBatchLoad) => {
  if (!shouldWarn || !batch) return null;

  const powderQualityTitle = MATERIAL_BATCH_POWDER_QUALITY_STATUS_MAP[batch.powder_quality];

  return powderQualityTitle ? (
    <OverlayTrigger
      placement='bottom'
      overlay={
        <Tooltip id='batch-quality-statustooltip'>
          Please note, the {isEmptyBatchLoad ? 'batch' : 'source batch of this container'} is{' '}
          {powderQualityTitle.toLowerCase()}.
        </Tooltip>
      }
    >
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        className='mr-1 ml-2 warning-color spacer-left'
      />
    </OverlayTrigger>
  ) : null;
};

export const renderActionColumnResourceBody = ({
  isDisposable,
  icon,
  resourceName,
  resourceLink,
}) => {
  if (isDisposable) {
    return (
      <>
        {icon}
        {resourceName}
      </>
    );
  }

  return (
    <Link className='success-link-text d-inline' to={resourceLink}>
      {icon}
      <span>{resourceName}</span>
    </Link>
  );
};

export const renderUnsupportedMaterialsText = (
  resourceTitle,
  resourceName,
  unsupportedMaterials
) => {
  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip id='action-materials-mismatch'>
          {resourceTitle} &#39;{resourceName}&#39; does not support&nbsp;
          {pluralWord('material', unsupportedMaterials)}&nbsp;
          {unsupportedMaterials.map(material => material).join(', ')}
        </Tooltip>
      }
    >
      <FontAwesomeIcon icon={faExclamationTriangle} className='d-block ml-2 warning-color' />
    </OverlayTrigger>
  );
};
