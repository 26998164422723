import PropTypes from 'prop-types';
import React from 'react';

const ActionButton = ({ className, disabled, onClick, children }) => {
  return (
    <button
      type='button'
      className={`btn btn-action mm-btn-text mm-ph-100 mm-pw-100 ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

ActionButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

ActionButton.defaultProps = {
  className: '',
  disabled: false,
  onClick: () => {},
  children: null,
};

export default ActionButton;
