import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import CircleProgressBar from 'src/components/circle-progress-bar';

const ActionProgressInfo = ({
  currentStepName,
  nextStepName,
  currentStep,
  totalSteps,
  isScrollVisible,
}) => {
  const nextStepClasses = classNames('action-progress-next-step', {
    'action-progress-next-step-hidden': !isScrollVisible,
  });

  return (
    <div className='action-progress-info'>
      <div className='action-progress-steps position-relative'>
        <div className='action-progress-step-data'>
          <div className='action-progress-current-step'>{currentStepName}</div>
          {nextStepName && <div className={nextStepClasses}>Next: {nextStepName}</div>}
        </div>
        <CircleProgressBar
          currentStep={currentStep}
          totalSteps={totalSteps}
          hiddenByScroll={!isScrollVisible}
        />
      </div>
    </div>
  );
};

ActionProgressInfo.propTypes = {
  currentStepName: PropTypes.string.isRequired,
  nextStepName: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  isScrollVisible: PropTypes.bool.isRequired,
};

export default ActionProgressInfo;
