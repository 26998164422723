import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Input from 'src/components/CustomInput';
import { MLINE_MODULE_TYPES } from 'src/constants/m-line';
import { useBatch } from 'src/hooks/services/useBatch';
import useConvertedInputHandler from 'src/hooks/useConvertedInputHandler';
import SingleMHSModuleContainer from 'src/pages/mline/sections/mhs/modules/single-mhs-module-container';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import { MATERIAL_UNITS } from 'src/utils/constants';
import { convertToUserUnits, formatThreeDecimalsNumber, performConversion } from 'src/utils/conversions';

const GeneralTrustCycloneForm = ({
                                   module,
                                   slot,
                                   trustCyclone,
                                   handleConfirmLoadFromInput,
                                 }) => {
  const moduleName = module.name;
  const moduleType = module.type;

  const mhsInputBatchUri = module?.current_batch;

  const { data: moduleBatch, isLoading: isModuleBatchLoading } = useBatch(mhsInputBatchUri, 'mhs-input-batch');

  const baseUnits = moduleBatch?.units;
  const batchQuantity = moduleBatch?.quantity;

  const [errorMessage, setErrorMessage] = useState('');
  const [isWeightValid, setIsWeightValid] = useState(true);

  const tareWeightInBatchUnits = useMemo(() => {
    if (baseUnits) {
      // Convert the tare weight from grams (source unit) to the batch’s unit.
      return performConversion(module.tare_weight, MATERIAL_UNITS.GRAM, null, baseUnits);
    }
    return { quantity: 0, units: baseUnits, isConverted: false };
  }, [module.tare_weight, baseUnits]);

  const { quantity: displayedTareWeightQuantity } = useMemo(() => {
    if (baseUnits) {
      return convertToUserUnits(tareWeightInBatchUnits.quantity, baseUnits);
    }
    return { quantity: 0, units: '' };
  }, [tareWeightInBatchUnits.quantity, baseUnits]);


  // Use the custom hook for weight input
  const {
    baseValue: weightBase,
    convertedValue: weightConverted,
    handleInputChange: handleWeightChange,
    convertedUnits: weightUnits,
  } = useConvertedInputHandler(0, baseUnits);

  const { closeActionPanel } = useActionPanelStore();


  const validateWeight = (newWeightBase) => {
    // Compare newWeightBase (which is in batch/base units) with tare weight (also in batch/base units)
    if (newWeightBase < displayedTareWeightQuantity && !trustCyclone) {
      setErrorMessage("Total Weight can't be less than Tare Weight");
      setIsWeightValid(false);
    } else {
      setErrorMessage('');
      setIsWeightValid(true);
    }
  };


  const handleWeightInputChange = (e) => {
    handleWeightChange(e);
    validateWeight(parseFloat(e.target.value) || 0); // Validate in base units
  };

  const reclaimedWeightBase = weightBase - tareWeightInBatchUnits.quantity;
  const consumedDoseWeightBase = batchQuantity - reclaimedWeightBase;
  const reclaimedWeightBaseFormatted = formatThreeDecimalsNumber(reclaimedWeightBase);
  // For Dose = show Consumed Dose Weight
  const consumedDoseWeightBaseFormatted = formatThreeDecimalsNumber(consumedDoseWeightBase);

  const handleSubmit = async () => {
    if (isWeightValid) {
      await handleConfirmLoadFromInput(moduleType, mhsInputBatchUri, reclaimedWeightBase, consumedDoseWeightBase)
    }
  };

  return (
    <div>
      <SingleMHSModuleContainer
        skipActions
        customStatusText={moduleName}
        module={module}
        slot={slot}
      >
        <div className="d-flex align-items-center justify-content-between">
          {/* TODO: GE Temporarily removed */}
          {/*<div>Expected Weight: {expectedWeight} lb</div>*/}
          {isModuleBatchLoading ? <Spinner animation="border" /> : (
            <div>Tare:&nbsp;
              <ConvertedUnits quantity={tareWeightInBatchUnits.quantity} units={tareWeightInBatchUnits.units} />
            </div>
          )}
        </div>
      </SingleMHSModuleContainer>

      <Input
        label={trustCyclone
            ? "Reclaimed Powder Weight:"
            : "Total Weight before Reclaim:"}
        type="number"
        value={weightConverted}
        suffix={weightUnits}
        isError={!isWeightValid}
        style={{ marginBottom: '15px' }}
        errorMessage={errorMessage}
        onChange={handleWeightInputChange}
        />

      {isWeightValid && weightBase > 0 && !trustCyclone && (
        <div className="mb15">
          Reclaimed Weight =
          <strong>
            &nbsp;<ConvertedUnits
              quantity={reclaimedWeightBaseFormatted} units={baseUnits} />
          </strong>

        </div>
        )}

      {isWeightValid
        && weightBase > 0
        && !trustCyclone
        && moduleType === MLINE_MODULE_TYPES.DOSE && (
          <div className="mb15">
            Consumed Weight =
            <strong>
              &nbsp;<ConvertedUnits
                quantity={consumedDoseWeightBaseFormatted} units={baseUnits} />
            </strong>

          </div>
        )}

      <button
        type='button'
        disabled={!isWeightValid || weightBase <= 0}
        className='btn btn-lg btn-primary action-btn btn-block mt60'
        onClick={handleSubmit}
      >
        Update
      </button>
      <button type='button' className='btn btn-lg btn-block' onClick={closeActionPanel}>
        Cancel
      </button>
    </div>
  );
};

GeneralTrustCycloneForm.propTypes = {
  /* eslint-disable camelcase */
  module: PropTypes.shape({
    name: PropTypes.string,
    tare_weight: PropTypes.number,
    type: PropTypes.string,
    current_batch: PropTypes.string,
  }).isRequired,
  slot: PropTypes.string.isRequired,
  trustCyclone: PropTypes.bool.isRequired,
  handleConfirmLoadFromInput: PropTypes.func.isRequired,
};

export default GeneralTrustCycloneForm;
