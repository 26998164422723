import React, { useCallback, useRef } from 'react';
import { useToasts } from 'react-toast-notifications';
import ContainerPowderQualityWarning from 'src/pages/material-container/sections/container-powder-quality-warning';
import useActionPanelStore from 'src/stores/useActionPanelStore';

/**
 * useSieveFlow
 *
 * A custom hook that encapsulates the sieve logic and uses the Action Panel
 * to display a warning when needed. Instead of having the calling component pass
 * a callback that calls itself (recursively), this hook returns a promise via the
 * `checkIfSieveRequiredFirst` function. Callers can then await this promise before proceeding.
 */
export default function useSieveFlow() {
  // Track whether the sieve step has been completed or approved.
  const didSieveRef = useRef(false);
  const userApprovedSieveRef = useRef(false);
  const { openActionPanel, closeAllActionPanels } = useActionPanelStore();
  const { addToast } = useToasts();

  /**
   * Mark the sieve action as successfully completed.
   */
  const markSieveSuccess = useCallback(() => {
    didSieveRef.current = true;
    addToast('Sieve action has successfully been completed', { appearance: 'success' });
  }, [addToast]);

  const resetUserApproval = useCallback(() => {
    userApprovedSieveRef.current = false;
  }, []);

  /**
   * checkIfSieveRequiredFirst
   *
   * Checks if the sieve action is needed based on the provided flag and internal state.
   * If not needed, the promise resolves immediately. Otherwise, it opens an action panel
   * to display the warning and returns a promise that resolves when the user either confirms
   * the sieve action or chooses to skip it.
   *
   * @param {Object} params
   * @param {boolean} params.shouldWarn - A condition (computed by the caller) that indicates whether to warn.
   * @param {string} params.action - The action name (e.g. 'LOAD_MATERIAL') used in the warning panel.
   * @param {boolean} params.isSubmitting - The current submitting state; passed along to the panel.
   *
   * @returns {Promise<void>} A promise that resolves when the sieve step is handled.
   */
  const checkIfSieveRequiredFirst = useCallback(
    ({ shouldWarn, action, isSubmitting }) => {
      // If no warning is needed or if the sieve action is already handled, resolve immediately.
      if (!shouldWarn || didSieveRef.current || userApprovedSieveRef.current) {
        return Promise.resolve();
      }
      // Otherwise, return a promise that will resolve when the user makes a decision.
      return new Promise(resolve => {
        openActionPanel({
          panelId: 'container-powder-quality-warning',
          stack: true,
          title: 'Warning',
          content: (
            <ContainerPowderQualityWarning
              action={action}
              isSubmitting={isSubmitting}
              // When the user confirms the sieve action:
              sieveContainerAction={() => {
                userApprovedSieveRef.current = true;
                closeAllActionPanels();
                resolve();
              }}
              // When the user chooses to skip the sieve:
              proceedWithInitialActionAnyway={() => {
                didSieveRef.current = true;
                closeAllActionPanels();
                resolve();
              }}
            />
          ),
        });
      });
    },
    [openActionPanel, closeAllActionPanels]
  );

  return {
    checkIfSieveRequiredFirst,
    markSieveSuccess,
    didSieveRef,
    resetUserApproval,
  };
}
