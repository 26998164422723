import create from 'zustand';

const useActionPanelStore = create(set => ({
  // Store the panel stack as an array.
  panelStack: [],

  /**
   * Opens a new panel.
   * @param {object} panelProps - The panel properties.
   * @param {string} panelProps.title - Title of the panel.
   * @param {React.Component|JSX.Element} panelProps.content - The panel content.
   * @param {string|number} [panelProps.panelId] - A unique identifier.
   * @param {boolean} [panelProps.isSidebar=false] - Whether the panel is a sidebar.
   * @param {boolean} [panelProps.stack=false] - If true, push onto the stack.
   * @param {object} [rest] - Any additional props.
   */
  openActionPanel: ({
    title,
    content,
    panelId = Date.now(),
    isSidebar = false,
    stack = false,
    ...rest
  }) =>
    set(state => {
      if (stack && state.panelStack.length > 0) {
        // Push a new panel on top of the existing one.
        return {
          panelStack: [...state.panelStack, { title, content, panelId, isSidebar, ...rest }],
        };
      } else {
        // Replace the stack with the new panel.
        return { panelStack: [{ title, content, panelId, isSidebar, ...rest }] };
      }
    }),

  /**
   * Closes the current (top) panel.
   * If there are panels underneath, it pops the top one off.
   * Otherwise, it clears the stack.
   */
  closeActionPanel: () =>
    set(state => {
      if (state.panelStack.length > 1) {
        // Remove the top panel and reveal the one beneath.
        return { panelStack: state.panelStack.slice(0, -1) };
      }
      // Otherwise, clear the stack completely.
      return { panelStack: [] };
    }),

  /**
   * Closes all action panels by clearing the entire panel stack.
   */
  closeAllActionPanels: () => set({ panelStack: [] }),

  /**
   * Updates the properties of the current (top) panel.
   * Useful for updating things like isSubmitting or errorMessage.
   */
  setActionPanelProps: props =>
    set(state => {
      if (state.panelStack.length) {
        const newStack = [...state.panelStack];
        newStack[newStack.length - 1] = { ...newStack[newStack.length - 1], ...props };
        return { panelStack: newStack };
      }
      return {};
    }),
}));

export default useActionPanelStore;
