import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Loader from 'src/components/loader';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS,
} from 'src/utils/constants';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../../utils/routes';
import ActionPage from './_action-wrapper';


const RelocateBatch = ({
                         user,
                       }) => {
  const { uuid: batchUUID } = useParams();
  const [batch, setBatch] = useState(undefined);
  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoadingSubLocations, setIsLoadingSubLocations] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [locationsState, setLocationsState] = useState({
    location: undefined,
    subLocation: undefined,
  });
  const [locations, setLocations] = useState([]);
  const [subLocations, setSubLocations] = useState([]);

  const navigate = useNavigate();

  const getInitialData = async () => {
    try {
      setLoading(true);
      const getBatch = api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();
      const getLocations = api.get(`${API_RESOURCES.LOCATION}/`).json();
      const [batch, { resources: locations }] = await Promise.all([getBatch, getLocations]);
      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[location]': batch.location,
        },
      }).json();

      setBatch(batch);
      setLocations(locations);
      setSubLocations(subLocations);
      setLocationsState({
        location: batch?.location,
        subLocation: batch?.sub_location,
      });

    } catch (error) {
      if (error.name === 'HTTPError' && error.response.status === 404) {
        setError('Failed to find batch.');
      } else {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => void getInitialData(), []);

  const onRelocateBatch = async (batchUri, locationUri, subLocationUri) => {
    let actionResult;
    const subLocation = subLocations.find((subLocation) => subLocation.uri === locationsState.subLocation)?.name;
    try {
      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          destination_location: locationUri,
          destination_sub_location: subLocationUri,
        },
        action_type: MATERIAL_BATCH_ACTIONS.RELOCATE,
        source_batch: batchUri,
      };

      setSubmitting(true);
      actionResult = await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
        json: payload,
      }).json();
    } catch (error_) {
      console.error(error_);
      setSubmitError(true);
      setSubmitting(false);
      return;
    }

    const { source_batch } = actionResult;

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(source_batch) },
      {
        action: MATERIAL_BATCH_ACTIONS.RELOCATE,
        batch: source_batch,
        subLocation,
      }));

    return new Promise(() => {
    });
  };

  async function fetchSubLocations(locationUri) {
    try {
      setIsLoadingSubLocations(true);
      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[location]': locationUri,
        },
      }).json();
      setSubLocations(subLocations);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingSubLocations(false);
    }
  }

  const handleChangeLocation = async (locationUri) => {
    await fetchSubLocations(locationUri);

    setLocationsState((previous) => ({
      ...previous,
      location: locationUri,
      subLocation: undefined,
    }));
  };

  const handleChangeSubLocation = async (subLocationUri) => {
    setLocationsState((previous) => ({
      ...previous,
      subLocation: subLocationUri,
    }));
  };

  return (
    <ActionPage
      id={batchUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.RELOCATE}
      isLoading={isLoading}
    >
      <h2 className="header-margin">
        Store Batch <span className="badge badge-secondary">{getShortUuid(batch?.uri)}</span>
      </h2>

      <h5 className="mb-4">
        <strong><ConvertedUnits
          quantity={batch?.quantity}
          units={batch?.units} /> of {batch?.materials.map((material) => material.name).join(', ')}
        </strong>
      </h5>

      <h5 className="header-margin">Select a Location and Sub-Location to store this batch.</h5>

      <div>
        <select
          className="form-control mt-4 mb-4"
          value={locationsState.location}
          onChange={(event) => handleChangeLocation(event.target.value)}
        >
          {locations.map((location) => (
            <option key={location?.uri} value={location?.uri}>{location?.name}</option>
          ))}
        </select>


        {isLoadingSubLocations ?
          <Loader inline className="mb-4" />
          : (
            <select
              className="form-control mb-4"
              value={locationsState.subLocation}
              disabled={!subLocations.length || isLoadingSubLocations}
              onChange={(event) => handleChangeSubLocation(event.target.value)}
            >
              <option value="">Choose...</option>
              {subLocations.map((subLocation) => (
                <option key={subLocation?.uri} value={subLocation?.uri}>{subLocation?.name}</option>
              ))}
            </select>
          )}

      </div>


      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isLoadingSubLocations || isSubmitting || !locationsState.subLocation || !subLocations.length}
        onClick={() => onRelocateBatch(batch.uri, locationsState.location, locationsState.subLocation)}
      >
        <div className="d-flex align-items-center justify-content-center">
          {isSubmitting && <Loader inline showText={false} />}
          Confirm
        </div>

      </button>
      <Link to={`/material-container?batch=${batchUUID}`}>
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>
    </ActionPage>
  );
};

RelocateBatch.propTypes = {
  user: userPropType,
};

export default RelocateBatch;
