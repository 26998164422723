import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faClock, faExclamationCircle, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import TruncatedTitle from 'src/components/TruncatedTitle';
import { getPostProcessorDurationInSpecialFormat } from 'src/utils/dateUtils';
import routes from 'src/utils/routes';
import { renderItemWithTooltip } from 'src/utils/ui';
import { getRouteURI, getUuid } from 'src/utils/url';

const PostProcessorCard = ({ postProcessor, location, subLocation, isError, customMainStyle }) => {
  const [locationShown, setLocationShown] = React.useState(false);

  if (!postProcessor) return null;

  const postProcessorDuration = getPostProcessorDurationInSpecialFormat(postProcessor.duration);

  if (isError) {
    return (
      <p className='error-loading-card-resource'>
        <FontAwesomeIcon icon={faExclamationCircle} className='d-block spacer-right danger-color' />
        An error occurred while loading the Details.
      </p>
    );
  }

  const toggleLocation = () => setLocationShown(previous => !previous);

  return (
    <div className='material-batch-card-details' style={customMainStyle}>
      <div className='material-batch-card-details-header'>
        <div className='d-flex align-items-center'>
          <FontAwesomeIcon className='spacer-right' icon={faPrint} />
          <p>
            <Link
              to={getRouteURI(routes.mhsPage, {
                uuid: getUuid(postProcessor.uri),
              })}
            >
              <TruncatedTitle
                inline
                title={postProcessor.name}
                titleClassName='material-batch-card-details-header-batch font-medium mb0'
                maxTextLength={25}
                maxTextLengthMobile={20}
              />
            </Link>
          </p>
        </div>
      </div>
      <div className='material-batch-card-details-body'>
        <div className='d-flex align-items-center justify-content-between'>
          {renderItemWithTooltip('Workstation Duration', postProcessorDuration, faClock)}
        </div>
        <div className='material-batch-card-details-location'>
          <p className='mb0 badge badge-secondary' onClick={toggleLocation}>
            {!locationShown ? 'Show' : 'Hide'} Location
          </p>
        </div>
        {locationShown ? (
          <div
            style={{
              textAlign: 'left',
              marginTop: '10px',
            }}
          >
            <div>
              <div className='d-flex align-items-center'>
                <FontAwesomeIcon className='spacer-right' icon={faLocationDot} />
                <p className='mb0'>{location.name}</p>
              </div>
              <p className='mb0 badge' style={{ paddingLeft: 0, marginLeft: '19px' }}>
                {subLocation.name}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

PostProcessorCard.defaultProps = {
  isError: null,
};

PostProcessorCard.propTypes = {
  postProcessor: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
    duration: PropTypes.number,
  }).isRequired,
  customMainStyle: PropTypes.shape({}).isRequired,
  isError: PropTypes.bool,
  location: PropTypes.string.isRequired,
  subLocation: PropTypes.string.isRequired,
};

export default PostProcessorCard;
