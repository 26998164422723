import { faQuestionCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import {
  faBoxOpen,
  faCodeBranch,
  faFillDrip,
  faFilter,
  faFlask,
  faTrashAlt,
  faVials,
} from '@fortawesome/pro-regular-svg-icons';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ActionButton from 'src/components/ActionButton';
import Alert from 'src/components/alert';
import ContainerQRCodes from 'src/components/ContainerQRCodes';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import Tooltip from 'src/components/Tooltip';
import { useBatchTransactionByBatchUriAndStatus } from 'src/hooks/services/useMhs';
import { useMlineMHSByPostProcessorUri } from 'src/hooks/services/useMhs';
import { usePostProcessor } from 'src/hooks/services/usePostProcessor';
import { usePrinter } from 'src/hooks/services/usePrinter';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import { api } from 'src/utils/api';
import {
  API_RESOURCES, MATERIAL_BATCH_ACTIONS, MATERIAL_BATCH_STATUSES, MATERIAL_UNITS,
  PERMANENT_CONTAINER_ACTIONS,
  PERMANENT_CONTAINER_STATUS_VERBOSE,
  PERMANENT_CONTAINER_STATUSES,
  PERMANENT_CONTAINER_TYPES,
} from 'src/utils/constants';
import { getMachineTypeFromUrl } from 'src/utils/mlineUtils';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../utils/routes';
import MaterialBatchCardDetails from './sections/material-batch-card-details';

const PermanentContainerPage = ({ user }) => {
  const { uuid: permanentContainerUuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [permanentContainer, setPermanentContainer] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [batch, setBatch] = useState(null);
  const [postProcessorUri, setPostProcessorUri] = useState(null);
  const isArchived = permanentContainer?.archived;
  const isCyclone = permanentContainer?.type === PERMANENT_CONTAINER_TYPES.CYCLONE;
  const isModule = [
    PERMANENT_CONTAINER_TYPES.DOSE,
    PERMANENT_CONTAINER_TYPES.BUILD,
    PERMANENT_CONTAINER_TYPES.OVERFLOW,
  ].includes(permanentContainer?.type);
  const isModuleEmpty = permanentContainer?.status === PERMANENT_CONTAINER_STATUSES.EMPTY;
  const machineName = getMachineTypeFromUrl(permanentContainer?.workstation_uri);
  const isPrinter = machineName === API_RESOURCES.PRINTER;
  const isPostProcessor = machineName === API_RESOURCES.POST_PROCESSOR;
  
  const {
    data: printer,
  } = usePrinter((isModule && isPrinter) ? permanentContainer?.workstation_uri : null);
  
  const isDockedAtPrinter = !!printer;
  const {
    data: postProcessor,
  } = usePostProcessor((isModule && isPostProcessor) ? getUuid(permanentContainer?.workstation_uri) : null);
  const {
    data: postProcessorDockedData,
  } = useMlineMHSByPostProcessorUri(isModule ? permanentContainer?.workstation_uri : null);
  const isDockedAtPostProcessorInput = postProcessorDockedData?.input_module?.includes(permanentContainer.uri);
  const isDockedAtPostProcessorOutput = postProcessorDockedData?.output_module?.includes(permanentContainer.uri)

  const tooltipMessagesForActions = {
    [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: 'splitting.',
    [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: 'topping off.',
    [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: 'loading.',
    [PERMANENT_CONTAINER_ACTIONS.SIEVE]: 'sieving.',
    [PERMANENT_CONTAINER_ACTIONS.SCRAP]: 'scrapping.',
    [PERMANENT_CONTAINER_ACTIONS.TEST]: 'testing.',
  };

  const pageRedirects = {
    [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: getRouteURI(routes.scan,
      {},
      {
        permanentContainer: permanentContainer?.uri,
        entity: API_RESOURCES.MATERIAL_CONTAINER,
        action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
        isLoad: true,
        'material-batch': permanentContainer?.current_batch,
        containerAction: true,
      }),
    [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: getRouteURI(routes.scan,
      {},
      {
        permanentContainer: permanentContainer?.uri,
        entity: API_RESOURCES.MATERIAL_CONTAINER,
        action: PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
        containerAction: true,
      }),
    [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: getRouteURI(routes.scan,
      {},
      {
        permanentContainer: permanentContainer?.uri,
        entity: API_RESOURCES.MATERIAL_CONTAINER,
        action: PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER,
        containerAction: true,
      }),
  };

  const {
    data: moduleBatchTransactions,
    isLoading: moduleBatchTransactionsLoading,
  } = useBatchTransactionByBatchUriAndStatus(permanentContainer?.current_batch, false);

  const hasIncompleteTransactions = !_isEmpty(moduleBatchTransactions);

  const navigate = useNavigate();

  const { openActionPanel } = useActionPanelStore();

  const fetchInitialData = async (permanentContainerUuid) => {
    try {
      const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${permanentContainerUuid}/`).json();
      setPermanentContainer(permanentContainer);

      if (permanentContainer) {
        const location = await api.get(`${API_RESOURCES.LOCATION}/${getUuid(permanentContainer.location)}/`).json();
        const subLocation = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(permanentContainer.sub_location)}/`).json();
        setLocationData({ location, subLocation });

        if (permanentContainer.current_batch) {
          const loadedBatch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(permanentContainer.current_batch)}/`).json();
          setBatch(loadedBatch);
        }

        if (permanentContainer.type === PERMANENT_CONTAINER_TYPES.CYCLONE) {
          const { resources: mLineResources } = await api.get(API_RESOURCES.MLINE_MATERIAL_HANDLING_SYSTEM, {
            searchParams: {
              'filter[cyclone]': permanentContainer.uri,
            },
          }).json();

          if (!_isEmpty(mLineResources)) {
            const mLinePostProcessorUri = mLineResources[0]?.post_processor;
            setPostProcessorUri(mLinePostProcessorUri || null)
          }
        }
      }
      setIsLoading(false);
      setError(null);
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData(permanentContainerUuid);
  }, [permanentContainerUuid]);

  const handleShowQRCodes = () => {
    openActionPanel({
      title: 'Print QR Codes',
      content: <ContainerQRCodes
        containers={[permanentContainer]}
        backUri={getRouteURI(routes.permanentContainerDetails, { uuid: permanentContainerUuid })}
        forResource={permanentContainer.uri}
      />,
    });
  };

  const renderTooltip = (id, text) => (
    <Tooltip
      id={id}
      placement="top"
      trigger={
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="text-info ml-2 relocate-batch-badge-info"
        />
      }
    >
      <div>
        {text}
      </div>
    </Tooltip>
  );

  const renderContainerBatchIncompleteTransactionsTooltip = (action) => renderTooltip(
    'incomplete_transactions_tooltip',
    `The module has pending transactions. Please complete the transactions before ${tooltipMessagesForActions[action]}`
  );

  const renderModuleIsDockedAtLPSTooltip = () => renderTooltip(
    'module_docked_lps_tooltip',
    `This module is currently docked at the printer ${printer.name}. Please undock the module before doing any action.`,
  );

  const renderModuleIsDockedAtMHSTooltip = () => renderTooltip(
    'module_docked_mhs_tooltip',
    `This module is currently docked at the MHS ${postProcessor.name}. Please undock the module before doing any action.`,
  );

  const renderContainerBatchTransactionsTooltip = () => {
    if (moduleBatchTransactionsLoading)
      return (
        <Spinner
          animation='border'
          size='sm'
          variant='light'
          className='spacer-left mb0 align-self-center'
        />
      );

    if (_isEmpty(moduleBatchTransactions)) return null;

    return (
      <div className='spacer-left'>
        <Tooltip
          id='incomplete_transactions_tooltip'
          placement='bottom'
          trigger={
            <span className='powder_quality_badge'>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                color='#FF9903'
                className='spacer-right'
              />
              <span className="text-black">Pending</span>
            </span>
          }
        >
          <div>Module has pending transactions. Please dock at an MHS to finalize the build process weights</div>
        </Tooltip>
      </div>
    );
  };

  const renderDockedTooltip = () => (
    <div>
      <Tooltip
        id="is_docke_tooltip"
        placement="right"
        trigger={(
          <span
            className="powder_quality_badge text-black"
          >
            <FontAwesomeIcon icon={faInfoCircle} color="#000000" className="spacer-right" />
            Docked
          </span>
        )}
      >
        <div>Module is docked</div>
      </Tooltip>
    </div>
  );

  if (isLoading) {
    return (
      <>
        <Header title="Loading" back="/scan" user={user} />
        <main role="main" className="text-center">
          <Loader />
        </main>
      </>
    );
  }

  if (error || !permanentContainer) {
    return (
      <>
        <Header title="Permanent Container" user={user} />
        <main role="main" className="text-center">
          <NotFound id={permanentContainerUuid} />
        </main>
      </>
    );
  }

  const isBatchDone = batch?.status === MATERIAL_BATCH_STATUSES.DONE;

  const scrapButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="scrapButton">
      <Link to={getRouteURI(routes.permanentContainerAction,
        { uuid: getUuid(permanentContainer.uri) },
        {
          type: PERMANENT_CONTAINER_ACTIONS.SCRAP,
          containerAction: true,
        }
      )}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-btn-text mm-ph-100 mm-pw-100"
          disabled={
            isArchived ||
            hasIncompleteTransactions ||
            isCyclone ||
            isDockedAtPrinter ||
            isDockedAtPostProcessorInput ||
            isDockedAtPostProcessorOutput
          }
        >
          <FontAwesomeIcon icon={faTrashAlt} size="3x" className="d-block m-auto" />
          Scrap
        </button>
      </Link>
      {hasIncompleteTransactions && renderContainerBatchIncompleteTransactionsTooltip(PERMANENT_CONTAINER_ACTIONS.SCRAP)}
      {isDockedAtPrinter && renderModuleIsDockedAtLPSTooltip()}
      {(isDockedAtPostProcessorInput || isDockedAtPostProcessorOutput) && renderModuleIsDockedAtMHSTooltip()}
    </div>
  );

  const sieveButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="sieveButton">
      <Link
        to={getRouteURI(routes.permanentContainerActionSieve,
          { uuid: getUuid(permanentContainer.uri) },
          { containerAction: true })}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100 mm-btn-text"
          disabled={
            isArchived ||
            hasIncompleteTransactions ||
            isCyclone  ||
            isModule ||
            isDockedAtPrinter ||
            isDockedAtPostProcessorInput ||
            isDockedAtPostProcessorOutput
          }
        >
          <FontAwesomeIcon icon={faFilter} size="3x" className="d-block m-auto" />
          Sieve
        </button>
      </Link>
      {hasIncompleteTransactions && renderContainerBatchIncompleteTransactionsTooltip(PERMANENT_CONTAINER_ACTIONS.SIEVE)}
      {isModule && renderTooltip(
        'module_sieve_unavailable',
        'This is a modular container - it needs to be docked at the MHS Vacuum Side to perform the sieving action.'
      )}
      {isDockedAtPrinter && renderModuleIsDockedAtLPSTooltip()}
      {(isDockedAtPostProcessorInput || isDockedAtPostProcessorOutput) && renderModuleIsDockedAtMHSTooltip()}
    </div>
  );

  const relocateButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="relocateButton">
      <Link
        to={getRouteURI(routes.permanentContainerAction,
          { uuid: getUuid(permanentContainer.uri) },
          { type: PERMANENT_CONTAINER_ACTIONS.RELOCATE, containerAction: true })}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100 mm-btn-text"
          disabled={
            isArchived ||
            isCyclone ||
            isDockedAtPrinter ||
            isDockedAtPostProcessorInput ||
            isDockedAtPostProcessorOutput
          }
        >
          <FontAwesomeIcon icon={faBoxOpen} size="3x" className="d-block m-auto" />
          Relocate
        </button>
      </Link>
      {isDockedAtPrinter && renderModuleIsDockedAtLPSTooltip()}
      {(isDockedAtPostProcessorInput || isDockedAtPostProcessorOutput) && renderModuleIsDockedAtMHSTooltip()}
    </div>
  );

  const treatmentButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="treatmentButton">
      <Link
        to={getRouteURI(routes.permanentContainerAction,
          { uuid: getUuid(permanentContainer.uri) },
          { type: PERMANENT_CONTAINER_ACTIONS.TREATMENT, containerAction: true })}
      >
        <button
          disabled
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100 mm-btn-text"
        >
          <FontAwesomeIcon icon={faFlask} size="3x" className="d-block m-auto" />
          Treatment
        </button>
      </Link>
    </div>
  );

  const testButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="testButton">
      <Link
        to={getRouteURI(routes.materialBatchAction, { uuid: getUuid(permanentContainer.current_batch) }, {
          type: MATERIAL_BATCH_ACTIONS.TEST,
          containerUUID: getUuid(permanentContainer.uri),
          containerAction: true,
        })}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100"
          disabled={
            isArchived ||
            hasIncompleteTransactions ||
            isCyclone ||
            isDockedAtPrinter ||
            isDockedAtPostProcessorInput
          }
        >
          <FontAwesomeIcon icon={faVials} size="3x" className="d-block m-auto" />
          Test
        </button>
      </Link>
      {hasIncompleteTransactions && renderContainerBatchIncompleteTransactionsTooltip(PERMANENT_CONTAINER_ACTIONS.TEST)}
      {isDockedAtPrinter && renderModuleIsDockedAtLPSTooltip()}
      {isDockedAtPostProcessorInput && renderModuleIsDockedAtMHSTooltip()}
    </div>
  );

  const topOffButton = (
    <div
      className="batch-option-button relocate-warning-wrapper"
      id="blendButton"
    >
      <Link
        to={pageRedirects[PERMANENT_CONTAINER_ACTIONS.TOP_OFF]}
      >
        <ActionButton
          skipDefaultClassName
          className="btn-outline-secondary"
          id="blendButton"
          disabled={isArchived || hasIncompleteTransactions || isCyclone || isDockedAtPrinter}
          onClick={() => navigate(pageRedirects[PERMANENT_CONTAINER_ACTIONS.TOP_OFF])}
        >
          <FontAwesomeIcon icon={faFillDrip} size="3x" className="d-block m-auto" />
          Top Off
        </ActionButton>
      </Link>
      {hasIncompleteTransactions && renderContainerBatchIncompleteTransactionsTooltip(
        PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL
      )}
      {isDockedAtPrinter && renderModuleIsDockedAtLPSTooltip()}
    </div>
  )

  const renderActionButton = (type, redirectLink) => {
    const BUTTON_TYPES = {
      [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: {
        button: (
          <ActionButton
            disabled={
              isArchived ||
              hasIncompleteTransactions ||
              isCyclone ||
              isDockedAtPrinter ||
              isDockedAtPostProcessorInput ||
              isDockedAtPostProcessorOutput
            }
            className="btn-primary"
            id="loadButton"
            onClick={() => navigate(pageRedirects[type])}
          >
            <FontAwesomeIcon icon={faFillDrip} size="3x" className="d-block m-auto" />
            Load
          </ActionButton>
        ),
      },
      [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: {
        button: (
          <ActionButton
            skipDefaultClassName
            className="btn-outline-secondary"
            id="splitButton"
            disabled={
              isArchived ||
              hasIncompleteTransactions ||
              isCyclone ||
              isDockedAtPrinter ||
              isDockedAtPostProcessorInput ||
              isDockedAtPostProcessorOutput
            }
            onClick={() => navigate(pageRedirects[type])}
          >
            <FontAwesomeIcon icon={faCodeBranch} size="3x" className="d-block m-auto" />
            Split
          </ActionButton>
        ),
      },
    };

    return (
      <div
        className="batch-option-button relocate-warning-wrapper"
        id={type === PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL ? 'loadButton' : 'blendButton'}
      >
        <Link
          to={redirectLink}
        >
          {BUTTON_TYPES[type].button}
        </Link>
        {hasIncompleteTransactions && renderContainerBatchIncompleteTransactionsTooltip(
          PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL
        )}
        {isDockedAtPrinter && renderModuleIsDockedAtLPSTooltip()}
        {(isDockedAtPostProcessorInput || isDockedAtPostProcessorOutput) && renderModuleIsDockedAtMHSTooltip()}
      </div>
    );
  };

  return (
    <>
      <Header title="Permanent Container" user={user} onPrintQRCodesClick={handleShowQRCodes} />
      <main role="main" className="text-center">
        <div>
          <h2 className="header-margin">
            {permanentContainer.name}
            <div className="container-title-uuid font-size-18">({getShortUuid(permanentContainer.uuid)})</div>
          </h2>
          <h3 className="header-margin">
            Location:
            <div className="mt10">
              <span className="badge badge-secondary">{locationData.location.name}</span> /&nbsp;
              <span className="badge badge-secondary">{locationData.subLocation.name}</span>
            </div>
          </h3>
          {!isCyclone && (
            <h3 className="header-margin">
              Tare Weight:&nbsp;
              <ConvertedUnits quantity={permanentContainer.tare_weight} units={MATERIAL_UNITS.GRAM} />
            </h3>
          )}
          {batch && (
            <h3 className="header-margin">Quantity:&nbsp;
              <ConvertedUnits quantity={permanentContainer.quantity} units={batch.units} />
              <div className="d-flex align-items-center justify-content-center">
                {permanentContainer.is_docked && renderDockedTooltip()}
                {renderContainerBatchTransactionsTooltip()}
              </div>

            </h3>
          )}

          {!permanentContainer.current_batch && (
            <h4 className="header-margin-large">
              Current Status:&nbsp;
              <span className="badge badge-warning">
                {isArchived ? 'Archived' : PERMANENT_CONTAINER_STATUS_VERBOSE[permanentContainer.status]}
              </span>
              {permanentContainer.is_docked && renderDockedTooltip()}
            </h4>
          )}

          {isCyclone && (
          <Alert
            name="qr-instructions"
            variant="warning"
            className="text-left d-flex align-items-center mt15"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="font-size-22 mr15" />
            <div>
              <p className="font-medium mb0">
                This is a cyclone and cannot be interacted with through this page.
                Please {postProcessorUri && (
                  <>
                    <Link to={getRouteURI(routes.postProcessorDetails, { uuid: getUuid(postProcessorUri) })}>
                      <button type="button" className="btn btn-link notification-link btn-sm alert-link font-size-16">
                        click here
                      </button>
                    </Link>
                    <span>&nbsp;to</span>
                  </>
              )} navigate to the MHS to process modules.
              </p>
            </div>
          </Alert>
          )}

          <MaterialBatchCardDetails batch={batch} />

          {permanentContainer.current_batch && (
            <Link className="link-btn" to={getRouteURI(routes.materialContainer, {}, { batch: batch.uuid })}>
              <button type="button" className="btn btn-lg btn-secondary btn-block mb15">View Batch Actions</button>
            </Link>
          )}

          {permanentContainer.current_batch && !isBatchDone && (
            <div className="batch-options-button-wrapper">
              {renderActionButton(
                PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER,
                pageRedirects[PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER])}
              {scrapButton}
              {treatmentButton}
              {testButton}
              {topOffButton}
              {sieveButton}
              {relocateButton}
              {renderActionButton(
                PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
                pageRedirects[PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL])}
            </div>
          )}


          {(!permanentContainer.current_batch || isBatchDone) && (
            <>
              {isDockedAtPrinter && isModuleEmpty && (
                <Alert
                  name="qr-instructions"
                  variant="warning"
                  className="text-left d-flex align-items-center mt15"
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} className="font-size-22 mr15" />
                  <div>
                    <p className="font-medium mb0">
                      This module is currently docked at the printer {printer.name}. Please undock the module before doing any action.
                    </p>
                  </div>
                </Alert>
              )}
              <Link
                to={getRouteURI(routes.scan,
                  {},
                  {
                    permanentContainer: permanentContainer.uri,
                    entity: API_RESOURCES.MATERIAL_CONTAINER,
                    action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
                    containerAction: true,
                    isFill: true,
                  })}
                className="link-btn"
              >
                <button type="button" disabled={isArchived || (isDockedAtPrinter && isModuleEmpty)} className="btn btn-lg btn-primary btn-block">
                  <FontAwesomeIcon icon={faFillDrip} className="spacer-right" />
                  Fill
                </button>
              </Link>
              <Link
                to={getRouteURI(routes.permanentContainerAction,
                  { uuid: permanentContainerUuid },
                  {
                    type: PERMANENT_CONTAINER_ACTIONS.RELOCATE,
                    containerAction: true,
                  })}
                className="link-btn"
              >
                <button
                  type="button"
                  disabled={isArchived || (isDockedAtPrinter && isModuleEmpty)}
                  className="btn btn-lg btn-primary btn-block"
                >
                  <FontAwesomeIcon icon={faBoxOpen} className="spacer-right" />
                  Relocate
                </button>
              </Link>
            </>
          )}

        </div>
      </main>

    </>
  );
};

PermanentContainerPage.propTypes = {
  error: PropTypes.shape({}),
  user: userPropType,
};

PermanentContainerPage.defaultProps = {
  error: null,
  user: null,
};

export default PermanentContainerPage;
