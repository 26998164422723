import PropTypes from "prop-types";
import React from "react";
import { Spinner } from 'react-bootstrap';

const MhsModule = ({ module, isLoading, children }) => {
  if (isLoading) {
    return <Spinner animation="grow" />;
  }

  return (
    <div className="mhs-module-card">
      <div className="module-title">
        {module && !children ? (
          <span>{module.name}</span>
        ) : null}
        {children}
      </div>
    </div>
  );
};

MhsModule.defaultProps = {
  children: null,
  isLoading: false,
}

MhsModule.propTypes = {
  module: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default MhsModule;
