import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import Alert from 'src/components/alert';
import Barcode from 'src/components/barcode';
import BarcodeStatusAlert from 'src/components/BarcodeStatusAlert';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import ScanButton from 'src/components/ScanButton';
import { api } from 'src/utils/api';
import { checkEncodedValue, decodeBarcodeUrl } from 'src/utils/barcodeEncoding';
import config from 'src/utils/config';
import {
  API_RESOURCES,
  API_RESOURCES_MAP,
  BARCODE_SCANNER_STATES,
  FULL_UUID_LENGTH,
  SCAN_BUTTON_STATES,
  SHORT_UUID_LENGTH,
} from 'src/utils/constants';
import { isShortUUID, isUUID } from 'src/utils/isUUID';
import { formatList } from 'src/utils/stringUtils';
import { getResourceName, isValidUrl } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

// Threshold in milliseconds
const KEY_PRESS_THRESHOLD = 50;
// Time in milliseconds to wait for the Barcode Full Code received
const BARCODE_SCAN_INPUT_DELAY = 500;

const BarcodeScan = ({
  user,
  allowedEntityType,
  successfulScan,
  handleScan,
  handleError,
  barcodeError,
  showInstructions,
  instructionText,
  requestedEntity,
  setBarcodeError,
  resourceError,
  renderUseWithoutPermanentContainer,
}) => {
  const [scannedBarcodeValue, setScannedBarcodeValue] = useState('');
  const barcodeInputRef = useRef(null);
  const barcodeScanTimeoutRef = useRef(null);
  const lastKeypressTime = useRef(Date.now());
  const [lastScannedBarcodeValue, setLastScannedBarcodeValue] = useState('');
  const [state, setState] = useState(SCAN_BUTTON_STATES.initialized);
  const [scannerState, setScannerState] = useState(BARCODE_SCANNER_STATES.ready);
  // It's possible to enter batch uuid if material management is enabled,
  // and (
  //   an exact resource type is not requested (user visited the main page)
  //   or
  //   if expected to scan material batch entity
  // )
  const canScanBatchID = !requestedEntity || requestedEntity === API_RESOURCES.MATERIAL_BATCH;

  const { addToast } = useToasts();

  const resourceLookupData = [
    API_RESOURCES.MATERIAL_BATCH,
    API_RESOURCES.MATERIAL_CONTAINER,
    API_RESOURCES.PRINTER,
    API_RESOURCES.POST_PROCESSOR,
    API_RESOURCES.RUN,
    API_RESOURCES.PIECE,
    // Shipment must be last as partial look ups will fail for this resource.
    API_RESOURCES.SHIPMENT,
  ];

  const isLoading = state === SCAN_BUTTON_STATES.loading;
  const isSearchDisabled = [SCAN_BUTTON_STATES.loading, SCAN_BUTTON_STATES.success].includes(state);
  const regularScanPlaceholder = `Scan barcode `;
  const getPlaceholderName = () => {
    const allowedResources = [
      ...new Set(
        resourceLookupData.map(resource => API_RESOURCES_MAP[resource] || _capitalize(resource))
      ),
    ];
    return formatList(allowedResources, 'disjunction');
  };

  const resourcePlaceholderName = getPlaceholderName();
  const materialBatchPlaceholder = `or type ${resourcePlaceholderName} ID`;
  const placeholder = canScanBatchID
    ? regularScanPlaceholder + materialBatchPlaceholder
    : regularScanPlaceholder;

  const focusBarcodeInput = () => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
  };

  const checkIfBarcodeInputIsNotFocused = () => {
    // Check if the "Barcode Output" input is not the currently focused element
    if (document.activeElement !== barcodeInputRef.current) {
      setScannerState(BARCODE_SCANNER_STATES.inactive);
    }
  };

  const setActiveScannerState = () => {
    // If the user scanned something when the input was not focused ->
    // Show "Idle Status" first, otherwise - Ready status.
    if (scannerState !== BARCODE_SCANNER_STATES.idle) {
      return setScannerState(BARCODE_SCANNER_STATES.ready);
    }
  };

  // Check if the Encoded Barcode value corresponds to the expected format
  const handleCheckBarcodeEncodedValue = encodedValue => checkEncodedValue(encodedValue);

  const handleCheckTypeOfInput = event => {
    // Get the time between the last two keypress
    const timeBetweenKeystrokes = Date.now() - lastKeypressTime.current;
    // Update the last keypress time
    lastKeypressTime.current = Date.now();
    // Check if the key pressed was the Enter key
    const wasEnterKeyTriggered = event.key === 'Enter';
    // Assume it's a scanner if time between keystrokes is very short
    // If true - the scanner input will be defined
    // And the Enter key was pressed
    return timeBetweenKeystrokes < KEY_PRESS_THRESHOLD && wasEnterKeyTriggered;
  };

  const showToast = (message, type) => {
    addToast(message, { appearance: type });
  };

  const handleBarcodeError = error => {
    console.error(`${error.name}:`, error);
    const defaultErrorMessage =
      'The scanned Barcode is incorrect. For continuation, please ensure to scan the correct one.';
    handleError(new Error(error.message || defaultErrorMessage));
  };

  const handleResetBarcodeState = (
    shouldResetBarcodeState = true,
    barcodeValue = '',
    lastScannedValue
  ) => {
    setScannedBarcodeValue(barcodeValue);
    if (shouldResetBarcodeState) {
      setScannerState(BARCODE_SCANNER_STATES.ready);
    }
    if (lastScannedValue) {
      setLastScannedBarcodeValue(lastScannedValue);
    }
  };

  const handleRedirectToDecodedResource = data => {
    try {
      const url = new URL(data);

      if (!isValidUrl(url)) {
        handleBarcodeError(new Error('The Barcode decoded output (URL) is incorrect.'));
        console.error(`The Barcode decoded output (URL) is incorrect: ${url.href}`);
        return;
      }

      console.info(`Scanned url ${url.href}`);

      const { searchParams } = url;
      const resourceUri = searchParams.get('resource');
      const resourceName = resourceUri ? getResourceName(resourceUri) : null;

      // Validate scanned entity to be the one that was requested
      if (allowedEntityType) {
        if (allowedEntityType !== resourceName) {
          handleBarcodeError(
            new Error(`Scanned Barcode is not a ${API_RESOURCES_MAP[allowedEntityType]}`)
          );
          return;
        }
      }

      setBarcodeError(false);
      handleScan(url, resourceName, resourceUri);
    } catch (error) {
      handleBarcodeError(error);
    }
  };

  const handleDecodeAndRedirect = value => {
    // Try to decode the Encoded Barcode Value. Assuming it is correct as we already checked it.
    const decodedBarcodeValue = decodeBarcodeUrl(value);

    if (!decodedBarcodeValue) {
      setScannedBarcodeValue('');
      return handleBarcodeError(new Error('The Barcode value you scanned was not a valid part.'));
    }

    console.info(`Decoded Barcode value ${decodedBarcodeValue}`);
    // If decoded value is fine, next step to check the resource and redirect.
    return handleRedirectToDecodedResource(decodedBarcodeValue);
  };

  const handleBarcodeScan = event => {
    clearTimeout(barcodeScanTimeoutRef.current);
    const currentBarcodeValue = event.target.value;

    if (scannerState !== BARCODE_SCANNER_STATES.idle) {
      // If the scanner is not idle, set it to idle and focus the input
      // Otherwise, leave the "Idle Status" until the user scan something again.
      setScannerState(BARCODE_SCANNER_STATES.ready);
    }

    if (!currentBarcodeValue) {
      // Nothing was scanned or manually typed, empty value.
      return;
    }

    // Once we received the value in the input, we should check if it's correct.
    const encodedValueCorrect = handleCheckBarcodeEncodedValue(event.target.value);

    if (!encodedValueCorrect) {
      // Encoded value is wrong, show the error and reset the Scanner state.
      handleResetBarcodeState(true, '', event.target.value);
      return handleBarcodeError(new Error('The Barcode value you scanned was not a valid part.'));
    }

    // If there was an error, reset it. And process the correct Scanner state.
    setBarcodeError(false);
    handleResetBarcodeState(true, currentBarcodeValue);

    barcodeScanTimeoutRef.current = setTimeout(() => {
      // Get the full barcode value (debounced)
      console.info('Scanned Barcode Value:', currentBarcodeValue);

      // Try to decode the Encoded Barcode Value. Assuming it is correct as we already checked it.
      handleDecodeAndRedirect(currentBarcodeValue);
      // Clear the barcode and input for the next scan
      handleResetBarcodeState(false, '', '');
    }, BARCODE_SCAN_INPUT_DELAY);
  };

  const fetchSingleResource = async resource => {
    try {
      const resourceQueryBuilder = {
        [API_RESOURCES.SHIPMENT]: {
          'filter[uri]': `${config.apiHost}/${API_RESOURCES.SHIPMENT}/${scannedBarcodeValue}/`,
        },
        default: { 'multicolumn_search[uuid]': scannedBarcodeValue },
      };

      const { resources } = await api
        .get(`${resource}/`, {
          searchParams: resourceQueryBuilder[resource] || resourceQueryBuilder.default,
        })
        .json();

      return { resources, initiator: resource };
    } catch (error) {
      setBarcodeError(new Error(error.message));
    }
  };

  const handleUUIDScan = async () => {
    // Assuming the user typed the UUID manually.
    // Set the button state before scanning.
    setState(SCAN_BUTTON_STATES.loading);
    setBarcodeError(false);

    for (const resource of resourceLookupData) {
      const data = await fetchSingleResource(resource);
      if (data.resources?.length === 1) {
        return processResources(data.resources, data.initiator);
      }
    }
    return null;
  };

  const handleSuccessfulScan = (resource, resourceName) => {
    handleScan(null, resourceName || API_RESOURCES.MATERIAL_BATCH, resource.uri, resource);
    setState(SCAN_BUTTON_STATES.success);
  };

  const processResources = (resources, resourceName) => {
    if (resources.length === 1) {
      handleSuccessfulScan(resources[0], resourceName);
      return;
    }
    // Multiple or zero resources are found, reset input to initial state
    setState(SCAN_BUTTON_STATES.initialized);
    handleMultipleOrNoResourcesFound(resources.length);
  };

  const handleMultipleOrNoResourcesFound = resourceCount => {
    if (resourceCount === 0) {
      showToast(`Resource with uuid "${scannedBarcodeValue}" is not found`, 'error');
    } else if (resourceCount > 1) {
      showToast('Multiple resources are found. Provide more accurate ID', 'error');
    }
  };

  // This means instead of scanning something via the Scanner, the user decided to type it manually.
  const handleManualInputScan = async () => {
    // Check if the user typed the actual Barcode Encoded Value.
    const isEncodedManualValueCorrect = handleCheckBarcodeEncodedValue(scannedBarcodeValue);
    // Or if the user typed the Full UUID manually.
    const isUUIDFormat = isUUID(scannedBarcodeValue);
    // Or Short UUID.
    const isShortUUIDFormat = isShortUUID(scannedBarcodeValue);

    // Handle a valid UUID or short UUID format but encoded value incorrect
    if ((isUUIDFormat || isShortUUIDFormat) && !isEncodedManualValueCorrect) {
      return handleUUIDScan();
    }

    // Handle an incorrect UUID or short UUID format + their lengths
    if (
      ((scannedBarcodeValue.length === SHORT_UUID_LENGTH && !isShortUUIDFormat) ||
        (scannedBarcodeValue.length === FULL_UUID_LENGTH && !isUUIDFormat)) &&
      !isEncodedManualValueCorrect
    ) {
      handleResetBarcodeState(false, '', scannedBarcodeValue);
      return handleBarcodeError(
        new Error('The material batch ID you scanned was not a valid part.')
      );
    }

    // Handle other cases where the encoded value is incorrect
    if (!isEncodedManualValueCorrect) {
      handleResetBarcodeState(false, '', scannedBarcodeValue);
      return handleBarcodeError(new Error('The Barcode value you scanned was not a valid part.'));
    }

    // Encoded value was correct, next step to check the resource and redirect.
    return handleDecodeAndRedirect(scannedBarcodeValue);
  };

  const handleScanBarcodeOutput = event => {
    // Check whether it was the scanner action or manual input.
    const isScannerInput = handleCheckTypeOfInput(event);

    if (isScannerInput) {
      // Handle scanner input logic.
      return handleBarcodeScan(event);
    }
    // Handle manual input logic.
    return setScannedBarcodeValue(event.target.value);
  };

  const handleGlobalBarcodeScan = event => {
    // Check if the user used the scanner.
    const isBarcodeScan = handleCheckTypeOfInput(event);

    if (isBarcodeScan) {
      // Input Value for the scanner is not focused
      if (document.activeElement !== barcodeInputRef.current) {
        // Set scanner state to idle and focus the input if it's not already focused
        setScannerState(BARCODE_SCANNER_STATES.idle);
        // Set the focus back to the input
        focusBarcodeInput();
      }
    }
  };

  // Action to set the focus back on "Click here to scan" button.
  const handleManualFocus = () => {
    focusBarcodeInput();
    setActiveScannerState(BARCODE_SCANNER_STATES.ready);
    setScannedBarcodeValue('');
  };

  useEffect(() => {
    if (resourceError) {
      setScannerState(BARCODE_SCANNER_STATES.inactive);
      handleResetBarcodeState(false, '', '');
    }
  }, [resourceError]);

  useEffect(() => {
    const handleKeyPress = event => {
      // Focus the input if it is not focused initially.
      checkIfBarcodeInputIsNotFocused();
      // Handle the global barcode scan.
      handleGlobalBarcodeScan(event);
    };

    document.addEventListener('keypress', handleKeyPress);
    focusBarcodeInput();

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return (
    <>
      <Header scan title='Scan Barcode' user={user} />

      <main role='main'>
        <div className='barcode-reader text-white bg-dark rounded-sm overflow-hidden mb-3'>
          <Barcode hidden={successfulScan} scannerState={scannerState}>
            <Loader inline text='data' className='mt-3' />
          </Barcode>
        </div>

        <BarcodeStatusAlert
          scannerState={scannerState}
          successfulScan={successfulScan}
          focusInput={handleManualFocus}
        />

        {!successfulScan && (
          <section className='barcode-input'>
            <label>Barcode Output</label>
            <div className='input-group mb-3'>
              <input
                ref={barcodeInputRef}
                type='text'
                className='form-control'
                value={scannedBarcodeValue}
                aria-label='Barcode value'
                placeholder={placeholder}
                disabled={isSearchDisabled}
                onChange={handleScanBarcodeOutput}
                onKeyUp={handleScanBarcodeOutput}
                onBlur={checkIfBarcodeInputIsNotFocused}
                onClick={setActiveScannerState}
              />
              <div className='input-group-append'>
                <ScanButton state={state} isLoading={isLoading} onSubmit={handleManualInputScan} />
              </div>
            </div>
          </section>
        )}

        {barcodeError && (
          <Alert name='qr-error' variant='danger' className='text-center'>
            {barcodeError.message}
            {lastScannedBarcodeValue && (
              <>
                <br />
                <small>
                  <strong>Last scanned value:</strong> {lastScannedBarcodeValue}
                </small>
              </>
            )}
          </Alert>
        )}
        {!barcodeError && showInstructions && (
          <Alert name='qr-instructions' variant='info' className='text-center'>
            <span>{instructionText}</span>
          </Alert>
        )}
        {renderUseWithoutPermanentContainer()}
      </main>
    </>
  );
};

BarcodeScan.propTypes = {
  user: userPropType.isRequired,
  allowedEntityType: PropTypes.string,
  requestedEntity: PropTypes.string,
  successfulScan: PropTypes.bool.isRequired,
  handleScan: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  barcodeError: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]).isRequired,
  showInstructions: PropTypes.bool.isRequired,
  instructionText: PropTypes.string.isRequired,
  setBarcodeError: PropTypes.func.isRequired,
  resourceError: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]).isRequired,
  renderUseWithoutPermanentContainer: PropTypes.func.isRequired,
};

BarcodeScan.defaultProps = {
  allowedEntityType: null,
  requestedEntity: null,
};

export default BarcodeScan;
