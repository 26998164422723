import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import Modal from 'src/components/modal';
import NotFound from 'src/components/not-found';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import { api } from 'src/utils/api';
import { API_RESOURCES, PERMANENT_CONTAINER_ACTIONS } from 'src/utils/constants';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { checkSupportedMaterialsMismatch, hasLocationsMismatch } from 'src/utils/validation';

import routes from '../../../utils/routes';
import PermanentContainerActionPageWrapper from './action/_action-wrapper';


const AlreadyLoadedPage = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: permanentContainerUUID } = useParams();
  const { batch: batchUri, batchAction } = Object.fromEntries(searchParams.entries()) ?? {};
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [showRelocateModal, setShowRelocateModal] = useState(false);
  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(null);
  const [pageState, setPageState] = useState({
    id: permanentContainerUUID,
    permanentContainer: null,
    loadedBatch: null,
    batchToLoad: null,
    locations: null,
    subLocations: null,
  });

  const navigate = useNavigate();

  const fetchInitialData = async (permanentContainerUUID) => {
    setLoading(true);
    try {
      const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${permanentContainerUUID}/`).json();
      const batchToLoad = await api.get(batchUri, { prefixUrl: false }).json();
      const loadedBatch = await api.get(permanentContainer.current_batch, { prefixUrl: false }).json();


      const subLocationsUris = permanentContainer && batchToLoad ?
        [...new Set([permanentContainer.sub_location, batchToLoad.sub_location])] :
        [];

      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[uri]': subLocationsUris.join(','),
        },
      }).json();

      const { resources: locations } = await api.get(`${API_RESOURCES.LOCATION}/`, {
        searchParams: {
          'filter[uri]': permanentContainer.location,
        },
      }).json();


      setPageState({
        id: permanentContainerUUID,
        permanentContainer,
        // Backend allows not more than 1 batch loaded at a time. Using [0] to get that
        loadedBatch: loadedBatch || {},
        batchToLoad,
        locations,
        subLocations,
      });
    } catch (error) {
      if (error.name === 'HTTPError' && error.response.status === 404) {
        setError({ name: error.name, status: error.response && error.response.status });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData(permanentContainerUUID);
  }, [permanentContainerUUID]);

  const hasDifferentLocations = hasLocationsMismatch(
    pageState.batchToLoad?.location,
    pageState.permanentContainer?.location
  );

  if (error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={pageState.id} />
        </main>
      </>
    );
  }

  const onActionRelocate = async (permanentContainerUri, locationUri, subLocationUri) => {
    try {
      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          destination_location: locationUri,
          destination_sub_location: subLocationUri,
        },
        action_type: PERMANENT_CONTAINER_ACTIONS.RELOCATE,
        source_material_container: permanentContainerUri,
      };

      setSubmitting(true);
      await api.post('material-container-action/', {
        json: payload,
      }).json();
    } catch (error_) {
      console.error(error_);
      setSubmitError(true);
      setSubmitting(false);
    }

  };


  const unsupportedMaterials = checkSupportedMaterialsMismatch(pageState.batchToLoad, pageState.permanentContainer);

  const onActionTopOff = async (currentlyLoadedBatchUri, batchToLoadUri, permanentContainerUri) => {
    try {
      const payload = {
        /* eslint-disable camelcase */
        action_type: PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
        source_material_container: permanentContainerUri,
        quantity: pageState.batchToLoad.quantity,
        source_batch: batchToLoadUri,
        metadata: {},
      };

      setSubmitting(true);
      await api.post('material-container-action/', {
        json: payload,
      });
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      return;
    }

    setSubmitting(false);

    navigate(getRouteURI(routes.permanentContainerSuccess,
      { uuid: permanentContainerUUID },
      {
        action: PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
        initialBatchUUID: getUuid(batchUri),
        batchAction,
      }));

    return new Promise(() => {
    });
  };

  const handleSubmitPermanentContainerTopOff = () => {
    if (hasDifferentLocations) {
      setShowRelocateModal(true);
      return;
    }

    return onActionTopOff(pageState.loadedBatch.uri, pageState.batchToLoad.uri, pageState.permanentContainer.uri);
  };

  const onHideLocationModalWarning = () => setShowRelocateModal(false);


  const onModalConfirm = async () => {
    await onActionRelocate(pageState.permanentContainer.uri, pageState.batchToLoad?.location, pageState.batchToLoad?.sub_location);
    return onActionTopOff(pageState.loadedBatch.uri, pageState.batchToLoad.uri, pageState.permanentContainer.uri);
  };

  const renderLocationMismatchText = () => {
    const batchSubLocation = pageState.subLocations.find(
      (subLocation) => subLocation.uri === pageState.batchToLoad.sub_location
    );
    const permanentContainerSubLocation = pageState.subLocations.find(
      (subLocation) => subLocation.uri === pageState.permanentContainer.sub_location
    );
    const permanentContainerLocation = pageState.locations.find(
      (location) => location.uri === pageState.permanentContainer.location
    );
    const permanentContainerSubLocationName = permanentContainerSubLocation?.name ? `(${permanentContainerSubLocation.name}).` :
    <strong>[Archived].</strong>;
    const batchSubLocationName = batchSubLocation?.name ? `(${batchSubLocation.name}).` : <strong>[Archived].</strong>;

    return (

      <div>
        <p>You are trying to <strong>Top Off Material</strong> but there is a Locations mismatch.</p>
        <p>The Permanent
          Container <strong>{pageState.permanentContainer.name}</strong> is
          in <strong>{permanentContainerLocation?.name}</strong> / <strong>{permanentContainerSubLocationName}</strong> and
          the Batch <strong>({getShortUuid(pageState.batchToLoad.uri)})</strong> is
          in <strong>{pageState.batchToLoad.location_name}</strong> / <strong>{batchSubLocationName}</strong>
        </p>
        <p>Before Top Off Material, you can relocate the entire Batch using the batch page or relocate the Permanent
          Container now to match the Batch location.
        </p>
      </div>
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !pageState.permanentContainer) {
    return <NotFound id={permanentContainerUUID} />;
  }

  if (error || !pageState.loadedBatch?.uri || !pageState.batchToLoad?.uri) {
    return (
      <>
        <Header title="Not Found" user={user} />
        <main role="main" className="text-center">
          <NotFound id={permanentContainerUUID} text={error} />
        </main>
      </>
    );
  }

  return (
    <PermanentContainerActionPageWrapper
      id={pageState.id}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={PERMANENT_CONTAINER_ACTIONS.TOP_OFF}
    >
      <h1 className="header-margin">
        <p style={{ whiteSpace: 'pre-wrap' }} className="badge badge-info">{pageState.permanentContainer?.name}</p>
        <p>is already loaded</p>
      </h1>
      <h4 className="header-margin">
        Our system indicates that this Permanent Container is already loaded with&nbsp;
        <span className="badge badge-secondary header-margin">
          {getShortUuid(pageState.loadedBatch.uri)}
        </span>.
        <p>Would you like to top off&nbsp;
          <span className="badge badge-secondary">
            {getShortUuid(pageState.batchToLoad.uri)}
          </span>?
        </p>
      </h4>
      <div className="row btn-group">
        <div className="optionButtons">
          <button
            type="submit"
            className="btn btn-primary btn-action mr-1"
            disabled={!!unsupportedMaterials.length || isSubmitting}
            onClick={handleSubmitPermanentContainerTopOff}
          >
            <p className="d-flex align-items-center justify-content-center mb0">
              Confirm
              {unsupportedMaterials.length ? (
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip id="workstation-is-overloaded">
                      Permanent Container &#39;{pageState.permanentContainer?.name}&#39; does not support&nbsp;
                      {unsupportedMaterials.length > 1 ? 'materials' : 'material'}&nbsp;
                      {unsupportedMaterials.map((material) => material).join(', ')}
                    </Tooltip>)}
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} className="d-block ml-2 warning-color" />
                </OverlayTrigger>
              ) : null}
              {isSubmitting && <Loader inline className="spacer-left" showText={false} />}
            </p>
          </button>
        </div>
        <div className="optionButtons">
          <Link to={getRouteURI(routes.permanentContainerDetails, { uuid: pageState.id })}>
            <button type="button" disabled={isSubmitting} className="btn btn-default btn-action">
              Cancel
            </button>
          </Link>
        </div>
      </div>

      <Modal
        isOpen={showRelocateModal}
        title="Locations Mismatch"
        dismissText="Cancel"
        confirmText="Relocate Permanent Container"
        onDismiss={onHideLocationModalWarning}
        onConfirm={onModalConfirm}
      >
        {renderLocationMismatchText()}
      </Modal>
    </PermanentContainerActionPageWrapper>
  );
};

AlreadyLoadedPage.propTypes = {
  user: userPropType,
};

AlreadyLoadedPage.defaultProps = {
  user: null,
};

export default AlreadyLoadedPage;
