import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RangeInputConversionTooltip from 'src/components/RangeInputConversionTooltip';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import { api } from 'src/utils/api';
import { API_RESOURCES, MATERIAL_BATCH_ACTIONS } from 'src/utils/constants';
import { convertToUserUnits } from 'src/utils/conversions';
import routes from 'src/utils/routes';
import { formatConvertedUnits, renderTotalQuantityTitle } from 'src/utils/ui';
import useQueryParams, { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import {
  formatDynamicPercentage,
  getInputStep,
  handleConvertedQuantityChange, handleConvertedRangeChange,
  validateMultipleZeros,
} from 'src/utils/validation';

import ActionPage from './_action-wrapper';

export const formatUnloadQuantityInMachineNumber = (number) => {
  if (!number || isNaN(number)) return 0;
  if (number % 1 === 0) { // Check if it's an integer
    // If it is an integer, return it as a string without decimal places
    return number.toString();
  } else {
    // If it's not an integer, return it as a string with three decimal places
    return number?.toFixed(3);
  }
};

export const renderRemainingValueClass = (remainingQuantity) => {
  if (remainingQuantity === 0) {
    return 'text-success';
  }

  if (remainingQuantity < 0) {
    return 'text-danger';
  }

  return 'text-warning';
};

export const preventNegativeOnKeyDownValue = (event) => {
  if (event.key === '-') {
    event.preventDefault();
  }
};

const MachineUnloadHopper = ({ user }) => {
  const { uuid: batchUUID } = useParams();
  const { actionFromPrinter } = useQueryParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(null);
  const [batch, setBatch] = useState(null);
  const [unloadHopperValueBase, setUnloadHopperValueBase] = useState(0);
  const [unloadHopperValueConverted, setUnloadHopperValueConverted] = useState(0);
  const [subLocation, setSubLocation] = useState(undefined);

  const navigate = useNavigate();

  const { quantity: convertedBatchQuantity, units: convertedBatchUnits, isConverted } =
    useMemo(() => {
      if (batch) {
        return convertToUserUnits(batch.quantity, batch.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [batch]);

  const getInitialData = async () => {
    setLoading(true);
    try {
      const batchData = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();
      const subLoc = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batchData.sub_location)}/`).json();
      setBatch(batchData);
      setSubLocation(subLoc);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, [batchUUID]);

  const handleUnloadHopperValueChange = (e) => {
    handleConvertedQuantityChange({
      e,
      setBaseQuantity: setUnloadHopperValueBase,
      setConvertedQuantity: setUnloadHopperValueConverted,
      batchQuantity: batch.quantity,
      batchUnits: batch.units,
      convertedBatchQuantity,
      convertedBatchUnits,
      validateMultipleZeros,
    });
  };

  const handleRangeChange = (value) => {
    handleConvertedRangeChange({
      value,
      baseUnits: batch.units,
      baseQuantity: batch.quantity,
      convertedQuantity: convertedBatchQuantity,
      convertedUnits: convertedBatchUnits,
      setQuantityBaseHandler: setUnloadHopperValueBase,
      setQuantityConvertedHandler: setUnloadHopperValueConverted,
    });
  };

  const remainingQuantity = batch?.quantity - unloadHopperValueBase;
  const remainingQuantityConverted = convertedBatchQuantity - unloadHopperValueConverted;
  const remainingPercentage = formatDynamicPercentage(remainingQuantity, batch?.quantity);

  const onActionUnloadedHopper = async (batchUri, value) => {
    let actionResult = null;
    try {
      const payload = {
        /* eslint-disable camelcase */
        source_batch: batchUri,
        action_type: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER,
        metadata: {
          unload_amount: value,
        },
      };
      setSubmitting(true);
      actionResult = await materialActionRequest(payload).json();
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    const { metadata: { resulting_batch: unloadedBatch } } = actionResult;

    navigate(
      getRouteURI(
        routes.materialBatchSuccess,
        { uuid: getUuid(unloadedBatch) },
        {
          action: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER,
          batch: getUuid(unloadedBatch),
          quantity: value,
          remainingQuantity,
          subLocation: subLocation.name,
          printer: batch.at_machine,
          ...(actionFromPrinter && { actionFromPrinter }),
        }
      )
    );
  };

  const materialActionRequest = (payload) => {
    return api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
      json: payload,
    });
  };

  return (
    <ActionPage
      id={batchUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER}
      isLoading={isLoading}
    >
      {batch && (
        <>
          <div className="alert alert-info" role="alert">
            <b>
              Please specify unused amount to remove. Unused material will not receive an additional Usage Cycle.
            </b>
          </div>

          {renderTotalQuantityTitle(
            remainingQuantity,
            remainingQuantityConverted,
            batch?.units,
            convertedBatchUnits,
            isConverted,
          )}

          <div className="alert">
            <div>
              <label>Quantity:</label>
              &nbsp;
              <input
                name="unloadHopperValueConverted"
                min="0"
                className="resource-input-field"
                max={convertedBatchQuantity}
                type="number"
                step={getInputStep(convertedBatchQuantity)}
                value={unloadHopperValueConverted}
                onChange={handleUnloadHopperValueChange}
                onKeyDown={preventNegativeOnKeyDownValue}
              />
              &nbsp;
              <span>
                {convertedBatchUnits}
                <span>&nbsp;({remainingPercentage || 0}%)</span>
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-center mt15">
              <div>
                <div className="mb5">0 ({batch?.units})</div>
                <div>{isConverted && formatConvertedUnits(0, convertedBatchUnits)}</div>
              </div>
              <div className="position-relative">
                <RangeInputConversionTooltip
                  className="rangeInputAbsolute"
                  visible={isConverted}
                  defaultUnits={batch?.units || ''}
                  convertedUnits={convertedBatchUnits}
                />
                &nbsp;
                <input
                  name="unloadHopperRange"
                  min="0"
                  step={getInputStep(batch?.quantity, true)}
                  max={batch?.quantity}
                  type="range"
                  value={unloadHopperValueBase}
                  onChange={(e) => handleRangeChange(e.target.value)}
                />
                &nbsp;
              </div>
              <div>
                <div className="mb5">
                  {batch?.quantity} ({batch?.units})
                </div>
                <div>
                  {isConverted && formatConvertedUnits(convertedBatchQuantity, convertedBatchUnits)}
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary btn-block"
            disabled={
              isSubmitting ||
              unloadHopperValueBase <= 0 ||
              unloadHopperValueBase > batch?.quantity
            }
            onClick={() => onActionUnloadedHopper(batch.uri, unloadHopperValueBase)}
          >
            Save
          </button>
        </>
      )}
    </ActionPage>
  );
};

MachineUnloadHopper.propTypes = {
  user: userPropType,
};

MachineUnloadHopper.defaultProps = {
  user: null,
};

export default MachineUnloadHopper;
