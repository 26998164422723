import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { api } from 'src/utils/api';
import { API_RESOURCES, API_RESOURCES_MAP } from 'src/utils/constants';
import { formatList } from 'src/utils/stringUtils';
import { getResourceUri } from 'src/utils/url';

const ResourceLookupField = ({ resourceName, onScan, successfulScan }) => {
  /*
   * Is used to type manually uuid instead of scanning QR code.
   *
   * Uses ?search[uuid] to find uri of resource by uuid
   *
   * Calls onScan() callback with the same format as QR scanner:
   * onScan(null, resourceName, uri)
   * - null value is related to old uri resource link format, which is used for legacy fields
   * - `resourceName` resource from props
   * - `uri` - uri of resource
   */

  const [searchString, setSearchString] = useState('');

  const { addToast } = useToasts();

  const states = {
    initialized: 'initialized',
    loading: 'loading',
    success: 'success',
  };

  const [state, setState] = useState(states.initialized); // any value from "states"

  if (!Array.isArray(resourceName)) {
    resourceName = [resourceName];
  }

  const isLoading = state === states.loading;
  const isSearchDisabled = [states.loading, states.success].includes(state);
  const getPlaceholderName = () => {
    const allowedResources = [
      ...new Set(
        resourceName.map(resource => API_RESOURCES_MAP[resource] || _capitalize(resource))
      ),
    ];
    return formatList(allowedResources, 'disjunction');
  };

  const resourcePlaceholderName = getPlaceholderName();
  const placeholder = `Enter ${resourcePlaceholderName} ID`;

  useEffect(() => {
    if (!successfulScan) {
      setState(states.initialized);
    }
  }, [successfulScan]);

  const fetchSingleResource = async resource => {
    try {
      const resourceQueryBuilder = {
        [API_RESOURCES.SHIPMENT]: {
          'filter[uri]': getResourceUri(API_RESOURCES.SHIPMENT, searchString),
        },
        default: { 'multicolumn_search[uuid]': searchString },
      };

      const { resources } = await api
        .get(`${resource}/`, {
          searchParams: resourceQueryBuilder[resource] || resourceQueryBuilder.default,
        })
        .json();

      return { resources, initiator: resource };
    } catch (error) {
      console.error(`Error fetching resource ${resource}:`, error);
      addToast(`Error: ${error.message}`, { appearance: 'error' });
    }
  };

  const fetchResources = async () => {
    for (const resource of resourceName) {
      const data = await fetchSingleResource(resource);
      if (data.resources?.length === 1) {
        return data;
      }
    }
    return null;
  };

  const onSubmit = async () => {
    if (!searchString) {
      addToast('Please enter resource ID', { appearance: 'error' });
      return;
    }

    setState(states.loading);

    const data = await fetchResources();
    const resources = data?.resources || [];
    const initiator = data?.initiator;

    if (resources.length === 1) {
      const foundResource = resources[0];
      onScan(null, initiator, foundResource.uri, foundResource);

      // TODO You may need to revert it to "initial state" and reset input state,
      //  but now its expected to scan only one resource and app will redirect to another route
      setState(states.success);
      return;
    }

    // Multiple or zero resources are found, reset input to initial state
    setState(states.initialized);

    if (resources.length === 0) {
      addToast(`Resource with uuid "${searchString}" is not found`, { appearance: 'error' });
    }
    if (resources.length > 1) {
      addToast('Multiple resources are found. Provide more accurate ID', { appearance: 'error' });
    }
  };

  const Button = () => {
    let text;
    let className = 'btn-outline-primary'; // bootstrap button class name

    switch (state) {
      case states.loading:
        text = (
          <>
            <div
              className='spinner-border spinner-border-sm mr-1'
              role='status'
              aria-hidden='true'
            />
            Loading...
          </>
        );
        break;
      case states.success:
        className = 'btn-outline-success';
        text = (
          <>
            <FontAwesomeIcon icon={faCheck} className='mr-1' />
            Scanned
          </>
        );
        break;
      default:
        text = <>Search</>;
    }

    return (
      <button
        className={`btn ${className}`}
        type='button'
        id='submit-uuid'
        disabled={isLoading}
        onClick={onSubmit}
      >
        {text}
      </button>
    );
  };

  return (
    <div className='input-group mb-3'>
      <input
        type='text'
        className='form-control'
        placeholder={placeholder}
        value={searchString}
        aria-label='ID field'
        disabled={isSearchDisabled}
        onChange={event => setSearchString(event.target.value)}
      />
      <div className='input-group-append'>
        <Button />
      </div>
    </div>
  );
};

ResourceLookupField.propTypes = {
  resourceName: PropTypes.arrayOf(PropTypes.oneOf(Object.values(API_RESOURCES))).isRequired,
  onScan: PropTypes.func.isRequired,
  successfulScan: PropTypes.bool.isRequired,
};

export default ResourceLookupField;
