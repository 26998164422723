import { faArrowUpShortWide, faObjectGroup } from "@fortawesome/free-solid-svg-icons";
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import BatchPowderQualityStatusIcon from "src/components/BatchPowderQualityStatusIcon";
import ConvertedUnits from "src/components/ConvertedUnits";
import StatusColorCode from "src/components/status-color-dot";
import TruncatedTitle from "src/components/TruncatedTitle";
import { useBatch } from 'src/hooks/services/useBatch';
import { MATERIAL_BATCH_STATUS_VERBOSE, STATUS_COLOR_CODE_TYPES } from "src/utils/constants";
import routes from "src/utils/routes";
import { getRouteURI, getShortUuid, getUuid } from "src/utils/url";

const LPSDoseModule = ({ module, isLoading }) => {

  const doseBatchUri = module?.current_batch;

  const { data: batch, isInitialLoading: isBatchLoading } = useBatch(doseBatchUri, 'lps-dose-batch');

  if (isLoading || isBatchLoading) {
    return <Spinner animation="grow" />;
  };

  // Batch is required for Dose
  if (!batch) return null;

  return (
    <div className="lps-module-card-dose">
      <div>
        <div className="mb15 text-left">
          <div className="lps-module-card-resource">
            <TruncatedTitle
              title={module.name}
              titleClassName='mb0'
              maxTextLength={50}
              maxTextLengthMobile={40}
            />
          </div>
          <p className="mb0 font-light">({getShortUuid(module.uri)})</p>
        </div>
        <div className="mb15 text-left">
          <div className="lps-module-card-resource">Batch</div>
          <Link
            className="batch-card-main-details-main-link d-flex align-items-center font-size-14"
            to={getRouteURI(routes.materialContainer, {}, { batch: getUuid(batch.uri) })}
          >
            <FontAwesomeIcon icon={faExternalLink} className="spacer-right" />
            <p className="mb0 font-light">{getShortUuid(batch.uri)}</p>
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <StatusColorCode
              status={batch.status}
              type={STATUS_COLOR_CODE_TYPES.BATCH_STATUS}
              sizeClassName="small"
            />
            <p className="mb0 spacer-left text-white">
              {MATERIAL_BATCH_STATUS_VERBOSE[batch.status]}
            </p>
          </div>
          {batch.powder_quality && (
            <div className="spacer-left">
              <BatchPowderQualityStatusIcon smaller qualityStatus={batch.powder_quality} />
            </div>
          )}
        </div>
      </div>
      <div className="lps-module-card-dose-right-content">
        <div className="lps-module-card-dose-quantity">
          <FontAwesomeIcon className="spacer-right" icon={faArrowUpShortWide} />
          <ConvertedUnits quantity={batch.quantity} units={batch.units} />
        </div>
        <div className="lps-module-card-dose-materials">
          <FontAwesomeIcon className="spacer-right" icon={faObjectGroup} />
          <TruncatedTitle
            inline
            title={batch.materials[0].name}
            titleClassName="mb0"
            maxTextLength={25}
            maxTextLengthMobile={20}
          />
        </div>

      </div>
    </div>
  );
};

LPSDoseModule.propTypes = {
  /* eslint-disable camelcase */
  module: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
    current_batch: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LPSDoseModule;
