import { faCoins, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import BatchMultipleContainersAlert from 'src/components/batch-multiple-containers-alert';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Loader from 'src/components/loader';
import LocationsMismatch from 'src/components/LocationsMismatch';
import Modal from 'src/components/modal';
import NotFound from 'src/components/not-found';
import RangeInputConversionTooltip from 'src/components/RangeInputConversionTooltip';
import useSelectedAmountHandler from 'src/hooks/useSelectedAmountHandler';
import useSieveFlow from 'src/hooks/useSieveFlow';
import useUnsupportedMaterials from 'src/hooks/useUnsupportedMaterials';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import {
  fullContainerLoadAction,
  fullPrinterLoadAction, partialLoadContainerAction,
  partialPrinterLoadAction,
  relocateContainerAction, sieveAndLoadPartialContainerAction, sieveFullContainerAndLoad,
} from 'src/utils/actionsAPIUtils';
import {
  renderActionColumnResourceBody,
  renderBatchPowderQualityIcon,
  renderUnsupportedMaterialsText,
} from 'src/utils/actionsUtils';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES,
  PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import { convertToUserUnits, formatThreeDecimalsNumber } from 'src/utils/conversions';
import routes from 'src/utils/routes';
import { getContainerName } from 'src/utils/stringUtils';
import { formatConvertedUnits } from 'src/utils/ui';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { getInputStep, hasLocationsMismatch } from 'src/utils/validation';

import PermanentContainerActionPageWrapper from './_action-wrapper';

const SplitContainer = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: sourceContainerUUID } = useParams();
  const {
    // container -> destination, source is permanentContainerUUID
    destinationContainer: container,
    printer,
    containerAction,
  } = Object.fromEntries(searchParams.entries()) ?? {};
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [showRelocateModal, setShowRelocateModal] = useState(false);
  const [sourceContainerBatch, setSourceContainerBatch] = useState(null);
  const [destinationContainer, setDestinationContainer] = useState({});
  const [destinationPrinter, setDestinationPrinter] = useState(null);
  const [destinationPrinterBatch, setDestinationPrinterBatch] = useState(null);
  const [destinationPrinterType, setDestinationPrinterType] = useState(null);
  const [sourceContainer, setSourceContainer] = useState(null);
  const [destinationContainerBatch, setDestinationContainerBatch] = useState(null);
  const [locations, setLocations] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { addToast } = useToasts();

  const isDestinationResourceLoaded = !_isEmpty(destinationContainer) ? destinationContainer.current_batch : destinationPrinterBatch;
  const destinationResource = printer ? destinationPrinter : destinationContainer;
  const destinationResourceBatch = printer ? destinationPrinterBatch : destinationContainerBatch;

  const didRelocateRef = useRef(false);

  const shouldWarnAboutBatchPowderQuality = !MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(destinationResourceBatch?.powder_quality)
    && MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(sourceContainerBatch?.powder_quality);

  const {
    checkIfSieveRequiredFirst,
    markSieveSuccess,
    didSieveRef,
    resetUserApproval,
  } = useSieveFlow();

  const { quantity: convertedSourceContainerQuantity, units: convertedBatchUnits, isConverted } =
    useMemo(() => {
      if (sourceContainer && sourceContainerBatch) {
        return convertToUserUnits(sourceContainer?.quantity, sourceContainerBatch.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [sourceContainer, sourceContainerBatch]);


  const {
    selectedAmountBase,
    selectedAmountConverted,
    handleSelectedAmountChange,
    handleRangeChange,
    fullQuantitySelected,
  } = useSelectedAmountHandler(
    sourceContainer?.quantity || 0,
    sourceContainerBatch?.units || '',
    convertedSourceContainerQuantity,
    convertedBatchUnits
  );

  const unsupportedMaterials = useUnsupportedMaterials(
    sourceContainerBatch,
    printer ? destinationPrinterType : destinationContainer,
    { isMultiple: false, isPrinter: !!printer }
  );

  const hasDifferentLocations = hasLocationsMismatch(
    sourceContainerBatch?.location,
    destinationResource?.location
  );

  const handleLoadAllClick = () => {
    if (!fullQuantitySelected) {
      // Make it "Load All"
      handleRangeChange(sourceContainer.quantity);
    } else {
      // Make it "Unload All" (reset to 0)
      handleRangeChange(0); // set selected amount to 0
    }
  };

  const getInitialData = async () => {
    setLoading(true);
    try {
      const sourceContainerData = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${sourceContainerUUID}/`).json();
      const sourceContainerBatchData = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(sourceContainerData.current_batch)}/`).json();

      const destinationPrinterData = printer ? await api.get(`${API_RESOURCES.PRINTER}/${getUuid(printer)}/`).json() : null;
      const destinationPrinterTypeData = destinationPrinterData ? await api.get(`${API_RESOURCES.PRINTER_TYPE}/${getUuid(destinationPrinterData.printer_type)}/`).json() : null;
      setDestinationPrinter(destinationPrinterData);
      setDestinationPrinterType(destinationPrinterTypeData);

      const { resources: batchesInPrinter } = printer ? await api.get(
        `${API_RESOURCES.MATERIAL_BATCH}/`,
        {
          searchParams: {
            'filter[at_machine]': `${API_RESOURCES.PRINTER}/${printer}/`,
          },
        },
      ).json() : {};

      setDestinationPrinterBatch(!_isEmpty(batchesInPrinter) ? batchesInPrinter[0] : null);

      const destinationContainerData = container ? await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${container}/`).json() : null;
      const destinationContainerBatchData = destinationContainerData?.current_batch
        && await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(destinationContainerData.current_batch)}/`).json();

      const destinationResource = destinationContainerData || destinationPrinterData;

      const subLocationsUris = destinationResource && sourceContainerBatchData ?
        [...new Set([destinationResource.sub_location, sourceContainerBatchData.sub_location])] :
        [];

      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[uri]': subLocationsUris.join(','),
        },
      }).json();

      const { resources: locations } = await api.get(`${API_RESOURCES.LOCATION}/`, {
        searchParams: {
          'filter[uri]': destinationResource.location,
        },
      }).json();

      setSourceContainer(sourceContainerData);
      setSourceContainerBatch(sourceContainerBatchData);
      setDestinationContainerBatch(destinationContainerBatchData);
      setDestinationContainer(destinationContainerData);
      setLocations(locations);
      setSubLocations(subLocations);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => void getInitialData(), [sourceContainerUUID]);


  const confirmAndSplit = async () => {
    // Check #1: Location mismatch
    if (!didRelocateRef.current && hasDifferentLocations) {
      setShowRelocateModal(true);
      return;
    }

    // Check #2: Check if we did not skip Sieve and we did not do Sieve yet + we have Powder Quality Warning

    await checkIfSieveRequiredFirst({
      shouldWarn: shouldWarnAboutBatchPowderQuality,
      action: PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER,
      isSubmitting,
    });


    // Reset user approval
    resetUserApproval();


    // If we reach here, all checks passed or resolved:
    await onActionSplit(sourceContainerBatch.uri);
  };


  const navigateToSuccessPage = (
    batchUri,
    splitQuantity,
  ) => {
    const routeParams = {
      action: PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER,
      ...(!destinationPrinter && { destinationContainerUUID: getUuid(destinationContainer?.uri) }),
      ...(destinationPrinter && { printer: printer }),
      ...(containerAction && { containerAction: true }),
      batch: getUuid(batchUri),
      quantity: splitQuantity,
    };

    navigate(getRouteURI(
      routes.permanentContainerSuccess,
      { uuid: sourceContainerUUID },
      routeParams
    ));
  };

  const onActionSplit = async (batchUri) => {
    try {
      setSubmitting(true);

      const isFullQuantitySelected = selectedAmountBase === sourceContainer.quantity;
      const sourceContainerBatchHasMultipleContainers = sourceContainerBatch.containers.length > 1;

      let splitQuantity = selectedAmountBase;

      if (isFullQuantitySelected) {
        splitQuantity = printer ? sourceContainer.quantity : sourceContainerBatch.quantity;
      }

      await executeSplitActions(batchUri, isFullQuantitySelected, sourceContainerBatchHasMultipleContainers);

      navigateToSuccessPage(
        batchUri,
        splitQuantity,
      );
    } catch (error) {
      addToast(`${error}`, { appearance: 'error' });
    } finally {
      setSubmitting(false);
    }
  };


  const handlePrinterSplitActions = async (batchUri, isFullQuantitySelected, shouldSieve) => {
    if (isFullQuantitySelected) {

      if (shouldSieve) {
        await sieveFullContainerAndLoad(
          sourceContainerBatch.uri,
          sourceContainer.uri,
          selectedAmountBase,
          null,
          destinationPrinter?.uri
        );
        markSieveSuccess();
      } else {
        await fullPrinterLoadAction(batchUri, sourceContainer.uri, destinationPrinter?.uri);
      }

    } else {

      if (shouldSieve) {
        await sieveAndLoadPartialContainerAction(
          true,
          sourceContainerBatch.uri,
          sourceContainer.uri,
          selectedAmountBase,
          isDestinationResourceLoaded,
          destinationPrinter?.uri,
          null,
          markSieveSuccess
        );
      } else {
        await partialPrinterLoadAction(
          sourceContainer.uri,
          batchUri,
          destinationPrinter?.uri,
          selectedAmountBase
        );
      }

    }
  };

  const handleContainerSplitActions = async (batchUri, isFullQuantitySelected, sourceContainerBatchHasMultipleContainers, shouldSieve) => {
    if (isFullQuantitySelected) {

      if (shouldSieve) {
        await sieveFullContainerAndLoad(
          sourceContainerBatch.uri,
          sourceContainer.uri,
          selectedAmountBase,
          destinationContainer?.uri,
        );
        markSieveSuccess();
      } else {
        await fullContainerLoadAction(
          batchUri,
          isDestinationResourceLoaded ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
          destinationContainer?.uri,
          sourceContainerBatch.quantity,
          sourceContainerBatchHasMultipleContainers,
          sourceContainer.uri,
        );
      }
    } else {
      if (shouldSieve) {
        await sieveAndLoadPartialContainerAction(
          false,
          sourceContainerBatch.uri,
          sourceContainer.uri,
          selectedAmountBase,
          isDestinationResourceLoaded,
          null,
          destinationContainer?.uri,
          markSieveSuccess
        );
      } else {
        await partialLoadContainerAction(
          sourceContainer.uri,
          batchUri,
          destinationContainer?.uri,
          selectedAmountBase
        );
      }
    }
  };


  const executeSplitActions = async (batchUri, isFullQuantitySelected, sourceContainerBatchHasMultipleContainers) => {
    const shouldSieve = shouldWarnAboutBatchPowderQuality && !didSieveRef.current;

    if (printer) {
      return handlePrinterSplitActions(batchUri, isFullQuantitySelected, shouldSieve);
    }

    return handleContainerSplitActions(
      batchUri,
      isFullQuantitySelected,
      sourceContainerBatchHasMultipleContainers,
      shouldSieve);
  };


  const hideRelocationWarningModal = () => setShowRelocateModal(false);

  const onActionRelocate = async () => {
    try {
      const destinationLocationUri = destinationResource.location;
      const destinationSubLocationUri = destinationResource.sub_location;
      const containerUriToRelocate = sourceContainer.uri;

      setSubmitting(true);
      await relocateContainerAction(
        destinationLocationUri,
        destinationSubLocationUri,
        containerUriToRelocate,
        sourceContainerBatch.uri
      );
    } catch (error_) {
      console.error(error_);
      setSubmitError(true);
      setSubmitting(false);
    }
  };

  const onModalConfirmRelocate = async () => {
    await onActionRelocate();
    setShowRelocateModal(false);
    setSubmitting(false);
    // Mark relocation as done immediately
    didRelocateRef.current = true;
    // Now re-run main confirm
    await confirmAndSplit();
  };

  const isNotSupportedByMaterials = unsupportedMaterials.length;

  const renderDestinationResourceExistingQuantity = () => {
    const destinationQuantity = printer ? destinationPrinterBatch?.quantity : destinationContainer?.quantity;
    const destinationUnits = printer ? destinationPrinterBatch?.units : destinationContainerBatch?.units;
    return (
      <div className="top-off-current-amount">
        <ConvertedUnits
          quantity={destinationQuantity}
          units={destinationUnits} />
      </div>
    );
  };

  const renderSourceResourceName = () => {
    const resourceLink = getRouteURI(routes.permanentContainerDetails, { uuid: getUuid(sourceContainer.uri) });
    const resourceName = getContainerName(sourceContainer);
    const isDisposableContainer = sourceContainer.disposable;
    const icon = (
      <FontAwesomeIcon icon={faCoins} className="spacer-right" />
    );

    return renderActionColumnResourceBody({
      isDisposable: isDisposableContainer,
      resourceLink,
      icon,
      resourceName,
    });
  };

  const renderDestinationResourceName = () => {
    const resourceUri = printer ? destinationPrinter.uri : destinationContainer.uri;
    const resourceLink = getRouteURI(destinationPrinter ? routes.printerMaterial : routes.permanentContainerDetails, {
      uuid: getUuid(resourceUri),
    });
    const resourceName = printer ? destinationPrinter.name : getContainerName(destinationContainer);
    const isDisposableContainer = !printer && destinationContainer.disposable;
    const icon = (
      <FontAwesomeIcon icon={printer ? faPrint : faCoins} className="spacer-right" />
    );

    return renderActionColumnResourceBody({
      isDisposable: isDisposableContainer,
      resourceLink,
      icon,
      resourceName,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !sourceContainer) {
    return <NotFound id={sourceContainerUUID} />;
  }


  const selectedPercentage = Math.round(selectedAmountBase / sourceContainer.quantity * 100);

  const quantityBarMaximumHeight = 150;
  // Height of EMPTY space of bar. If batch is fully loaded, it's 0 value
  const sourceBatchAmountBarHeight = (selectedAmountBase / sourceContainer.quantity) * quantityBarMaximumHeight;
  const newBatchAmountBarHeight = quantityBarMaximumHeight - sourceBatchAmountBarHeight;

  const formattedRemainingBase = formatThreeDecimalsNumber(sourceContainer.quantity - selectedAmountBase);
  const formattedRemainingConverted = formatThreeDecimalsNumber(convertedSourceContainerQuantity - selectedAmountConverted);


  const inputText = printer ? 'Destination printer quantity:' : 'Destination container quantity:';
  const rightColumnTitle = printer ? 'Destination Printer' : 'Destination Container';


  return (
    <PermanentContainerActionPageWrapper
      id={container}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER}
      isLoading={isLoading}
    >
      <h2>
        <div className="mb10">Split Container</div>
        <div className="badge badge-secondary white-space-normal">
          {destinationContainer?.name}
        </div>
      </h2>

      <div className="alert">
        <div>
          <label className="new-quantity-label">{inputText}</label>
          <div className="mb15">
            <input
              name="selectedAmount"
              min="0"
              style={{ width: 70 }}
              step={getInputStep(convertedSourceContainerQuantity)}
              max={convertedSourceContainerQuantity}
              type="number"
              value={selectedAmountConverted}
              onChange={handleSelectedAmountChange}
            />
            <span> {convertedBatchUnits} ({selectedPercentage}%)</span>
          </div>
        </div>

        <div className="row batch-container-split-list-row">
          <div className="col-xs-6 justify-items-center">
            <div className="mb10">
              <div className="h5">
                Source Container
                {renderBatchPowderQualityIcon(shouldWarnAboutBatchPowderQuality, sourceContainerBatch)}
              </div>
              <div className="load-container-title">
                {renderSourceResourceName()}
              </div>
            </div>
            <div className="batch-material-amount" style={isDestinationResourceLoaded ? { marginTop: 40 } : {}}>
              <div style={{ paddingTop: `${sourceBatchAmountBarHeight}px`, background: 'white' }} />
            </div>
            <div
              className="h6"
            >{formattedRemainingBase} {sourceContainerBatch.units}
            </div>
            <div
              className="header-margin"
            >{isConverted && formatConvertedUnits(formattedRemainingConverted, convertedBatchUnits)}
            </div>
          </div>
          <div className="col-xs-6 justify-items-center">
            <div className="mb10">
              <div className="h5">{rightColumnTitle}</div>
              <div className="load-container-title">
                {renderDestinationResourceName()}
              </div>
            </div>
            <div className="batch-material-amount position-relative">
              <div style={{ paddingTop: `${newBatchAmountBarHeight}px`, background: 'white' }} />
            </div>
            {isDestinationResourceLoaded && renderDestinationResourceExistingQuantity()}
            <div
              className="h6"
            >{selectedAmountBase} {sourceContainerBatch.units}
            </div>
            <div
              className="header-margin"
            >
              {isConverted && formatConvertedUnits(selectedAmountConverted, convertedBatchUnits)}
            </div>
          </div>
        </div>

        <div className="form-group">
          <RangeInputConversionTooltip
            visible={isConverted} defaultUnits={sourceContainerBatch?.units}
            convertedUnits={convertedBatchUnits} />
          <input
            name="selectedAmountRange"
            min="0"
            step={getInputStep(sourceContainer?.quantity, true)}
            max={sourceContainer?.quantity}
            type="range"
            value={selectedAmountBase}
            onChange={(e) => handleRangeChange(e.target.value)}
          />
        </div>

        <button
          type="button"
          className={`btn btn-sm mb30 mt15 ${fullQuantitySelected ? 'btn-info' : 'btn-secondary'}`}
          disabled={isSubmitting || unsupportedMaterials.length > 0}
          onClick={handleLoadAllClick}
        >
          <p className="d-flex align-items-center justify-content-center mb0">
            {fullQuantitySelected ? 'Unload All' : 'Load All'}
          </p>
        </button>

      </div>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting || unsupportedMaterials.length || !selectedAmountBase}
        onClick={confirmAndSplit}
      >
        <p className="d-flex align-items-center justify-content-center mb0">
          Confirm
          {isSubmitting && <Loader inline className="spacer-left" showText={false} />}
          {isNotSupportedByMaterials ? (
            renderUnsupportedMaterialsText(
              printer ? 'Printer' : 'Container',
              printer
                ? destinationPrinter?.name
                : (destinationContainer?.name || sourceContainer?.name),
              unsupportedMaterials
            )
          ) : null}
        </p>
      </button>

      <Link to={getRouteURI(routes.permanentContainerDetails, {
        uuid: getUuid(sourceContainer.uri),
      })}
      >
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>

      <BatchMultipleContainersAlert
        sourceBatch={sourceContainerBatch}
        destinationBatch={destinationContainerBatch}
        sourceContainer={sourceContainer}
        destinationContainer={destinationContainerBatch?.containers?.length > 1 ? destinationContainer : null}
      />

      <Modal
        isOpen={showRelocateModal}
        title="Locations Mismatch"
        dismissText="Cancel"
        confirmText="Relocate Permanent Container"
        onDismiss={hideRelocationWarningModal}
        onConfirm={onModalConfirmRelocate}
      >
        <LocationsMismatch
          sourceBatch={sourceContainerBatch}
          destinationResource={destinationResource}
          locations={locations}
          subLocations={subLocations}
          sourceType="Container"
          destinationType={`Destination ${printer ? 'Printer' : 'Container'}`}
          sourceDisplayName={sourceContainer.name}
          action="Split Container"
        />
      </Modal>
    </PermanentContainerActionPageWrapper>
  );
};

SplitContainer.propTypes = {
  user: userPropType,
};

SplitContainer.defaultProps = {
  user: null,
};

export default SplitContainer;
