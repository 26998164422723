import PropTypes from "prop-types";
import React from "react";

const UndockIcon = ({ onUndock, hidden }) => {
  if (hidden) return null;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" width="42" height="42"
      viewBox="0 0 32 32" fill="none"
      className="lps-module-card-action-btn"
      onClick={onUndock}
    >
      <g clipPath="url(#clip0_12_15191)">
        <path
          d="M16 -1.39876e-06C11.7565 -1.76974e-06 7.68688 1.68571 4.68629 4.68629C1.68571 7.68687 1.76974e-06 11.7565 1.39876e-06 16C1.02779e-06 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 -1.02779e-06 16 -1.39876e-06ZM7.9375 17.5625C7.35 16.975 7.35 16.025 7.9375 15.4437C8.525 14.8625 9.475 14.8562 10.0563 15.4437L14.4938 19.8812L14.4938 8.5C14.4938 7.66875 15.1625 7 15.9938 7C16.825 7 17.4938 7.66875 17.4938 8.5L17.4938 19.8812L21.9313 15.4437C22.5188 14.8562 23.4688 14.8563 24.05 15.4437C24.6313 16.0312 24.6375 16.9812 24.05 17.5625L17.0625 24.5625C16.475 25.15 15.525 25.15 14.9438 24.5625L7.9375 17.5625Z"
          fill="url(#paint0_linear_12_15191)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_12_15191" x1="29.5" y1="32" x2="29" y2="16" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8DC3FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_12_15191">
          <rect width="32" height="32" fill="white" transform="translate(32 32) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

UndockIcon.defaultProps = {
  hidden: false,
}

UndockIcon.propTypes = {
  onUndock: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
};

export default UndockIcon;
