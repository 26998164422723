import { faArchive, faCoins, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import ActionContext from 'src/components/action-context';
import BatchMultipleContainersAlert from 'src/components/batch-multiple-containers-alert';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Loader from 'src/components/loader';
import LocationsMismatch from 'src/components/LocationsMismatch';
import Modal from 'src/components/modal';
import NotFound from 'src/components/not-found';
import RangeInputConversionTooltip from 'src/components/RangeInputConversionTooltip';
import useSelectedAmountHandler from 'src/hooks/useSelectedAmountHandler';
import useSieveFlow from 'src/hooks/useSieveFlow';
import useUnsupportedMaterials from 'src/hooks/useUnsupportedMaterials';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import {
  fullContainerLoadAction,
  fullLoadEmptyBatchAction,
  fullPrinterLoadAction,
  partialLoadContainerAction,
  partialLoadEmptyBatchAction,
  partialPrinterLoadAction,
  relocateBatchAction,
  relocateContainerAction,
  sieveAndLoadPartialContainerAction,
  sieveAndLoadPartialEmptyBatchAction,
  sieveFullBatchAction,
  sieveFullContainerAndLoad,
} from 'src/utils/actionsAPIUtils';
import {
  renderActionColumnResourceBody,
  renderBatchPowderQualityIcon,
  renderUnsupportedMaterialsText,
} from 'src/utils/actionsUtils';
import { api } from 'src/utils/api';
import {
  API_RESOURCES, MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES,
  MATERIAL_CONTAINER_STATUSES,
  PERMANENT_CONTAINER_ACTIONS,
  PRINTER_ACTIONS,
} from 'src/utils/constants';
import { convertToUserUnits, formatThreeDecimalsNumber } from 'src/utils/conversions';
import { getContainerName, getTextForCondition } from 'src/utils/stringUtils';
import { formatConvertedUnits } from 'src/utils/ui';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { getInputStep, hasLocationsMismatch } from 'src/utils/validation';

import routes from '../../../../utils/routes';
import PermanentContainerActionPageWrapper from './_action-wrapper';

const LoadMaterial = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: resourceUUID } = useParams();
  const {
    batch: sourceBatchUri,
    sourceContainerUUID,
    action,
    isFill: isFillAction,
    isLoad: isLoadAction,
    actionFromPrinter,
    initialBatchAction,
    containerAction,
    actionBatchLoad,
    printerUUID,
  } = Object.fromEntries(searchParams.entries()) ?? {};
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [showRelocateModal, setShowRelocateModal] = useState(false);
  const [sourceContainerBatch, setSourceContainerBatch] = useState(null);
  const [destinationContainer, setDestinationContainer] = useState(null);
  const [destinationPrinter, setDestinationPrinter] = useState(null);
  const [destinationPrinterType, setDestinationPrinterType] = useState(null);
  const [destinationPrinterBatch, setDestinationPrinterBatch] = useState(null);
  const [sourceContainer, setSourceContainer] = useState(null);
  const [destinationContainerBatch, setDestinationContainerBatch] = useState(null);
  const [locations, setLocations] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(null);
  const [isEmptyBatchLoad, setIsEmptyBatchLoad] = useState(false);

  const isTopOff = action === PERMANENT_CONTAINER_ACTIONS.TOP_OFF || destinationContainer?.current_batch || !!destinationPrinterBatch;
  const sourceResource = isEmptyBatchLoad ? sourceContainerBatch : sourceContainer;
  const destinationResource = printerUUID ? destinationPrinter : destinationContainer;
  const destinationBatchResource = printerUUID && destinationPrinterBatch ? destinationPrinterBatch : destinationContainerBatch;
  const isLoadPrinterMaterialAction = printerUUID && action === PRINTER_ACTIONS.LOAD_MATERIAL;

  const didRelocateRef = useRef(false);

  const shouldWarnAboutBatchPowderQuality = (isTopOff || isLoadAction)
    && !MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(destinationBatchResource?.powder_quality)
    && MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(sourceContainerBatch?.powder_quality);

  const navigate = useNavigate();
  const { addToast } = useToasts();

  const {
    checkIfSieveRequiredFirst,
    markSieveSuccess,
    didSieveRef,
    resetUserApproval,
  } = useSieveFlow();

  const unsupportedMaterials = useUnsupportedMaterials(
    sourceContainerBatch,
    printerUUID && destinationPrinterType ? destinationPrinterType : destinationContainer,
    { isMultiple: false, isPrinter: !!printerUUID }
  );

  const shouldLoadAction = isLoadAction || actionBatchLoad;

  const {
    containerActionData: { resetActionContainerState },
  } = useContext(ActionContext);

  const { quantity: convertedSourceContainerQuantity, units: convertedBatchUnits, isConverted } =
    useMemo(() => {
      if (sourceResource && sourceContainerBatch) {
        return convertToUserUnits(sourceResource?.quantity, sourceContainerBatch.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [sourceResource, sourceContainerBatch]);


  const {
    selectedAmountBase,
    selectedAmountConverted,
    handleSelectedAmountChange,
    handleRangeChange,
    fullQuantitySelected,
  } = useSelectedAmountHandler(
    sourceResource?.quantity || 0,
    sourceContainerBatch?.units || '',
    convertedSourceContainerQuantity,
    convertedBatchUnits
  );

  const handleLoadAllClick = () => {
    if (!fullQuantitySelected) {
      // Make it "Load All"
      handleRangeChange(sourceResource.quantity);
    } else {
      // Make it "Unload All" (reset to 0)
      handleRangeChange(0); // set selected amount to 0
    }
  };

  const getActionBtnTitle = () => {
    if (shouldLoadAction && !isTopOff) {
      return 'Load';
    }
    return isTopOff ? 'Top Off' : 'Fill';
  };


  const getInitialData = async (sourceBatchUri) => {
    setLoading(true);
    resetActionContainerState();
    try {
      const sourceContainerBatch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(sourceBatchUri)}/`).json();
      const sourceBatchHasNoContainers = _isEmpty(sourceContainerBatch?.containers);

      const sourceBatchHasMultipleContainers = sourceContainerBatch?.containers?.length > 1;

      const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;

      const sourceBatchContainersResponse = sourceBatchHasMultipleContainers ?
        await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
          searchParams: {
            'filter[current_batch]': sourceContainerBatch.uri,
            'filter[status]': Object.values(containerStatusesExceptEmpty).join(','),
          },
        }).json() : null;

      const sourceBatchContainers = sourceBatchContainersResponse?.resources || [];

      // If batch has multiple containers but the system display 1 - it means some of the hidden - are empty
      // We should show the empty container in the list and use the loaded container for this UI
      const batchSourceContainerUUID = sourceContainerUUID ||
        getUuid(sourceBatchHasMultipleContainers
          ? sourceBatchContainers?.[0]?.uri
          : sourceContainerBatch?.containers[0]);

      const destinationContainer = !printerUUID && await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${resourceUUID}/`).json();
      const destinationPrinter = printerUUID ? await api.get(`${API_RESOURCES.PRINTER}/${printerUUID}/`).json() : null;
      const destinationPrinterType = destinationPrinter ? await api.get(`${API_RESOURCES.PRINTER_TYPE}/${getUuid(destinationPrinter.printer_type)}/`).json() : null;

      const destinationPrinterBatchResource = printerUUID && destinationPrinter
        ? await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
          searchParams: {
            'filter[at_machine]': destinationPrinter.uri,
          },
        }).json() : null;

      const destinationPrinterBatch = destinationPrinterBatchResource?.resources && destinationPrinterBatchResource.resources[0] || null;

      const sourceContainer = !sourceBatchHasNoContainers ? await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${batchSourceContainerUUID}/`).json() : null;
      const destinationContainerBatch = destinationContainer?.current_batch === sourceContainerBatch?.uri
        ? sourceContainerBatch
        : destinationContainer?.current_batch
        && await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(destinationContainer.current_batch)}/`).json();

      const destinationResourceData = destinationPrinter ? destinationPrinter : destinationContainer;

      const subLocationsUris = destinationResourceData && sourceContainerBatch ?
        [...new Set([destinationResourceData.sub_location, sourceContainerBatch.sub_location])] :
        [];

      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[uri]': subLocationsUris.join(','),
        },
      }).json();

      const { resources: locations } = await api.get(`${API_RESOURCES.LOCATION}/`, {
        searchParams: {
          'filter[uri]': destinationResourceData.location,
        },
      }).json();

      setSourceContainerBatch(sourceContainerBatch);
      setSourceContainer(sourceContainer);
      setDestinationContainerBatch(destinationContainerBatch);
      setDestinationContainer(destinationContainer);
      setDestinationPrinter(destinationPrinter);
      setDestinationPrinterType(destinationPrinterType);
      setDestinationPrinterBatch(destinationPrinterBatch);
      setLocations(locations);
      setSubLocations(subLocations);
      setIsEmptyBatchLoad(sourceBatchHasNoContainers);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => void getInitialData(sourceBatchUri), [sourceBatchUri]);

  const fillBtnTitle = getActionBtnTitle();

  const hasDifferentLocations = hasLocationsMismatch(
    sourceContainerBatch?.location,
    destinationResource?.location
  );

  const confirmAndLoadMaterial = async () => {
    // Check #1: Location mismatch
    if (!didRelocateRef.current && hasDifferentLocations) {
      setShowRelocateModal(true);
      return;
    }

    // Check #2: Check if we did not skip Sieve and we did not do Sieve yet + we have Powder Quality Warning

    await checkIfSieveRequiredFirst({
      shouldWarn: shouldWarnAboutBatchPowderQuality,
      action: isLoadAction
        ? PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL
        : PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
      isSubmitting,
    });


    // Reset user approval
    resetUserApproval();


    // If we reach here, all checks passed or resolved:
    await onActionLoad(destinationContainer.uri, sourceContainerBatch.uri);
  };

  const onModalConfirmRelocate = async () => {
    await onActionRelocate();
    setShowRelocateModal(false);
    setSubmitting(false);
    // Mark relocation as done immediately
    didRelocateRef.current = true;
    // Now re-run main confirm
    await confirmAndLoadMaterial();
  };


  const onActionLoad = async (permanentContainerUri, batchUri) => {
    try {
      setSubmitting(true);
      await executeLoadActions(permanentContainerUri, batchUri);
      navigateToSuccessPage(batchUri);
    } catch (error) {
      addToast(`${error}`, { appearance: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const executeLoadActions = async (permanentContainerUri, batchUri) => {
    const isFullQuantitySelected = selectedAmountBase === sourceResource.quantity;
    const shouldSieve = shouldWarnAboutBatchPowderQuality && !didSieveRef.current;

    if (isEmptyBatchLoad) {
      return handleEmptyBatchLoad(batchUri, isFullQuantitySelected, shouldSieve);
    }

    if (printerUUID) {
      return handlePrinterLoad(batchUri, isFullQuantitySelected, shouldSieve);
    }

    return handleContainerLoad(batchUri, isFullQuantitySelected, shouldSieve);
  };

  const handleEmptyBatchLoad = async (batchUri, isFullQuantitySelected, shouldSieve) => {

    if (isFullQuantitySelected) {
      await handleFullEmptyBatchLoad(batchUri, shouldSieve);
    } else {
      await handlePartialEmptyBatchLoad(batchUri, shouldSieve);
    }
  };

  const handleFullEmptyBatchLoad = async (batchUri, shouldSieve) => {
    if (shouldSieve) {
      await sieveFullBatchAction(sourceContainerBatch.uri, sourceContainerBatch.quantity);
      markSieveSuccess();
    }

    if (printerUUID) {
      await fullLoadEmptyBatchAction(
        destinationPrinter?.uri,
        isTopOff,
        batchUri,
        destinationPrinterBatch?.uri
      );
    } else {
      await fullContainerLoadAction(
        batchUri,
        isTopOff ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
        destinationContainer?.uri,
        sourceContainerBatch.quantity,
        sourceContainerBatch.containers.length > 1,
        sourceContainer?.uri,
      );
    }
  };

  const handlePartialEmptyBatchLoad = async (batchUri, shouldSieve) => {
    if (shouldSieve) {
      await sieveAndLoadPartialEmptyBatchAction(
        sourceContainerBatch.uri,
        selectedAmountBase,
        isTopOff,
        destinationContainer.uri,
        markSieveSuccess
      );
    } else {
      await partialLoadEmptyBatchAction(
        selectedAmountBase,
        batchUri,
        isLoadPrinterMaterialAction,
        destinationPrinter?.uri,
        destinationContainer?.uri
      );
    }
  };

  const handlePrinterLoad = async (batchUri, isFullQuantitySelected, shouldSieve) => {
    if (isFullQuantitySelected) {
      await handleFullPrinterLoad(batchUri, shouldSieve);
    } else {
      await handlePartialPrinterLoad(shouldSieve);
    }
  };

  const handleFullPrinterLoad = async (batchUri, shouldSieve) => {
    if (shouldSieve) {
      await sieveFullContainerAndLoad(
        sourceContainerBatch.uri,
        sourceContainer.uri,
        selectedAmountBase,
        null,
        destinationPrinter?.uri
      );
      markSieveSuccess();
    } else {
      await fullPrinterLoadAction(batchUri, sourceContainer?.uri, destinationPrinter?.uri);
    }
  };

  const handlePartialPrinterLoad = async (shouldSieve) => {
    if (shouldSieve) {
      await sieveAndLoadPartialContainerAction(
        true,
        sourceContainerBatch.uri,
        sourceContainer.uri,
        selectedAmountBase,
        isTopOff,
        destinationPrinter?.uri,
        destinationContainer.uri,
        markSieveSuccess
      );
    } else {
      await partialPrinterLoadAction(
        sourceContainer?.uri,
        sourceContainerBatch?.uri,
        destinationPrinter?.uri,
        selectedAmountBase
      );
    }
  };

  const handleContainerLoad = async (batchUri, isFullQuantitySelected, shouldSieve) => {
    if (isFullQuantitySelected) {
      await handleFullContainerLoad(batchUri, shouldSieve);
    } else {
      await handlePartialContainerLoad(shouldSieve);
    }
  };

  const handleFullContainerLoad = async (batchUri, shouldSieve) => {
    if (shouldSieve) {
      await sieveFullContainerAndLoad(
        sourceContainerBatch.uri,
        sourceContainer.uri,
        selectedAmountBase,
        destinationContainer?.uri,
      );
      markSieveSuccess();
    } else {
      await fullContainerLoadAction(
        batchUri,
        isTopOff ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
        destinationContainer?.uri,
        sourceContainerBatch.quantity,
        sourceContainerBatch.containers.length > 1,
        sourceContainer.uri,
      );
    }
  };

  const handlePartialContainerLoad = async (shouldSieve) => {
    if (shouldSieve) {
      await sieveAndLoadPartialContainerAction(
        false,
        sourceContainerBatch.uri,
        sourceContainer.uri,
        selectedAmountBase,
        isTopOff,
        destinationPrinter?.uri,
        destinationContainer.uri,
        markSieveSuccess
      );
    } else {
      await partialLoadContainerAction(
        sourceContainer.uri,
        sourceContainerBatch.uri,
        destinationContainer?.uri,
        selectedAmountBase
      );
    }
  };

  const navigateToSuccessPage = (batchUri) => {
    const routeParams = {
      action: isLoadPrinterMaterialAction ? PRINTER_ACTIONS.LOAD_MATERIAL :
        isTopOff ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
      ...(!isTopOff && !isLoadAction && !actionBatchLoad && { isFill: isFillAction }),
      ...(!isEmptyBatchLoad && { sourceContainerUUID: getUuid(sourceContainer?.uri) }),
      ...(isEmptyBatchLoad && { batch: getUuid(batchUri), isFullBatchLoad: true }),
      ...(isLoadAction && { isLoad: true }),
      ...(actionBatchLoad && { actionBatchLoad: true }),
      ...(actionFromPrinter && { actionFromPrinter: true }),
      ...(isTopOff && { isTopOff: true }),
      ...(containerAction && { containerAction: true }),
      ...(initialBatchAction && { initialBatchAction: true, initialBatchUUID: getUuid(batchUri) }),
      ...(printerUUID && destinationPrinter && {
        printer: destinationPrinter.uri,
        batch: getUuid(sourceBatchUri),
      }),
    };

    navigate(
      getRouteURI(
        isLoadPrinterMaterialAction ? routes.printerSuccess : routes.permanentContainerSuccess,
        { uuid: resourceUUID },
        routeParams
      )
    );
  };

  const hideRelocationWarningModal = () => setShowRelocateModal(false);

  const onActionRelocate = async () => {
    try {
      // If Container Fill / Top Off / Load -> Relocate Destination Container to match Source Container Location
      // If Printer Load / Top Off -> Relocate Source Container to match Printer Location
      const printerResourceToRelocate = isLoadPrinterMaterialAction ? sourceContainerBatch : sourceContainer;
      const containerToRelocate = printerUUID ? printerResourceToRelocate.uri : destinationContainer.uri;
      const locationToRelocate = printerUUID ? destinationPrinter.location : sourceContainerBatch.location;
      const subLocationToRelocate = printerUUID ? destinationPrinter.sub_location : sourceContainerBatch.sub_location;


      setSubmitting(true);

      if (isLoadPrinterMaterialAction) {
        await relocateBatchAction(locationToRelocate, subLocationToRelocate, sourceContainerBatch.uri);
      } else {
        await relocateContainerAction(locationToRelocate, subLocationToRelocate, containerToRelocate, sourceContainerBatch.uri);
      }

    } catch (error_) {
      console.error(error_);
      setSubmitError(true);
      setSubmitting(false);
    }

  };

  const renderCancelBtnRedirectUri = () => {
    if (isLoadAction) {
      return actionFromPrinter && destinationPrinter ? destinationPrinter.uri : sourceContainer.uri;
    }

    return destinationContainer?.uri;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !sourceResource) {
    return <NotFound id={resourceUUID} />;
  }

  const selectedPercentage = Math.round(selectedAmountBase / sourceResource.quantity * 100);

  const quantityBarMaximumHeight = 150;
  // Height of EMPTY space of bar. If batch is fully loaded, it's 0 value
  const sourceBatchAmountBarHeight = (selectedAmountBase / sourceResource.quantity) * quantityBarMaximumHeight;
  const newBatchAmountBarHeight = quantityBarMaximumHeight - sourceBatchAmountBarHeight;

  const formattedRemainingBase = formatThreeDecimalsNumber(sourceResource.quantity - selectedAmountBase);
  const formattedRemainingConverted = formatThreeDecimalsNumber(convertedSourceContainerQuantity - selectedAmountConverted);

  const getTitle = () => {
    return getTextForCondition([
      [isTopOff, 'Top Off Destination'],
      [printerUUID, `${fillBtnTitle} Printer from`],
    ], `${fillBtnTitle} Container from`);
  };

  const getInputText = () => {
    return getTextForCondition([
      [isTopOff, 'Top Off quantity:'],
      [printerUUID, 'Destination printer quantity:'],
    ], 'Destination container quantity:');
  };

  const getRightColumnTitle = () => {
    return getTextForCondition([
      [printerUUID, 'Destination Printer'],
    ], 'Destination Container');
  };

  const getTitleResourceName = () => {
    const destinationResourceName = destinationResource?.name || getShortUuid(destinationResource?.uri);
    const sourceResourceName = sourceResource?.name || getShortUuid(sourceResource?.uri);
    return getTextForCondition([
      [isTopOff, destinationResourceName],
      [printerUUID, sourceResourceName],
    ], sourceResourceName);
  };

  const renderSourceResourceName = () => {
    const resourceUri = isEmptyBatchLoad ? sourceContainerBatch.uri : sourceContainer.uri;
    const resourceLink = isEmptyBatchLoad
      ? getRouteURI(routes.materialContainer, {}, { batch: getUuid(resourceUri) })
      : getRouteURI(routes.permanentContainerDetails, { uuid: getUuid(resourceUri) });
    const resourceName = isEmptyBatchLoad ? getShortUuid(sourceContainerBatch.uri) : getContainerName(sourceContainer);
    const isDisposableContainer = !isEmptyBatchLoad && sourceContainer.disposable;
    const icon = (
      <FontAwesomeIcon icon={isEmptyBatchLoad ? faArchive : faCoins} className="spacer-right" />
    );

    return renderActionColumnResourceBody({
      isDisposable: isDisposableContainer,
      resourceLink,
      icon,
      resourceName,
    });
  };

  const renderDestinationResourceName = () => {
    const resourceUri = destinationPrinter ? destinationPrinter.uri : destinationContainer?.uri;
    const resourceLink = getRouteURI(destinationPrinter ? routes.printerMaterial : routes.permanentContainerDetails, {
      uuid: getUuid(resourceUri),
    });
    const resourceName = destinationPrinter ? destinationPrinter.name : getContainerName(destinationContainer);
    const isDisposableContainer = !destinationPrinter && destinationContainer.disposable;
    const icon = (
      <FontAwesomeIcon icon={destinationPrinter ? faPrint : faCoins} className="spacer-right" />
    );

    return renderActionColumnResourceBody({
      isDisposable: isDisposableContainer,
      resourceLink,
      icon,
      resourceName,
    });
  };

  const renderLocationActionText = () => {
    if (printerUUID) {
      return (
        <p>Before {fillBtnTitle}, you can relocate the
          Source {isLoadPrinterMaterialAction ? 'Batch' : 'Container'} now to match the
          Destination Printer location.
        </p>
      );
    } else {
      return (
        <p>Before {fillBtnTitle} Material, you can relocate
          the {isEmptyBatchLoad ? 'Entire Batch' : 'Source Container'} using
          the {isEmptyBatchLoad ? 'Batch' : 'Container'} page or relocate
          the Destination {printerUUID ? 'Printer' : 'Container'} now to match the Source Container location.
        </p>
      );
    }
  };

  return (
    <PermanentContainerActionPageWrapper
      id={resourceUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      customHeaderText={shouldLoadAction && !isTopOff && 'Load'}
      action={isTopOff ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL}
      isLoading={isLoading}
    >
      <h2>
        <div className="mb10">{getTitle()}</div>
        <div className="badge badge-secondary white-space-normal">
          {getTitleResourceName()}
        </div>
      </h2>

      <div className="alert">
        <div>
          <label className="new-quantity-label">{getInputText()}</label>
          <div className="mb15">
            <input
              name="selectedAmount"
              min="0"
              style={{ width: 70 }}
              step={getInputStep(convertedSourceContainerQuantity)}
              max={convertedSourceContainerQuantity}
              type="number"
              value={selectedAmountConverted}
              onChange={handleSelectedAmountChange}
            />
            <span> {convertedBatchUnits} ({selectedPercentage}%)</span>
          </div>
        </div>

        <div className="row batch-container-split-list-row">
          <div className="col-xs-6 resource-column-flexible-height justify-items-center">
            <div className="mb10">
              <div className="h5">
                Source {isEmptyBatchLoad ? 'Batch' : 'Container'}
                {renderBatchPowderQualityIcon(shouldWarnAboutBatchPowderQuality, sourceContainerBatch, isEmptyBatchLoad)}
              </div>
              <div className="load-container-title">
                {renderSourceResourceName()}
              </div>
            </div>

            <div>
              <div className="batch-material-amount">
                <div style={{ paddingTop: `${sourceBatchAmountBarHeight}px`, background: 'white' }} />
              </div>
              <div
                className="h6"
              >{formattedRemainingBase} {sourceContainerBatch.units}
              </div>
              <div
                className="header-margin"
              >{isConverted && formatConvertedUnits(formattedRemainingConverted, convertedBatchUnits)}
              </div>
            </div>


          </div>
          <div className="col-xs-6 resource-column-flexible-height justify-items-center">
            <div className="mb10">
              <div className="h5">{getRightColumnTitle()}</div>
              <div className="load-container-title">
                {renderDestinationResourceName()}
              </div>
            </div>
            <div>
              <div className="batch-material-amount position-relative">
                <div style={{ paddingTop: `${newBatchAmountBarHeight}px`, background: 'white' }} />
              </div>
              {isTopOff && (
                <div className="top-off-current-amount">
                  <ConvertedUnits
                    quantity={printerUUID && destinationPrinterBatch
                      ? destinationPrinterBatch.quantity
                      : destinationContainer?.quantity} units={destinationBatchResource?.units} />
                </div>
              )}
              <div
                className="h6"
              >{selectedAmountBase} {sourceContainerBatch.units}
              </div>
              <div
                className="header-margin"
              >
                {isConverted && formatConvertedUnits(selectedAmountConverted, convertedBatchUnits)}
              </div>
            </div>

          </div>
        </div>

        <div className="form-group">
          <RangeInputConversionTooltip
            visible={isConverted} defaultUnits={sourceContainerBatch?.units}
            convertedUnits={convertedBatchUnits} />
          <input
            name="selectedAmountRange"
            min="0"
            step={getInputStep(sourceResource?.quantity, true)}
            max={sourceResource?.quantity}
            type="range"
            value={selectedAmountBase}
            onChange={(e) => handleRangeChange(e.target.value)}
          />
        </div>

        <button
          type="button"
          className={`btn btn-sm mb30 mt15 ${fullQuantitySelected ? 'btn-info' : 'btn-secondary'}`}
          disabled={isSubmitting || unsupportedMaterials.length > 0}
          onClick={handleLoadAllClick}
        >
          <p className="d-flex align-items-center justify-content-center mb0">
            {fullQuantitySelected ? 'Unload All' : 'Load All'}
          </p>
        </button>

      </div>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting || unsupportedMaterials.length || !selectedAmountBase}
        onClick={confirmAndLoadMaterial}
      >
        <p className="d-flex align-items-center justify-content-center mb0">
          Confirm
          {isSubmitting && <Loader inline className="spacer-left" showText={false} />}
          {unsupportedMaterials.length ? (
            renderUnsupportedMaterialsText(
              printerUUID ? 'Printer' : 'Container',
              destinationResource?.name,
              unsupportedMaterials
            )
          ) : null}
        </p>
      </button>

      <Link to={getRouteURI(actionFromPrinter ? routes.printerMaterial : routes.permanentContainerDetails, {
        uuid: getUuid(renderCancelBtnRedirectUri()),
      })}
      >
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>

      <BatchMultipleContainersAlert
        sourceBatch={sourceContainerBatch}
        destinationBatch={destinationBatchResource}
        sourceContainer={sourceContainer}
        destinationContainer={destinationContainerBatch?.containers?.length > 1 ? destinationContainer : null}
        containerUriToRedirect={sourceContainerBatch?.containers?.length > 1
          ? getRouteURI(
            printerUUID && isLoadAction ? routes.printerLoadMaterialBatchAction : routes.permanentContainerLoadMaterialBatchAction,
            {
              uuid: getUuid(destinationResource.uri),
            },
            {
              batch: sourceContainerBatch.uri,
              action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH,
              ...(isLoadAction && { isLoad: true }),
              ...(actionBatchLoad && { actionBatchLoad: true }),
              ...(actionFromPrinter && { actionFromPrinter: true }),
              ...(containerAction && { containerAction: true }),
              ...(initialBatchAction && { initialBatchAction: true }),
            }
          )
          : null} />

      <Modal
        isOpen={showRelocateModal}
        title="Locations Mismatch"
        dismissText="Cancel"
        confirmText={`Relocate ${isLoadPrinterMaterialAction ? 'Batch' : 'Container'}`}
        onDismiss={hideRelocationWarningModal}
        onConfirm={onModalConfirmRelocate}
      >
        <LocationsMismatch
          sourceBatch={sourceContainerBatch}
          bottomTextRenderer={renderLocationActionText}
          destinationResource={destinationResource}
          locations={locations}
          subLocations={subLocations}
          sourceType={isEmptyBatchLoad ? 'Batch' : 'Container'}
          destinationType={`Destination ${printerUUID ? 'Printer' : 'Container'}`}
          sourceDisplayName={`(${isEmptyBatchLoad
            ? getShortUuid(sourceContainerBatch.uri)
            : getContainerName(sourceContainer)})`}
          action={`${fillBtnTitle} Material`}
        />
      </Modal>
    </PermanentContainerActionPageWrapper>
  );
};

LoadMaterial.propTypes = {
  user: userPropType,
};

LoadMaterial.defaultProps = {
  user: null,
};

export default LoadMaterial;
