import React from 'react';

const Spinner = () => {
  return (
    <div className='modal-spinner d-flex align-items-center justify-content-center'>
      <div className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
    </div>
  );
};

export default Spinner;
