import _forEach from 'lodash/forEach';
import _replace from 'lodash/replace';
import { matchPath, useSearchParams } from 'react-router-dom';
import config from 'src/utils/config';

import { API_RESOURCES } from './constants';

export const isValidUrl = url => {
  try {
    const { protocol, origin, pathname, searchParams } = new URL(url);

    if (protocol !== 'https:' && protocol !== 'http:') {
      return false;
    }

    if (process.browser) {
      // Validate only for browser
      const { origin: locationOrigin } = window.location;
      if (locationOrigin !== origin) {
        console.error(
          `Origin in QR code (${origin}) is different than app is running ${locationOrigin}`
        );
        return false;
      }
    }

    if (
      // Old summary links generated QR App side
      pathname !== '/summary' &&
      pathname !== '/summary/' &&
      // New scan links generated Rapidfab side
      pathname !== '/scan' &&
      pathname !== '/scan/'
    ) {
      console.info(
        `Pathname in QR code (${pathname}) is different than expected (/summary or /scan)`
      );
      return false;
    }

    // For Summary links it should contain line_item and copy
    if (searchParams.has('line_item') && searchParams.has('copy')) {
      return true;
    }

    // For Scan links it should contain resource uri
    if (searchParams.has('resource')) {
      return true;
    }

    return false;
  } catch (_) {
    return false;
  }
};

export const getRoute = url => {
  if (!isValidUrl(url)) {
    return false;
  }

  const { pathname, search } = new URL(url);
  return `${pathname}${search}`;
};

export const getResourceName = url => {
  try {
    const { pathname } = new URL(url);
    const parts = pathname.split('/');
    // pathname always starts with '/' so:
    // 0 -> nothing
    // 1 -> resource type

    if (parts[1]) {
      return parts[1];
    }

    return false;
  } catch (_) {
    return false;
  }
};

export const getUuid = searchString => {
  if (typeof searchString !== 'string') {
    return false;
  }

  const uuidV4Regex = /[[^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}/gi;
  const uuids = searchString.match(uuidV4Regex);

  if (!uuids) {
    return false;
  }

  if (uuids.length > 1) {
    console.info(`Two uuids in search string are provided for getUuid(${searchString})`);
  }

  return uuids[0];
};

export const getResourceUri = (resource, uuid) => {
  if (!Object.values(API_RESOURCES).includes(resource)) {
    throw new URIError(`Resource ${resource} is exist in constants.API_RESOURCES`);
  }

  return `${config.apiHost}/${resource}/${uuid}/`;
};

export const getShortUuid = searchString => {
  if (!searchString) {
    return false;
  }

  const uuid = getUuid(searchString);
  if (!uuid) {
    return false;
  }

  return uuid.slice(0, 8);
};

/**
 * Generates a route URI by replacing dynamic route parameters and appending query parameters.
 *
 * @param {string} route - The base route string with optional dynamic parameters.
 * @param {Object} [params={}] - An object containing key-value pairs for dynamic route parameters (optional).
 * @param {Object} [queryParams={}] - An object containing key-value pairs for query parameters (optional).
 * @returns {string} The generated route URI.
 *
 * @example
 * // Example usage:
 *
 * // Define dynamic parameters and query parameters
 * const dynamicParams = { uuid: '123' };
 * const queryParams = {
 *   type: 'start',
 *   blendedBatch: 'container123',
 *   skipSieving: false,
 *   initialBatchAction: 'load',
 * };
 *
 * // Generate a route URI
 * const routeURI = getRouteURI('/material-batch/:uuid/action', dynamicParams, queryParams);
 *
 * // The value of routeURI will be:
 * // '/material-batch/123/action?type=start&blendedBatch=container123&skipSieving=false&initialBatchAction=load'
 */

export function getRouteURI(route, params = {}, queryParams = {}) {
  let selectedRoute = route;

  if (params) {
    _forEach(params, (value, key) => {
      selectedRoute = _replace(selectedRoute, `:${key}`, encodeURIComponent(value));
    });
  }

  if (queryParams) {
    const queryParamsString = new URLSearchParams(queryParams).toString();
    selectedRoute = `${selectedRoute}?${queryParamsString}`;
  }

  return selectedRoute;
}

const useQueryParams = () => {
  const [searchParams] = useSearchParams();
  const params = {};
  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }
  return params;
};

export default useQueryParams;

/**
 * Checks if a pathname matches any allowed routes with dynamic segments.
 * @param {string} pathname - The current pathname from the location.
 * @param {Array<string>} allowedRoutes - List of allowed routes to match against.
 * @returns {boolean} - True if the pathname matches one of the allowed routes, otherwise false.
 */
export const isRouteAllowed = (pathname, allowedRoutes) => {
  return allowedRoutes.some(route => matchPath(route, pathname));
};

export function getQRAppUri(resourceUri) {
  if (!resourceUri) return null;
  return `${window.location.origin}/scan?resource=${resourceUri}`;
}

/**
 * Filters out undefined values from an object and returns a new cleaned object
 *
 * @param {Object} params - The source object containing parameters to clean
 * @returns {Object} A new object with all key-value pairs where value is defined
 *
 * @example
 * // Returns { name: 'Alice', age: 30 }
 * buildCleanParams({ name: 'Alice', age: 30, hobby: undefined });
 *
 * @example
 * // Returns { active: true }
 * buildCleanParams({ active: true, role: null });
 *
 * @example
 * // Returns empty object
 * buildCleanParams({});
 */
export const buildCleanParams = params => {
  const cleaned = {};
  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined) {
      cleaned[key] = value;
    }
  }
  return cleaned;
};

export function isUri(uri) {
  try {
    new URL(uri);
    return true;
  } catch {
    return false;
  }
}
