import { API_RESOURCES } from 'src/utils/constants';

export const MLINE_MODULE_TYPES = {
  DOSE: 'dose',
  BUILD: 'build',
  OVERFLOW: 'overflow',
};

export const MHS_MODULES = {
  INPUT: 'input',
  OUTPUT: 'output',
};

export const MHS_CYCLONE = 'cyclone';

export const MODULE_TYPES_MAP = {
  [MLINE_MODULE_TYPES.DOSE]: 'Dose',
  [MLINE_MODULE_TYPES.BUILD]: 'Build',
  [MLINE_MODULE_TYPES.OVERFLOW]: 'Overflow',
};

export const MHS_MODULES_MAP = {
  [MHS_MODULES.INPUT]: 'Vacuum Side',
  [MHS_MODULES.OUTPUT]: 'Sieve Side',
};

export const MLINE_ACTIONS_ALLOWED_RESOURCES = {
  [MLINE_MODULE_TYPES.DOSE]: [API_RESOURCES.MATERIAL_CONTAINER],
  [MLINE_MODULE_TYPES.BUILD]: [API_RESOURCES.MATERIAL_CONTAINER],
  [MLINE_MODULE_TYPES.OVERFLOW]: [API_RESOURCES.MATERIAL_CONTAINER],
  [MHS_MODULES.INPUT]: [API_RESOURCES.MATERIAL_CONTAINER],
  [MHS_MODULES.OUTPUT]: [API_RESOURCES.MATERIAL_CONTAINER],
  [MHS_CYCLONE]: [API_RESOURCES.MATERIAL_CONTAINER, API_RESOURCES.MATERIAL_BATCH],
};
