import _capitalize from 'lodash/capitalize';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QrBarcodeToggle from 'src/components/qr-barcode-toggle';
import ScanBarcode from 'src/components/scan/scan-barcode';
import ScanQr from 'src/components/scan/scan-qr';
import FeaturesContext from 'src/context/FeaturesContext';
import useQueryParams from 'src/hooks/useQueryParams';
import useScanStore from 'src/stores/useScanStore';
import {
  API_RESOURCES,
  API_RESOURCES_MAP,
  API_RESOURCES_TITLE_MAP,
  BUREAU_BARCODE_FORMAT,
  LOCALSTORAGE_KEYS,
  MLINE_ACTIONS,
  PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import { formatList, pluralWord } from 'src/utils/stringUtils';
import { getResourceName, getRoute } from 'src/utils/url';

import useLocalstorage from '../../utils/useLocalStorage';

const Scan = ({ action: initiatorAction, allowedResourcesToScan, handleAction }) => {
  const navigate = useNavigate();

  const { resource } = useQueryParams();

  const {
    successfulScan,
    setSuccessfulScan,
    urlError,
    setUrlError,
    triggerScanError,
    cleanupScan,
  } = useScanStore();

  const [showInstructions, setShowInstructions] = useState(true);
  // const [urlError, setUrlError] = useState(false);
  const [resourceError, setResourceError] = useState(false);
  // const [successfulScan, setSuccessfulScan] = useState(Boolean(resource));

  const { features } = useContext(FeaturesContext);
  const isMaterialManagementEnabled = isFeatureEnabled(features, FEATURES.MATERIAL_MANAGEMENT);

  const [scanMode, handleScanMode] = useLocalstorage(
    LOCALSTORAGE_KEYS.SCAN_MODE,
    BUREAU_BARCODE_FORMAT.QR
  );
  const isBarcode = scanMode === BUREAU_BARCODE_FORMAT.BARCODE;

  const handleResourceError = error => {
    if (error.message === 'NOT FOUND') {
      setUrlError(new Error('Resource not found. Please scan a valid resouce.'));
      setResourceError(true);
      return setSuccessfulScan(false);
    }

    setResourceError(true);
    return setUrlError(
      new Error('Something went wrong while scanning the resource. Please try again.')
    );
  };

  useEffect(() => {
    async function handleResourceRedirect() {
      try {
        // In case user is scanning new QR code - redirect him to appropriate page
        const resourceName = getResourceName(resource);
        switch (resourceName) {
          default:
            setSuccessfulScan(false);
            break;
        }
      } catch (error) {
        handleResourceError(error);
      }
    }

    if (resource) {
      handleResourceRedirect();
    }
  }, [resource]);

  async function handleScan(url, resourceName, resourceUri) {
    setShowInstructions(false);
    setUrlError(false);
    setSuccessfulScan(true);

    // Validate scanned entity to be the one that was requested
    if (allowedResourcesToScan) {
      if (!allowedResourcesToScan.includes(resourceName)) {
        const allowedResources = allowedResourcesToScan.map(
          resource => API_RESOURCES_TITLE_MAP[resource] || _capitalize(resource)
        );
        triggerScanError(
          `Scanned ${isBarcode ? 'Barcode' : 'QR Code'} is invalid. Please scan a QR Code of type: ${formatList(allowedResources)}`
        );
        return;
      }
    }

    try {
      // In case user was redirected to this page in order to scan additional QR Code
      if (initiatorAction) {
        // Set initial URIs, if any provided in URL

        // Redirect user to appropriate page adding all required params
        switch (initiatorAction) {
          case MLINE_ACTIONS.DOCK_MODULE:
          case PERMANENT_CONTAINER_ACTIONS.TOP_OFF:
          case PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL: {
            await handleAction(resourceUri, resourceName);
            return null;
          }

          default:
            break;
        }
      }
    } catch (error) {
      setSuccessfulScan(false);
      triggerScanError('Something went wrong while scanning the resource. Please try again.');
      return;
    }

    if (!url && resourceName && resourceUri) {
      // Looks like it can be scanned code from initial view
      console.info('Scanned the same code');
    }

    if (url) {
      navigate(getRoute(url));
    }
  }

  function handleError(error) {
    setUrlError(error);
  }

  const getAllowedResourceNames = () => {
    if (!_isEmpty(allowedResourcesToScan)) {
      const API_RESOURCES_MAP_KEYS = Object.keys(API_RESOURCES_MAP);
      const resourcesNotDefinedInKeys = allowedResourcesToScan.filter(
        key => !API_RESOURCES_MAP_KEYS.includes(key)
      );
      if (!_isEmpty(resourcesNotDefinedInKeys)) {
        // If there are resources that are not defined in API_RESOURCES_MAP
        throw new Error(
          `Requested QR code ${pluralWord('type', resourcesNotDefinedInKeys)} "${resourcesNotDefinedInKeys.join(', ')}" is not supported.`
        );
      }

      const allowedResourcesNames = allowedResourcesToScan.map(
        allowedResource => API_RESOURCES_MAP[allowedResource]
      );

      return allowedResourcesNames.join(', ');
    }

    // By default we have QR codes only for line-item+copy (called traveler initially)
    const allowedEntities = ['traveler'];
    if (isMaterialManagementEnabled) {
      allowedEntities.push(
        API_RESOURCES_MAP[API_RESOURCES.MATERIAL_CONTAINER],
        API_RESOURCES_MAP[API_RESOURCES.PRINTER]
      );
    }
    if (allowedEntities.length === 1) {
      // Only 1 entity is allowed.
      return allowedEntities[0];
    }

    // Separating the last item to make it `xxx, yyy, zzz OR mmm`
    // Where `xxx, yyy, zzz` is all except last and `mmm` is the last item
    const allowedEntitiesExceptLast = allowedEntities.slice(0, -1);
    return `${allowedEntitiesExceptLast.join(', ')} or ${allowedEntities[allowedEntities.length - 1]}`;
  };

  const instructionText = `Use your camera to scan the QR code on the ${getAllowedResourceNames()}.`;
  const barcodeInstructionText = `Scan the barcode on a ${getAllowedResourceNames()} 
  using your Barcode Scanner, and you will be automatically taken to the relevant page.`;

  const toggleScanMode = () =>
    handleScanMode(isBarcode ? BUREAU_BARCODE_FORMAT.QR : BUREAU_BARCODE_FORMAT.BARCODE);

  useEffect(() => {
    return () => {
      cleanupScan();
    };
  }, []);

  const content = isBarcode ? (
    <ScanBarcode
      successfulScan={successfulScan}
      handleScan={handleScan}
      handleError={handleError}
      barcodeError={urlError}
      showInstructions={showInstructions}
      instructionText={barcodeInstructionText}
      allowedResourcesToScan={allowedResourcesToScan}
      setBarcodeError={setUrlError}
      resourceError={resourceError}
    />
  ) : (
    <ScanQr
      successfulScan={successfulScan}
      handleScan={handleScan}
      handleError={handleError}
      urlError={urlError}
      showInstructions={showInstructions}
      instructionText={instructionText}
      allowedResourcesToScan={allowedResourcesToScan}
    />
  );

  return (
    <>
      {content}
      <QrBarcodeToggle
        notFixed
        scanMode={scanMode}
        toggleScanMode={toggleScanMode}
        action={initiatorAction}
      />
    </>
  );
};

Scan.propTypes = {
  action: PropTypes.string,
  allowedResourcesToScan: PropTypes.arrayOf(PropTypes.string),
  handleAction: PropTypes.func.isRequired,
};

Scan.defaultProps = {
  action: null,
  allowedResourcesToScan: [],
};

export default Scan;
