import { useMemo } from 'react';
import { checkSupportedMaterialsMismatch } from 'src/utils/validation';

/**
 * Computes unsupported materials for a given batch and one or more resources.
 *
 * @param {Object} batch - The material batch (must include a `materials` array).
 * @param {Object|Array} resourceOrResources - Either a single resource (container, printer, etc.)
 *   or an array of such resources.
 * @param {Object} options - Optional flags:
 *   - isMultiple (boolean): If true, returns detailed objects (useful for multiple resources).
 *   - isPrinter (boolean): If true, applies printer-specific logic.
 *
 * @returns {Array} An array of unsupported material names (or detailed objects if isMultiple is true).
 */
export default function useUnsupportedMaterials(batch, resourceOrResources, options = {}) {
  const { isMultiple = false, isPrinter = false } = options;

  return useMemo(() => {
    if (!batch || !resourceOrResources) {
      return [];
    }
    // If resourceOrResources is an array, map over it and flatten the results.
    if (Array.isArray(resourceOrResources)) {
      return resourceOrResources.flatMap(resource =>
        checkSupportedMaterialsMismatch(batch, resource, isMultiple, isPrinter)
      );
    }
    // Otherwise, assume it's a single resource.
    return checkSupportedMaterialsMismatch(batch, resourceOrResources, isMultiple, isPrinter);
  }, [batch, resourceOrResources, isMultiple, isPrinter]);
}
