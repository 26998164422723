import { faArrowUpShortWide, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faCoins, faExternalLink, faInfoCircle, faObjectGroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Scan from "src/components/scan/scan";
import TruncatedItemsList from 'src/components/TruncatedItemsList';
import TruncatedTitle from 'src/components/TruncatedTitle';
import UndockConfirmation from "src/components/UndockConfirmation";
import {
  MHS_MODULES,
  MHS_MODULES_MAP,
  MLINE_ACTIONS_ALLOWED_RESOURCES,
} from 'src/constants/m-line';
import DockBtn from "src/pages/mline/sections/DockIcon";
import MhsModule from "src/pages/mline/sections/mhs/modules/mhs-module";
import UndockIcon from "src/pages/mline/sections/UndockIcon";
import { MLINE_ACTIONS } from "src/utils/constants";
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';

const MHSModulesContainer = ({
                               modules,
                               handleConnectModule,
                               handleUndockModule,
                               actionPanelProps,
                               inputModuleTransactions,
                               inputModuleBatch,
                               outputModuleBatch,
                               modulesLoadingState,
                             }) => {

  const { openActionPanel }  = actionPanelProps;

  const {
    isInputModuleLoading,
    isOutputModuleLoading,
    inputModuleBatchLoading,
    outputModuleBatchLoading,
  } = modulesLoadingState;

  const moduleBatchMap = {
    [MHS_MODULES.INPUT]: inputModuleBatch,
    [MHS_MODULES.OUTPUT]: outputModuleBatch,
  };

  const modulesLoadingMap = {
    [MHS_MODULES.INPUT]: isInputModuleLoading || inputModuleBatchLoading,
    [MHS_MODULES.OUTPUT]: isOutputModuleLoading || outputModuleBatchLoading,
  }

  const handleUndock = (module, moduleSlot) => {
    openActionPanel({
      panelId: 'undock-confirmation',
      title: 'Undock Confirmation',
      content: <UndockConfirmation
        module={module}
        handleAction={() => handleUndockModule(MLINE_ACTIONS.UNDOCK_MODULE, module, moduleSlot)} />,
    });
  };

  const handleDockModule = (moduleSlot) => {
    const moduleName = MHS_MODULES_MAP[moduleSlot];
    const allowedResourcesToScan = MLINE_ACTIONS_ALLOWED_RESOURCES[moduleSlot];

    openActionPanel({
      panelId: 'scan-module-container',
      title: `Scan ${moduleName}`,
      isSidebar: true,
      content: (<Scan
        action={MLINE_ACTIONS.DOCK_MODULE}
        allowedResourcesToScan={allowedResourcesToScan}
        handleAction={(moduleUri) => handleConnectModule(MLINE_ACTIONS.DOCK_MODULE, moduleUri, moduleSlot)} />
      ),
    });
  };

  const renderTransactionsTooltip = (module, slot) => {
    if (!module || slot !== MHS_MODULES.INPUT) return null;
    const noPendingTransactions = _isEmpty(inputModuleTransactions);

    return (
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip id="undock-module-info">
            {noPendingTransactions
              ? 'No pending transactions'
              : "Module has pending transactions, please complete them before proceeding"}
          </Tooltip>
        )}
      >
        <FontAwesomeIcon
          icon={noPendingTransactions ? faInfoCircle : faTriangleExclamation}
          color={`${noPendingTransactions ? '#d6ffdf' : '#FFC008'}`}
          className="spacer-left"
        />
      </OverlayTrigger>
    )
  }

  return (
    <div className="mhs-modules-container">
      {modules.map(({ slot, module }) => {
        const wrapperClassName = classNames("module-card", {
          [`module-card-bg-${module?.type}`]: !!module,
          "module-card-empty-bg": !module,
        });

        const isModuleEmpty = !module;
        const moduleQuantity = Number(module?.quantity);
        const moduleBatchUnits = moduleBatchMap[slot]?.units;
        const moduleBatchMaterials = moduleBatchMap[slot]?.materials || [];
        const batchMaterialNames = _uniq(_map(moduleBatchMaterials, 'name'));
        const isVacuumModule = slot === MHS_MODULES.INPUT;

        return (
          <div key={slot} className={wrapperClassName}>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-base">
                <div>
                  <FontAwesomeIcon icon={faCoins} className="spacer-right module-title-size" />
                </div>
                <div className="text-left">
                  <div className="module-title-size">{MHS_MODULES_MAP[slot]}</div>
                  <div className="module-id">
                    <span>{isModuleEmpty ? 'Empty'
                      : (_isEmpty(inputModuleTransactions) && isVacuumModule)
                      && !module.quantity
                        ? 'Can be undocked'
                        : 'Docked'}
                    </span>
                  </div>
                </div>
              </div>
              {modulesLoadingMap[slot] ?
                <Spinner animation='border' />
              : (
                <div>
                  {!isModuleEmpty ? (
                    <UndockIcon onUndock={() => handleUndock(module, slot)} />
                  ) :
                    <DockBtn onDock={() => handleDockModule(slot)} />}
                </div>
              )}
            </div>
            {!isModuleEmpty && (
            <MhsModule module={module} isLoading={modulesLoadingMap[slot]}>
              <div className="mb10">
                <div>
                  <div>
                    <TruncatedTitle
                      inline
                      title={module.name}
                      titleClassName='mb0'
                      maxTextLength={25}
                      maxTextLengthMobile={20}
                    />
                    <span>{renderTransactionsTooltip(module, slot)}</span>
                  </div>
                  <div className="font-light font-size-14">

                    <Link
                      className='batch-card-main-details-main-link'
                      to={getRouteURI(routes.permanentContainerDetails, { uuid: getUuid(module.uri) })}
                    >
                      <FontAwesomeIcon icon={faExternalLink} className='spacer-right' />
                      <p className='mb0'>{getShortUuid(module.uri)}</p>
                    </Link>

                    {/*({getShortUuid(module.uri)})*/}
                  </div>
                </div>
              </div>
              {(!isNaN(moduleQuantity) && (moduleQuantity > 0 || !_isEmpty(batchMaterialNames))) && moduleBatchUnits && (
              <div className="mb5">
                <FontAwesomeIcon className='spacer-right' icon={faArrowUpShortWide} />
                <ConvertedUnits quantity={module.quantity} units={moduleBatchUnits} />
              </div>
                )}
              {!_isEmpty(batchMaterialNames) && (
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon className='spacer-right' icon={faObjectGroup} />
                  <TruncatedItemsList
                    titleClassName='font-size-17'
                    iconClassName='font-size-17'
                    data={batchMaterialNames}
                    showCount={1}
                    icon={faObjectGroup}
                    showVisibleIcon={false}
                  />
                </div>
              )}
            </MhsModule>
            )}
          </div>
        );
      })}
    </div>
  );
};

MHSModulesContainer.propTypes = {
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      slot: PropTypes.string,
      module: PropTypes.shape({}),
    }),
  ).isRequired,
  inputModuleBatch: PropTypes.shape({
    units: PropTypes.string,
  }),
  outputModuleBatch: PropTypes.shape({
    units: PropTypes.string,
  }),
  inputModuleTransactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleConnectModule: PropTypes.func.isRequired,
  handleUndockModule: PropTypes.func.isRequired,
  actionPanelProps: PropTypes.shape({
    openActionPanel: PropTypes.func,
    closeActionPanel: PropTypes.func,
  }).isRequired,
  modulesLoadingState: PropTypes.shape({
    isInputModuleLoading: PropTypes.bool,
    isOutputModuleLoading: PropTypes.bool,
    inputModuleBatchLoading: PropTypes.bool,
    outputModuleBatchLoading: PropTypes.bool,
  }).isRequired,
};

export default MHSModulesContainer;
