import { faArchive, faCoins } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ActionContext from 'src/components/action-context';
import BatchLoadPanel from 'src/components/batch-load';
import BatchMultipleContainersAlert from 'src/components/batch-multiple-containers-alert';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Loader from 'src/components/loader';
import LocationsMismatch from 'src/components/LocationsMismatch';
import Modal from 'src/components/modal';
import RangeInputConversionTooltip from 'src/components/RangeInputConversionTooltip';
import { useBatch } from 'src/hooks/services/useBatch';
import {
  useLocationResourcesByUri,
  useSubLocationResourcesByUri,
} from 'src/hooks/services/useLocation';
import useSelectedAmountHandler from 'src/hooks/useSelectedAmountHandler';
import useSieveFlow from 'src/hooks/useSieveFlow';
import useUnsupportedMaterials from 'src/hooks/useUnsupportedMaterials';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import {
  fullContainerLoadAction,
  partialLoadContainerAction,
  partialLoadEmptyBatchAction,
  relocateBatchAction,
  relocateContainerAction,
  sieveAndLoadPartialContainerAction,
  sieveAndLoadPartialEmptyBatchAction,
  sieveFullBatchAction,
  sieveFullContainerAndLoad,
  sieveFullContainerSplitAndLoad,
} from 'src/utils/actionsAPIUtils';
import {
  renderActionColumnResourceBody,
  renderBatchPowderQualityIcon,
  renderUnsupportedMaterialsText,
} from 'src/utils/actionsUtils';
import {
  MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES,
  PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import { convertToUserUnits, formatThreeDecimalsNumber } from 'src/utils/conversions';
import routes from 'src/utils/routes';
import { getContainerName, getTextForCondition } from 'src/utils/stringUtils';
import { formatConvertedUnits } from 'src/utils/ui';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import { getInputStep, hasLocationsMismatch } from 'src/utils/validation';

const PartialLoadPanel = ({
  sourceResource,
  sourceResourceBatchUri,
  destinationResource,
  destinationResourceBatchUri,
  isEmptyBatchLoad,
  relocateOptions,
  isTopOff,
  action,
  actionTitle,
  destinationResourceTitle,
  hideDestinationResourceTitleHyperlinked,
  hideSourceResourceTitleHyperlinked,
  sourceResourceTitle,
  postSubmitAction,
}) => {
  const queryClient = useQueryClient();

  const { openActionPanel, closeActionPanel, closeAllActionPanels } = useActionPanelStore();
  const { resourceToRelocate, locationToRelocate, subLocationToRelocate } = relocateOptions ?? {};

  const { data: sourceBatch } = useBatch(sourceResourceBatchUri, 'sourceBatch');
  const { data: destinationBatch } = useBatch(destinationResourceBatchUri, 'destinationBatch');
  const { data: locations } = useLocationResourcesByUri(destinationResource.location);

  const subLocationsUris =
    destinationResource && sourceBatch
      ? [...new Set([destinationResource.sub_location, sourceBatch.sub_location])]
      : [].join(',');

  const { data: subLocations } = useSubLocationResourcesByUri(subLocationsUris);

  const [isSubmitting, setSubmitting] = useState(false);
  const [showRelocateModal, setShowRelocateModal] = useState(false);

  const didRelocateRef = useRef(false);

  const shouldWarnAboutBatchPowderQuality =
    isTopOff &&
    !MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(destinationBatch?.powder_quality) &&
    MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(sourceBatch?.powder_quality);

  const { addToast } = useToasts();

  const { checkIfSieveRequiredFirst, markSieveSuccess, didSieveRef, resetUserApproval } =
    useSieveFlow();
  const isRequestedSieveAndLoadAction = action === PERMANENT_CONTAINER_ACTIONS.SIEVE;

  const unsupportedMaterials = useUnsupportedMaterials(sourceBatch, destinationResource, {
    isMultiple: false,
    isPrinter: false,
  });

  const {
    containerActionData: { resetActionContainerState },
  } = useContext(ActionContext);

  const {
    quantity: convertedSourceResourceQuantity,
    units: convertedBatchUnits,
    isConverted,
  } = useMemo(() => {
    if (sourceResource && sourceBatch) {
      return convertToUserUnits(sourceResource.quantity, sourceBatch.units);
    }
    return { quantity: '0.00', units: '', isConverted: false };
  }, [sourceResource, sourceBatch]);

  const {
    selectedAmountBase,
    selectedAmountConverted,
    handleSelectedAmountChange,
    handleRangeChange,
    fullQuantitySelected,
  } = useSelectedAmountHandler(
    sourceResource?.quantity || 0,
    sourceBatch?.units || '',
    convertedSourceResourceQuantity,
    convertedBatchUnits
  );

  const handleLoadAllClick = () => {
    if (!fullQuantitySelected) {
      // Make it "Load All"
      handleRangeChange(sourceResource.quantity);
    } else {
      // Make it "Unload All" (reset to 0)
      handleRangeChange(0); // set selected amount to 0
    }
  };

  useEffect(() => {
    resetActionContainerState();
  }, []);

  const hasDifferentLocations = hasLocationsMismatch(
    sourceBatch?.location,
    destinationResource?.location
  );

  const confirmAndLoadMaterial = async () => {
    // Check #1: Location mismatch
    if (!didRelocateRef.current && hasDifferentLocations && !_isEmpty(relocateOptions)) {
      setShowRelocateModal(true);
      return;
    }

    // Check #2: Check if we did not skip Sieve and we did not do Sieve yet + we have Powder Quality Warning

    await checkIfSieveRequiredFirst({
      shouldWarn: shouldWarnAboutBatchPowderQuality && !isRequestedSieveAndLoadAction,
      action,
      isSubmitting,
    });

    // Reset user approval
    resetUserApproval();

    // If we reach here, all checks passed or resolved:
    await onActionLoad(destinationResource.uri, sourceBatch.uri);
  };

  const onModalConfirmRelocate = async () => {
    try {
      await onActionRelocate();
      setShowRelocateModal(false);
      setSubmitting(false);
      didRelocateRef.current = true;
      // Only if relocation succeeded do we continue:
      await confirmAndLoadMaterial();
    } catch (error) {
      addToast(`${error}`, { appearance: 'error' });
      setShowRelocateModal(false);
      setSubmitting(false);
    }
  };

  const onActionLoad = async (permanentContainerUri, batchUri) => {
    try {
      setSubmitting(true);
      await executeLoadActions(permanentContainerUri, batchUri);
      addToast(
        `${destinationResourceTitle} has successfully been ${isTopOff ? 'topped up' : 'loaded'}.`,
        { appearance: 'success' }
      );
      await queryClient.invalidateQueries([
        'cyclone',
        isRequestedSieveAndLoadAction ? sourceResource.uri : destinationResource.uri,
      ]);
      await queryClient.invalidateQueries([
        'cycloneBatch',
        isRequestedSieveAndLoadAction
          ? sourceResource.current_batch
          : destinationResource.current_batch,
      ]);
      if (postSubmitAction) {
        await postSubmitAction();
      }
    } catch (error) {
      addToast(`${error}`, { appearance: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const executeLoadActions = async (permanentContainerUri, batchUri) => {
    const isFullQuantitySelected = selectedAmountBase === sourceResource.quantity;
    const shouldSieve = shouldWarnAboutBatchPowderQuality && !didSieveRef.current;

    if (isEmptyBatchLoad) {
      return handleEmptyBatchLoad(batchUri, isFullQuantitySelected, shouldSieve);
    }
    return handleContainerLoad(batchUri, isFullQuantitySelected, shouldSieve);
  };

  const handleContainerLoad = async (batchUri, isFullQuantitySelected, shouldSieve) => {
    if (isFullQuantitySelected) {
      await handleFullContainerLoad(batchUri, shouldSieve);
    } else {
      await handlePartialContainerLoad(shouldSieve);
    }

    closeAllActionPanels();
  };

  const handleFullContainerLoad = async (batchUri, shouldSieve) => {
    if (shouldSieve || isRequestedSieveAndLoadAction) {
      await sieveFullContainerAndLoad(
        sourceBatch.uri,
        sourceResource.uri,
        selectedAmountBase,
        destinationResource?.uri
      );

      if (isRequestedSieveAndLoadAction) {
        // No need for further actions
        return;
      }

      markSieveSuccess();
    } else {
      await fullContainerLoadAction(
        sourceResource.current_batch,
        action,
        destinationResource.uri,
        sourceBatch.quantity,
        sourceBatch.containers.length > 1,
        sourceResource.uri
      );
    }
  };

  const handlePartialContainerLoad = async shouldSieve => {
    if (isRequestedSieveAndLoadAction) {
      await sieveFullContainerSplitAndLoad(
        sourceBatch.uri,
        sourceResource.uri,
        selectedAmountBase,
        destinationResource?.uri,
        null,
        selectedAmountBase
      );

      // No need for further actions
      return;
    }

    if (shouldSieve) {
      await sieveAndLoadPartialContainerAction(
        false,
        sourceBatch.uri,
        sourceResource.uri,
        selectedAmountBase,
        isTopOff,
        null,
        destinationResource.uri,
        markSieveSuccess
      );
    } else {
      await partialLoadContainerAction(
        sourceResource.uri,
        sourceBatch.uri,
        destinationResource.uri,
        selectedAmountBase
      );
    }
  };

  const handleEmptyBatchLoad = async (batchUri, isFullQuantitySelected, shouldSieve) => {
    if (isFullQuantitySelected) {
      await handleFullEmptyBatchLoad(batchUri, shouldSieve);
    } else {
      await handlePartialEmptyBatchLoad(batchUri, shouldSieve);
    }
    closeAllActionPanels();
  };

  const handleFullEmptyBatchLoad = async (batchUri, shouldSieve) => {
    if (shouldSieve) {
      await sieveFullBatchAction(sourceBatch.uri, sourceBatch.quantity);
      markSieveSuccess();
    }

    await fullContainerLoadAction(
      batchUri,
      action,
      destinationResource?.uri,
      sourceBatch.quantity,
      sourceBatch.containers.length > 1,
      sourceResource?.uri
    );
  };

  const handlePartialEmptyBatchLoad = async (batchUri, shouldSieve) => {
    if (shouldSieve) {
      await sieveAndLoadPartialEmptyBatchAction(
        sourceBatch.uri,
        selectedAmountBase,
        isTopOff,
        destinationResource.uri,
        markSieveSuccess
      );
    } else {
      await partialLoadEmptyBatchAction(
        selectedAmountBase,
        batchUri,
        null,
        null,
        destinationResource?.uri
      );
    }
  };

  const hideRelocationWarningModal = () => setShowRelocateModal(false);

  const onActionRelocate = async () => {
    try {
      setSubmitting(true);

      if (isEmptyBatchLoad) {
        await relocateBatchAction(locationToRelocate, subLocationToRelocate, sourceBatch.uri);
      } else {
        await relocateContainerAction(
          locationToRelocate,
          subLocationToRelocate,
          resourceToRelocate,
          sourceBatch.uri
        );
      }
    } catch (error_) {
      console.error(error_);
      throw error_;
    }
  };

  const handleCancelAction = () => {
    closeActionPanel();
  };

  const selectedPercentage = Math.round((selectedAmountBase / sourceResource.quantity) * 100);

  const quantityBarMaximumHeight = 150;
  // Height of EMPTY space of bar. If batch is fully loaded, it's 0 value
  const sourceBatchAmountBarHeight =
    (selectedAmountBase / sourceResource.quantity) * quantityBarMaximumHeight;
  const newBatchAmountBarHeight = quantityBarMaximumHeight - sourceBatchAmountBarHeight;

  const formattedRemainingBase = formatThreeDecimalsNumber(
    sourceResource.quantity - selectedAmountBase
  );
  const formattedRemainingConverted = formatThreeDecimalsNumber(
    convertedSourceResourceQuantity - selectedAmountConverted
  );

  const getInputText = () => {
    return getTextForCondition(
      [[isTopOff, 'Top Off quantity:']],
      `${destinationResourceTitle} quantity:`
    );
  };

  const getRightColumnTitle = () => {
    return getTextForCondition([[]], destinationResourceTitle);
  };

  const renderSourceResourceName = () => {
    if (hideSourceResourceTitleHyperlinked) return null;
    const resourceUri = sourceResource.uri;
    const resourceLink = isEmptyBatchLoad
      ? getRouteURI(routes.materialContainer, {}, { batch: getUuid(resourceUri) })
      : getRouteURI(routes.permanentContainerDetails, { uuid: getUuid(resourceUri) });
    const resourceName = isEmptyBatchLoad
      ? getShortUuid(sourceBatch?.uri)
      : getContainerName(sourceResource);
    const isDisposableContainer = !isEmptyBatchLoad && sourceResource.disposable;
    const icon = (
      <FontAwesomeIcon icon={isEmptyBatchLoad ? faArchive : faCoins} className='spacer-right' />
    );

    return renderActionColumnResourceBody({
      isDisposable: isDisposableContainer,
      resourceLink,
      icon,
      resourceName,
    });
  };

  const renderDestinationResourceName = () => {
    if (hideDestinationResourceTitleHyperlinked) return null;

    const resourceUri = destinationResource?.uri;
    const resourceLink = getRouteURI(routes.permanentContainerDetails, {
      uuid: getUuid(resourceUri),
    });
    const resourceName = getContainerName(destinationResource);
    const isDisposableContainer = destinationResource.disposable;
    const icon = <FontAwesomeIcon icon={faCoins} className='spacer-right' />;

    return renderActionColumnResourceBody({
      isDisposable: isDisposableContainer,
      resourceLink,
      icon,
      resourceName,
    });
  };

  const renderLocationActionText = () => {
    return (
      <p>
        Before {actionTitle} Material, you can relocate the{' '}
        {isEmptyBatchLoad ? 'Entire Batch' : `Source ${sourceResourceTitle}`} using the{' '}
        {isEmptyBatchLoad ? 'Batch' : 'Container'} page or relocate the {destinationResourceTitle}{' '}
        now to match the Source Container location.
      </p>
    );
  };

  const handleBatchLoad = () => {
    openActionPanel({
      panelId: 'scan-source-resource',
      title: `Confirm ${!isTopOff ? 'Load' : 'Top Off'} ${destinationResourceTitle}`,
      stack: true,
      content: (
        <BatchLoadPanel
          isTopOff={isTopOff}
          sourceBatchUri={sourceBatch.uri}
          destinationResource={destinationResource}
          destinationResourceBatchUri={destinationResource.current_batch}
          destinationResourceTitle={destinationResourceTitle}
          actionTitle={actionTitle}
          action={action}
        />
      ),
    });
  };

  return (
    <div className='text-center'>
      <div className='alert'>
        <div className='d-flex align-items-base justify-content-center'>
          <label className='new-quantity-label'>{getInputText()}</label>
          <div className='mb15'>
            <input
              name='selectedAmount'
              min='0'
              style={{ width: 70 }}
              step={getInputStep(convertedSourceResourceQuantity)}
              max={convertedSourceResourceQuantity}
              type='number'
              value={selectedAmountConverted}
              onChange={handleSelectedAmountChange}
            />
            <span>
              {' '}
              {convertedBatchUnits} ({selectedPercentage}%)
            </span>
          </div>
        </div>

        <div className='row batch-container-split-list-row'>
          <div className='col-xs-6 resource-column-flexible-height justify-items-center'>
            <div className='mb10'>
              <div className='h5'>
                Source {sourceResourceTitle}
                {renderBatchPowderQualityIcon(
                  shouldWarnAboutBatchPowderQuality && !isRequestedSieveAndLoadAction,
                  sourceBatch
                )}
              </div>
              <div className='load-container-title'>{renderSourceResourceName()}</div>
            </div>

            <div>
              <div className='batch-material-amount'>
                <div
                  style={{ paddingTop: `${sourceBatchAmountBarHeight}px`, background: 'white' }}
                />
              </div>
              <div className='h6'>
                {formattedRemainingBase} {sourceBatch?.units}
              </div>
              <div className='header-margin'>
                {isConverted &&
                  formatConvertedUnits(formattedRemainingConverted, convertedBatchUnits)}
              </div>
            </div>
          </div>
          <div className='col-xs-6 resource-column-flexible-height justify-items-center'>
            <div className='mb10'>
              <div className='h5'>{getRightColumnTitle()}</div>
              <div className='load-container-title'>{renderDestinationResourceName()}</div>
            </div>
            <div>
              <div className='batch-material-amount position-relative'>
                <div style={{ paddingTop: `${newBatchAmountBarHeight}px`, background: 'white' }} />
              </div>
              {isTopOff && (
                <div className='top-off-current-amount'>
                  <ConvertedUnits
                    quantity={destinationResource?.quantity}
                    units={destinationBatch?.units}
                  />
                </div>
              )}
              <div className='h6'>
                {selectedAmountBase} {sourceBatch?.units}
              </div>
              <div className='header-margin'>
                {isConverted && formatConvertedUnits(selectedAmountConverted, convertedBatchUnits)}
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <RangeInputConversionTooltip
            visible={isConverted}
            defaultUnits={sourceBatch?.units}
            convertedUnits={convertedBatchUnits}
          />
          <input
            name='selectedAmountRange'
            min='0'
            step={getInputStep(sourceResource?.quantity, true)}
            max={sourceResource?.quantity}
            type='range'
            value={selectedAmountBase}
            onChange={e => handleRangeChange(e.target.value)}
          />
        </div>

        <button
          type='button'
          className={`btn btn-sm mb30 mt15 ${fullQuantitySelected ? 'btn-info' : 'btn-secondary'}`}
          disabled={isSubmitting || unsupportedMaterials.length > 0}
          onClick={handleLoadAllClick}
        >
          <p className='d-flex align-items-center justify-content-center mb0'>
            {fullQuantitySelected ? 'Unload All' : 'Load All'}
          </p>
        </button>
      </div>

      <button
        type='submit'
        className='btn btn-lg btn-primary action-btn btn-block'
        disabled={isSubmitting || unsupportedMaterials.length || !selectedAmountBase}
        onClick={confirmAndLoadMaterial}
      >
        <p className='d-flex align-items-center justify-content-center mb0'>
          Confirm
          {isSubmitting && <Loader inline className='spacer-left' showText={false} />}
          {unsupportedMaterials.length
            ? renderUnsupportedMaterialsText(
                { destinationResourceTitle },
                destinationResource?.name,
                unsupportedMaterials
              )
            : null}
        </p>
      </button>

      <button type='button' className='btn btn-default btn-action' onClick={handleCancelAction}>
        Cancel
      </button>

      <BatchMultipleContainersAlert
        sourceBatch={sourceBatch}
        destinationBatch={destinationBatch}
        sourceContainer={sourceResource}
        destinationContainer={destinationBatch?.containers?.length > 1 ? destinationResource : null}
        containerActionToOpen={sourceBatch?.containers?.length > 1 ? handleBatchLoad : null}
      />

      <Modal
        isOpen={showRelocateModal}
        title='Locations Mismatch'
        dismissText='Cancel'
        confirmText={`Relocate ${
          action === PERMANENT_CONTAINER_ACTIONS.SIEVE
            ? destinationResourceTitle
            : sourceResourceTitle
        }`}
        onDismiss={hideRelocationWarningModal}
        onConfirm={onModalConfirmRelocate}
      >
        <LocationsMismatch
          sourceBatch={sourceBatch}
          bottomTextRenderer={renderLocationActionText}
          destinationResource={destinationResource}
          locations={locations}
          subLocations={subLocations}
          sourceType={sourceResourceTitle}
          destinationType={destinationResourceTitle}
          sourceDisplayName={`(${
            isEmptyBatchLoad ? getShortUuid(sourceBatch?.uri) : getContainerName(sourceResource)
          })`}
          action={`${actionTitle} Material`}
        />
      </Modal>
    </div>
  );
};

PartialLoadPanel.defaultProps = {
  postSubmitAction: null,
};

PartialLoadPanel.propTypes = {
  sourceResource: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    /* eslint-disable camelcase */
    current_batch: PropTypes.string,
    location: PropTypes.string,
    sub_location: PropTypes.string,
    disposable: PropTypes.bool,
  }).isRequired,
  sourceResourceBatchUri: PropTypes.string.isRequired,
  destinationResource: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    quantity: PropTypes.number,
    location: PropTypes.string,
    sub_location: PropTypes.string,
    current_batch: PropTypes.string,
    disposable: PropTypes.bool,
  }).isRequired,
  destinationResourceBatchUri: PropTypes.string,
  isTopOff: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  isEmptyBatchLoad: PropTypes.bool.isRequired,
  relocateOptions: PropTypes.shape({
    resourceToRelocate: PropTypes.shape({
      uri: PropTypes.string,
    }),
    locationToRelocate: PropTypes.string,
    subLocationToRelocate: PropTypes.string,
  }).isRequired,
  actionTitle: PropTypes.string.isRequired,
  destinationResourceTitle: PropTypes.string.isRequired,
  hideDestinationResourceTitleHyperlinked: PropTypes.bool,
  hideSourceResourceTitleHyperlinked: PropTypes.bool,
  sourceResourceTitle: PropTypes.string.isRequired,
  postSubmitAction: PropTypes.func,
};

export default PartialLoadPanel;
