import { faBarcode } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import BarcodePanel from 'src/components/barcode-panel';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Feature from 'src/components/feature';
import RangeInputConversionTooltip from 'src/components/RangeInputConversionTooltip';
import FeaturesContext from 'src/context/FeaturesContext';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import useBarcodePanelStore from 'src/stores/useBarcodePanelStore';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS, MATERIAL_UNITS,
} from 'src/utils/constants';
import { convertMaterialQuantity, convertToUserUnits } from 'src/utils/conversions';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import { formatConvertedUnits, renderTotalQuantityTitle } from 'src/utils/ui';
import useQueryParams, { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import {
  getInputStep,
  handleConvertedQuantityChange, handleConvertedRangeChange,
  isValidCode128,
  validateMultipleZeros,
} from 'src/utils/validation';

import routes from '../../../../utils/routes';
import ActionPage from './_action-wrapper';

const MachineUnloadChamber = ({ user }) => {
  const { uuid: batchUUID } = useParams();
  const { actionFromPrinter } = useQueryParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(null);
  const [batch, setBatch] = useState(null);
  const [batchMaterial, setBatchMaterial] = useState(null);
  const [batchSelectedRun, setBatchSelectedRun] = useState(null);
  const [subLocation, setSubLocation] = useState(undefined);
  const [batchRuns, setBatchRuns] = useState([]);
  const [runActualsByRunUri, setRunActualsByRunUri] = useState({});
  const [unloadedQuantityBase, setUnloadedQuantityBase] = useState(0);
  const [unloadedQuantityConverted, setUnloadedQuantityConverted] = useState('');
  const { addToast } = useToasts();

  const { features } = useContext(FeaturesContext);
  const isExternalBuildIdFeatureEnabled = isFeatureEnabled(features, FEATURES.EXTERNAL_BUILD_ID);

  const quantityInMachine = batch?.at_machine ? batch.quantity : 0;

  const {
    scannedBarcodeValue,
    setScannedBarcodeValue,
    resetBarcodeValue,
  } = useBarcodePanelStore();

  const {
    openActionPanel,
  } = useActionPanelStore();

  const setQuantity = (quantity, units) => {
    setUnloadedQuantityBase(quantity);

    // Convert the initial unloaded quantity to user's preferred units
    const conversionResult = convertToUserUnits(quantity, units);
    setUnloadedQuantityConverted(conversionResult.quantity);
  };

  const navigate = useNavigate();

  const getInitialData = async () => {
    setLoading(true);
    try {
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();

      if (!batch) return;

      const batchMaterial = batch.materials[0];

      const fetchedBatchMaterial = await api.get(`${API_RESOURCES.MATERIAL}/${getUuid(batchMaterial.uri)}/`).json();

      const [subLocation, runActualsAdditiveResponse] = await Promise.all([
        api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batch.sub_location)}/`).json(),
        api.get(`${API_RESOURCES.RUN_ACTUALS_ADDITIVE}/`, {
          searchParams: {
            'filter[material_batch]': batch.uri,
          },
        }).json(),
      ]);

      const runActualsAdditive = runActualsAdditiveResponse?.resources || [];

      const { resources: runActuals } = !_isEmpty(runActualsAdditive) ?
        await api.get(`${API_RESOURCES.RUN_ACTUALS}/`, {
          searchParams: {
            'filter[uri]': _map(runActualsAdditive, 'run_actual'),
          },
        }).json() : [];

      const { resources: runs } = !_isEmpty(runActuals) ?
        await api.get(`${API_RESOURCES.RUN}/`, {
          searchParams: {
            'filter[uri]': _map(runActuals, 'run'),
          },
        }).json() : [];


      const runActualsByRunUri = _keyBy(runActuals, 'run');

      setBatch(batch);
      setBatchMaterial(fetchedBatchMaterial);
      setSubLocation(subLocation);

      setBatchRuns(runs || []);
      setRunActualsByRunUri(runActualsByRunUri || {});

      const quantityInMachine = batch.at_machine ? batch.quantity : 0;
      setQuantity(quantityInMachine, batch.units);

    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => void getInitialData(), [batchUUID]);

  // Convert batch quantity to user's preferred units
  const { quantity: convertedBatchQuantity, units: convertedBatchUnits, isConverted } =
    useMemo(() => {
      if (batch) {
        return convertToUserUnits(batch.quantity, batch.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [batch]);

  const handleUnloadedQuantityChange = (e) => {
    handleConvertedQuantityChange({
      e,
      setBaseQuantity: setUnloadedQuantityBase,
      setConvertedQuantity: setUnloadedQuantityConverted,
      batchQuantity: batch.quantity,
      batchUnits: batch.units,
      convertedBatchQuantity,
      convertedBatchUnits,
      validateMultipleZeros,
    });
  };

  const handleUnloadedRangeChange = (value) => {
    handleConvertedRangeChange({
      value,
      baseUnits: batch.units,
      baseQuantity: batch.quantity,
      convertedQuantity: convertedBatchQuantity,
      convertedUnits: convertedBatchUnits,
      setQuantityBaseHandler: setUnloadedQuantityBase,
      setQuantityConvertedHandler: setUnloadedQuantityConverted,
    });
  };

  const handleOpenExternalBuildIdBarcodeScanner = () => {
    resetBarcodeValue();
    openActionPanel({
      title: 'Scan Barcode',
      content: <BarcodePanel
        placeholder="Scan External Build ID"
        instructionText="Please scan or type the barcode" />,
    });
  };


  const remainingQuantity = batch ? batch.quantity - unloadedQuantityBase : 0;
  const remainingQuantityConverted = convertedBatchQuantity - unloadedQuantityConverted;
  const unloadedQuantityPercentage =
    batch && batch.quantity
      ? Math.round((unloadedQuantityBase / batch.quantity) * 100)
      : 0;

  const onActionUnloadedChamber = async (batchUri) => {
    if (isExternalBuildIdFeatureEnabled
      && (!_isEmpty(scannedBarcodeValue) && !isValidCode128(scannedBarcodeValue))) {
      return addToast('Invalid input: External Build ID must follow Code 128 barcode standards.', {
        appearance: 'error',
      });
    }

    let actionResult = null;
    try {
      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          unload_amount: unloadedQuantityBase,
          unload_type: 'consumed',
        },
        source_batch: batchUri,
        action_type: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER,
      };

      if (scannedBarcodeValue) {
        payload.metadata.external_build_id = scannedBarcodeValue;
      }

      if (batchSelectedRun) {
        payload.metadata.run = batchSelectedRun;
      }

      setSubmitting(true);
      actionResult = await materialActionRequest(payload).json();
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    resetBarcodeValue();

    const { metadata: { resulting_batch: unloadedBatch } } = actionResult;

    const run = batchSelectedRun ?
      batchRuns.find((run) => run.uri === batchSelectedRun) :
      null;

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(unloadedBatch) },
      {
        action: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER,
        batch: getUuid(unloadedBatch),
        quantity: unloadedQuantityBase,
        remainingQuantity,
        subLocation: subLocation.name,
        printer: batch.at_machine,
        runUUID: getUuid(run?.uri) || '',
        buildID: scannedBarcodeValue,
        ...(actionFromPrinter && { actionFromPrinter: true }),
      }));

    return new Promise(() => {
    });
  };

  const materialActionRequest = (payload) => {
    return api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
      json: payload,
    });
  };

  const handleSetBatchRun = (uri) => {
    setBatchSelectedRun(uri);
    // Get the Material Used from the selected Run
    const runActualsBaseMaterialUsed = runActualsByRunUri[uri]?.additive?.base_material_used || 0;
    const materialDensity = batchMaterial?.density || 0;

    // Do not set the Quantity if the Material Used is 0 or not defined
    if (!runActualsBaseMaterialUsed) {
      // Run Selector is not set.
      setQuantity(quantityInMachine, batch.units);
      return;
    }

    const { quantity } = convertMaterialQuantity(
      // base_material_used value
      runActualsBaseMaterialUsed,
      // backend will always return it in CM3
      MATERIAL_UNITS.CM3,
      // get batch.units as the destination units
      batch.units,
      // if it is weight material -> density is required and defined in batch.materials[0].density
      materialDensity
    );

    // If the Material Used is greater than the Quantity in the Machine, set the Quantity to the Quantity in the Machine

    if ((quantity > quantityInMachine)) {
      setQuantity(quantityInMachine, batch.units);
      return;
    }

    // Set the Quantity to the Material Used
    setQuantity(quantity, batch.units);
  };

  return (
    <ActionPage
      id={batchUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER}
      isLoading={isLoading}
    >
      {batch &&
        <>
          <div className="alert alert-warning" role="alert">
            <b>Amount of material originally loaded into machine:</b>&nbsp;
            <ConvertedUnits quantity={quantityInMachine} units={batch.units} />
          </div>
          <div className="alert">
            <div>
              <label>
                Record the weight of the build as extracted from the build chamber
                (this may include trapped powder). If you cannot weigh the build,
                please enter an estimated build weight based on the volume of the build and the used material&apos;s
                density.
              </label>

              {renderTotalQuantityTitle(
                remainingQuantity,
                remainingQuantityConverted,
                batch?.units,
                convertedBatchUnits,
                isConverted,
              )}

              <Feature isInverted featureName={FEATURES.EXTERNAL_BUILD_ID}>
                {!_isEmpty(batchRuns) &&
                  <select
                    className="form-control mt-4 mb-4"
                    value={batchSelectedRun?.uri}
                    onChange={(event) => handleSetBatchRun(event.target.value)}
                  >
                    <option value="">Select Run</option>
                    {batchRuns.map((run) => (
                      <option key={run?.uri} value={run?.uri}>{run?.name}</option>
                    ))}
                  </select>}
              </Feature>
              <input
                name="remainingQuantity"
                min="0"
                className="resource-input-field"
                step={getInputStep(convertedBatchQuantity)}
                max={convertedBatchQuantity}
                type="number"
                value={unloadedQuantityConverted}
                onChange={handleUnloadedQuantityChange}
              />
              &nbsp;<span>{convertedBatchUnits} ({unloadedQuantityPercentage}%)</span>

              <div className="d-flex align-items-center justify-content-center mt15">
                <div>
                  <div className="mb5">0 ({batch?.units})</div>
                  <div>{isConverted && formatConvertedUnits(0, convertedBatchUnits)}</div>
                </div>
                <div className="position-relative">
                  <RangeInputConversionTooltip
                    className="rangeInputAbsolute"
                    visible={isConverted} defaultUnits={batch?.units || ''}
                    convertedUnits={convertedBatchUnits} />
                  &nbsp;
                  <input
                    name="remainingQuantityRange"
                    min="0"
                    step={getInputStep(batch?.quantity, true)}
                    max={batch?.quantity}
                    type="range"
                    value={unloadedQuantityBase}
                    onChange={(e) => handleUnloadedRangeChange(e.target.value)}
                  /> &nbsp;
                </div>
                <div>
                  <div className="mb5">{batch?.quantity} ({batch?.units})</div>
                  <div>{isConverted && formatConvertedUnits(convertedBatchQuantity, convertedBatchUnits)}</div>
                </div>
              </div>

              <Feature featureName={FEATURES.EXTERNAL_BUILD_ID}>
                <div className="input-group mt-4 mb-4 d-flex align-items-center">
                  <input
                    name="storedAmount"
                    value={scannedBarcodeValue}
                    type="text"
                    aria-label="External Build ID"
                    placeholder="Enter Build ID"
                    className="form-control"
                    onChange={(event) =>
                      setScannedBarcodeValue({ scannedBarcodeValue: event.target.value })}
                  />
                  <button
                    type="button" className="btn btn-link btn-sm barcode-scan-btn"
                    onClick={handleOpenExternalBuildIdBarcodeScanner}
                  >
                    <FontAwesomeIcon icon={faBarcode} className="spacer-left" />
                  </button>
                </div>
              </Feature>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary btn-block"
            disabled={!batch.at_machine || isSubmitting}
            onClick={() => onActionUnloadedChamber(batch.uri)}
          >
            Save
          </button>
        </>}

    </ActionPage>
  );
};

MachineUnloadChamber.propTypes = {
  user: userPropType,
};

MachineUnloadChamber.defaultProps = {
  user: null,
};

export default MachineUnloadChamber;
