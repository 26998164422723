import { faArrowsSpin, faArrowUpShortWide, faLocationDot, faObjectGroup } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _isEmpty from 'lodash/isEmpty';
import PropTypes from "prop-types";
import React from "react";
import { Button, Spinner } from 'react-bootstrap';
import BatchPowderQualityStatusIcon from "src/components/BatchPowderQualityStatusIcon";
import ConvertedUnits from "src/components/ConvertedUnits";
import StatusColorCode from "src/components/status-color-dot";
import Tooltip from 'src/components/Tooltip';
import TruncatedTitle from "src/components/TruncatedTitle";
import {
  MATERIAL_BATCH_STATUS_VERBOSE,
  STATUS_COLOR_CODE_TYPES,
} from 'src/utils/constants';
import { renderDynamicContent } from 'src/utils/mlineUtils';
import { getShortUuid } from "src/utils/url";

const MHSCard = ({
  mhsMachine,
  batch,
  allowedMaterials,
  postProcessorType,
  loadingState,
  mhsLocationData,
  handleActionPanel,
}) => {
  if (!mhsMachine) return null;

  const {
    postProcessorTypeLoading,
    allowedMaterialsLoading,
    cycloneBatchLoading,
  } = loadingState;

  const {
    location,
    isMhsLocationLoading,
    subLocation,
    isMhsSubLocationLoading,
  } = mhsLocationData;

  const allowedMaterialNames = !_isEmpty(allowedMaterials) ? allowedMaterials.map((material) => material.name) : [];

  const renderMhsLocationData = () => (
    renderDynamicContent(
      isMhsLocationLoading || isMhsSubLocationLoading,
      location && subLocation && (
        <div className="d-flex text-left">
          <FontAwesomeIcon icon={faLocationDot} className="spacer-right mt5" />
          <div>
            <TruncatedTitle
              title={location.name}
              titleClassName='mb0 font-medium font-size-14'
              maxTextLength={25}
              maxTextLengthMobile={20}
          />
            <TruncatedTitle
              title={subLocation.name}
              titleClassName='mb0 font-light font-size-12'
              maxTextLength={35}
              maxTextLengthMobile={30}
          />
          </div>
        </div>
      )
    )
  );

  const renderPostProcessorType = () =>
    renderDynamicContent(
      postProcessorTypeLoading,
      postProcessorType && (
        <div className="mhs-card-machine-type">
          <FontAwesomeIcon icon={faPrint} className="spacer-right" />
          <span className="font-light">{postProcessorType.name}</span>
        </div>
      )
    );

  const renderAllowedMaterials = () =>
    renderDynamicContent(
      allowedMaterialsLoading,
      !_isEmpty(allowedMaterialNames) && (
        <div>
          Allowed Materials
          <Tooltip
            id='mhs_allowed_materials_tooltip'
            placement='bottom'
            trigger={(
              <FontAwesomeIcon className="spacer-left" icon={faInfoCircle} />
            )}
          >
            <div>{allowedMaterialNames.join(', ')}</div>
          </Tooltip>
        </div>
      )
    );

  const renderCycloneBatch = () => (
    renderDynamicContent(
      cycloneBatchLoading,
      batch && (
        <div className="mhs-card-batch-info-top w-auto">
          <div className="d-flex align-items-center mr30">
            <div className="mr10">
              <FontAwesomeIcon className="spacer-right" icon={faArrowsSpin} />
              {batch.usage_cycles}
            </div>
            <div className="d-flex align-items-center mr5">
              <StatusColorCode
                status={batch.status}
                type={STATUS_COLOR_CODE_TYPES.BATCH_STATUS}
                sizeClassName="small"
              />
              <div className="spacer-left text-white">
                {MATERIAL_BATCH_STATUS_VERBOSE[batch.status]}
              </div>
            </div>
            {batch.powder_quality && (
              <BatchPowderQualityStatusIcon smaller qualityStatus={batch.powder_quality} />
            )}
          </div>
          <div className="mhs-card-batch-details">
            <h4 className="mhs-card-batch">Batch</h4>
            <Button
              type="button"
              variant="link"
              className="batch-card-main-details-main-link d-flex align-items-center font-size-14 font-light p-0 ml-auto border-0"
              onClick={handleActionPanel}
            >
              {getShortUuid(batch.uri)}
            </Button>
          </div>
        </div>
      ),
      <Spinner animation='border' />
    )
  );

  return (
    <div className="mhs-card">
      <div className="mhs-card-top-content" style={{ gap: '20px' }}>
        <div className="text-left" style={{ flex: '1' }}>
          <TruncatedTitle
            inline
            title={(<h4 className="mb0">{mhsMachine.name}</h4>)}
            titleClassName="font-bold mb0"
            maxTextLength={25}
            maxTextLengthMobile={20}
          />
          <div className="font-light mb10">{getShortUuid(mhsMachine.uri)}</div>
          {renderAllowedMaterials()}
        </div>
        {renderCycloneBatch()}
      </div>
      <div className="mhs-card-bottom-content">
        <div className="align-self-end">
          {renderPostProcessorType()}
          {renderMhsLocationData()}
        </div>
        {batch && (
          <div className="text-right">
            <div className="mhs-module-card-dose-quantity">
              <FontAwesomeIcon className="spacer-right" icon={faArrowUpShortWide} />
              <ConvertedUnits quantity={batch.quantity} units={batch.units} />
            </div>
            <div className="mhs-module-card-dose-materials">
              <FontAwesomeIcon className="spacer-right" icon={faObjectGroup} />
              <TruncatedTitle
                inline
                title={batch.materials[0].name}
                titleClassName="mb0"
                maxTextLength={25}
                maxTextLengthMobile={20}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

MHSCard.propTypes = {
  mhsMachine: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  batch: PropTypes.shape({
    uri: PropTypes.string,
    status: PropTypes.string,
    materials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    quantity: PropTypes.number,
    units: PropTypes.string,
    /* eslint-disable camelcase */
    powder_quality: PropTypes.string,
    usage_cycles: PropTypes.number,
  }).isRequired,
  allowedMaterials: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  postProcessorType: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  loadingState: PropTypes.shape({
    postProcessorTypeLoading: PropTypes.bool,
    allowedMaterialsLoading: PropTypes.bool,
    cycloneBatchLoading: PropTypes.bool,
  }).isRequired,
  mhsLocationData: PropTypes.shape({
    location: PropTypes.shape({
      name: PropTypes.string,
    }),
    subLocation: PropTypes.shape({
      name: PropTypes.string,
    }),
    isMhsLocationLoading: PropTypes.bool,
    isMhsSubLocationLoading: PropTypes.bool,
  }).isRequired,
  handleActionPanel: PropTypes.func.isRequired,
};

export default MHSCard;
