import convert from 'convert-units';
import {
  CONVERT_UNITS_MAP,
  IMPERIAL_DEFAULT_UNITS,
  IMPERIAL_UNITS,
  LOCALSTORAGE_KEYS,
  MATERIAL_UNITS,
  MEASUREMENT_SYSTEM,
  METRIC_DEFAULT_UNITS,
  METRIC_UNITS,
  VOLUME_UNITS,
  WEIGHT_UNITS,
} from 'src/utils/constants';

/**
 * Formats a given quantity to three decimal places.
 *
 * @param {number} quantity - The number to format.
 * @param {boolean} [roundUp=false] - Whether to round up the quantity.
 * @returns {number} The formatted number with up to three decimal places.
 */
export const formatThreeDecimalsNumber = (quantity, roundUp = false) => {
  // If quantity is undefined, null, or not a number, return 0
  if (quantity === undefined || quantity === null || isNaN(quantity)) return 0;

  // Ensure the quantity is of type number
  if (typeof quantity !== 'number') return 0;

  // If rounding up is requested, use Math.ceil after scaling
  if (roundUp) {
    return Math.ceil(quantity * 1000) / 1000;
  }

  // Otherwise, round to three decimal places using toFixed and parseFloat
  return parseFloat(quantity.toFixed(3));
};

/**
 * Helper function to perform unit conversion with optional post-processing.
 *
 * @param {number} quantity - The quantity to convert.
 * @param {string} fromUnit - The current unit.
 * @param {string} toUnit - The target unit.
 * @param {object} [options] - Optional parameters for post-processing.
 * @param {function} [options.postProcess] - A function to process the converted quantity.
 * @returns {{ quantity: number, units: string, isConverted: boolean }} The conversion result.
 */
export const convertUnits = (quantity, fromUnit, toUnit, options = {}) => {
  const { postProcess } = options;

  // If both units are the same, no conversion needed
  if (fromUnit === toUnit) {
    // Format the quantity to three decimals
    const formattedQuantity = formatThreeDecimalsNumber(quantity);

    // If a postProcess function is provided, apply it to the formatted quantity
    const finalQuantity = postProcess ? postProcess(formattedQuantity) : formattedQuantity;

    // Return the result without indicating a conversion
    return { quantity: finalQuantity, units: toUnit, isConverted: false };
  }

  try {
    // Perform the conversion using the 'convert-units' library
    const converted = convert(quantity)
      .from(CONVERT_UNITS_MAP[fromUnit]) // Map the fromUnit to the library's expected unit
      .to(CONVERT_UNITS_MAP[toUnit]); // Map the toUnit to the library's expected unit

    // Format the converted quantity to three decimals
    const formattedQuantity = formatThreeDecimalsNumber(converted);

    // If a postProcess function is provided, apply it to the formatted quantity
    const finalQuantity = postProcess ? postProcess(formattedQuantity) : formattedQuantity;

    // Return the converted quantity with the target unit and a flag indicating successful conversion
    return { quantity: finalQuantity, units: toUnit, isConverted: true };
  } catch (error) {
    // Log a warning if the conversion fails (e.g., unsupported units)
    console.warn(`Conversion error from ${fromUnit} to ${toUnit}:`, error);

    // Return the original quantity formatted, keeping the original unit and indicating no conversion
    return { quantity: formatThreeDecimalsNumber(quantity), units: fromUnit, isConverted: false };
  }
};

/**
 * Converts a given quantity from one unit to another using convert-units library.
 *
 * @param {number} quantity - The quantity to be converted.
 * @param {string} currentUnit - The unit of the given quantity (e.g., "LB", "KG", "CM3").
 * @param {string} targetVolumeUnit - The target volume unit for conversion (e.g., "L", "ML").
 * @param {string} targetWeightUnit - The target weight unit for conversion (e.g., "LB", "GRAM").
 * @returns {{ quantity: number, units: string, isConverted: boolean }} The conversion result.
 */
export const performConversion = (quantity, currentUnit, targetVolumeUnit, targetWeightUnit) => {
  // Check if the current unit is a weight unit and a target weight unit is provided
  if (WEIGHT_UNITS.includes(currentUnit) && targetWeightUnit) {
    // Perform the conversion from currentWeightUnit to targetWeightUnit
    return convertUnits(quantity, currentUnit, targetWeightUnit);
  }

  // Check if the current unit is a volume unit and a target volume unit is provided
  if (VOLUME_UNITS.includes(currentUnit) && targetVolumeUnit) {
    // Perform the conversion from currentVolumeUnit to targetVolumeUnit
    return convertUnits(quantity, currentUnit, targetVolumeUnit);
  }

  // If no applicable conversion is found, return the original quantity and unit without conversion
  return { quantity: formatThreeDecimalsNumber(quantity), units: currentUnit, isConverted: false };
};

/**
 * Retrieves unit settings from localStorage.
 *
 * @returns {object} The unit settings.
 */
export const getUnitsSettings = () => {
  // Attempt to retrieve the units settings from localStorage using the predefined key
  const settings = window.localStorage.getItem(LOCALSTORAGE_KEYS.UNITS_SETTINGS);

  // If no settings are found, return default settings
  if (!settings) {
    return {
      measurementSystem: MEASUREMENT_SYSTEM.METRIC, // Default to Metric system
      customVolumeUnit: METRIC_DEFAULT_UNITS.volume, // Default volume unit for Metric
      customWeightUnit: METRIC_DEFAULT_UNITS.weight, // Default weight unit for Metric
    };
  }

  try {
    // Parse the JSON string from localStorage into an object
    return JSON.parse(settings);
  } catch (error) {
    // Log a warning if parsing fails and return default settings
    console.warn('Failed to parse unitsSettings from localStorage:', error);
    return {
      measurementSystem: MEASUREMENT_SYSTEM.METRIC,
      customVolumeUnit: METRIC_DEFAULT_UNITS.volume,
      customWeightUnit: METRIC_DEFAULT_UNITS.weight,
    };
  }
};

/**
 * Converts the given quantity to user-preferred units based on measurement system settings.
 *
 * @param {number} quantity - The quantity to be converted.
 * @param {string} units - The current unit of the quantity.
 * @returns {{ quantity: number, units: string, isConverted: boolean }} The conversion result.
 */
export const convertToUserUnits = (quantity, units) => {
  // Retrieve the user's unit settings from localStorage
  const { measurementSystem, customVolumeUnit, customWeightUnit } = getUnitsSettings();

  // Determine if the current unit is metric or imperial
  const isMetricUnit = METRIC_UNITS.includes(units);
  const isImperialUnit = IMPERIAL_UNITS.includes(units);

  // If the unit is neither metric nor imperial, log a warning and return the original quantity and unit
  if (!isMetricUnit && !isImperialUnit) {
    console.warn(`Unknown unit: ${units}`);
    return { quantity: formatThreeDecimalsNumber(quantity), units, isConverted: false };
  }

  // If the user has selected a custom measurement system
  if (measurementSystem === MEASUREMENT_SYSTEM.CUSTOM) {
    // Check if the current unit matches the user's custom units
    const isCustomWeightUnit = WEIGHT_UNITS.includes(units) && units === customWeightUnit;
    const isCustomVolumeUnit = VOLUME_UNITS.includes(units) && units === customVolumeUnit;

    if (isCustomWeightUnit || isCustomVolumeUnit) {
      // If the current unit matches the custom unit, no conversion is needed
      return { quantity: formatThreeDecimalsNumber(quantity), units, isConverted: false };
    }

    // Perform conversion to the user's custom volume or weight unit
    return performConversion(quantity, units, customVolumeUnit, customWeightUnit);
  }

  // Determine the default units based on the user's selected measurement system
  const defaultUnits =
    measurementSystem === MEASUREMENT_SYSTEM.METRIC ? METRIC_DEFAULT_UNITS : IMPERIAL_DEFAULT_UNITS;

  const targetVolumeUnit = defaultUnits.volume; // Target volume unit based on measurement system
  const targetWeightUnit = defaultUnits.weight; // Target weight unit based on measurement system

  // Check if the current unit is already in the user's selected measurement system
  const isSameSystem =
    (measurementSystem === MEASUREMENT_SYSTEM.METRIC && isMetricUnit) ||
    (measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL && isImperialUnit);

  if (isSameSystem) {
    // If the unit is already in the desired measurement system, no conversion is needed
    return { quantity: formatThreeDecimalsNumber(quantity), units, isConverted: false };
  }

  // Determine the type of unit (weight or volume) and perform the appropriate conversion
  if (WEIGHT_UNITS.includes(units)) {
    // Convert weight unit to the target weight unit
    return performConversion(quantity, units, null, targetWeightUnit);
  }

  if (VOLUME_UNITS.includes(units)) {
    // Convert volume unit to the target volume unit
    return performConversion(quantity, units, targetVolumeUnit, null);
  }

  // Fallback: if unit type is neither weight nor volume, return original quantity and unit
  return { quantity: formatThreeDecimalsNumber(quantity), units, isConverted: false };
};

/**
 * Converts the given quantity back to the original unit.
 *
 * @param {number} quantity - The quantity to be converted.
 * @param {string} currentUnit - The current unit of the quantity.
 * @param {string} originalUnit - The unit to convert back to.
 * @returns {{ quantity: number, units: string, isConverted: boolean }} The conversion result.
 */
export const performReverseConversion = (quantity, currentUnit, originalUnit) => {
  // Check if both currentUnit and originalUnit are valid and exist in the conversion map
  if (!CONVERT_UNITS_MAP[currentUnit] || !CONVERT_UNITS_MAP[originalUnit]) {
    // Log a warning if either unit is invalid and return the original quantity and unit
    console.warn(`Invalid units for reverse conversion: ${currentUnit}, ${originalUnit}`);
    return {
      quantity: formatThreeDecimalsNumber(quantity),
      units: currentUnit,
      isConverted: false,
    };
  }

  /**
   * Define a post-processing function to handle specific rounding and precision needs
   * after the conversion is performed.
   *
   * @param {number} converted - The converted quantity before post-processing.
   * @returns {number} The post-processed converted quantity.
   */
  const postProcess = converted => {
    // Apply a tiny increment to address floating-point precision errors and round to three decimals
    let rounded = Math.round((converted + 1e-10) * 1000) / 1000;

    // Define units that should only have integer quantities
    const integerUnits = [MATERIAL_UNITS.MM3];

    // If the original unit requires integer quantities, round accordingly
    if (integerUnits.includes(originalUnit)) {
      rounded = Math.round(rounded);
    }

    return rounded;
  };

  // Perform the unit conversion with the defined post-processing function
  return convertUnits(quantity, currentUnit, originalUnit, { postProcess });
};

/**
 * Rounds a given number to the specified precision.
 *
 * @param {number} value - The number to be rounded.
 * @param {number} [precision=3] - The number of decimal places to round to. Defaults to 3 if not provided.
 * @returns {number} The rounded number with the specified precision.
 *
 * @example
 * roundToPrecision(3.14159); // Returns 3.142
 * roundToPrecision(3.14159, 2); // Returns 3.14
 * roundToPrecision(123.456, 0); // Returns 123
 */
export const roundToPrecision = (value, precision = 3) => {
  // Calculate the factor based on the desired precision (e.g., 1000 for 3 decimals)
  const factor = Math.pow(10, precision);

  // Multiply, round, and divide to achieve the desired precision
  return Math.round(value * factor) / factor;
};

/**
 * Converts a material quantity from cm³ to a target unit.
 *
 * Since the source value is always in cubic centimeters (cm³), this helper checks if the target unit
 * is a volume or weight unit:
 *
 * - **Volume Conversion:** If the target unit is a volume unit (e.g. L, ML, INCH3), it simply converts from
 *   cm³ to the desired volume unit using the existing conversion logic.
 *
 * - **Weight Conversion:** If the target unit is a weight unit (e.g. KG, LB), a valid density (in g/cm³)
 *   is required. The function converts the cm³ value to grams (by multiplying by density) and then converts
 *   grams to the target weight unit.
 *
 * If density is not provided (or is less than or equal to 0) when converting from volume to weight,
 * the conversion is aborted with a warning.
 *
 * @param {number} quantity - The quantity in cm³.
 * @param {string} fromUnit - The source unit; should be MATERIAL_UNITS.CM3.
 * @param {string} toUnit - The target unit (can be a volume or weight unit).
 * @param {number} density - Material density in grams per cubic centimeter (g/cm³) <- we use this unit for density. Required for weight conversions.
 * @param {object} [options] - Optional parameters.
 * @param {function} [options.postProcess] - A function to further adjust the final converted value.
 * @returns {{ quantity: number, units: string, isConverted: boolean }}
 *    An object containing the final converted quantity, the target unit, and a flag indicating if conversion occurred.
 *
 * @example
 * // Converting 100 cm³ of material with density 1.2 g/cm³ into kilograms:
 * const result = convertMaterialQuantity(100, MATERIAL_UNITS.CM3, MATERIAL_UNITS.KG, 1.2);
 * // { quantity: 0.12, units: MATERIAL_UNITS.KG, isConverted: true }
 *
 * @example
 * // Converting 100 cm³ to liters (density not needed):
 * const result = convertMaterialQuantity(100, MATERIAL_UNITS.CM3, MATERIAL_UNITS.L, 0);
 * // { quantity: convertedValue, units: MATERIAL_UNITS.L, isConverted: true }
 */
export const convertMaterialQuantity = (quantity, fromUnit, toUnit, density, options = {}) => {
  const { postProcess } = options;

  // Validate the input quantity.
  if (quantity == null || isNaN(quantity)) {
    return { quantity: 0, units: toUnit, isConverted: false };
  }

  // If no conversion is needed.
  if (fromUnit === toUnit) {
    const formatted = formatThreeDecimalsNumber(quantity);
    const finalQuantity = postProcess ? postProcess(formatted) : formatted;
    return { quantity: finalQuantity, units: toUnit, isConverted: false };
  }

  // Determine if the target unit is a volume or weight unit.
  const isTargetVolume = VOLUME_UNITS.includes(toUnit);
  const isTargetWeight = WEIGHT_UNITS.includes(toUnit);

  // If converting to a volume unit, simply convert from cm³ to the desired volume.
  if (isTargetVolume) {
    return convertUnits(quantity, fromUnit, toUnit, { postProcess });
  }

  // For weight conversion, density is required.
  if (isTargetWeight) {
    if (!density || density <= 0) {
      console.warn('Density must be provided and greater than 0 to convert volume to weight.');
      return { quantity: formatThreeDecimalsNumber(quantity), units: fromUnit, isConverted: false };
    }
    // Since the source is cm³, no conversion is needed for volume.
    const volumeInCm3 =
      fromUnit === MATERIAL_UNITS.CM3
        ? quantity
        : convertUnits(quantity, fromUnit, MATERIAL_UNITS.CM3).quantity;
    // Multiply the volume (cm³) by density (g/cm³) to obtain weight in grams.
    const grams = volumeInCm3 * density;
    // Convert grams to the target weight unit.
    return convertUnits(grams, MATERIAL_UNITS.GRAM, toUnit, { postProcess });
  }

  // Fallback: if target unit is not recognized.
  console.warn(`Unable to determine conversion path from ${fromUnit} to ${toUnit}`);
  return { quantity: formatThreeDecimalsNumber(quantity), units: fromUnit, isConverted: false };
};
