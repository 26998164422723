import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MLineMHSPage from 'src/pages/mline/mhs';
import PrivateRoute from 'src/routes/PrivateRoute';

import NotFoundPage from '../pages/not-found';

const PostProcessorRoutes = () => (
  <Routes>
    <Route index path='/:uuid' element={<PrivateRoute Component={MLineMHSPage} />} />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default PostProcessorRoutes;
