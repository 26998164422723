import { useQuery } from '@tanstack/react-query';
import _isEmpty from 'lodash/isEmpty';
import { fetcher } from 'src/api/fetcher';
import { API_RESOURCES } from 'src/utils/constants';

export const useMaterialsByUri = (materialUris, key = 'materialsByUris', options = {}) => {
  return useQuery({
    queryKey: ['materials-by-uri-resources', key, materialUris],
    queryFn: () =>
      fetcher(API_RESOURCES.MATERIAL, {
        params: {
          'filter[uri]': materialUris,
        },
      }),
    enabled: !_isEmpty(materialUris),
    select: data => data.resources,
    ...options,
  });
};
