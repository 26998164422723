import { useQuery } from '@tanstack/react-query';
import { fetcher } from 'src/api/fetcher';
import { API_RESOURCES } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

export const useLocationResourcesByUri = (locationUri, key = 'byUri') => {
  return useQuery({
    queryKey: ['location-resources', key, locationUri],
    queryFn: () =>
      fetcher(API_RESOURCES.LOCATION, {
        params: {
          'filter[uri]': locationUri,
        },
      }),
    enabled: !!locationUri,
    select: data => data.resources,
  });
};

export const useSubLocationResourcesByUri = (subLocationUri, key = 'byUri', options = {}) => {
  return useQuery({
    queryKey: ['sub-location-resources', key, subLocationUri],
    queryFn: () =>
      fetcher(API_RESOURCES.SUB_LOCATION, {
        params: {
          'filter[uri]': subLocationUri,
        },
      }),
    enabled: !!subLocationUri,
    select: data => data.resources,
    ...options,
  });
};

export const useLocation = (locationUri, key = 'location-by-uri') => {
  return useQuery({
    queryKey: ['location', key, locationUri],
    queryFn: () => fetcher(`${API_RESOURCES.LOCATION}/${getUuid(locationUri)}`),
    enabled: !!locationUri,
  });
};

export const useSubLocation = (subLocationUri, key = 'sub-location-by-uri') => {
  return useQuery({
    queryKey: ['sub-location', key, subLocationUri],
    queryFn: () => fetcher(`${API_RESOURCES.SUB_LOCATION}/${getUuid(subLocationUri)}`),
    enabled: !!subLocationUri,
  });
};

export const useResourceLocationInfo = resource => {
  const { data: location } = useLocation(resource?.location, 'location-by-uri-mismatch');
  const { data: subLocation } = useSubLocation(resource?.sub_location, 'location-by-uri-mismatch');
  return { location, subLocation };
};
