import { faArrowDownLong, faArrowUpLong, faArrowUpShortWide, faBars } from "@fortawesome/free-solid-svg-icons";
import { faFillDrip } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _isEmpty from 'lodash/isEmpty';
import PropTypes from "prop-types";
import React from "react";
import { Dropdown, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ConvertedUnits from "src/components/ConvertedUnits";
import Scan from 'src/components/scan/scan';
import { MHS_CYCLONE, MHS_MODULES, MLINE_ACTIONS_ALLOWED_RESOURCES } from 'src/constants/m-line';
import { MATERIAL_BATCH_ACTIONS, PERMANENT_CONTAINER_ACTIONS } from 'src/utils/constants';
import { isModuleEmpty } from 'src/utils/mlineUtils';
import { getRouteURI, getUuid } from 'src/utils/url';

import routes from '../../../../utils/routes';

const MHSActions = ({
  modules,
  cyclone,
  handleProcessCycloneLoad,
  batch,
  actionPanelProps,
  handleSieveToOutput,
  handleRequestLoadFromInput,
  transactionsLoading,
  inputModuleTransactions,
  cycloneLoadingState,
  mhsLoading,
}) => {
  const {
    cycloneLoading,
    cycloneBatchLoading,
  } = cycloneLoadingState;

  if (mhsLoading || cycloneLoading) {
    return <Spinner animation="border" className="mb15 mt15" />;
  }

  if (!cyclone) return null;

  const isOutputModuleEmpty = isModuleEmpty(modules, MHS_MODULES.OUTPUT);
  const isInputModuleEmpty = isModuleEmpty(modules, MHS_MODULES.INPUT);
  const inputModuleQuantity = modules.find(module => module.slot === MHS_MODULES.INPUT)?.module?.quantity || 0;
  const inputModuleEmptyAndNoTransactions = _isEmpty(inputModuleTransactions) && !inputModuleQuantity;

  const { openActionPanel } = actionPanelProps;

  const handleScanAndLoadCyclone = () => {
    const action = cyclone.current_batch
      ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF
      : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL;
    const allowedResourcesToScan = MLINE_ACTIONS_ALLOWED_RESOURCES[MHS_CYCLONE];

    openActionPanel({
      panelId: 'scan-source-resource',
      title: `Scan Source Resource`,
      isSidebar: true,
      content: (<Scan
        action={action}
        allowedResourcesToScan={allowedResourcesToScan}
        handleAction={(resourceUri, resourceName) => handleProcessCycloneLoad(action, resourceUri, resourceName)} />
      ),
    });
  };

  const quantity = cyclone.quantity || 0;
  const units = batch?.units;
  const isCycloneEmpty = quantity === 0 && !batch;

  return (
    <div className="mhs-actions">
      <div className="position-relative">
        <button
          type="button"
          className="btn btn-lg btn-primary action-btn-cyclone action-mhs-cyclone-load"
          onClick={handleScanAndLoadCyclone}
        >
          <FontAwesomeIcon icon={faFillDrip} className="spacer-right" />
          <div>{isCycloneEmpty ? 'Load' : 'Top Off'}</div>
        </button>
        <Dropdown align="end" className="position-absolute" style={{ top: 0, right: 0 }}>
          <Dropdown.Toggle className="mhs-menu-button">
            <FontAwesomeIcon icon={faBars} />
          </Dropdown.Toggle>
          <Dropdown.Menu className="mhs-menu-inner">
            <Dropdown.Item
              as={Link}
              className="mhs-menu-link"
              to={getRouteURI(routes.materialBatchAction,
                { uuid: getUuid(batch?.uri) },
                {
                  type: MATERIAL_BATCH_ACTIONS.SPLIT_BATCH,
                  batchMultipleContainers: true,
                }
              )}
            >
              Unload Material from Cyclone
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="mhs-cyclone-container">
        <div className="mhs-cyclone-outer">
          <div className="mhs-cyclone-inner">
            <div className="mhs-cyclone-title">Cyclone</div>
            {cycloneBatchLoading || cycloneLoading ? <Spinner animation="border" variant="light" /> : (
              <div className="mhs-cyclone-icon-row">
                <FontAwesomeIcon icon={faArrowUpShortWide} className="mhs-cyclone-arrow" />
                <span className="mhs-cyclone-quantity">
                  {!batch ? "Empty" : <ConvertedUnits quantity={quantity} units={units} />}
                </span>
              </div>
                )}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <button
          type="button"
          disabled={isCycloneEmpty || isOutputModuleEmpty}
          className="btn btn-lg btn-primary action-btn-cyclone action-mhs-cyclone-sieve-to-output"
          onClick={handleSieveToOutput}
        >
          <FontAwesomeIcon icon={faArrowDownLong} className="spacer-right" />
          <div>Sieve To</div>
          <div>Module</div>
        </button>
        <button
          type="button"
          disabled={isInputModuleEmpty || transactionsLoading || inputModuleEmptyAndNoTransactions}
          className="btn btn-lg btn-primary action-btn-cyclone action-mhs-cyclone-load-from-input"
          onClick={handleRequestLoadFromInput}
        >
          {(transactionsLoading && !isInputModuleEmpty)
            ? <Spinner animation="grow" />
            : <FontAwesomeIcon icon={faArrowUpLong} className="spacer-right" />}
          <div>Load From</div>
          <div>Module</div>
        </button>
      </div>
    </div>
  );
};

MHSActions.propTypes = {
  cyclone: PropTypes.shape({
    quantity: PropTypes.number,
    /* eslint-disable camelcase */
    current_batch: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  batch: PropTypes.oneOfType([null, PropTypes.shape({
    units: PropTypes.string,
  })]).isRequired,
  handleProcessCycloneLoad: PropTypes.func.isRequired,
  actionPanelProps: PropTypes.shape({
    openActionPanel: PropTypes.func,
  }),
  modules: PropTypes.arrayOf(PropTypes.shape({
    slot: PropTypes.string,
    module: PropTypes.shape({}),
  })).isRequired,
  handleSieveToOutput: PropTypes.func.isRequired,
  handleRequestLoadFromInput: PropTypes.func.isRequired,
  transactionsLoading: PropTypes.bool.isRequired,
  inputModuleTransactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cycloneLoadingState: PropTypes.shape({
    cycloneLoading: PropTypes.bool,
    cycloneBatchLoading: PropTypes.bool,
  }).isRequired,
  mhsLoading: PropTypes.bool.isRequired,
}

export default MHSActions;
