import { useQuery } from '@tanstack/react-query';
import { fetcher } from 'src/api/fetcher';
import { API_RESOURCES } from 'src/utils/constants';

export const useMlineLPSByPrinterUri = printerUri => {
  return useQuery({
    queryKey: ['mlineLPSByPrinterUri', printerUri],
    queryFn: () =>
      fetcher(API_RESOURCES.MLINE_LASER_PROCESSING_SYSTEM, {
        params: {
          'filter[printer]': printerUri,
        },
      }),
    enabled: !!printerUri,
    select: data => data.resources?.[0],
    refetchOnWindowFocus: false,
  });
};

export const useBatchActionTransactionByDoseBatchUri = doseBatchUri => {
  return useQuery({
    queryKey: ['doseBatchUri', doseBatchUri],
    queryFn: () =>
      fetcher(API_RESOURCES.MATERIAL_BATCH_ACTION_TRANSACTION, {
        params: {
          'filter[material_batch]': doseBatchUri,
        },
      }),
    enabled: !!doseBatchUri,
    select: data => data.resources?.[0],
  });
};
