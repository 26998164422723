import PropTypes from 'prop-types';
import React from 'react';
import { useResourceLocationInfo } from 'src/hooks/services/useLocation';

const LocationsMismatchWarning = ({
  sourceResource,
  destinationResource,
  bottomTextRenderer,
  sourceType = 'Container',
  destinationType = 'Container',
  sourceDisplayName,
  action,
  onConfirm,
  onCancel,
}) => {
  const { location: sourceLocation, subLocation: sourceSubLocation } =
    useResourceLocationInfo(sourceResource);
  const { location: destLocation, subLocation: destSubLocation } =
    useResourceLocationInfo(destinationResource);

  const sourceLocationName = sourceLocation?.name || '[Not Rendered]';
  const sourceSubLocationName = sourceSubLocation?.name || '[Not Rendered]';
  const destLocationName = destLocation?.name || '[Not Rendered]';
  const destSubLocationName = destSubLocation?.name || '[Not Rendered]';

  return (
    <div>
      <p>
        You are trying to <strong>{action}</strong> but there is a location mismatch.
      </p>
      <p>
        The {destinationType} <strong>{destinationResource.name}</strong> is in&nbsp;
        <strong>{destLocationName}</strong> ({destSubLocationName}).
      </p>
      <p>
        The {sourceType} <strong>{sourceDisplayName}</strong> is in&nbsp;
        <strong>{sourceLocationName}</strong> ({sourceSubLocationName}).
      </p>
      {bottomTextRenderer ? (
        bottomTextRenderer()
      ) : (
        <p>
          Please relocate the {sourceType} to match the {destinationType} location.
        </p>
      )}

      <div>
        <button
          type='button'
          className='btn btn-lg btn-primary action-btn btn-block'
          onClick={onConfirm}
        >
          Relocate Module
        </button>
        <button type='button' className='btn btn-lg btn-block' onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

LocationsMismatchWarning.propTypes = {
  sourceResource: PropTypes.object.isRequired,
  destinationResource: PropTypes.object.isRequired,
  bottomTextRenderer: PropTypes.func,
  sourceType: PropTypes.string,
  destinationType: PropTypes.string,
  sourceDisplayName: PropTypes.string,
  action: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default LocationsMismatchWarning;
