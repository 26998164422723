import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConvertedUnits from 'src/components/ConvertedUnits';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS,
  MATERIAL_CONTAINER_STATUSES,
} from 'src/utils/constants';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../../utils/routes';
import ActionPage from './_action-wrapper';

const ScrapBatch = ({ user }) => {
  const { uuid: batchUUID } = useParams();
  const [batch, setBatch] = useState(undefined);
  const { isLoading, setLoading } = useActionLoadingStore();
  const [containers, setContainers] = useState([]);
  const [error, setError] = useState(undefined);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const containerText = containers.length > 1 ? 'Containers' : 'Container';

  const navigate = useNavigate();

  const getInitialData = async () => {
    setLoading(true);
    try {
      const batch = await api.get(`material-batch/${batchUUID}/`).json();

      const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;
      const { resources: nonEmptyContainers } = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
        searchParams: {
          'filter[current_batch]': batch.uri,
          'filter[status]': Object.values(containerStatusesExceptEmpty).join(','),
        },
      }).json();

      setBatch(batch);
      setContainers(nonEmptyContainers.map((nonEmptyContainer) => nonEmptyContainer.uri));
    } catch (error) {
      setError(error.name === 'HTTPError' ? error.name : error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => void getInitialData(), []);

  const onActionScrapBatch = async (batchUri) => {
    try {
      const payload = {
        /* eslint-disable camelcase */
        action_type: MATERIAL_BATCH_ACTIONS.SCRAP_BATCH,
        source_batch: batchUri,
        /* eslint-enable camelcase */
      };

      setSubmitting(true);
      await api.post('material-batch-action/', {
        json: payload,
      });
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(batchUri) },
      {
        action: MATERIAL_BATCH_ACTIONS.SCRAP_BATCH,
      }));

    return new Promise(() => {
    });
  };

  return (
    <ActionPage
      id={batchUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.SCRAP_BATCH}
      isLoading={isLoading}
    >
      <div>
        <FontAwesomeIcon icon={faExclamationTriangle} className="d-block mr-auto ml-auto mb-3 warning-icon" />
        <h2 className="header-margin">
          This action will mark batch <span className="badge badge-secondary">{getShortUuid(batch?.uri)}</span> and all
          of its contents as scrap.
        </h2>
        <h4>Are you sure you wish to proceed?</h4>
        {
          batch?.material_in_containers &&
          <>
            <h4 className="header-margin">
              The following <span className="badge-circle">{containers.length}</span> {containerText} are in this batch:
            </h4>
            <ol className="batch-container-list">
              {containers.map((uri) => (
                <li key={uri}>
                  {getShortUuid(uri)}
                </li>
              ))}
            </ol>
          </>
        }
        {!batch?.material_in_containers && (
          <div className="h6 header-margin">Quantity&nbsp;
            <ConvertedUnits
              quantity={batch?.quantity} units={batch?.units} />
          </div>
        )}
      </div>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting}
        onClick={() => onActionScrapBatch(batch.uri)}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="m-auto warning-icon-in-btn" />
        &nbsp;Mark batch as scrap
      </button>
      <Link to={`/material-container?batch=${batchUUID}`}>
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>
    </ActionPage>
  );
};


ScrapBatch.propTypes = {
  user: userPropType,
};

ScrapBatch.defaultProps = {
  user: null,
};

export default ScrapBatch;
