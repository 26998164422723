import { useQuery } from '@tanstack/react-query';
import { fetcher } from 'src/api/fetcher';
import { API_RESOURCES } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

export const useMlineMHS = mhsUUID => {
  return useQuery({
    queryKey: ['mlineMhs', mhsUUID],
    queryFn: () => fetcher(`${API_RESOURCES.MLINE_MATERIAL_HANDLING_SYSTEM}/${mhsUUID}`),
    enabled: !!mhsUUID,
  });
};

export const useMlineMHSByPostProcessorUri = postProcessorUri => {
  return useQuery({
    queryKey: ['mlineMhsByPostProcessorUri', postProcessorUri],
    queryFn: () =>
      fetcher(API_RESOURCES.MLINE_MATERIAL_HANDLING_SYSTEM, {
        params: {
          'filter[post_processor]': postProcessorUri,
        },
      }),
    enabled: !!postProcessorUri,
    select: data => data.resources?.[0],
    refetchOnWindowFocus: false,
  });
};

export const useMhsInputModule = inputUri => {
  return useQuery({
    queryKey: ['mhs-module', 'input', inputUri],
    queryFn: () => fetcher(`${API_RESOURCES.MATERIAL_CONTAINER}/${getUuid(inputUri)}`),
    enabled: !!inputUri,
  });
};

export const useMhsOutputModule = outputUri => {
  return useQuery({
    queryKey: ['mhs-module', 'output', outputUri],
    queryFn: () => fetcher(`${API_RESOURCES.MATERIAL_CONTAINER}/${getUuid(outputUri)}`),
    enabled: !!outputUri,
  });
};

export const useCyclone = cycloneUri => {
  return useQuery({
    queryKey: ['cyclone', cycloneUri],
    queryFn: () => fetcher(`${API_RESOURCES.MATERIAL_CONTAINER}/${getUuid(cycloneUri)}`),
    enabled: !!cycloneUri,
    refetchOnWindowFocus: false,
  });
};

export const useCycloneBatch = cycloneBatchUri => {
  return useQuery({
    queryKey: ['cycloneBatch', cycloneBatchUri],
    queryFn: () => fetcher(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(cycloneBatchUri)}`),
    enabled: !!cycloneBatchUri,
  });
};

export const useBatchTransactionByBatchUriAndStatus = (
  moduleBatchUri,
  status = false,
  key = 'batch-transaction-by-batch-uri-and-status'
) => {
  return useQuery({
    queryKey: ['batch-transactions', key, moduleBatchUri],
    queryFn: () =>
      fetcher(API_RESOURCES.MATERIAL_BATCH_ACTION_TRANSACTION, {
        params: {
          'filter[material_batch]': moduleBatchUri,
          'filter[is_complete]': status,
        },
      }),
    select: data => data.resources,
    enabled: !!moduleBatchUri,
    initialData: [],
  });
};
