import { faCoins, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import ActionContext from 'src/components/action-context';
import BatchMultipleContainersAlert from 'src/components/batch-multiple-containers-alert';
import { BatchCardPreview } from 'src/components/BatchCardPreview';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Loader from 'src/components/loader';
import LocationsMismatch from 'src/components/LocationsMismatch';
import PartialLoadPanel from 'src/components/partial-load';
import { useBatch } from 'src/hooks/services/useBatch';
import { useContainersUriAndStatus } from 'src/hooks/services/useContainer';
import {
  useLocationResourcesByUri,
  useSubLocationResourcesByUri,
} from 'src/hooks/services/useLocation';
import { usePrinter } from 'src/hooks/services/usePrinter';
import useSieveFlow from 'src/hooks/useSieveFlow';
import useUnsupportedMaterials from 'src/hooks/useUnsupportedMaterials';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import {
  fullContainerLoadAction,
  loadMultipleContainersIntoDestinationAction,
  relocateBatchAction,
  sieveFullBatchAction,
  sieveFullContainerAndLoad,
  sieveMultipleContainersAndLoadAction,
} from 'src/utils/actionsAPIUtils';
import {
  MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES,
  MATERIAL_CONTAINER_STATUSES,
} from 'src/utils/constants';
import { getContainerName, pluralWord } from 'src/utils/stringUtils';
import { getShortUuid } from 'src/utils/url';
import { hasLocationsMismatch } from 'src/utils/validation';

const BatchLoadPanel = ({
  sourceBatchUri,
  destinationResource,
  destinationResourceBatchUri,
  isTopOff,
  actionTitle,
  destinationResourceTitle,
  action,
}) => {
  const queryClient = useQueryClient();

  const { openActionPanel, closeActionPanel, closeAllActionPanels } = useActionPanelStore();

  const [isSubmitting, setSubmitting] = useState(false);

  // This is the full list of container objects from the API:
  // const [allContainers, setAllContainers] = useState([]);
  // This state holds URIs for containers that are "selectable" (left side)
  const [availableContainers, setAvailableContainers] = useState([]);

  // This state holds URIs for containers that are "selected" (right side)
  const [selectedContainers, setSelectedContainers] = useState([]);

  const { data: batch, isInitialLoading: isSourceBatchLoading } = useBatch(
    sourceBatchUri,
    'source-batch'
  );
  const { data: destinationResourceBatch, isInitialLoading: isDestinationBatchLoading } = useBatch(
    destinationResourceBatchUri,
    'destination-batch'
  );

  const { data: batchSubLocation } = useSubLocationResourcesByUri(
    batch?.sub_location,
    'batch-sub-location',
    {
      select: data => data?.resources?.[0],
    }
  );

  const batchPrinter = batch?.at_machine;

  const { data: loadedPrinterToSelectedBatch } = usePrinter(batchPrinter);

  // Fetch Non-Empty containers for the batch
  const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;

  const {
    data: allContainers = [],
    isLoading: containersFetching,
    error: containersError,
  } = useContainersUriAndStatus(batch?.uri, Object.values(containerStatusesExceptEmpty), {
    onSuccess: data => {
      if (!_isEmpty(data)) {
        const containerUris = data.map(c => c.uri);
        // Remove any containers that are already selected
        const filteredUris = containerUris.filter(uri => !selectedContainers.includes(uri));
        setAvailableContainers(filteredUris);
      }
    },
  });

  const nonEmptyContainersByUri = useMemo(
    () => Object.fromEntries(allContainers.map(container => [container.uri, container])),
    [allContainers]
  );

  const { data: locations } = useLocationResourcesByUri(destinationResource.location);

  const subLocationsUris =
    destinationResource && batch
      ? [...new Set([destinationResource.sub_location, batch.sub_location])]
      : [].join(',');

  const { data: subLocations } = useSubLocationResourcesByUri(
    subLocationsUris,
    'sub-locations-by-uris'
  );

  const singleContainerSelected = selectedContainers.length === 1;

  const [firstContainer] = selectedContainers;
  const firstSelectedContainer = allContainers.find(({ uri }) => uri === firstContainer);

  const didRelocateRef = useRef(false);

  const { checkIfSieveRequiredFirst, markSieveSuccess, didSieveRef, resetUserApproval } =
    useSieveFlow();

  const unsupportedMaterials = useUnsupportedMaterials(batch, destinationResource, {
    isMultiple: false,
    isPrinter: false,
  });

  const hasUnsupportedMaterials = unsupportedMaterials.length > 0;

  const shouldWarnAboutBatchPowderQuality =
    isTopOff &&
    !MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(
      destinationResourceBatch?.powder_quality
    ) &&
    MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(batch?.powder_quality);

  const { addToast } = useToasts();

  const {
    containerActionData: { initScannedContainers, resetActionContainerState },
  } = useContext(ActionContext);

  useEffect(() => {
    resetActionContainerState();
  }, []);

  // Toggle container from available to selected and vice versa
  const containerClicked = containerUri => {
    const updatedAvailable = [...availableContainers];
    const updatedSelected = [...selectedContainers];

    const inAvailableIdx = updatedAvailable.indexOf(containerUri);
    const inSelectedIdx = updatedSelected.indexOf(containerUri);

    // If container is in the 'available' list, move it to 'selected'
    if (inAvailableIdx !== -1) {
      updatedAvailable.splice(inAvailableIdx, 1);
      updatedSelected.push(containerUri);
    }
    // Otherwise, move it from 'selected' back to 'available'
    else if (inSelectedIdx !== -1) {
      updatedSelected.splice(inSelectedIdx, 1);
      updatedAvailable.push(containerUri);
    }

    setAvailableContainers(updatedAvailable);
    setSelectedContainers(updatedSelected);
  };

  const hasDifferentLocations = hasLocationsMismatch(
    batch?.location,
    destinationResource?.location
  );

  const fillWithEntireContainer = () => {
    const containers = selectedContainers.map(uri => nonEmptyContainersByUri[uri]);
    const containersTotalQuantity = containers.reduce(
      (acc, container) => acc + container.quantity,
      0
    );

    openActionPanel({
      title: `Confirm ${actionTitle}`,
      panelId: `confirm-${actionTitle}`,
      stack: true,
      content: (
        <div>
          <div className='mb30'>
            <p>
              Would you like to {actionTitle} {selectedContainers.length}{' '}
              {pluralWord('container', selectedContainers)} of the{' '}
              <strong>Batch {getShortUuid(batch.uri)}</strong> into the {destinationResourceTitle}{' '}
              <strong>{destinationResource.name}</strong> ?
            </p>
            <div>
              Total amount to {actionTitle}:{' '}
              <strong>
                <ConvertedUnits
                  skipTooltip
                  quantity={containersTotalQuantity}
                  units={batch?.units}
                />
              </strong>
            </div>
          </div>

          <div className='text-center'>
            <button
              type='submit'
              className='btn btn-lg btn-primary btn-block'
              disabled={isSubmitting}
              onClick={confirmAndLoadMaterial}
            >
              <p className='d-flex align-items-center justify-content-center mb0'>
                Confirm
                {isSubmitting && <Loader inline className='spacer-left' showText={false} />}
              </p>
            </button>

            <button type='button' className='btn btn-default btn-action' onClick={closeActionPanel}>
              Cancel
            </button>
          </div>
        </div>
      ),
    });
  };

  const openRelocateActionPanel = () => {
    openActionPanel({
      panelId: 'relocation-panel',
      title: 'Locations Mismatch',
      stack: true,
      content: (
        <>
          <LocationsMismatch
            sourceBatch={batch}
            destinationResource={destinationResource}
            bottomTextRenderer={renderLocationActionText}
            locations={locations}
            subLocations={subLocations}
            sourceType='Batch'
            destinationType={destinationResourceTitle}
            sourceDisplayName={getShortUuid(batch?.uri)}
            action={`${actionTitle} Material`}
          />

          <button
            type='button'
            className='btn btn-lg btn-primary action-btn btn-block mt60'
            onClick={onModalConfirmRelocate}
          >
            Relocate Batch
          </button>
          <button type='button' className='btn btn-lg btn-block' onClick={closeActionPanel}>
            Cancel
          </button>
        </>
      ),
    });
  };

  const handleContainerLoadAction = async (
    allContainersSelected,
    partialContainersSelected,
    containersToSplit,
    shouldSieve
  ) => {
    if (allContainersSelected) {
      if (shouldSieve) {
        await sieveFullBatchAction(batch.uri, batch.quantity);
        markSieveSuccess();
      }

      await fullContainerLoadAction(batch.uri, action, destinationResource.uri);
    } else if (partialContainersSelected) {
      if (shouldSieve) {
        await sieveMultipleContainersAndLoadAction(
          containersToSplit,
          batch.uri,
          destinationResource.uri,
          isTopOff,
          markSieveSuccess
        );
      } else {
        await loadMultipleContainersIntoDestinationAction(
          containersToSplit,
          batch.uri,
          destinationResource.uri
        );
      }
    } else {
      if (shouldSieve) {
        await sieveFullContainerAndLoad(
          batch.uri,
          // Source container -> from where
          firstContainer,
          // Quantity to load
          firstSelectedContainer?.quantity,
          // Where to actually sieve -> destination
          destinationResource.uri
        );
        markSieveSuccess();
      } else {
        await fullContainerLoadAction(
          batch.uri,
          action,
          destinationResource.uri,
          firstSelectedContainer?.quantity,
          true,
          firstContainer
        );
      }
    }

    closeAllActionPanels();
  };

  const executeLoadActions = async (allContainersSelected, partialContainersSelected) => {
    const containersToSplit = selectedContainers.map(uri => ({ uri }));
    const allSelectedContainers = allContainers.filter(({ uri }) =>
      selectedContainers.includes(uri)
    );

    const shouldSieve = shouldWarnAboutBatchPowderQuality && !didSieveRef.current;

    if (partialContainersSelected || allContainersSelected) {
      initScannedContainers(allSelectedContainers);
    }

    return handleContainerLoadAction(
      allContainersSelected,
      partialContainersSelected,
      containersToSplit,
      shouldSieve
    );
  };

  const handleLoadWithEntireContainer = async () => {
    try {
      setSubmitting(true);

      const allContainersSelected = selectedContainers.length === allContainers.length;
      const partialContainersSelected =
        selectedContainers.length > 1 && selectedContainers.length < allContainers.length;

      await executeLoadActions(allContainersSelected, partialContainersSelected);
      addToast(
        `${destinationResourceTitle} has successfully been ${isTopOff ? 'topped up' : 'loaded'}.`,
        { appearance: 'success' }
      );
      await queryClient.invalidateQueries(['cyclone', destinationResource.uri]);
      await queryClient.invalidateQueries(['cycloneBatch', destinationResource.uri]);
    } catch (error) {
      addToast(`${error}`, { appearance: 'error' });
      closeActionPanel();
      setSubmitting(false);
      resetActionContainerState();
    } finally {
      setSubmitting(false);
    }
  };

  const onActionRelocate = async () => {
    try {
      const locationToRelocate = destinationResource.location;
      const subLocationToRelocate = destinationResource.sub_location;

      await relocateBatchAction(locationToRelocate, subLocationToRelocate, batch.uri);
      closeActionPanel();
    } catch (error_) {
      console.error(error_);
      throw error_;
    }
  };

  const confirmAndLoadMaterial = async () => {
    // Check #1: Location mismatch
    if (!didRelocateRef.current && hasDifferentLocations) {
      openRelocateActionPanel();
      return;
    }

    // Check #2: Check if we did not skip Sieve and we did not do Sieve yet + we have Powder Quality Warning
    await checkIfSieveRequiredFirst({
      shouldWarn: shouldWarnAboutBatchPowderQuality,
      action,
      isSubmitting,
    });

    // Reset user approval
    resetUserApproval();

    // If we reach here, all checks passed or resolved:
    await handleLoadWithEntireContainer();
  };

  const onModalConfirmRelocate = async () => {
    try {
      await onActionRelocate();
      closeActionPanel();
      // Mark relocation as done immediately
      didRelocateRef.current = true;
      // Now re-run main confirm
      await confirmAndLoadMaterial();
    } catch (error) {
      addToast(`${error}`, { appearance: 'error' });
      closeActionPanel();
    }
  };

  /**
   * Click handler for the "Load All"/"Unload All" button.
   * - If not all containers are selected, select them all (move them from `availableContainers` to `selectedContainers`).
   * - If all containers are already selected, unselect them all (reset to the initial state).
   */
  const handleLoadAllClick = () => {
    // `allContainers` is an array of container objects
    // We only store URIs in selected/available states
    const allContainerUris = allContainers.map(c => c.uri);

    // Are *all* containers selected?
    const isAllSelected = selectedContainers.length === allContainerUris.length;

    if (!isAllSelected) {
      // Not all selected -> select them all
      setSelectedContainers(allContainerUris);
      setAvailableContainers([]);
    } else {
      // All are selected -> reset to the initial state:
      setSelectedContainers([]);
      setAvailableContainers(allContainerUris);
    }
  };

  // We can use this to switch the button label & styling
  const allContainerUris = allContainers.map(c => c.uri);
  const isAllSelected =
    selectedContainers.length === allContainerUris.length && allContainerUris.length > 0;
  const loadAllButtonLabel = isAllSelected ? 'Unload All' : 'Load All';

  const renderLocationActionText = () => {
    return (
      <p>
        Before {actionTitle}, you can relocate the entire batch now to match the Destination Printer
        location.
      </p>
    );
  };

  const handleOpenPartialLoadPanel = () => {
    openActionPanel({
      panelId: 'partial-load-panel',
      title: `Confirm ${!isTopOff ? 'Load' : 'Top Off'} Cyclone`,
      stack: true,
      content: (
        <PartialLoadPanel
          hideDestinationResourceTitleHyperlinked
          sourceResource={firstSelectedContainer}
          sourceResourceBatchUri={batch.uri}
          destinationResource={destinationResource}
          destinationResourceBatchUri={destinationResourceBatch?.uri}
          isTopOff={isTopOff}
          action={action}
          isEmptyBatchLoad={false}
          relocateOptions={{
            resourceToRelocate: firstSelectedContainer?.uri,
            locationToRelocate: destinationResource.location,
            subLocationToRelocate: destinationResource.sub_location,
          }}
          actionTitle={actionTitle}
          destinationResourceTitle={destinationResourceTitle}
          sourceResourceTitle='Container'
        />
      ),
    });
  };

  return (
    <div className='text-center'>
      <div className='mb30'>
        <h2 className='header-margin'>Selected Batch:</h2>
        {isDestinationBatchLoading || isSourceBatchLoading ? (
          <Spinner animation='border' />
        ) : (
          <BatchCardPreview
            hideContainers
            shouldShowBatchLink
            batch={batch}
            allContainers={allContainers}
            containersFetching={containersFetching}
            containersFetchError={containersError}
            subLocation={batchSubLocation?.name}
            loadedPrinter={loadedPrinterToSelectedBatch}
          />
        )}
      </div>

      <div className='row justify-content-between' style={{ padding: '1rem' }}>
        <div className='col-xs-6'>
          <ul className='batch-containers-split-list'>
            {containersFetching ? (
              <Spinner animation='grow' />
            ) : (
              availableContainers.map(uri => {
                const container = nonEmptyContainersByUri[uri];
                return (
                  <li key={uri} className='batch-container-split-list-item'>
                    <button
                      type='button'
                      className='btn btn-outline-secondary btn-action batch-container-split-list-btn mb5'
                      onClick={() => containerClicked(uri)}
                    >
                      <FontAwesomeIcon icon={faCoins} className='spacer-right' />
                      {getContainerName(container)}{' '}
                      <div className={container?.disposable ? 'd-inline-block' : ''}>
                        (
                        <ConvertedUnits
                          skipTooltip
                          quantity={container?.quantity}
                          units={batch?.units}
                        />
                        )
                      </div>
                    </button>
                  </li>
                );
              })
            )}
          </ul>
        </div>
        <div className='col-xs-6'>
          <ul className='batch-containers-split-list'>
            {selectedContainers.map(uri => {
              const container = nonEmptyContainersByUri[uri];
              return (
                <li key={uri} className='batch-container-split-list-item'>
                  <button
                    type='button'
                    className='btn btn-outline-primary btn-action batch-container-split-list-btn mb5'
                    onClick={() => containerClicked(uri)}
                  >
                    <FontAwesomeIcon icon={faCoins} className='spacer-right' />
                    {getContainerName(container)}{' '}
                    <div className={container?.disposable ? 'd-inline-block' : ''}>
                      (
                      <ConvertedUnits
                        skipTooltip
                        quantity={container?.quantity}
                        units={batch?.units}
                      />
                      )
                    </div>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {/*
        "Load All" button changes to "Unload All" when everything is selected,
        and toggles the selection on click.
      */}
      <button
        type='button'
        className={`btn btn-sm mb30 ${isAllSelected ? 'btn-info' : 'btn-secondary'}`}
        disabled={isSubmitting}
        onClick={handleLoadAllClick}
      >
        <p className='d-flex align-items-center justify-content-center mb0'>{loadAllButtonLabel}</p>
      </button>

      <button
        type='submit'
        className='btn btn-lg btn-primary btn-block'
        disabled={isSubmitting || !singleContainerSelected || hasUnsupportedMaterials}
        onClick={handleOpenPartialLoadPanel}
      >
        <p className='d-flex align-items-center justify-content-center mb0'>
          {actionTitle} With Partial Container
          {isSubmitting && <Loader inline className='spacer-left' showText={false} />}
        </p>
      </button>

      <button
        type='submit'
        className='btn btn-lg btn-primary btn-block'
        disabled={isSubmitting || !selectedContainers.length || hasUnsupportedMaterials}
        onClick={fillWithEntireContainer}
      >
        <p className='d-flex align-items-center justify-content-center mb0'>
          {actionTitle} With Entire Container(s)
          {isSubmitting && <Loader inline className='spacer-left' showText={false} />}
        </p>
      </button>

      {hasUnsupportedMaterials && (
        <Alert
          name='qr-instructions'
          variant='warning'
          className='text-left d-flex align-items-center mt15'
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className='font-size-22 mr15' />
          <div>
            {destinationResourceTitle} &#39;{destinationResource?.name}&#39; does not support&nbsp;
            {pluralWord('material', unsupportedMaterials)}&nbsp;
            <strong>{unsupportedMaterials.map(material => material).join(', ')}</strong>
          </div>
        </Alert>
      )}

      <BatchMultipleContainersAlert sourceBatch={batch} />
    </div>
  );
};

BatchLoadPanel.propTypes = {
  /* eslint-disable camelcase */
  sourceBatchUri: PropTypes.string.isRequired,
  destinationResource: PropTypes.shape({
    uri: PropTypes.string,
    location: PropTypes.string,
    sub_location: PropTypes.string,
    name: PropTypes,
  }).isRequired,
  isTopOff: PropTypes.bool.isRequired,
  actionTitle: PropTypes.string.isRequired,
  destinationResourceTitle: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  destinationResourceBatchUri: PropTypes.string.isRequired,
};

export default BatchLoadPanel;
