import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BatchMultipleContainersAlert from 'src/components/batch-multiple-containers-alert';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import { api } from 'src/utils/api';
import {
  PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../../utils/routes';
import PermanentContainerActionPageWrapper from './_action-wrapper';

const ScrapContainer = ({ user }) => {
  const { uuid: containerUUID } = useParams();
  const [container, setContainer] = useState(undefined);
  const [containerBatch, setContainerBatch] = useState(undefined);
  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(undefined);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const navigate = useNavigate();

  const getInitialData = async () => {
    setLoading(true);
    try {
      const container = await api.get(`material-container/${containerUUID}/`).json();
      const containerBatch = await api.get(`material-batch/${getUuid(container.current_batch)}/`).json();
      setContainer(container);
      setContainerBatch(containerBatch);
    } catch (error) {
      setError(error.name === 'HTTPError' ? error.name : error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => void getInitialData(), []);

  const onActionScrapBatch = async (containerUri) => {
    try {
      const payload = {
        /* eslint-disable camelcase */
        action_type: PERMANENT_CONTAINER_ACTIONS.SCRAP,
        source_material_container: containerUri,
        source_batch: containerBatch.uri,
      };

      setSubmitting(true);
      await api.post('material-container-action/', {
        json: payload,
      });
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.permanentContainerSuccess,
      { uuid: getUuid(containerUri) },
      {
        action: PERMANENT_CONTAINER_ACTIONS.SCRAP,
        batch: getUuid(containerBatch.uri),
      }));

    return new Promise(() => {
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !container) {
    return <NotFound id={containerUUID} />;
  }


  return (
    <PermanentContainerActionPageWrapper
      id={containerUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={PERMANENT_CONTAINER_ACTIONS.SCRAP}
      isLoading={isLoading}
    >
      <div>
        <FontAwesomeIcon icon={faExclamationTriangle} className="d-block mr-auto ml-auto mb-3 warning-icon" />
        <h2 className="header-margin">
          This action will mark container <span className="badge badge-secondary">{container?.name}</span> and
          all
          of its contents as scrap.
        </h2>
        <h4>Are you sure you wish to proceed?</h4>
        <h6 className="header-margin">
          Quantity: <ConvertedUnits
            quantity={container?.quantity} units={containerBatch?.units} />
        </h6>
      </div>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting}
        onClick={() => onActionScrapBatch(container.uri)}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="m-auto warning-icon-in-btn" />
        &nbsp;Mark container as scrap
      </button>
      <Link to={`/permanent-container/${containerUUID}`}>
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>

      <BatchMultipleContainersAlert sourceBatch={containerBatch} sourceContainer={container} />
    </PermanentContainerActionPageWrapper>
  );
};


ScrapContainer.propTypes = {
  user: userPropType,
};

ScrapContainer.defaultProps = {
  user: null,
};

export default ScrapContainer;
