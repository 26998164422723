import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faChevronUp, faFastBackward } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import ActionContext from 'src/components/action-context';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import MaterialBatchSuccessCardDetails from 'src/components/material-batch-success-card-details';
import NotFound from 'src/components/not-found';
import ScanButton from 'src/components/scan-button';
import { ActionLink } from 'src/pages/material-batch/[uuid]/success';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import { getBatchApiUrl } from 'src/utils/actionsAPIUtils';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS, PERMANENT_CONTAINER_ACTIONS,
  PERMANENT_CONTAINER_ACTIONS_VERBOSE,
} from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getContainerType } from 'src/utils/stringUtils';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

const PermanentContainerSuccessPage = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: permanentContainerUUID } = useParams();
  const {
    batch: batchUUID,
    action,
    sampleName,
    subLocation,
    location,
    printer: printerUri,
    sourceContainerUUID,
    destinationContainerUUID,
    quantity,
    initialBatchUUID,
    isFill: isFillAction,
    isLoad: isLoadAction,
    isTopOff: isTopOffAction,
    actionBatchLoad,
  } = Object.fromEntries(searchParams.entries()) ?? {};
  const { isLoading, setLoading } = useActionLoadingStore();
  const [pageState, setPageState] = useState({
    id: batchUUID,
    batch: null,
    batchLoadedToPrinter: null,
    scannedContainerBatches: null,
    fetchedContainersSubLocations: null,
    fetchedPermanentContainersLocations: null,
    fetchedContainers: [],
    sourcePermanentContainer: null,
    destinationContainer: null,
    printer: null,
    error: null,
  });

  const [showEmptiedContainers, setShowEmptiedContainers] = useState(false);

  const {
    containerActionData: { permanentContainersActionState, scannedContainers },
  } = useContext(ActionContext);

  const UNLOAD_ACTIONS = [
    PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL,
    PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL,
    PERMANENT_CONTAINER_ACTIONS.TRANSFER_MATERIAL,
  ];

  const LOAD_ACTIONS = [
    PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
    PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH,
    PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
  ];

  const PERMANENT_CONTAINER_ACTIONS_LIST = [
    PERMANENT_CONTAINER_ACTIONS.RELOCATE,
    PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
    PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
    PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH,
  ];

  const isUnloadAction = UNLOAD_ACTIONS.includes(action);
  const isBatchSieveAction = action === PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH;
  const isContainerTopOffAction = action === PERMANENT_CONTAINER_ACTIONS.TOP_OFF || isTopOffAction;
  const isPermanentContainerAction = PERMANENT_CONTAINER_ACTIONS_LIST.includes(action);
  const isContainerLoadActions = LOAD_ACTIONS.includes(action);
  const shouldShowViewPermanentContainerButton = (isPermanentContainerAction || isUnloadAction) && !isBatchSieveAction;

  const actionTitles = {
    [PERMANENT_CONTAINER_ACTIONS.SCRAP]: 'Scrap',
    [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: isFillAction ? 'Fill' : 'Load',
    [PERMANENT_CONTAINER_ACTIONS.RELOCATE]: 'Relocate',
    [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: 'Top Off',
    [MATERIAL_BATCH_ACTIONS.RELOCATE]: 'Relocate',
    [MATERIAL_BATCH_ACTIONS.SIEVE]: 'Sieve',
    [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: 'Split',
  };

  const actionTitle = actionTitles[action] || 'Permanent Container';

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${permanentContainerUUID}/`).json();
      const sourcePermanentContainer = sourceContainerUUID ? await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${sourceContainerUUID}/`).json() : null;
      const destinationContainer = destinationContainerUUID ? await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${destinationContainerUUID}/`).json() : null;
      const batchUrl = getBatchApiUrl(permanentContainer, batchUUID, initialBatchUUID);
      const batch = batchUrl ? await api.get(batchUrl).json() : null;
      const batchSubLocation = !subLocation && batch ? await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batch.sub_location)}/`).json() : null;
      const printer = printerUri ? await api.get(`${API_RESOURCES.PRINTER}/${getUuid(printerUri)}/`).json() : null;

      const printerBatches = printer ? await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
        searchParams: {
          'filter[at_machine]': printer.uri,
        },
      }).json() : null;

      const permanentContainersScanned = (isFillAction || isContainerTopOffAction)
        ? scannedContainers
        : Object.values(permanentContainersActionState.containersScanned);

      const fetchedContainers = (isUnloadAction || isBatchSieveAction || isContainerLoadActions) && permanentContainersScanned?.length ?
        await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
          searchParams: {
            'filter[uri]': permanentContainersScanned.map((container) => container.uri).join(','),
          },
        }).json() :
        null;

      const fetchedContainersResources = fetchedContainers?.resources;

      const fetchedContainersBatches = fetchedContainersResources?.length
        && fetchedContainersResources.map((container) => container.current_batch).filter(Boolean);

      const scannedContainerBatches = fetchedContainersBatches?.length ?
        await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
          searchParams: {
            'filter[uri]': fetchedContainersBatches.join(','),
          },
        }).json() : null;

      const fetchedContainersLocations = !_isEmpty(fetchedContainersResources) ?
        fetchedContainers.resources.map((container) => container.location).filter(Boolean)
        : [];

      const fetchedPermanentContainersLocations = !_isEmpty(fetchedContainersLocations) ?
        await api.get(`${API_RESOURCES.LOCATION}/`, {
          searchParams: {
            'filter[uri]': fetchedContainersLocations.join(','),
          },
        }).json() : null;

      const fetchedContainersSubLocations = !_isEmpty(fetchedContainersResources) ?
        fetchedContainers.resources.map((container) => container.sub_location).filter(Boolean)
        : [];

      const fetchedPermanentContainersSubLocations = !_isEmpty(fetchedContainersSubLocations) ?
        await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
          searchParams: {
            'filter[uri]': fetchedContainersSubLocations.join(','),
          },
        }).json() : null;

      setPageState({
        id: permanentContainerUUID,
        batch,
        batchLoadedToPrinter: printerBatches?.resources?.[0] || null,
        printer,
        permanentContainer,
        destinationContainer,
        sourcePermanentContainer,
        fetchedContainers: fetchedContainers?.resources || [],
        scannedContainerBatches: scannedContainerBatches?.resources || [],
        fetchedContainersSubLocations: fetchedPermanentContainersSubLocations?.resources || null,
        fetchedPermanentContainersLocations: fetchedPermanentContainersLocations?.resources || null,
        action,
        sampleName,
        subLocation: subLocation || batchSubLocation?.name,
        error: null,
      });
    } catch (error) {
      if (error.name === 'HTTPError') {
        setPageState((prevState) => ({
          ...prevState,
          id: batchUUID,
          error: {
            name: error.name,
            status: error.response && error.response.status,
          },
        }));
      }

    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, [batchUUID, permanentContainerUUID]);

  const getBatchLink = (batch = pageState.batch) => batch
    ? getRouteURI(routes.materialContainer, {}, { batch: getUuid(batch.uri) })
    : null;

  const getPermanentContainerLink = (permanentContainer = pageState.permanentContainer) => permanentContainer
    ? getRouteURI(routes.permanentContainerDetails, { uuid: getUuid(permanentContainer.uri) })
    : null;

  const getContainerName = (container = pageState.permanentContainer) => {
    if (!container) return null;
    return container.disposable ? getShortUuid(container.uri) : container.name;
  };

  const renderLoadActionUI = () => {
    const loadedWording = isContainerTopOffAction ? 'topped up' : 'loaded';
    if (_isEmpty(scannedContainers)) {
      return (
        <p className="success-action-text">
          {batchUUID && !isLoadAction ? (
            <>
              Batch
              <ActionLink
                text={getShortUuid(batchUUID)}
                link={getBatchLink()}
              />
            </>
          ) : getContainerType(pageState.sourcePermanentContainer)}
          <ActionLink
            text={getContainerName(pageState.sourcePermanentContainer)}
            link={getPermanentContainerLink(pageState.sourcePermanentContainer)}
          />

          was successfully {loadedWording} into the&nbsp;

          {printerUri ? (
            <>
              <span>printer</span>
              <ActionLink
                text={pageState.printer.name}
                link={getPrinterUrl()}
              />
            </>
          ) : (
            <>
              <span>{getContainerType(pageState.permanentContainer, false)}</span>
              <ActionLink
                text={getContainerName(pageState.permanentContainer)}
                link={getPermanentContainerLink()}
              />
            </>
          )}
        </p>
      );
    }

    return (
      <p className="success-action-text">
        {scannedContainers.length} containers
        were successfully {loadedWording} into the {getContainerType(pageState.permanentContainer, false)}
        <ActionLink
          text={pageState.permanentContainer?.name}
          link={getPermanentContainerLink()}
        />
      </p>
    );
  };

  const ACTION_SUCCESS_MESSAGES = {
    [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: () => renderLoadActionUI(),
    [PERMANENT_CONTAINER_ACTIONS.SCRAP]: () => {
      return (
        <p className="success-action-text">
          Container
          <ActionLink
            text={pageState.permanentContainer?.name}
            link={getPermanentContainerLink()}
          />
          was successfully marked as scrap
        </p>
      );
    },
    [PERMANENT_CONTAINER_ACTIONS.RELOCATE]: () => (
      <p className="success-action-text">
        Permanent Container
        <ActionLink
          text={pageState.permanentContainer?.name}
          link={getPermanentContainerLink()}
        />
        was successfully relocated to&nbsp;
        <span className="badge badge-secondary">{location}</span> /&nbsp;
        <span className="badge badge-secondary">{subLocation}</span>
      </p>
    ),
    [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: () => renderLoadActionUI(),
    [PERMANENT_CONTAINER_ACTIONS.TRANSFER_MATERIAL]: () => (
      <p className="success-action-text">
        Material was successfully transferred into permanent container
        <ActionLink
          text={pageState.permanentContainer?.name}
          link={getPermanentContainerLink()}
        />
      </p>
    ),
    [PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL]: () => (
      <p className="success-action-text">
        <strong>
          <ConvertedUnits quantity={pageState.batch.quantity} units={pageState.batch.units} />&nbsp;
        </strong>
        of <strong>{pageState.batch.materials[0].name}&nbsp;</strong>
        successfully unloaded from the hopper of Machine
        <ActionLink
          text={pageState.printer?.name}
          link={getRouteURI(routes.printerMaterial, { uuid: getUuid(pageState.printer?.uri) })}
        />
      </p>
    ),
    [PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH]: () => (
      <p className="success-action-text">
        <strong>
          <ConvertedUnits quantity={pageState.batch.quantity} units={pageState.batch.units} />&nbsp;
        </strong>
        of <strong>{pageState.batch.materials[0].name}&nbsp;</strong>
        successfully sieved from the Batch
        <ActionLink
          text={getShortUuid(pageState.batch.uri)}
          link={getBatchLink()}
        />
      </p>
    ),
    [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: () => {
      return (
        <p className="success-action-text">
          You have successfully split&nbsp;
          <strong>
            <ConvertedUnits
              quantity={Number(quantity) || pageState.batch.quantity}
              units={pageState.batch.units} />&nbsp;
          </strong>
          of <strong>{pageState.batch.materials[0].name}&nbsp;</strong>
          from the {getContainerType(pageState.permanentContainer)}
          <ActionLink
            text={pageState.permanentContainer.name}
            link={getPermanentContainerLink()}
          />

          {destinationContainerUUID && (
            <>
              <span>into the {getContainerType(pageState.destinationContainer)}</span>
              <ActionLink
                text={getContainerName(pageState.destinationContainer)}
                link={getPermanentContainerLink(pageState.destinationContainer)}
              />
            </>
          )}

          {printerUri && (
            <>
              <span>into the printer</span>
              <ActionLink
                text={pageState.printer.name}
                link={getPrinterUrl()}
              />
            </>
          )}
        </p>
      );
    },
    [PERMANENT_CONTAINER_ACTIONS.SIEVE]: () => {
      const container = sourceContainerUUID ? pageState.sourcePermanentContainer : pageState.permanentContainer;
      return (
        <p className="success-action-text">
          <strong>
            <ConvertedUnits
              quantity={Number(quantity) || pageState.batch.quantity}
              units={pageState.batch.units} />&nbsp;
          </strong>
          of <strong>{pageState.batch.materials[0].name}&nbsp;</strong>
          successfully sieved from the container
          <ActionLink
            text={container.name}
            link={getPermanentContainerLink(container)}
          />
          {sourceContainerUUID && (
            <>
              <span>into the {getContainerType(pageState.permanentContainer)}</span>
              <ActionLink
                text={pageState.permanentContainer.name}
                link={getPermanentContainerLink()}
              />
            </>
          )}
          {printerUri && (
            <>
              <span>into the printer</span>
              <ActionLink
                text={pageState.printer.name}
                link={getPrinterUrl()}
              />
            </>
          )}
        </p>
      );
    },
    [PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: () => (
      <p className="success-action-text">
        <strong>
          <ConvertedUnits quantity={pageState.batch.quantity} units={pageState.batch.units} />&nbsp;
        </strong>
        of <strong>{pageState.batch.materials[0].name}&nbsp;</strong>
        has been reclaimed from the build chamber of Machine
        <ActionLink
          text={pageState.printer?.name}
          link={getRouteURI(routes.printerMaterial, { uuid: getUuid(pageState.printer?.uri) })}
        />
      </p>
    ),
  };

  const createMaterialBatchConfig = (title, batchProp, subLocationProp) => ({
    title,
    batch: pageState[batchProp],
    subLocation: pageState[subLocationProp],
  });

  const createMaterialBatchSuccessCardDetails = (config) => (
    <MaterialBatchSuccessCardDetails key={config.title || config?.batch?.uri} {...config} />
  );

  const PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG = {
    UNLOAD_MACHINE: [
      ...pageState.fetchedContainers.map(container => ({
        permanentContainer: container,
        customQuantity: container.quantity,
        batch: pageState.scannedContainerBatches.find(batch => batch.uri === container.current_batch),
        customLocation: pageState.fetchedPermanentContainersLocations?.find(location => location.uri === container.location)?.name,
        subLocation: pageState.fetchedContainersSubLocations?.find(subLocation => subLocation.uri === container.sub_location)?.name,
      })),
      createMaterialBatchConfig('Batch', 'batch', 'subLocation'),
      {
        printer: pageState.printer,
        batch: pageState.batchLoadedToPrinter,
        subLocation: pageState.subLocation,
      },
    ],
    SCRAP_CONTAINER: [
      permanentContainerUUID ? {
        containerTitle: 'Original Container',
        permanentContainer: pageState.permanentContainer,
        batch: pageState.batch,
        customQuantity: pageState.permanentContainer?.quantity,
        customLocation: pageState.permanentContainerLocation,
        subLocation: pageState.subLocation,
        containerOnly: true,
      } : {},
    ],
    LOAD_CONTAINER: [
      permanentContainerUUID && !printerUri ? {
        containerTitle: 'Destination Container',
        permanentContainer: pageState.permanentContainer,
        batch: pageState.batch,
        customQuantity: String(pageState.permanentContainer?.quantity),
        customLocation: pageState.permanentContainerLocation,
        subLocation: pageState.subLocation,
        containerOnly: true,
        isPrimaryContainer: true,
      } : {},
      printerUri ? {
        printer: pageState.printer,
        batch: pageState.batchLoadedToPrinter,
        subLocation: pageState.subLocation,
      } : {},
      sourceContainerUUID ? {
        containerTitle: 'Source Container',
        permanentContainer: pageState.sourcePermanentContainer,
        batch: pageState.batch,
        customQuantity: String(pageState.sourcePermanentContainer?.quantity),
        hideLocation: true,
        containerOnly: true,
      } : {},
      !_isEmpty(scannedContainers) ? createMaterialBatchConfig('Selected Batch', 'batch', 'subLocation') : {},
      batchUUID && !isLoadAction && _isEmpty(scannedContainers) ? createMaterialBatchConfig('Batch', 'batch', 'subLocation') : {},
    ],
    SIEVE_BATCH: [
      ...pageState.fetchedContainers.map(container => ({
        permanentContainer: container,
        customQuantity: container.quantity,
        batch: pageState.scannedContainerBatches.find(batch => batch.uri === container.current_batch),
        customLocation: pageState.fetchedPermanentContainersLocations?.find(location => location.uri === container.location)?.name,
        subLocation: pageState.fetchedContainersSubLocations?.find(subLocation => subLocation.uri === container.sub_location)?.name,
      })),
      createMaterialBatchConfig('Source Batch', 'batch', 'subLocation'),
    ],
    SIEVE: [
      permanentContainerUUID ? {
        containerTitle: `${sourceContainerUUID ? 'Destination' : 'Source'} Container`,
        permanentContainer: pageState.permanentContainer,
        batch: pageState.batch,
        customQuantity: String(pageState.permanentContainer?.quantity),
        customLocation: pageState.permanentContainerLocation,
        subLocation: pageState.subLocation,
        containerOnly: true,
      } : {},
      printerUri ? {
        printer: pageState.printer,
        batch: pageState.batchLoadedToPrinter,
        subLocation: pageState.subLocation,
      } : {},
      sourceContainerUUID ? {
        containerTitle: 'Source Container',
        permanentContainer: pageState.sourcePermanentContainer,
        batch: pageState.batch,
        customQuantity: String(pageState.sourcePermanentContainer?.quantity),
        customLocation: pageState.permanentContainerLocation,
        subLocation: pageState.subLocation,
        containerOnly: true,
      } : {},
    ],
    SPLIT: [
      destinationContainerUUID ? {
        containerTitle: 'Destination Container',
        permanentContainer: pageState.destinationContainer,
        batch: pageState.batch,
        customQuantity: String(pageState.destinationContainer?.quantity),
        customLocation: pageState.permanentContainerLocation,
        subLocation: pageState.subLocation,
        containerOnly: true,
        isPrimaryContainer: true,
      } : {},
      printerUri ? {
        printer: pageState.printer,
        batch: pageState.batchLoadedToPrinter,
        subLocation: pageState.subLocation,
      } : {},
      permanentContainerUUID ? {
        containerTitle: 'Source Container',
        permanentContainer: pageState.permanentContainer,
        batch: pageState.batch,
        customQuantity: String(pageState.permanentContainer?.quantity),
        customLocation: pageState.permanentContainerLocation,
        subLocation: pageState.subLocation,
        containerOnly: true,
      } : {},
    ],
    DEFAULT: createMaterialBatchConfig('Batch', 'batch', 'subLocation'),
  };

  const renderCards = (config) => {
    return config.map(createMaterialBatchSuccessCardDetails);
  };

  const ACTION_SUCCESS_DATA = {
    [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.LOAD_CONTAINER),
    [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.LOAD_CONTAINER),
    [PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.UNLOAD_MACHINE),
    [PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.UNLOAD_MACHINE),
    [PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.SIEVE_BATCH),
    [PERMANENT_CONTAINER_ACTIONS.SIEVE]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.SIEVE),
    [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.SPLIT),
    [PERMANENT_CONTAINER_ACTIONS.SCRAP]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.SCRAP_CONTAINER),
  };

  const toggleEmptiedContainers = () => {
    setShowEmptiedContainers((prev) => !prev);
  };

  if (pageState.error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={pageState.id} />
        </main>
      </>
    );
  }

  const getPermanentContainerUrl = (uuid = permanentContainerUUID) => {
    return `/permanent-container/${uuid}`;
  };

  const getPrinterUrl = () => {
    return `/printer/${getUuid(printerUri)}/material`;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !pageState?.permanentContainer) {
    return <NotFound id={pageState.id} />;
  }

  const renderBottomButtons = () => (
    <div className="success-buttons-bottom">
      {
        shouldShowViewPermanentContainerButton && (
          <Link
            to={isUnloadAction ? getPrinterUrl() : getPermanentContainerUrl()}
          >
            <div className="btn btn-outline-primary btn-action mt-4">
              <FontAwesomeIcon icon={faFastBackward} size="3x" className="d-block m-auto" />
              {isUnloadAction ? 'View Printer' : 'View Container'}
            </div>
          </Link>
        )
      }
      <ScanButton />
    </div>
  );

  const renderEmptiedContainers = () => {
    if (isContainerLoadActions && !_isEmpty(scannedContainers)) {
      return (
        <div>
          <button
            type="submit"
            className="btn btn-lg btn-primary mb5"
            onClick={toggleEmptiedContainers}
          >
            <FontAwesomeIcon icon={showEmptiedContainers ? faChevronUp : faChevronDown} className="spacer-right" />
            Emptied Containers
          </button>
          {showEmptiedContainers && (
            <div className="mt15">
              {pageState.fetchedContainers.map((container, index) => (
                <p key={container.uri} className="mb0">
                  {index + 1}.
                  {container.disposable
                    ? getShortUuid(container.uri)
                    : (
                      <ActionLink
                        text={getContainerName(container)}
                        link={getPermanentContainerLink(container)}
                      />
                    )}
                </p>
              ))}
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Header
        title={((isLoadAction || actionBatchLoad) && !isContainerTopOffAction) ? 'Load Success' : `${PERMANENT_CONTAINER_ACTIONS_VERBOSE[action]} Success`}
        user={user} />
      <main role="main" className="text-center">
        <div className="success-main">
          <div className="success-animation">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
          <p className="success-text">{actionTitle} Action Complete</p>

          <div className="success-main-content">
            {ACTION_SUCCESS_MESSAGES?.[action] && ACTION_SUCCESS_MESSAGES[action]()}

            <hr className="success-hr-line" />

            {ACTION_SUCCESS_DATA?.[action] && ACTION_SUCCESS_DATA[action]()}
            {renderEmptiedContainers()}
            {renderBottomButtons()}
          </div>
        </div>
      </main>
    </>
  );

};

PermanentContainerSuccessPage.propTypes = {
  user: userPropType,
};

PermanentContainerSuccessPage.defaultProps = {
  user: null,
};

export default PermanentContainerSuccessPage;
