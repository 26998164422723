import { useQuery } from '@tanstack/react-query';
import { fetcher } from 'src/api/fetcher';
import { API_RESOURCES } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

export const useBatch = (batchUri, batchKey = 'source-batch-uri') => {
  return useQuery({
    queryKey: ['batch', batchKey, batchUri],
    queryFn: () => fetcher(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(batchUri)}`),
    enabled: !!batchUri,
  });
};
