import { useCallback, useMemo, useState } from 'react';
import { convertToUserUnits, performReverseConversion } from 'src/utils/conversions';
import { validateMultipleZeros } from 'src/utils/validation';

const useConvertedInputHandler = (initialValue = 0, baseUnits) => {
  const {
    quantity: convertedInitialValue,
    units: convertedUnits,
    isConverted,
  } = useMemo(() => {
    return convertToUserUnits(initialValue, baseUnits);
  }, [initialValue, baseUnits]);

  const [baseValue, setBaseValue] = useState(initialValue);
  const [convertedValue, setConvertedValue] = useState(convertedInitialValue);

  const handleInputChange = useCallback(
    e => {
      const { value } = e.target;
      validateMultipleZeros(e);

      if (value === '') {
        setBaseValue(0);
        setConvertedValue('');
        return;
      }

      const regex = /^\d*\.?\d{0,3}$/;
      if (regex.test(value)) {
        const numericValue = parseFloat(value);
        if (!isNaN(numericValue)) {
          const roundedValue = Math.round(numericValue * 1000) / 1000;
          setConvertedValue(roundedValue);

          if (!isConverted || baseUnits === convertedUnits) {
            setBaseValue(roundedValue);
          } else {
            const reverseConversion = performReverseConversion(
              roundedValue,
              convertedUnits,
              baseUnits
            );
            setBaseValue(reverseConversion.isConverted ? reverseConversion.quantity : 0);
          }
        }
      }
    },
    [baseUnits, convertedUnits, isConverted]
  );

  const setValueInBaseUnits = useCallback(
    newBaseValue => {
      setBaseValue(newBaseValue);
      const { quantity: newConvertedValue } = convertToUserUnits(newBaseValue, baseUnits);
      setConvertedValue(newConvertedValue);
    },
    [baseUnits]
  );

  return {
    baseValue,
    convertedValue,
    convertedUnits,
    isConverted,
    handleInputChange,
    setValueInBaseUnits,
  };
};

export default useConvertedInputHandler;
