import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  faArrowsSpin,
  faArrowUpShortWide,
  faLocationDot,
  faObjectGroup,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { faExpandAlt, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { OverlayTrigger, Spinner, Tooltip as BSTooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BatchCardExpandedContainer from 'src/components/BatchCardExpandedContainer';
import BatchPowderQualityStatusIcon from 'src/components/BatchPowderQualityStatusIcon';
import ConvertedUnits from 'src/components/ConvertedUnits';
import StatusColorCode from 'src/components/status-color-dot';
import Tooltip from 'src/components/Tooltip';
import TruncatedItemsList from 'src/components/TruncatedItemsList';
import { MATERIAL_BATCH_STATUS_VERBOSE, STATUS_COLOR_CODE_TYPES } from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid } from 'src/utils/url';

export const BatchCardSmall = ({
  batch,
  togglePreview,
  subLocation,
  shouldShowBatchLink,
  batchHasIncompleteTransactions,
  batchTransactionsLoading,
}) => {
  const batchMaterialNames = _uniq(_map(batch.materials, 'name'));

  const renderBatchTransactionsTooltip = () => {
    if (batchTransactionsLoading)
      return (
        <Spinner
          animation='border'
          size='sm'
          variant='light'
          className='spacer-left mb0 align-self-center'
        />
      );

    if (!batchHasIncompleteTransactions) return null;

    return (
      <div className='spacer-right'>
        <Tooltip
          id='incomplete_transactions_tooltip'
          placement='bottom'
          trigger={
            <span className='powder_quality_badge'>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                color='#FF9903'
                className='spacer-right'
              />
              <span className='text-black'>Pending</span>
            </span>
          }
        >
          <div>Batch has pending transactions</div>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className='batch-card-small'>
      <div className='batch-card-small-main-details'>
        <div className='batch-card-small-main-details-info'>
          <div className='d-flex align-items-center'>
            <p className='mb0 d-flex align-items-center'>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <BSTooltip id='tooltip'>
                    <p className='mb0'>{MATERIAL_BATCH_STATUS_VERBOSE[batch.status]}</p>
                  </BSTooltip>
                }
              >
                <p className='d-flex align-items-center mb0'>
                  <StatusColorCode
                    status={batch.status}
                    type={STATUS_COLOR_CODE_TYPES.BATCH_STATUS}
                    sizeClassName='small'
                  />
                </p>
              </OverlayTrigger>
            </p>
            <span className='batch-card-small-main-details-title'>Batch</span>
          </div>
          <div>
            {shouldShowBatchLink ? (
              <Link
                className='batch-card-main-details-main-link'
                to={getRouteURI(routes.materialContainer, {}, { batch: batch.uuid })}
              >
                <FontAwesomeIcon icon={faExternalLink} className='spacer-right' />
                <p className='mb0'>{getShortUuid(batch.uuid)}</p>
              </Link>
            ) : (
              <p className='mb0 batch-card-main-details-main-link'>{getShortUuid(batch.uuid)}</p>
            )}
          </div>
        </div>
        {batch.powder_quality && (
          <div className='batch-card-small-main-details-powder-quality'>
            <BatchPowderQualityStatusIcon qualityStatus={batch?.powder_quality} />
          </div>
        )}
      </div>
      <div className='batch-card-small-secondary-details'>
        <div className='batch-card-small-center-details'>
          <div className='d-flex align-items-center justify-content-end'>
            {renderBatchTransactionsTooltip()}
            <p className='mt10 mb10 batch-card-small-secondary-details-units'>
              <ConvertedUnits quantity={batch.quantity} units={batch.units} />
              <div className='batch-card-header-secondary-icon'>
                <FontAwesomeIcon className='spacer-left' icon={faArrowUpShortWide} />
              </div>
            </p>
          </div>

          <p className='mt10 mb10 batch-card-small-secondary-details-units'>
            {batch.usage_cycles}
            <div className='batch-card-header-secondary-icon'>
              <FontAwesomeIcon className='spacer-left' icon={faArrowsSpin} />
            </div>
          </p>
          <p className='mb10 batch-card-small-secondary-details-units'>
            <TruncatedItemsList
              data={batchMaterialNames}
              showCount={1}
              icon={faObjectGroup}
              showVisibleIcon={false}
            />
            <div className='batch-card-header-secondary-icon'>
              <FontAwesomeIcon className='spacer-left' icon={faObjectGroup} />
            </div>
          </p>
        </div>
        <div className='batch-card-small-right-details'>
          <div>
            <OverlayTrigger
              placement='left'
              overlay={
                <BSTooltip id='tooltip'>
                  <p className='mb5'>
                    <strong>Location:</strong> {batch.location_name}
                  </p>
                  <p className='mb5'>
                    <strong>Sub-Location:</strong> {subLocation || 'N/A'}
                  </p>
                </BSTooltip>
              }
            >
              <FontAwesomeIcon icon={faLocationDot} className='text-white' />
            </OverlayTrigger>
          </div>
          <button type='button' className='btn btn-link btn-sm text-white' onClick={togglePreview}>
            <FontAwesomeIcon icon={faExpandAlt} />
          </button>
        </div>
      </div>
    </div>
  );
};

BatchCardSmall.defaultProps = {
  shouldShowBatchLink: true,
  batchHasIncompleteTransactions: false,
  batchTransactionsLoading: false,
};

BatchCardSmall.propTypes = {
  batch: PropTypes.shape({
    uuid: PropTypes.string,
    units: PropTypes.string,
    status: PropTypes.string,
    quantity: PropTypes.number,
    /* eslint-disable camelcase */
    powder_quality: PropTypes.string,
    usage_cycles: PropTypes.number,
    location_name: PropTypes.string,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  subLocation: PropTypes.string.isRequired,
  togglePreview: PropTypes.func.isRequired,
  shouldShowBatchLink: PropTypes.bool,
  batchHasIncompleteTransactions: PropTypes.bool,
  batchTransactionsLoading: PropTypes.bool,
};

export const BatchCardPreview = ({
  batch,
  allContainers,
  hideContainers,
  containersFetching,
  containersFetchError,
  subLocation,
  shouldShowBatchLink,
  isExpanded,
  loadedPrinter,
  loadedPostProcessor,
  mlineLps,
  batchTransactions,
  batchTransactionsLoading,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const togglePreview = () => setExpanded(prev => !prev);

  if (!batch) return null;

  const batchHasIncompleteTransactions = !_isEmpty(batchTransactions);

  return expanded ? (
    <BatchCardExpandedContainer
      batch={batch}
      allContainers={allContainers}
      hideContainers={hideContainers}
      containersFetching={containersFetching}
      containersFetchError={containersFetchError}
      togglePreview={togglePreview}
      subLocation={subLocation}
      loadedPrinter={loadedPrinter}
      loadedPostProcessor={loadedPostProcessor}
      mlineLps={mlineLps}
      shouldShowBatchLink={shouldShowBatchLink}
      batchHasIncompleteTransactions={batchHasIncompleteTransactions}
      batchTransactionsLoading={batchTransactionsLoading}
    />
  ) : (
    <BatchCardSmall
      batch={batch}
      togglePreview={togglePreview}
      subLocation={subLocation}
      shouldShowBatchLink={shouldShowBatchLink}
      batchHasIncompleteTransactions={batchHasIncompleteTransactions}
      batchTransactionsLoading={batchTransactionsLoading}
    />
  );
};

BatchCardPreview.defaultProps = {
  shouldShowBatchLink: true,
  isExpanded: false,
  loadedPrinter: null,
  loadedPostProcessor: null,
  mlineLps: null,
  hideContainers: false,
  batchTransactions: [],
  batchTransactionsLoading: false,
};

BatchCardPreview.propTypes = {
  batch: PropTypes.shape({}).isRequired,
  subLocation: PropTypes.string.isRequired,
  shouldShowBatchLink: PropTypes.bool,
  isExpanded: PropTypes.bool,
  loadedPrinter: PropTypes.shape({}),
  loadedPostProcessor: PropTypes.shape({}),
  mlineLps: PropTypes.oneOfType([PropTypes.shape({}), undefined]),
  allContainers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  containersFetching: PropTypes.bool.isRequired,
  containersFetchError: PropTypes.string.isRequired,
  hideContainers: PropTypes.bool,
  batchTransactionsLoading: PropTypes.bool,
  batchTransactions: PropTypes.arrayOf(PropTypes.shape({})),
};
