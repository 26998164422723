import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MLineLpsPage from 'src/pages/mline/lps';
import LoadMaterial from 'src/pages/permanent-container/[uuid]/action/load-material';
import LoadMaterialBatch from 'src/pages/permanent-container/[uuid]/action/load-material-batch';
import AlreadyLoadedPage from 'src/pages/printer/[uuid]/already-loaded';
import PrinterMaterialPage from 'src/pages/printer/[uuid]/material';
import PrinterSuccessPage from 'src/pages/printer/[uuid]/success';
import PrivateRoute from 'src/routes/PrivateRoute';

import NotFoundPage from '../pages/not-found';

const PrinterRoutes = () => (
  <Routes>
    <Route path='/:uuid/material' element={<PrivateRoute Component={PrinterMaterialPage} />} />
    <Route path='/:uuid/lps' element={<PrivateRoute Component={MLineLpsPage} />} />
    <Route path='/:uuid/already-loaded' element={<PrivateRoute Component={AlreadyLoadedPage} />} />
    <Route path='/:uuid/success' element={<PrivateRoute Component={PrinterSuccessPage} />} />
    <Route
      path='/:uuid/action/load-material-batch'
      element={<PrivateRoute Component={LoadMaterialBatch} />}
    />
    <Route path='/:uuid/action/load-material' element={<PrivateRoute Component={LoadMaterial} />} />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default PrinterRoutes;
