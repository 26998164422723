import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import ActionError from 'src/components/action-error';
import ActionSpinner from 'src/components/loaders/action-spinner';
import SuccessCheckmarkAnimation from 'src/components/success-checkmark-animation';
import useMediaQuery from 'src/hooks/useMediaQuery';
import useActionPanelStore from 'src/stores/useActionPanelStore';

const ActionPanel = () => {
  // Retrieve the panel stack and the close function.
  const { panelStack, closeActionPanel } = useActionPanelStore();
  // Get the top panel (if any).
  const currentPanel = panelStack[panelStack.length - 1] || {};
  const isVisible = panelStack.length > 0;

  // Destructure properties from the current panel.
  const {
    title,
    isSidebar,
    content: ContentComponent,
    panelId,
    isSubmitting,
    successMessage,
    errorMessage,
  } = currentPanel;

  const timeoutRef = useRef(null);
  const isMobileView = useMediaQuery('(min-width: 300px) and (max-width: 600px)');
  const isDesktopSidebar = isSidebar && !isMobileView;

  const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const panelVariants = {
    hidden: isDesktopSidebar ? { x: '100%', y: 0 } : { x: 0, y: '100%' },
    visible: { x: 0, y: 0 },
    exit: isDesktopSidebar ? { x: '100%', y: 0 } : { x: 0, y: '100%' },
  };

  const desktopPanelVariants = {
    ...panelVariants,
    hidden: {
      ...panelVariants.hidden,
      x: isDesktopSidebar ? '100%' : 0,
      y: isDesktopSidebar ? 0 : '100%',
    },
    visible: { ...panelVariants.visible, x: 0, y: 0 },
  };

  const variants = isMobileView ? panelVariants : desktopPanelVariants;

  useEffect(() => {
    if (!isSubmitting && successMessage) {
      // Automatically close the panel after 2.5 seconds if there’s a success message.
      timeoutRef.current = setTimeout(() => {
        closeActionPanel();
      }, 2500);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isSubmitting, successMessage, closeActionPanel]);

  const closeActionPanelBtn = (
    <button
      type='button'
      className='btn btn-default btn-action action-panel-close-btn'
      onClick={closeActionPanel}
    >
      <FontAwesomeIcon className='action-panel-close-btn-icon' icon={faXmark} />
    </button>
  );

  const renderPanelHeader = () => {
    if (errorMessage) {
      return (
        <>
          <div>Error</div>
          {closeActionPanelBtn}
        </>
      );
    }
    return (
      <>
        <div>{title}</div>
        {closeActionPanelBtn}
      </>
    );
  };

  const renderPanelBody = () => {
    if (isSubmitting) {
      return <ActionSpinner />;
    }
    if (!isSubmitting && successMessage) {
      return (
        <>
          <SuccessCheckmarkAnimation />
          <div className='action-panel-success-message'>{successMessage}</div>
        </>
      );
    }
    if (errorMessage) {
      return (
        <>
          <ActionError />
          <div className='action-panel-error-message'>
            {errorMessage || 'Something went wrong. Please try again.'}
          </div>
        </>
      );
    }
    return ContentComponent;
  };

  return (
    <AnimatePresence mode='wait'>
      {isVisible && (
        <React.Fragment key={`panel-group-${panelId}`}>
          <motion.div
            key={`backdrop-${panelId}`}
            className='action-panel-backdrop'
            initial='hidden'
            animate='visible'
            exit='hidden'
            variants={backdrop}
            transition={{ duration: 0.3 }}
            onClick={closeActionPanel}
          />
          <motion.div
            key={`panel-${panelId}`}
            className={`${isSidebar ? 'action-panel-sidebar' : 'action-panel'}`}
            initial='hidden'
            animate='visible'
            exit='exit'
            variants={variants}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          >
            <div className='action-panel-header'>{renderPanelHeader()}</div>
            <div className='action-panel-body'>{renderPanelBody()}</div>
          </motion.div>
        </React.Fragment>
      )}
    </AnimatePresence>
  );
};

export default ActionPanel;
