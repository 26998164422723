import _isEmpty from 'lodash/isEmpty';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ActionContext from 'src/components/action-context';
import QrBarcodeToggle from 'src/components/qr-barcode-toggle';
import QrScan from 'src/components/qr-scan';
import FeaturesContext from 'src/context/FeaturesContext';
import UserContext from 'src/context/UserContext';
import { api } from 'src/utils/api';
import config from 'src/utils/config';
import {
  API_RESOURCES,
  API_RESOURCES_MAP,
  BUREAU_BARCODE_FORMAT,
  LOCALSTORAGE_KEYS,
  MATERIAL_BATCH_ACTIONS, MATERIAL_BATCH_STATUSES,
  PERMANENT_CONTAINER_ACTIONS, PERMANENT_CONTAINER_TYPES, PRINTER_ACTIONS, RUN_ACTIONS, TOOLING_STOCK_ACTION_TYPES,
} from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import { getResourceName, getRoute, getRouteURI, getUuid, isValidUrl } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import BarcodeScan from '../components/barcode-scan';
import routes from '../utils/routes';
import useLocalstorage from '../utils/useLocalStorage';

const ScanPage = ({
                    user,
                  }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    resource,
    action: initiatorAction,
    entity: requestedEntity,
    printer: printerUri,
    permanentContainer: permanentContainerUri,
    'material-batch': batchUri,
    isSameScan,
    skipSieving,
    sieveQuantity,
    initialBatchAction,
    initialSieveAction,
    isModular,
    actionFromPrinter,
    containerAction,
    runUri,
    toolUUID,
    container,
    sieveIntoPrinter,
    isFill,
    isLoad,
  } = Object.fromEntries(searchParams.entries()) ?? {};

  const [showInstructions, setShowInstructions] = useState(true);
  const [urlError, setUrlError] = useState(false);
  const [resourceError, setResourceError] = useState(false);
  const [successfulScan, setSuccessfulScan] = useState(Boolean(resource));
  const [customInstructionText, setCustomInstructionText] = useState('');

  const { features } = useContext(FeaturesContext);
  const isMaterialManagementEnabled = isFeatureEnabled(features, FEATURES.MATERIAL_MANAGEMENT);
  const isPermanentContainerUnloadAction = initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL
    || initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL;
  const isLoadPrinterAction = printerUri && initiatorAction === MATERIAL_BATCH_ACTIONS.MACHINE_LOAD;
  const isTopOffPrinterAction = printerUri && initiatorAction === MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF;
  const isSieveBatchToPermanentContainerAction = initiatorAction === PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH;
  const isActionMachineLoad = initiatorAction === MATERIAL_BATCH_ACTIONS.MACHINE_LOAD;
  const isLoadPermanentContainerAction = initiatorAction === PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL || initiatorAction === PERMANENT_CONTAINER_ACTIONS.TOP_OFF;
  const isShipmentForOrderEnabled = isFeatureEnabled(features, FEATURES.SHIPMENT_FOR_ORDER);
  const [scanMode, handleScanMode] = useLocalstorage(LOCALSTORAGE_KEYS.SCAN_MODE, BUREAU_BARCODE_FORMAT.QR);
  const isBarcode = scanMode === BUREAU_BARCODE_FORMAT.BARCODE;
  const isSieveIntoPrinterContainerAction = initiatorAction === PERMANENT_CONTAINER_ACTIONS.SIEVE && sieveIntoPrinter;

  const { setUser } = useContext(UserContext);

  const {
    containerActionData: { permanentContainersActionState },
  } = useContext(ActionContext);

  const handleResourceError = (error) => {
    if (error.message === 'NOT FOUND') {
      setUrlError(new Error('Resource not found. Please scan a valid resouce.'));
      setResourceError(true);
      return setSuccessfulScan(false);
    }

    setResourceError(true);
    return setUrlError(new Error('Something went wrong while scanning the resource. Please try again.'));
  };

  const handleGetRunFromSummaryURL = async (summaryUrl) => {
    if (!summaryUrl || !isValidUrl(summaryUrl)) return triggerScanError('The URL is not valid. Please scan a valid URL.');

    const runSummaryPageParams = new URLSearchParams(new URL(summaryUrl).search);
    const { line_item: lineItemUuid, copy } = Object.fromEntries(runSummaryPageParams.entries());

    const lineItemPath = `line-item/${lineItemUuid}/`;

    // Step 1: Get the Pieces for the Line Item by the Copy (Piece "ordering" -> #) number.
    const { resources: pieces } = await api.get(
      `${API_RESOURCES.PIECE}/`,
      {
        searchParams: {
          'filter[copy]': copy,
          // Line item may already be deleted, but piece may still exist
          // (so filter by line item will still work)
          'filter[line_item]': `${config.apiHost}/${lineItemPath}`,
          'sort': '-created',
        },
      }
    ).json();

    // Step 2: Either find the Piece (if multiple) which has "current_print" URI or get the first Piece.
    const activePrintPiece = pieces.find((piece) => piece.current_print) ?? pieces[0];

    // Step 3: If the Piece has a "current_print" URI, send API GET for /print to get the current Print.
    const currentPrint = activePrintPiece.current_print ?
      await api.get(activePrintPiece.current_print, { prefixUrl: false }).json()
      : null;

    // Step 4: If we have the Current Print, get its Run URI.
    const runUri = currentPrint && currentPrint.run;

    return runUri;
  };

  const runAlreadyHasTool = async (runUri) => {
    const toolingStockByRun = await api.get(`${API_RESOURCES.TOOLING_STOCK}/`, {
      searchParams: {
        'filter[run]': runUri,
      },
    }).json();

    return toolingStockByRun?.resources?.length;
  };

  const getBatch = async (batchUri) => {
    const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(batchUri)}/`).json();
    return batch;
  };

  const getBatchForPrinter = async (printerUri) => {
    const printerBatchResponse = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
      searchParams: {
        'filter[at_machine]': printerUri,
      },
    }).json();

    return printerBatchResponse?.resources?.[0];
  };

  useEffect(() => {
    async function handleResourceRedirect() {
      try {
        // In case user is scanning new QR code - redirect him to appropriate page
        const resourceName = getResourceName(resource);
        const resourceUUID = getUuid(resource);
        switch (resourceName) {
          case API_RESOURCES.TOOLING_STOCK:
            navigate(`/tool/${resourceUUID}`);
            break;
          case API_RESOURCES.PRINTER: {
            if (isModular) {
              return navigate(`/printer/${resourceUUID}/lps`);
            }
            navigate(`/printer/${resourceUUID}/material`);
          }
            break;
          case API_RESOURCES.SHIPMENT:
            navigate(`/shipment/${resourceUUID}`);
            break;
          case API_RESOURCES.POST_PROCESSOR:
            navigate(`/post-processor/${resourceUUID}`);
            break;
          case API_RESOURCES.MATERIAL_CONTAINER: {
            const container = await api.get(`material-container/${resourceUUID}/`).json();
            const containerType = container.type;

            const allowedPermanentContainerTypes = [
              PERMANENT_CONTAINER_TYPES.PERMANENT,
              PERMANENT_CONTAINER_TYPES.DOSE,
              PERMANENT_CONTAINER_TYPES.OVERFLOW,
              PERMANENT_CONTAINER_TYPES.BUILD,
            ]

            if ((
              !container.disposable && allowedPermanentContainerTypes.includes(containerType)) && !initialBatchAction) {
              return navigate(`/permanent-container/${resourceUUID}`);
            }


            if (containerType === PERMANENT_CONTAINER_TYPES.CYCLONE) {
              const mhsByCyclone = await api.get(API_RESOURCES.MLINE_MATERIAL_HANDLING_SYSTEM, {
                searchParams: {
                  'filter[cyclone]': resource,
                },
              }).json();
              const lpsPrinterUri = mhsByCyclone?.resources?.[0]?.post_processor;
              return navigate(`/post-processor/${getUuid(lpsPrinterUri)}`);
            }

            if (container.current_batch) {
              // If a container is assigned to a batch - redirect to that batch actions page
              navigate(getRouteURI(routes.materialContainer, {}, { batch: encodeURIComponent(getUuid(container.current_batch)) }));
            } else {
              // Redirect user to initial batch creation page otherwise
              navigate(getRouteURI(routes.materialContainerDetails, { uuid: resourceUUID }));
            }
            break;
          }
          default:
            setSuccessfulScan(false);
            break;
        }
      } catch (error) {
        handleResourceError(error);
      }
    }

    if (resource) {
      handleResourceRedirect();
    }
  }, [resource]);

  useEffect(() => {
    if (user?.initialLogin) {
      // Reset the initial redirect to /scan page when log in
      setUser({ ...user, initialLogin: false });
    }
  }, [user]);

  useEffect(() => {
    if (requestedEntity === API_RESOURCES.RUN) {
      setCustomInstructionText(`Please scan 
          ${isBarcode ? 'the Barcode' : 'the QR Code'} of a Tool 
          ${isBarcode ? 'using your Barcode Scanner' : ''} to assign it to this Run.
          ${isBarcode ? 'Then you will be automatically taken to the relevant page.' : ''}`);
    }
  }, [requestedEntity, isBarcode]);

  const triggerScanError = (errorText) => {
    setUrlError(new Error(errorText));
    setSuccessfulScan(false);
  };

  async function handleScan(url, resourceName, resourceUri, resource) {
    // url field is deprecated and just for legacy field support,
    // you might not want to use it.

    setShowInstructions(false);
    setUrlError(false);
    setSuccessfulScan(true);

    // In case user was redirected to this page in order to scan additional QR Code
    if (initiatorAction) {
      // Set initial URIs, if any provided in URL
      let scannedContainerBatchUri;
      let scannedPermanentContainerUri;
      if (resourceName === API_RESOURCES.MATERIAL_BATCH) {
        // It's possible to enter it manually
        scannedContainerBatchUri = resourceUri;
      }

      if (resourceName === API_RESOURCES.MATERIAL_CONTAINER) {
        if (
          (isPermanentContainerUnloadAction || isSieveBatchToPermanentContainerAction) &&
          isSameScan &&
          resourceUri &&
          permanentContainersActionState.containersScanned[getUuid(resourceUri)]
        ) {
          const errorMessage =
            `The container scanned has already been processed and is listed for 
            ${isPermanentContainerUnloadAction ? 'material unloading' : 'sieving batch'}.\nPlease scan a different container.`;

          return triggerScanError(errorMessage);
        }

        // Fill missing data based on scanned resource
        const materialContainer = await api.get(resourceUri, { prefixUrl: false }).json();
        const isLoadAction = initiatorAction === PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL ||
          initiatorAction === PERMANENT_CONTAINER_ACTIONS.TOP_OFF ||
          initiatorAction === MATERIAL_BATCH_ACTIONS.MACHINE_LOAD;
        const isUnloadAction = initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL
          || initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL;

        if ((isSieveBatchToPermanentContainerAction && materialContainer) && materialContainer.disposable) {
          return triggerScanError('Materials may only be sieved into the Permanent Container');
        }

        /* Sieve Batch into the Permanent Container is possible only if the Permanent Container
           does not have the batch assigned (is Empty) or if it has the same Batch
           (which means we will sieve the amount into the same container) */

        if ((isSieveBatchToPermanentContainerAction && materialContainer)
          && (!!materialContainer.current_batch && materialContainer.current_batch !== batchUri)) {
          return triggerScanError('The permanent container you have scanned is already loaded with a different batch');
        }

        if ((isLoadAction && materialContainer) && materialContainer.current_batch === batchUri) {
          return triggerScanError('The permanent container you have scanned is already loaded with the same batch');
        }

        if (isUnloadAction && materialContainer.disposable) {
          return triggerScanError('Materials may only be unloaded into the Permanent Container');
        }

        // We can scan the Permanent Container which Has No Batch in it only to Unload Unused Material (Hopper)
        if (isUnloadAction && !materialContainer.disposable && materialContainer.current_batch) {
          return triggerScanError('Materials may only be unloaded into an Empty Permanent Container');
        }

        if (!materialContainer.current_batch && materialContainer.disposable) {
          // Containers not added to any batch are not allowed to be used for any action
          // except Initial Batch creation
          return triggerScanError('The container that you have scanned is not part of a batch. Please scan the container and create a batch before attempting to load the container into the machine.');
        }
        scannedPermanentContainerUri = resourceUri;
        scannedContainerBatchUri = materialContainer.current_batch;
      }

      // Redirect user to appropriate page adding all required params
      switch (initiatorAction) {
        case MATERIAL_BATCH_ACTIONS.MACHINE_LOAD: {
          // Set initial URIs, if any provided in URL
          let materialBatchUriForRedirect = scannedContainerBatchUri || batchUri;
          let resourceUriForRedirect = printerUri;

          if (resourceName === API_RESOURCES.MATERIAL_BATCH) {
            materialBatchUriForRedirect = resourceUri;

            const batch = await getBatch(resourceUri);

            if (batch?.status === MATERIAL_BATCH_STATUSES.DONE) {
              return triggerScanError('The batch you scanned is already completed. Please scan a different batch.');
            }

            const batchQueryParams = {
              type: initiatorAction,
              action: PERMANENT_CONTAINER_ACTIONS.MACHINE_LOAD,
              batch: resourceUri,
              ...(actionFromPrinter && { actionFromPrinter: true }),
              ...(initialBatchAction && { initialBatchAction: true }),
            };

            if (batch?.containers?.length > 1) {
              batchQueryParams.type = PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH;
              batchQueryParams.action = PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH;
              batchQueryParams.isLoad = true;

              navigate(getRouteURI(routes.printerLoadMaterialBatchAction,
                { uuid: getUuid(printerUri) },
                batchQueryParams));
              return;
            }

            // Empty batch has been scanned, redirect to Load Material (+Partial) UI for Printer
            if (_isEmpty(batch.containers)) {
              const printerRedirectQuery = {
                type: MATERIAL_BATCH_ACTIONS.MACHINE_LOAD,
                batch: resourceUri,
                action: PRINTER_ACTIONS.LOAD_MATERIAL,
                isLoad: true,
                printerUUID: getUuid(printerUri),
                actionFromPrinter: true,
              };

              navigate(getRouteURI(routes.printerLoadMaterialAction,
                { uuid: getUuid(printerUri) },
                printerRedirectQuery));
              return;
            }

            if (batch.containers.length === 1) {
              // One container was scanned, redirect to Container Load Material (+Partial) UI for Printer

              const permanentContainerQueryParams = {
                type: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
                batch: batch.uri,
                action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
                isLoad: true,
                printer: resourceUriForRedirect,
                actionFromPrinter: true,
              };

              navigate(getRouteURI(routes.permanentContainerAction,
                { uuid: getUuid(batch.containers[0]) },
                permanentContainerQueryParams));
              return;

            }
          }

          if (resourceName === API_RESOURCES.PRINTER ||
            (resourceName === API_RESOURCES.MATERIAL_CONTAINER
              && requestedEntity !== API_RESOURCES.MATERIAL_BATCH)) {
            resourceUriForRedirect = resourceUri;
          }

          if (resourceName === API_RESOURCES.MATERIAL_CONTAINER
            && requestedEntity !== API_RESOURCES.MATERIAL_BATCH) {

            const batch = batchUri ? await getBatch(batchUri) : null;

            if (batch?.containers?.length > 1) {

              const permanentContainerQueryParams = {
                type: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH,
                batch: batchUri,
                action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH,
                actionBatchLoad: true,
                initialBatchAction: true,
              };

              navigate(getRouteURI(routes.permanentContainerAction,
                { uuid: getUuid(resourceUri) },
                permanentContainerQueryParams));
              return;
            }

            const permanentContainerQueryParams = {
              type: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
              batch: batchUri,
              batchAction: true,
              ...(initialBatchAction && { initialBatchAction: true }),
              action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
              actionBatchLoad: true,
            };

            navigate(getRouteURI(routes.permanentContainerAction,
              { uuid: getUuid(resourceUriForRedirect) },
              permanentContainerQueryParams));
            return;
          }

          // If we load Printer from Batch Page -> Redirect to Partial / Batch Containers Subset page
          if (resourceName === API_RESOURCES.PRINTER && requestedEntity === API_RESOURCES.PRINTER) {
            const batch = batchUri ? await getBatch(batchUri) : null;
            const printerBatch = await getBatchForPrinter(resourceUri);

            if (batch?.status === MATERIAL_BATCH_STATUSES.DONE) {
              return triggerScanError('The batch you scanned is already completed. Please scan a different batch.');
            }

            if (printerBatch?.uri === batch?.uri) {
              return triggerScanError('The batch you scanned is already loaded into the printer. Please scan a different batch.');
            }

            const batchQueryParams = {
              type: initiatorAction,
              action: PERMANENT_CONTAINER_ACTIONS.MACHINE_LOAD,
              batch: batchUri,
              ...(initialBatchAction && { initialBatchAction: true }),
            };

            if (batch?.containers?.length > 1) {
              batchQueryParams.type = PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH;
              batchQueryParams.action = PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH;
              batchQueryParams.isLoad = true;

              navigate(getRouteURI(routes.printerLoadMaterialBatchAction,
                { uuid: getUuid(resourceUri) },
                batchQueryParams));
              return;
            }

            // Empty batch has been scanned, redirect to Load Material (+Partial) UI for Printer
            if (_isEmpty(batch.containers)) {
              const printerRedirectQuery = {
                type: MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF,
                batch: batchUri,
                action: PRINTER_ACTIONS.LOAD_MATERIAL,
                isLoad: true,
                printerUUID: getUuid(resourceUri),
                ...(initialBatchAction && { initialBatchAction: true }),
                actionFromPrinter: true,
              };

              navigate(getRouteURI(routes.printerLoadMaterialAction,
                { uuid: getUuid(resourceUri) },
                printerRedirectQuery));
              return;
            }

            if (batch.containers.length === 1) {
              // One container was scanned, redirect to Container Load Material (+Partial) UI for Printer

              const permanentContainerQueryParams = {
                type: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
                batch: batch.uri,
                action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
                isLoad: true,
                printer: resourceUri,
                ...(initialBatchAction && { initialBatchAction: true }),
                actionFromPrinter: true,
              };

              navigate(getRouteURI(routes.permanentContainerAction,
                { uuid: getUuid(batch.containers[0]) },
                permanentContainerQueryParams));
              return;

            }

          }

          // Redirect to Load / Top Off Material UI (+Partial) for Container
          if (resourceName === API_RESOURCES.MATERIAL_CONTAINER
            && requestedEntity === API_RESOURCES.MATERIAL_BATCH) {
            const containerUri = resourceUri;

            const container = await api.get(`material-container/${getUuid(containerUri)}/`).json();

            if (!container?.current_batch) {
              return triggerScanError('The container you scanned is empty. Please scan a permanent container with material.');
            }

            const permanentContainerQueryParams = {
              type: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
              batch: container.current_batch,
              action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
              isLoad: true,
              printer: resourceUriForRedirect,
              ...(initialBatchAction && { initialBatchAction: true }),
              actionFromPrinter: true,
            };

            navigate(getRouteURI(routes.permanentContainerAction,
              { uuid: getUuid(containerUri) },
              permanentContainerQueryParams));
            return;
          }

          const batchQueryParams = {
            type: initiatorAction,
            printer: resourceUriForRedirect,
            initialBatchAction: initialBatchAction,
          };

          navigate(getRouteURI(routes.materialBatchAction,
            { uuid: getUuid(materialBatchUriForRedirect) },
            batchQueryParams));

          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL: {
          // Set initial URIs, if any provided in URL
          const materialBatchUri = scannedContainerBatchUri || batchUri;
          const isContainerLink = resourceUri.includes('material-container');
          const isOriginalContainerDestination = isLoad && resourceUri.includes('material-container');
          const isPrinterLink = resourceUri.includes('printer');

          if (!materialBatchUri && !isLoad) {
            return triggerScanError('The container you scanned is empty. Please scan a permanent container with material.');
          }

          // If we do Fill / Top Off for Container, use the regulat Batch URI
          let batchForSourceContainer = materialBatchUri;

          if (isLoad && !isPrinterLink && permanentContainerUri) {
            // If we use Load action, we need to get the Batch from the source Container
            // (swap Source and Destination for Load, source will be the resource where we clicked "Load")
            const container = await api.get(`material-container/${getUuid(permanentContainerUri)}/`).json();
            batchForSourceContainer = container.current_batch;
          }

          const permanentContainerQueryParams = {
            type: initiatorAction,
            action: initiatorAction,
            ...(materialBatchUri && { batch: batchForSourceContainer }),
            ...(isFill && { isFill }),
            ...(isLoad && { isLoad }),
            ...(containerAction && { containerAction: true }),
            ...(isLoad && isPrinterLink && { printer: resourceUri }),
            ...(isLoad && !isPrinterLink && { sourceContainerUUID: getUuid(permanentContainerUri) }),
          };

          const batch = !isLoad && await getBatch(materialBatchUri);

          if (batch?.status === MATERIAL_BATCH_STATUSES.DONE) {
            return triggerScanError('The batch you scanned is already completed. Please scan a different batch.');
          }

          if (batch?.containers?.length > 1 && !isContainerLink && !isLoad) {
            permanentContainerQueryParams.type = PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH;
            permanentContainerQueryParams.action = PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH;

            navigate(getRouteURI(routes.permanentContainerAction,
              { uuid: getUuid(permanentContainerUri) },
              permanentContainerQueryParams));
            return;
          }

          const containerRedirectUri = isOriginalContainerDestination && !isPrinterLink ? resourceUri : permanentContainerUri;

          navigate(getRouteURI(routes.permanentContainerAction,
            { uuid: getUuid(containerRedirectUri) },
            permanentContainerQueryParams));
          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.TOP_OFF: {
          // Set initial URIs, if any provided in URL
          const materialBatchUri = scannedContainerBatchUri || batchUri;

          if (!materialBatchUri) {
            return triggerScanError('The permanent container you scanned is empty. Please scan a permanent container with material.');
          }

          const permanentContainerQueryParams = {
            batch: materialBatchUri,
            action: PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
            ...(containerAction && { containerAction: true }),
          };

          const batch = await getBatch(materialBatchUri);

          if (batch?.status === MATERIAL_BATCH_STATUSES.DONE) {
            return triggerScanError('The batch you scanned is already completed. Please scan a different batch.');
          }

          const isContainerLink = resourceUri.includes('material-container');

          if (batch?.containers?.length > 1 && !isContainerLink) {
            permanentContainerQueryParams.type = PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH;
            permanentContainerQueryParams.action = PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH;

            navigate(getRouteURI(routes.permanentContainerAction,
              { uuid: getUuid(permanentContainerUri) },
              permanentContainerQueryParams));
            return;
          }

          navigate(getRouteURI(routes.permanentContainerLoadMaterialAction,
            { uuid: getUuid(permanentContainerUri) },
            permanentContainerQueryParams));
          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER: {
          const isScannedResourcePrinter = resourceUri.includes('printer');

          const permanentContainerQueryParams = {
            type: initiatorAction,
            action: initiatorAction,
            ...(containerAction && { containerAction: true }),
            ...(!isScannedResourcePrinter
              ? { destinationContainer: getUuid(resourceUri) }
              : { printer: getUuid(resourceUri) }),
          };

          navigate(getRouteURI(routes.permanentContainerActionSplit,
            { uuid: getUuid(permanentContainerUri) },
            permanentContainerQueryParams));
          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.TRANSFER_MATERIAL: {
          // Set initial URIs, if any provided in URL
          const materialBatchUri = scannedContainerBatchUri || batchUri;
          const permanentContainerUriToRedirect = scannedPermanentContainerUri || permanentContainerUri;

          const permanentContainerQueryParams = {
            type: initiatorAction,
            batch: materialBatchUri,
          };

          navigate(getRouteURI(routes.permanentContainerAction,
            { uuid: getUuid(permanentContainerUriToRedirect) },
            permanentContainerQueryParams));
          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL:
        case PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL: {
          // Set initial URIs, if any provided in URL
          const permanentContainerUriToRedirect = scannedPermanentContainerUri || permanentContainerUri;

          const permanentContainerQueryParams = {
            type: initiatorAction,
            batch: batchUri,
            printer: printerUri,
            ...(actionFromPrinter && { actionFromPrinter: true }),
          };

          navigate(getRouteURI(routes.permanentContainerAction,
            { uuid: getUuid(permanentContainerUriToRedirect) },
            permanentContainerQueryParams));
          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH: {
          // Set initial URIs, if any provided in URL
          const permanentContainerUriToRedirect = scannedPermanentContainerUri || permanentContainerUri;

          const permanentContainerQueryParams = {
            type: initiatorAction,
            batch: batchUri,
            sieveQuantity,
            batchAction: true,
          };

          if (initialSieveAction) {
            permanentContainerQueryParams.initialSieveAction = initialSieveAction;
          }

          navigate(getRouteURI(routes.permanentContainerAction,
            { uuid: getUuid(permanentContainerUriToRedirect) },
            permanentContainerQueryParams));
          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.SIEVE: {
          // Set initial URIs, if any provided in URL
          const permanentContainerUriToRedirect = scannedPermanentContainerUri || permanentContainerUri;

          const permanentContainerQueryParams = {
            type: initiatorAction,
            container,
            ...(containerAction && { containerAction: true }),
            sieveQuantity,
          };

          if (initialSieveAction) {
            permanentContainerQueryParams.initialSieveAction = initialSieveAction;
          }

          if (sieveIntoPrinter) {
            delete permanentContainerQueryParams.container;
            permanentContainerQueryParams.sieveIntoPrinter = sieveIntoPrinter;
            permanentContainerQueryParams.printer = resourceUri;
          }

          navigate(getRouteURI(routes.permanentContainerAction,
            { uuid: getUuid(sieveIntoPrinter ? container : permanentContainerUriToRedirect) },
            permanentContainerQueryParams));
          return;
        }
        case MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF: {
          // Set initial URIs, if any provided in URL
          let materialBatchUriForRedirect = scannedContainerBatchUri || batchUri;
          let printerUriForRedirect = printerUri;

          if (resourceName === API_RESOURCES.MATERIAL_BATCH) {
            materialBatchUriForRedirect = resourceUri;


            const batch = await getBatch(resourceUri);
            const printerBatch = await getBatchForPrinter(printerUri);

            if (batch?.status === MATERIAL_BATCH_STATUSES.DONE) {
              return triggerScanError('The batch you scanned is already completed. Please scan a different batch.');
            }

            if (printerBatch?.uri === batch?.uri) {
              return triggerScanError('The batch you scanned is already loaded into the printer. Please scan a different batch.');
            }

            const batchQueryParams = {
              type: initiatorAction,
              action: PERMANENT_CONTAINER_ACTIONS.MACHINE_LOAD,
              batch: resourceUri,
              ...(actionFromPrinter && { actionFromPrinter: true }),
            };

            if (batch?.containers?.length > 1) {
              batchQueryParams.type = PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH;
              batchQueryParams.action = PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH;
              batchQueryParams.isLoad = true;

              navigate(getRouteURI(routes.printerLoadMaterialBatchAction,
                { uuid: getUuid(printerUri) },
                batchQueryParams));
              return;
            }

            // Empty batch has been scanned, redirect to Load Material (+Partial) UI for Printer
            if (_isEmpty(batch.containers)) {
              const printerRedirectQuery = {
                type: MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF,
                batch: resourceUri,
                action: PRINTER_ACTIONS.LOAD_MATERIAL,
                isLoad: true,
                printerUUID: getUuid(printerUri),
                ...(initialBatchAction && { initialBatchAction: true }),
                actionFromPrinter: true,
              };

              navigate(getRouteURI(routes.printerLoadMaterialAction,
                { uuid: getUuid(printerUri) },
                printerRedirectQuery));
              return;
            }

            if (batch.containers.length === 1) {
              // One container was scanned, redirect to Container Load Material (+Partial) UI for Printer

              const permanentContainerQueryParams = {
                type: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
                batch: batch.uri,
                action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
                isLoad: true,
                printer: printerUriForRedirect,
                ...(initialBatchAction && { initialBatchAction: true }),
                actionFromPrinter: true,
              };

              navigate(getRouteURI(routes.permanentContainerAction,
                { uuid: getUuid(batch.containers[0]) },
                permanentContainerQueryParams));
              return;

            }

          }

          // Redirect to Load / Top Off Material UI (+Partial) for Container
          if (resourceName === API_RESOURCES.MATERIAL_CONTAINER
            && requestedEntity === API_RESOURCES.MATERIAL_BATCH) {
            const containerUri = resourceUri;

            const container = await api.get(`material-container/${getUuid(containerUri)}/`).json();

            if (!container?.current_batch) {
              return triggerScanError('The container you scanned is empty. Please scan a permanent container with material.');
            }

            const permanentContainerQueryParams = {
              type: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
              batch: container.current_batch,
              action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
              isLoad: true,
              printer: printerUri,
              ...(initialBatchAction && { initialBatchAction: true }),
              actionFromPrinter: true,
            };

            navigate(getRouteURI(routes.permanentContainerAction,
              { uuid: getUuid(containerUri) },
              permanentContainerQueryParams));
            return;
          }


          const printerQueryParams = {
            batch: materialBatchUriForRedirect,
            initialBatchAction,
          };

          navigate(getRouteURI(routes.printerAlreadyLoaded,
            { uuid: getUuid(printerUriForRedirect) },
            printerQueryParams));
          return;
        }

        case MATERIAL_BATCH_ACTIONS.BLEND_BATCHES: {

          const batchQueryParams = {
            type: initiatorAction,
            blendedBatch: scannedContainerBatchUri,
            skipSieving,
            initialBatchAction,
          };

          navigate(getRouteURI(routes.materialBatchAction,
            { uuid: getUuid(batchUri) },
            batchQueryParams));
          return;
        }

        case RUN_ACTIONS.SCAN_TOOLING_STOCK: {
          const runQueryParams = {
            type: initiatorAction,
            runUri,
            toolingStockUUID: getUuid(resourceUri),
          };

          navigate(getRouteURI(routes.runAction,
            { uuid: getUuid(runUri) },
            runQueryParams));
          return;
        }

        case TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE: {
          const runUri = await handleGetRunFromSummaryURL(resourceUri);
          const isAlreadyAssociated = await runAlreadyHasTool(runUri);

          if (isAlreadyAssociated) return triggerScanError('The Tool is already associated with this Run. Please scan another Run.');

          const runQueryParams = {
            type: initiatorAction,
            runUUID: getUuid(runUri),
            toolingStockUUID: toolUUID,
          };

          navigate(getRouteURI(routes.toolAction,
            { uuid: toolUUID },
            runQueryParams));
          return;
        }

        default:
          break;
      }
    }

    if (!url && resourceName && resourceUri) {
      const uuid = getUuid(resourceUri);

      const routesMap = {
        [API_RESOURCES.MATERIAL_BATCH]: () => navigate(`/material-container?batch=${uuid}`),
        [API_RESOURCES.SHIPMENT]: () => navigate(`/shipment/${uuid}`),
        [API_RESOURCES.POST_PROCESSOR]: () => navigate(`/post-processor/${uuid}`),
        [API_RESOURCES.RUN]: () => navigate(`/traveler/run/${uuid}`),
      };

      if (routesMap[resourceName]) {
        return routesMap[resourceName]();
      }

      switch (resourceName) {
        case API_RESOURCES.PRINTER: {
          const printerType = await api.get(`${API_RESOURCES.PRINTER_TYPE}/${getUuid(resource.printer_type)}/`).json();

          const destination = printerType.is_modular
            ? `/printer/${uuid}/lps`
            : `/printer/${uuid}/material`;
          navigate(destination);
          break;
        }
        case API_RESOURCES.PIECE: {
          const piece = await api.get(`${API_RESOURCES.PIECE}/${uuid}/`).json();
          navigate(`/traveler/print/${getUuid(piece.current_print)}`);
          break;
        }
        case API_RESOURCES.MATERIAL_CONTAINER: {
          const container = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${uuid}/`).json();
          const containerType = container.type;

          const allowedPermanentContainerTypes = [
            PERMANENT_CONTAINER_TYPES.PERMANENT,
            PERMANENT_CONTAINER_TYPES.DOSE,
            PERMANENT_CONTAINER_TYPES.OVERFLOW,
            PERMANENT_CONTAINER_TYPES.BUILD,
          ]

          if ((
            !container.disposable && allowedPermanentContainerTypes.includes(containerType)) && !initialBatchAction) {
            return navigate(`/permanent-container/${uuid}`);
          }

          if (containerType === PERMANENT_CONTAINER_TYPES.CYCLONE) {
            const mhsByCyclone = await api.get(API_RESOURCES.MLINE_MATERIAL_HANDLING_SYSTEM, {
              searchParams: {
                'filter[cyclone]': resourceUri,
              },
            }).json();
            const lpsPrinterUri = mhsByCyclone?.resources?.[0]?.post_processor;
            return navigate(`/post-processor/${getUuid(lpsPrinterUri)}`);
          }

          if (container.current_batch) {
            // If a container is assigned to a batch - redirect to that batch actions page
            navigate(getRouteURI(routes.materialContainer, {}, { batch: encodeURIComponent(getUuid(container.current_batch)) }));
          } else {
            // Redirect user to initial batch creation page otherwise
            navigate(getRouteURI(routes.materialContainerDetails, { uuid: uuid }));
          }
          break;
        }
        default:
          setUrlError(new Error('This resource is not supported yet'));
          setSuccessfulScan(false);
      }
    }

    if (url) {
      navigate(getRoute(url));
    }
  }

  function handleError(error) {
    setUrlError(error);
  }

  const getAllowedEntityType = () => {
    if (requestedEntity === API_RESOURCES.MATERIAL_BATCH) {
      return API_RESOURCES.MATERIAL_CONTAINER;
    }

    return requestedEntity;
  };
  // When no entity requested - allowed entity type is not set (which means - Any is allowed)
  const allowedEntityType = getAllowedEntityType();

  const getAllowedEntityName = () => {
    if (requestedEntity) {
      if (!API_RESOURCES_MAP[allowedEntityType]) {
        // Adding exception as a fall-back.
        // Anyway, should not be visible in real life
        // unless someone changes the URL manually
        throw new Error(`Requested QR code type "${requestedEntity}" is not supported.`);
      }
      return API_RESOURCES_MAP[allowedEntityType];
    }

    // By default we have QR codes only for line-item+copy (called traveler initially)
    const allowedEntities = ['traveler'];
    if (isShipmentForOrderEnabled) {
      allowedEntities.push(API_RESOURCES_MAP[API_RESOURCES.SHIPMENT]);
    }
    if (isMaterialManagementEnabled) {
      allowedEntities.push(
        API_RESOURCES_MAP[API_RESOURCES.MATERIAL_CONTAINER],
        API_RESOURCES_MAP[API_RESOURCES.PRINTER],
      );
    }
    if (allowedEntities.length === 1) {
      // Only 1 entity is allowed.
      return allowedEntities[0];
    }

    // Separating the last item to make it `xxx, yyy, zzz OR mmm`
    // Where `xxx, yyy, zzz` is all except last and `mmm` is the last item
    const allowedEntitiesExceptLast = allowedEntities.slice(0, -1);
    return `${allowedEntitiesExceptLast.join(', ')} or ${allowedEntities[allowedEntities.length - 1]}`;
  };

  const instructionText = `Use your camera to scan the QR code on the ${getAllowedEntityName()}.`;
  const barcodeInstructionText = `Scan the barcode on a ${getAllowedEntityName()} 
  using your Barcode Scanner, and you will be automatically taken to the relevant page.`;

  const toggleScanMode = () => handleScanMode(isBarcode ? BUREAU_BARCODE_FORMAT.QR : BUREAU_BARCODE_FORMAT.BARCODE);

  const renderUseWithoutPermanentContainer = useCallback(() => {
    if (
      (initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL ||
        initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL) &&
      !isSameScan) {

      const action = initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL ?
        MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER :
        MATERIAL_BATCH_ACTIONS.UNLOAD_RECLAIMED_MATERIAL;

      return (
        <Link
          to={`/material-batch/${getUuid(batchUri)}/action?type=${action}${actionFromPrinter ? `&actionFromPrinter=true&printer=${printerUri}` : ''}`}
          className="link-btn"
        >
          <button
            type="button"
            className="btn btn-lg btn-primary btn-block"
            disabled={!batchUri}
          >
            Unload without permanent container
          </button>
        </Link>
      );
    }

    return null;

  }, [batchUri, initiatorAction]);

  const content = isBarcode ? (
    <BarcodeScan
      user={user}
      allowedEntityType={allowedEntityType}
      successfulScan={successfulScan}
      handleScan={handleScan}
      handleError={handleError}
      barcodeError={urlError}
      showInstructions={showInstructions}
      instructionText={customInstructionText || barcodeInstructionText}
      requestedEntity={requestedEntity}
      setBarcodeError={setUrlError}
      resourceError={resourceError}
      renderUseWithoutPermanentContainer={renderUseWithoutPermanentContainer}
    />
  ) : (
    <QrScan
      user={user}
      allowedEntityType={allowedEntityType}
      successfulScan={successfulScan}
      handleScan={handleScan}
      handleError={handleError}
      initiatorAction={initiatorAction}
      urlError={urlError}
      showInstructions={showInstructions}
      instructionText={customInstructionText || instructionText}
      requestedEntity={requestedEntity}
      renderUseWithoutPermanentContainer={renderUseWithoutPermanentContainer}
      isUnloadAction={isPermanentContainerUnloadAction || isSieveBatchToPermanentContainerAction}
      isActionMachineLoad={isActionMachineLoad}
      isLoadPermanentContainerAction={isLoadPermanentContainerAction}
      isLoadPrinterAction={isLoadPrinterAction || isTopOffPrinterAction}
      isFillAction={isFill}
      isLoadAction={isLoad}
      isSieveIntoPrinterContainerAction={isSieveIntoPrinterContainerAction}
    />
  );


  return (
    <>
      {content}
      <QrBarcodeToggle scanMode={scanMode} toggleScanMode={toggleScanMode} action={initiatorAction} />
    </>
  );
};

ScanPage.propTypes = {
  user: userPropType.isRequired,
};

export default ScanPage;
