import { faBarcode } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import BarcodePanel from 'src/components/barcode-panel';
import Feature from 'src/components/feature';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import useBarcodePanelStore from 'src/stores/useBarcodePanelStore';
import { FEATURES } from 'src/utils/features';

const RecordBuild = ({ handleStartBuild }) => {
  const {
    openActionPanel,
    closeActionPanel,
  } = useActionPanelStore();

  const {
    scannedBarcodeValue,
    setScannedBarcodeValue,
    resetBarcodeValue,
  } = useBarcodePanelStore();

  const handleOpenExternalBuildIdBarcodeScanner = () => {
    resetBarcodeValue();
    openActionPanel({
      title: 'Scan Barcode',
      stack: true,
      content: <BarcodePanel
        placeholder="Scan External Build ID"
        instructionText="Please scan or type the barcode" />,
    });
  };

  return (
    <div>
      <label>
        <p>Please record the Build ID for the currently active build.</p>
        <p className="mb0">When the build is complete, you will be able to process the Dose, Overflow, and Build Modules at the MHS to calculate the amount of powder reclaimed and consumed by the build process.</p>
        <p className="mb0">You will then be able to use the modules to complete future print jobs.</p>
      </label>

      <Feature featureName={FEATURES.EXTERNAL_BUILD_ID}>
        <div className="input-group mt-4 mb-4 d-flex align-items-center">
          <input
            name="storedAmount"
            value={scannedBarcodeValue}
            type="text"
            aria-label="External Build ID"
            placeholder="Enter Build ID"
            className="form-control"
            onChange={(event) =>
                setScannedBarcodeValue({ scannedBarcodeValue: event.target.value })}
            />
          <button
            type="button" className="btn btn-link btn-sm barcode-scan-btn"
            onClick={handleOpenExternalBuildIdBarcodeScanner}
          >
            <FontAwesomeIcon icon={faBarcode} className="spacer-left" />
          </button>
        </div>
      </Feature>

      <button
        type='button'
        className='btn btn-lg btn-primary action-btn btn-block mt60'
        onClick={() => handleStartBuild(scannedBarcodeValue)}
      >
        Confirm
      </button>
      <button type='button' className='btn btn-lg btn-block' onClick={closeActionPanel}>
        Cancel
      </button>
    </div>
  )
}

RecordBuild.propTypes = {
  handleStartBuild: PropTypes.func.isRequired,
}

export default RecordBuild;
