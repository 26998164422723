import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import { MATERIAL_BATCH_ACTIONS } from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

const BatchActionPage = ({ user }) => {
  const [actionNotFound, setActionNotFound] = useState(false);
  const { uuid: id } = useParams();
  const [searchParams] = useSearchParams();
  const {
    printer: printerUri,
    type,
    blendedBatch,
    skipSieving,
    initialBatchAction,
    containerAction,
    actionFromPrinter,
    batchMultipleContainers,
    containerUUID,
  } = Object.fromEntries(searchParams.entries()) ?? {};

  const navigate = useNavigate();

  useEffect(() => {
    let pageName = null;
    const queryParams = {};

    switch (type) {
      case MATERIAL_BATCH_ACTIONS.MACHINE_LOAD:
        pageName = 'machine-load';
        queryParams.printer = printerUri;
        if (initialBatchAction) {
          queryParams.initialBatchAction = initialBatchAction;
        }
        break;
      case MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER:
        pageName = 'machine-unload-chamber';

        if (actionFromPrinter) {
          queryParams.actionFromPrinter = actionFromPrinter;
        }

        if (printerUri) {
          queryParams.printer = printerUri;
        }

        break;
      case MATERIAL_BATCH_ACTIONS.UNLOAD_RECLAIMED_MATERIAL:
        pageName = 'unload-reclaimed-material';

        if (actionFromPrinter) {
          queryParams.actionFromPrinter = actionFromPrinter;
        }

        if (printerUri) {
          queryParams.printer = printerUri;
        }

        break;
      case MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER:
        pageName = 'machine-unload-hopper';

        if (actionFromPrinter) {
          queryParams.actionFromPrinter = actionFromPrinter;
        }

        if (printerUri) {
          queryParams.printer = printerUri;
        }

        break;
      case MATERIAL_BATCH_ACTIONS.SCRAP_BATCH:
        pageName = 'scrap-batch';
        break;
      case MATERIAL_BATCH_ACTIONS.BLEND_BATCHES:
        pageName = 'blend-batches';
        queryParams.blendedBatch = blendedBatch;
        if (skipSieving) {
          queryParams.skipSieving = skipSieving;
        }
        break;
      case MATERIAL_BATCH_ACTIONS.SIEVE:
        pageName = 'sieve';
        break;
      case MATERIAL_BATCH_ACTIONS.RELOCATE:
        pageName = 'relocate';
        break;
      case MATERIAL_BATCH_ACTIONS.TREATMENT:
        pageName = 'treatment';
        break;
      case MATERIAL_BATCH_ACTIONS.SPLIT_BATCH:
        pageName = 'split-batch';

        if (batchMultipleContainers) {
          queryParams.batchMultipleContainers = batchMultipleContainers;
        }

        break;
      case MATERIAL_BATCH_ACTIONS.TEST:
        pageName = 'start-test';
        if (containerUUID) {
          queryParams.containerUUID = containerUUID;
        }

        if (containerAction) {
          queryParams.containerAction = containerAction;
        }
        break;
      default:
        setActionNotFound(true);
        break;
    }

    if (pageName) {
      navigate(getRouteURI(
          `${routes.materialBatchAction}/${pageName}`, { uuid: id }, queryParams),
        { replace: true });
    }
  }, [type]);

  if (actionNotFound) {
    return (
      <>
        <Header title="Material Batch Action Not Found" user={user} />
        <main role="main" className="text-center">
          <NotFound id={id} />
        </main>
      </>
    );
  }

  return (
    <>
      <Header title="Material Batch Action" user={user} />
      <main role="main" className="text-center">
        <Loader />
      </main>
    </>
  );
};

BatchActionPage.propTypes = {
  user: userPropType,
};

BatchActionPage.defaultProps = {
  user: null,
};

export default BatchActionPage;
