import { ACTION_PROGRESS_CONFIG, ACTION_STEP_CONFIG } from 'src/constants/action-progress';
import { API_RESOURCES } from 'src/utils/constants';

function kebabToSnake(str) {
  return str.replace(/-/g, '_');
}

function extractActionFromUrl(url) {
  const searchAction = url.searchParams.get('action');
  if (searchAction) {
    return searchAction;
  }

  const parts = url.pathname.split('/').filter(Boolean);
  const actionIndex = parts.indexOf('action');

  if (actionIndex !== -1 && parts[actionIndex + 1]) {
    return kebabToSnake(parts[actionIndex + 1]);
  }

  // Handle "already-loaded" as a potential action
  if (parts.includes('already-loaded')) {
    return 'already_loaded';
  }

  return null;
}

function isValidUUID(uuid) {
  return /^[0-9a-fA-F-]{36}$/.test(uuid);
}

function extractUUIDFromPathname(pathname, resourceType = API_RESOURCES.MATERIAL_BATCH) {
  const parts = pathname.split('/').filter(Boolean);
  const mbIndex = parts.indexOf(resourceType);
  if (mbIndex !== -1 && parts[mbIndex + 1]) {
    const candidate = parts[mbIndex + 1];
    if (/^[0-9a-fA-F-]{36}$/.test(candidate)) {
      return candidate;
    }
  }
  return null;
}

function extractUUIDFromQueryParams(url, { isBatchAction, isPrinterAction, isContainerAction }) {
  const action = extractActionFromUrl(url);

  // 1. Create action type map for cleaner access
  const actionMap = {
    batch: { active: isBatchAction, ...ACTION_PROGRESS_CONFIG.batch },
    printer: { active: isPrinterAction, ...ACTION_PROGRESS_CONFIG.printer },
    container: { active: isContainerAction, ...ACTION_PROGRESS_CONFIG.container },
  };

  // 2. Action type override based on specific actions
  const resolvedType = Object.keys(actionMap).find(type =>
    actionMap[type].specificActions.has(action)
  );

  if (resolvedType) {
    // Reset all actions and enable only the resolved type
    Object.keys(actionMap).forEach(type => {
      actionMap[type].active = type === resolvedType;
    });
  }

  // 3. Direct UUID parameters with safe access
  const directUUIDParams = Object.values(actionMap).flatMap(({ active, uuidParams }) =>
    active ? uuidParams.map(param => ({ param })) : []
  );

  for (const { param } of directUUIDParams) {
    const value = url.searchParams.get(param);
    if (value && isValidUUID(value)) return value;
  }

  // 4. Build action handlers with:
  //    - dynamic param additions or exclusions
  //    - "exclusions" set for mismatched specificActions
  const actionHandlers = Object.values(actionMap).map(handler => {
    // base params from config
    let finalParams = [...(handler.params || [])];

    // dynamic params if this handler has them
    const { dynamicParams = {} } = handler;
    if (dynamicParams[action]) {
      const { add = [], exclude = [] } = dynamicParams[action];
      // remove excluded, then add extras
      finalParams = finalParams.filter(p => !exclude.includes(p)).concat(add);
    }

    return {
      ...handler,
      params: finalParams,
      exclusions: new Set(
        Object.values(actionMap)
          .flatMap(h => h.specificActions)
          .filter(a => !handler.specificActions.has(a))
      ),
    };
  });

  for (const handler of actionHandlers) {
    if (!handler.active || handler.exclusions.has(action)) continue;

    for (const param of handler.params) {
      // skip any falsey param
      if (!param) continue;

      const value = url.searchParams.get(param);
      if (!value) continue;

      const decoded = decodeURIComponent(value);
      const match = decoded.match(handler.pattern);
      if (match) return match[1];
      if (isValidUUID(decoded)) return decoded;
    }
  }

  // 5. Path fallback
  return url.pathname.match(/([0-9a-fA-F-]{36})/)?.[0] || null;
}

export function parseUrlForActionAndUUID(urlString) {
  const url = new URL(urlString);
  const action = extractActionFromUrl(url);

  // Explicit action flags (highest priority)
  const explicitBatchFlags = ['batchAction', 'initialBatchAction', 'actionBatchLoad'];
  const explicitPrinterFlag = 'actionFromPrinter';
  const explicitContainerFlag = 'containerAction';

  // Detect explicit actions with conflict resolution
  const hasExplicitBatch = explicitBatchFlags.some(p => url.searchParams.get(p) === 'true');
  const hasExplicitPrinter = url.searchParams.get(explicitPrinterFlag) === 'true';
  const hasExplicitContainer = url.searchParams.get(explicitContainerFlag) === 'true';

  // Action priority: Batch > Printer > Container
  const isBatchAction =
    hasExplicitBatch ||
    (!hasExplicitPrinter &&
      !hasExplicitContainer &&
      ['initialBatchUUID', 'initialBatch', 'batch', 'material-batch'].some(p =>
        url.searchParams.has(p)
      ));

  const isPrinterAction =
    !isBatchAction &&
    (hasExplicitPrinter || (!hasExplicitContainer && url.pathname.includes('/printer/')));

  const isContainerAction =
    !isBatchAction &&
    !isPrinterAction &&
    (hasExplicitContainer || url.pathname.includes('/permanent-container/'));

  const uuid = extractUUIDFromQueryParams(url, {
    isPrinterAction,
    isContainerAction,
    isBatchAction,
  });

  return {
    action,
    uuid: uuid || extractUUIDFromPathname(url.pathname),
  };
}

export const urlIncludes = (str, substring) => str.toLowerCase().includes(substring.toLowerCase());

export const queryParamEquals = (urlObj, key, value) => {
  const paramVal = urlObj.searchParams.get(key);
  return paramVal && paramVal.toLowerCase() === value.toLowerCase();
};

/**
 * Determines the current step and any custom step name by evaluating the URL against
 * the action's step configuration. The function:
 * 1. Checks all step configurations for the given action in order
 * 2. Returns the first matching step configuration that satisfies all conditions
 * 3. Falls back to success page detection or step 1 if no configurations match
 *
 * @param {string} action - The action identifier (e.g. 'blend_batches', 'machine_load')
 * @param {string} href - The full current URL including search parameters
 * @param {number} totalSteps - Total expected steps for this action
 * @returns {{
 *   step: number,
 *   customNextStepName: string|null
 * }} An object containing:
 * - step: Current step number (1-based index)
 * - customNextStepName: Custom step name if defined in matching config, null otherwise
 *
 * @example
 * // Returns { step: 2, customNextStepName: 'Select Containers' }
 * getStepFromUrl('machine_load', '/action/machine-load?batchMultipleContainers=true', 3)
 */
export const getStepFromUrl = (action, href, totalSteps) => {
  const url = new URL(href);
  const pathname = url.pathname;
  const configs = ACTION_STEP_CONFIG[action];

  if (!configs) {
    // If no specific config, fallback:
    return {
      step: pathname.includes('/success') ? totalSteps : 1,
      customNextStepName: null,
    };
  }

  // Try each step config in order:
  for (const { step, conditions, customNextStep } of configs) {
    let allMatch = true;
    for (const cond of conditions) {
      if (cond.type === 'pathIncludes' && !urlIncludes(pathname, cond.value)) {
        allMatch = false;
        break;
      }
      if (cond.type === 'queryParamEquals' && !queryParamEquals(url, cond.key, cond.value)) {
        allMatch = false;
        break;
      }
    }
    if (allMatch) {
      return {
        step,
        customNextStepName:
          typeof customNextStep === 'function'
            ? customNextStep(href) // Pass href to the function
            : customNextStep,
      };
    }
  }

  // If no steps matched and we are on a success page, default to totalSteps:
  if (pathname.includes('/success')) return { step: totalSteps, customNextStepName: null };

  // Otherwise fallback to step 1
  return { step: 1, customNextStepName: null };
};
