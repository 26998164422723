import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import { permanentContainerActionConfig } from 'src/constants/action-config';
import routes from 'src/utils/routes';
import useQueryParams, { buildCleanParams, getRouteURI } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

const PermanentContainerActionPage = ({ user }) => {
  const [actionNotFound, setActionNotFound] = useState(false);
  const { uuid: id } = useParams();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const actionType = queryParams.type;
  const config = permanentContainerActionConfig[actionType];

  useEffect(() => {
    if (!config) {
      setActionNotFound(true);
      return;
    }

    const rawParams = config.params(queryParams);
    const params = buildCleanParams(rawParams);

    navigate(
      getRouteURI(
        `${routes.permanentContainerAction}/${config.page}`,
        { uuid: id },
        params
      ),
      { replace: true }
    );
  }, [actionType, config, id, navigate, queryParams]);

  if (actionNotFound) {
    return (
      <>
        <Header title="Permanent Container Action Not Found" user={user} />
        <main role="main" className="text-center">
          <NotFound id={id} />
        </main>
      </>
    );
  }

  return (
    <>
      <Header title="Permanent Container Action" user={user} />
      <main role="main" className="text-center">
        <Loader />
      </main>
    </>
  );
};

PermanentContainerActionPage.propTypes = {
  user: userPropType,
};

PermanentContainerActionPage.defaultProps = {
  user: null,
};

export default PermanentContainerActionPage;
