import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Input from 'src/components/CustomInput';
import { useBatch } from 'src/hooks/services/useBatch';
import useConvertedInputHandler from 'src/hooks/useConvertedInputHandler';
import SingleMHSModuleContainer from 'src/pages/mline/sections/mhs/modules/single-mhs-module-container';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import { MATERIAL_UNITS } from 'src/utils/constants';
import { convertToUserUnits, formatThreeDecimalsNumber, performConversion } from 'src/utils/conversions';

const BuildTrustCycloneForm = ({
                                 module,
                                 slot,
                                 trustCyclone,
                                 handleConfirmLoadFromInput,
                               }) => {
  const moduleName = module.name;
  const moduleType = module.type;

  const mhsInputBatchUri = module?.current_batch;

  const { data: moduleBatch, isLoading: isModuleBatchLoading } = useBatch(mhsInputBatchUri, 'mhs-input-batch');

  const baseUnits = moduleBatch?.units;

  const { closeActionPanel } = useActionPanelStore();

  const tareWeightInBatchUnits = useMemo(() => {
    if (baseUnits) {
      // Convert from grams (always the stored unit) to the batch's unit
      return performConversion(module.tare_weight, MATERIAL_UNITS.GRAM, null, baseUnits);
    }
    return { quantity: 0, units: baseUnits, isConverted: false };
  }, [module.tare_weight, baseUnits]);


  const { quantity: displayedTareWeightQuantity } = useMemo(() => {
    if (baseUnits) {
      return convertToUserUnits(tareWeightInBatchUnits.quantity, baseUnits);
    }
    return { quantity: 0, units: '' };
  }, [tareWeightInBatchUnits.quantity, baseUnits]);



  const {
    baseValue: totalWeightBeforeReclaimBase,
    convertedValue: totalWeightBeforeReclaimConverted,
    handleInputChange: handleBeforeReclaimChange,
    convertedUnits: beforeUnits,
  } = useConvertedInputHandler('', baseUnits);

  const {
    baseValue: totalWeightAfterReclaimBase,
    convertedValue: totalWeightAfterReclaimConverted,
    handleInputChange: handleAfterReclaimChange,
  } = useConvertedInputHandler('', baseUnits);

  const {
    // baseValue: reclaimedPowderWeightBase,
    convertedValue: reclaimedPowderWeightConverted,
    handleInputChange: handleReclaimedPowderChange,
  } = useConvertedInputHandler('', baseUnits);

  const [fieldErrors, setFieldErrors] = useState({
    totalWeightBeforeReclaim: '',
    totalWeightAfterReclaim: '',
  });

  // Validation applies only in non-trustCyclone mode.
  // We check:
  //   • Total Weight after Reclaim must be at least equal to tareWeight.
  //   • Total Weight before Reclaim must be greater than Total Weight after Reclaim.

  const validateInputs = (beforeConverted, afterConverted) => {
    if (trustCyclone) {
      setFieldErrors({ totalWeightBeforeReclaim: '', totalWeightAfterReclaim: '' });
      return;
    }

    const numericBefore = parseFloat(beforeConverted) || 0;
    const numericAfter = parseFloat(afterConverted) || 0;
    const errors = { totalWeightBeforeReclaim: '', totalWeightAfterReclaim: '' };

    if (numericAfter < displayedTareWeightQuantity) {
      errors.totalWeightAfterReclaim = "Total Weight after Reclaim must be at least equal to Tare Weight";
    }
    if (numericBefore <= numericAfter) {
      errors.totalWeightBeforeReclaim = "Total Weight before Reclaim must be greater than Total Weight after Reclaim";
    }
    setFieldErrors(errors);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'totalWeightBeforeReclaim') {
      handleBeforeReclaimChange(e);
      validateInputs(value, totalWeightAfterReclaimConverted);
    } else if (name === 'totalWeightAfterReclaim') {
      handleAfterReclaimChange(e);
      validateInputs(totalWeightBeforeReclaimConverted, value);
    } else if (name === 'reclaimedPowderWeight') {
      handleReclaimedPowderChange(e);
    }
  };

  const overallIsValid = trustCyclone
    ? true
    : (!fieldErrors.totalWeightBeforeReclaim && !fieldErrors.totalWeightAfterReclaim);

  const reclaimedWeightBase = totalWeightBeforeReclaimBase - totalWeightAfterReclaimBase;
  const consumedWeightBase = totalWeightAfterReclaimBase - tareWeightInBatchUnits.quantity;
  const reclaimedWeightBaseFormatted = formatThreeDecimalsNumber(reclaimedWeightBase);
  const consumedWeightBaseFormatted = formatThreeDecimalsNumber(consumedWeightBase);

  const handleSubmit = async () => {
    if (overallIsValid) {
      await handleConfirmLoadFromInput(moduleType, mhsInputBatchUri, reclaimedWeightBase, consumedWeightBase)
    }
  };

  return (
    <div>
      <SingleMHSModuleContainer
        skipActions
        customStatusText={moduleName}
        module={module}
        slot={slot}
      >
        <div className="d-flex align-items-center justify-content-between">
          {/* TODO: GE Temporarily removed */}
          {/*<div>Expected Weight: {expectedWeight} lb</div>*/}
          {isModuleBatchLoading ? <Spinner animation="border" /> : (
            <div>Tare:&nbsp;
              <ConvertedUnits quantity={tareWeightInBatchUnits.quantity} units={tareWeightInBatchUnits.units} />
            </div>
          )}
        </div>
      </SingleMHSModuleContainer>

      <Input
        label="Total Weight before Reclaim:"
        type="number"
        name="totalWeightBeforeReclaim"
        value={totalWeightBeforeReclaimConverted}
        suffix={beforeUnits}
        isError={Boolean(fieldErrors.totalWeightBeforeReclaim)}
        errorMessage={fieldErrors.totalWeightBeforeReclaim}
        style={{ marginBottom: '15px' }}
        onChange={handleInputChange}
      />

      {trustCyclone ? (
        <Input
          label="Reclaimed Powder Weight:"
          type="number"
          name="reclaimedPowderWeight"
          value={reclaimedPowderWeightConverted}
          suffix={beforeUnits}
          isError={false}
          errorMessage=""
          style={{ marginBottom: '15px' }}
          onChange={handleInputChange}
        />
      ) : (
        <Input
          label="Total Weight after Reclaim:"
          type="number"
          name="totalWeightAfterReclaim"
          value={totalWeightAfterReclaimConverted}
          suffix={beforeUnits}
          isError={Boolean(fieldErrors.totalWeightAfterReclaim)}
          errorMessage={fieldErrors.totalWeightAfterReclaim}
          style={{ marginBottom: '15px' }}
          onChange={handleInputChange}
        />
      )}

      {!trustCyclone &&
        overallIsValid &&
        totalWeightBeforeReclaimBase !== '' &&
        totalWeightAfterReclaimBase !== '' && (
          <div>
            <div className="mb15">
              Reclaimed Weight =&nbsp;
              <strong>
                <ConvertedUnits
                  quantity={reclaimedWeightBaseFormatted}
                  units={baseUnits}
                />
              </strong>
            </div>
            <div className="mb15">
              Consumed Weight =&nbsp;
              <strong>
                <ConvertedUnits
                  quantity={consumedWeightBaseFormatted}
                  units={baseUnits}
                />
              </strong>
            </div>
          </div>
        )}

      <button
        type="button"
        className="btn btn-lg btn-primary action-btn btn-block mt60"
        disabled={!overallIsValid}
        onClick={handleSubmit}
      >
        Update
      </button>
      <button
        type="button"
        className="btn btn-lg btn-block"
        onClick={closeActionPanel}
      >
        Cancel
      </button>
    </div>
  );
};

BuildTrustCycloneForm.propTypes = {
  /* eslint-disable camelcase */
  module: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    tare_weight: PropTypes.number,
    current_batch: PropTypes.string,
  }).isRequired,
  slot: PropTypes.string.isRequired,
  trustCyclone: PropTypes.bool.isRequired,
  handleConfirmLoadFromInput: PropTypes.func.isRequired,
};

export default BuildTrustCycloneForm;
