import config from 'src/utils/config';

export const fetcher = async (path, { method = 'GET', params = {}, ...options } = {}) => {
  // Create the base URL using the provided path and API host
  const url = new URL(`${config.apiHost}/${path}/`);

  // Append query parameters dynamically if provided
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });

  // Execute the fetch with credentials and any additional options
  const response = await fetch(url.toString(), {
    credentials: 'include',
    method,
    ...options,
  });

  // Parse the response JSON
  const payload = await response.json();

  if (!response.ok) {
    throw payload;
  }
  return payload;
};
