import PropTypes from 'prop-types';
import React from 'react';
import { MODULE_TYPES_MAP } from 'src/constants/m-line';
import useActionPanelStore from 'src/stores/useActionPanelStore';

const UndockConfirmation = ({ module, handleAction, allModulesUndock }) => {
  const { closeActionPanel } = useActionPanelStore();
  if (!module && !allModulesUndock) return null;
  const { name, type } = module ?? {};

  return (
    <div>
      <p>
        {allModulesUndock ? (
          <span>
            <strong>All modules</strong> will be undocked
          </span>
        ) : (
          <>
            <strong>{MODULE_TYPES_MAP[type]}</strong> module <strong>{name}</strong> will be
            undocked.
          </>
        )}
      </p>
      <div>Are you sure you want to proceed?</div>
      <button
        type='button'
        className='btn btn-lg btn-primary action-btn btn-block mt60'
        onClick={handleAction}
      >
        Undock
      </button>
      <button type='button' className='btn btn-lg btn-block' onClick={closeActionPanel}>
        Cancel
      </button>
    </div>
  );
};

UndockConfirmation.defaultProps = {
  allModulesUndock: false,
  module: null,
};

UndockConfirmation.propTypes = {
  module: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  handleAction: PropTypes.func.isRequired,
  allModulesUndock: PropTypes.bool,
};

export default UndockConfirmation;
