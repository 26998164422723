import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faCoins } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Spinner, Tooltip as BSTooltip } from 'react-bootstrap';
import Scan from 'src/components/scan/scan';
import Tooltip from 'src/components/Tooltip';
import UndockConfirmation from 'src/components/UndockConfirmation';
import { MLINE_ACTIONS_ALLOWED_RESOURCES, MLINE_MODULE_TYPES, MODULE_TYPES_MAP } from 'src/constants/m-line';
import DockBtn from 'src/pages/mline/sections/DockIcon';
import LPSBuildModule from 'src/pages/mline/sections/lps/modules/lps-build-module';
import LPSDoseModule from 'src/pages/mline/sections/lps/modules/lps-dose-module';
import LPSOverflowModule from 'src/pages/mline/sections/lps/modules/lps-overflow-module';
import UndockIcon from 'src/pages/mline/sections/UndockIcon';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import { MLINE_ACTIONS } from 'src/utils/constants';
import { pluralWord } from 'src/utils/stringUtils';

const MODULE_COMPONENTS = {
  [MLINE_MODULE_TYPES.DOSE]: LPSDoseModule,
  [MLINE_MODULE_TYPES.BUILD]: LPSBuildModule,
  [MLINE_MODULE_TYPES.OVERFLOW]: LPSOverflowModule,
};

const LPSModulesContainer = ({
                               modules,
                               handleConnectModule,
                               handleUndockModule,
                               allModulesShouldBeUndocked,
                               modulesLoadingState,
                               unsupportedMaterialsForDose,
                             }) => {
  const {
    openActionPanel,
  } = useActionPanelStore();

  const {
    isDoseModuleLoading,
    isBuildModuleLoading,
    isOverflowModuleLoading,
  } = modulesLoadingState;


  const modulesLoadingStateMap = {
    [MLINE_MODULE_TYPES.DOSE]: isDoseModuleLoading,
    [MLINE_MODULE_TYPES.BUILD]: isBuildModuleLoading,
    [MLINE_MODULE_TYPES.OVERFLOW]: isOverflowModuleLoading,
  }

  const handleDockModule = (moduleType) => {
    const moduleName = MODULE_TYPES_MAP[moduleType];
    const allowedResourcesToScan = MLINE_ACTIONS_ALLOWED_RESOURCES[moduleType];

    openActionPanel({
      title: `Scan ${moduleName}`,
      isSidebar: true,
      content: (<Scan
        action={MLINE_ACTIONS.DOCK_MODULE}
        allowedResourcesToScan={allowedResourcesToScan}
        handleAction={(moduleUri) => handleConnectModule(MLINE_ACTIONS.DOCK_MODULE, moduleUri, moduleType)} />
      ),
    });
  };

  const handleUndock = (module, moduleSlot) => {
    openActionPanel({
      title: 'Undock Confirmation',
      content: <UndockConfirmation
        module={module}
        handleAction={() => handleUndockModule(MLINE_ACTIONS.UNDOCK_MODULE, module, moduleSlot)} />,
    });
  };

  const renderUnsupportedModuleMaterialsForDose = (moduleSlot) => {
    if (_isEmpty(unsupportedMaterialsForDose)) return null;
    const notSupportedMaterialsByModule = unsupportedMaterialsForDose[moduleSlot];

    const pluralMaterials = pluralWord('material', notSupportedMaterialsByModule);
    const pluralToBe = notSupportedMaterialsByModule?.length > 1 ? 'are' : 'is';

    if (notSupportedMaterialsByModule) {
      return (
        <Tooltip
          id='unsupported_dose_materials_tooltip'
          placement='right'
          trigger={
            <span>
              <FontAwesomeIcon
                color="#FFC008"
                icon={faTriangleExclamation}
                className="spacer-left"
          />
            </span>
          }
        >
          <div>
            This docked module does not support the following {pluralMaterials}&nbsp;
            <strong>{notSupportedMaterialsByModule.join(', ')}</strong> which {pluralToBe} loaded in the Dose module.
          </div>
        </Tooltip>
      )
    }
  }

  return (
    <div className="lps-modules-container">
      {modules.map(({ type, module }) => {
        // Retrieve the component associated with this module type
        const ModuleComponent = MODULE_COMPONENTS[type];
        if (!ModuleComponent) return null;

        const wrapperClassName = classNames('module-card', {
          [`module-card-bg-${type}`]: !!module,
          'module-card-empty-bg': !module,
        });

        const isModuleEmpty = !module;

        return (
          <div key={type} className={wrapperClassName}>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-base">
                <div>
                  <FontAwesomeIcon icon={faCoins} className="spacer-right module-title-size" />
                </div>
                <div className="text-left">
                  <div className="d-flex align-items-center">
                    <div className="module-title-size">{MODULE_TYPES_MAP[type]}</div>
                    {renderUnsupportedModuleMaterialsForDose(type)}
                  </div>

                  <div className="module-id">
                    <span>{isModuleEmpty ? 'Empty' : 'Docked'}</span>
                  </div>
                </div>
              </div>
              {modulesLoadingStateMap[type] ? <Spinner animation="border" /> : (
                <div className="d-flex align-items-center">
                  {allModulesShouldBeUndocked && module && (
                    <OverlayTrigger
                      placement="top"
                      overlay={(
                        <BSTooltip id="undock-module-info">
                          Module can be undocked
                        </BSTooltip>
                      )}
                    >
                      <FontAwesomeIcon size="2x" icon={faTriangleExclamation} color="#FFC008" className="spacer-right" />
                    </OverlayTrigger>
                  )}
                  {module ?
                    <UndockIcon hidden={allModulesShouldBeUndocked && modules.length === 3} onUndock={() => handleUndock(module, type)} /> :
                    <DockBtn onDock={() => handleDockModule(type)} />}
                </div>
              )}
            </div>
            <ModuleComponent module={module} isLoading={modulesLoadingStateMap[type]} />
          </div>
        );
      })}
    </div>
  );
};

LPSModulesContainer.propTypes = {
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      module: PropTypes.shape({}),
    }),
  ).isRequired,
  handleConnectModule: PropTypes.func.isRequired,
  handleUndockModule: PropTypes.func.isRequired,
  /* eslint-disable camelcase */
  allModulesShouldBeUndocked: PropTypes.bool.isRequired,
  modulesLoadingState: PropTypes.shape({
    isDoseModuleLoading: PropTypes.bool,
    isBuildModuleLoading: PropTypes.bool,
    isOverflowModuleLoading: PropTypes.bool,
  }).isRequired,
  unsupportedMaterialsForDose: PropTypes.shape({}).isRequired,
};

export default LPSModulesContainer;
