import { faCoins } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Scan from "src/components/scan/scan";
import UndockConfirmation from "src/components/UndockConfirmation";
import { MHS_MODULES_MAP,MLINE_ACTIONS_ALLOWED_RESOURCES } from "src/constants/m-line";
import DockBtn from "src/pages/mline/sections/DockIcon";
import MhsModule from "src/pages/mline/sections/mhs/modules/mhs-module";
import UndockIcon from "src/pages/mline/sections/UndockIcon";
import useActionPanelStore from 'src/stores/useActionPanelStore';
import { MLINE_ACTIONS } from "src/utils/constants";

const SingleMHSModuleContainer = ({
                                    module,
                                    slot,
                               handleConnectModule,
                               handleUndockModule,
                               skipActions,
  customStatusText,
  children,
}) => {
  const { openActionPanel }  = useActionPanelStore();

  if (!module) return null;

  const isModuleEmpty = !module;

  const wrapperClassName = classNames("module-card", {
    [`module-card-bg-${module?.type}`]: !!module,
    "module-card-empty-bg": !module,
  });

  const handleUndock = (moduleSlot) => {
    openActionPanel({
      panelId: 'undock-confirmation',
      title: 'Undock Confirmation',
      content: <UndockConfirmation
        module={module}
        handleAction={() => handleUndockModule(MLINE_ACTIONS.UNDOCK_MODULE, module, moduleSlot)} />,
    });
  };

  const handleDockModule = (moduleSlot) => {
    const moduleName = MHS_MODULES_MAP[moduleSlot];
    const allowedResourcesToScan = MLINE_ACTIONS_ALLOWED_RESOURCES[moduleSlot];

    openActionPanel({
      panelId: 'scan-module-container',
      title: `Scan ${moduleName}`,
      isSidebar: true,
      content: (<Scan
        action={MLINE_ACTIONS.DOCK_MODULE}
        allowedResourcesToScan={allowedResourcesToScan}
        handleAction={(moduleUri) => handleConnectModule(MLINE_ACTIONS.DOCK_MODULE, moduleUri, moduleSlot)} />
      ),
    });
  };

  const renderActionButtons = () => {
    if (skipActions) return null;

    return !isModuleEmpty ? (
      <UndockIcon onUndock={() => handleUndock(module, slot)} />
      ) : (
        <DockBtn onDock={() => handleDockModule(slot)} />
    )
  }

  const renderModuleStatus = () => {
    if (customStatusText) {
      return customStatusText;
    }

    return <span>{isModuleEmpty ? 'Empty' : 'Docked'}</span>
  }

  return (
    <div className="mhs-modules-container">
      <div key={slot} className={wrapperClassName}>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-base">
            <div>
              <FontAwesomeIcon icon={faCoins} className="spacer-right module-title-size" />
            </div>
            <div className="text-left">
              <div className="module-title-size">{MHS_MODULES_MAP[slot]}</div>
              <div className="module-id">
                {renderModuleStatus()}
              </div>
            </div>
          </div>
          <div>
            {renderActionButtons()}
          </div>
        </div>
        {!isModuleEmpty && (
          <MhsModule module={module}>
            {children}
          </MhsModule>
            )}
      </div>
    </div>
  );
};

SingleMHSModuleContainer.defaultProps = {
  handleConnectModule: () => {},
  handleUndockModule: () => {},
  skipActions: false,
  customStatusText: '',
  children: null,
}

SingleMHSModuleContainer.propTypes = {
  module: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  handleConnectModule: PropTypes.func,
  handleUndockModule: PropTypes.func,
  skipActions: PropTypes.bool,
  customStatusText: PropTypes.string,
  children: PropTypes.node,
  slot: PropTypes.string.isRequired,
};

export default SingleMHSModuleContainer;
