import { faCoins, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import ActionContext from 'src/components/action-context';
import BatchMultipleContainersAlert from 'src/components/batch-multiple-containers-alert';
import { BatchCardPreview } from 'src/components/BatchCardPreview';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Loader from 'src/components/loader';
import LocationsMismatch from 'src/components/LocationsMismatch';
import Modal from 'src/components/modal';
import NotFound from 'src/components/not-found';
import useSieveFlow from 'src/hooks/useSieveFlow';
import useUnsupportedMaterials from 'src/hooks/useUnsupportedMaterials';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import {
  fullContainerLoadAction,
  fullLoadEmptyBatchAction,
  fullPrinterLoadAction,
  loadMultipleContainersIntoDestinationAction, relocateBatchAction, relocateContainerAction, sieveFullBatchAction,
  sieveFullContainerAndLoad, sieveMultipleContainersAndLoadAction,
} from 'src/utils/actionsAPIUtils';
import { api } from 'src/utils/api';
import {
  API_RESOURCES, MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES,
  MATERIAL_CONTAINER_STATUSES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import { getContainerName, pluralWord } from 'src/utils/stringUtils';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { hasLocationsMismatch } from 'src/utils/validation';

import routes from '../../../../utils/routes';
import PermanentContainerActionPageWrapper from './_action-wrapper';

const LoadMaterialBatch = ({ user }) => {
  const [searchParams] = useSearchParams();
  // resourceUUID can be either Printer (for Load action from Printer) or Container UUID
  const { uuid: resourceUUID } = useParams();
  const {
    batch: batchUri,
    action,
    isFill: isFillAction,
    isLoad: isLoadAction,
    actionBatchLoad,
    initialBatchAction,
    actionFromPrinter,
    containerAction,
  } = Object.fromEntries(searchParams.entries()) ?? {};

  const { openActionPanel, closeActionPanel } = useActionPanelStore();

  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [showRelocateModal, setShowRelocateModal] = useState(false);

  const [batch, setBatch] = useState(null);
  const [loadedPrinterToSelectedBatch, setLoadedPrinterToSelectedBatch] = useState(null);
  const [destinationPrinter, setDestinationPrinter] = useState(null);
  const [destinationPrinterBatch, setDestinationPrinterBatch] = useState(null);
  const [destinationPrinterType, setDestinationPrinterType] = useState(null);
  const [batchSubLocation, setBatchSubLocation] = useState(null);

  // This is the full list of container objects from the API:
  const [allContainers, setAllContainers] = useState([]);
  // This state holds URIs for containers that are "selectable" (left side)
  const [availableContainers, setAvailableContainers] = useState([]);
  // This state holds URIs for containers that are "selected" (right side)
  const [selectedContainers, setSelectedContainers] = useState([]);

  const [containersFetching, setContainersFetching] = useState(true);
  const [nonEmptyContainersByUri, setNonEmptyContainersByUri] = useState({});

  const [permanentContainer, setPermanentContainer] = useState(null);
  const [permanentContainerBatch, setPermanentContainerBatch] = useState(null);

  const [locations, setLocations] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(null);

  const singleContainerSelected = selectedContainers.length === 1;
  const isTopOff = action === PERMANENT_CONTAINER_ACTIONS.TOP_OFF || permanentContainer?.current_batch || !!destinationPrinterBatch;

  const destinationResource = isLoadAction ? destinationPrinter : permanentContainer;
  const destinationResourceBatch = isLoadAction ? destinationPrinterBatch : permanentContainerBatch;

  const [firstContainer] = selectedContainers;
  const firstSelectedContainer = allContainers.find(({ uri }) => uri === firstContainer);

  const shouldBeLoadText = isLoadAction || actionBatchLoad;

  const didRelocateRef = useRef(false);

  const {
    checkIfSieveRequiredFirst,
    markSieveSuccess,
    didSieveRef,
    resetUserApproval,
  } = useSieveFlow();

  const unsupportedMaterials = useUnsupportedMaterials(
    batch,
    isLoadAction && destinationPrinterType ? destinationPrinterType : permanentContainer,
    { isMultiple: false, isPrinter: !!isLoadAction }
  );

  const hasUnsupportedMaterials = unsupportedMaterials.length > 0;

  const shouldWarnAboutBatchPowderQuality = (isTopOff || isLoadAction)
    && !MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(destinationResourceBatch?.powder_quality)
    && MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(batch?.powder_quality);

  const getActionBtnTitle = () => {
    if (shouldBeLoadText && !isTopOff) {
      return 'Load';
    }
    return isTopOff ? 'Top Off' : 'Fill';
  };

  const fillBtnTitle = getActionBtnTitle();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const {
    containerActionData: { initScannedContainers, resetActionContainerState },
  } = useContext(ActionContext);

  const getInitialData = async (batchUri) => {
    setLoading(true);
    resetActionContainerState();
    try {
      const batchResponse = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(batchUri)}/`).json();
      setBatch(batchResponse);

      const batchSubLocationData = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batchResponse.sub_location)}/`).json();
      setBatchSubLocation(batchSubLocationData);

      if (batchResponse.at_machine) {
        const loadedPrinterData = await api.get(`${API_RESOURCES.PRINTER}/${getUuid(batchResponse.at_machine)}/`).json();
        setLoadedPrinterToSelectedBatch(loadedPrinterData);
      }

      // Fetch Non-Empty containers for the batch
      const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;
      const { resources: nonEmptyContainers } = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
        searchParams: {
          'filter[current_batch]': batchResponse.uri,
          'filter[status]': Object.values(containerStatusesExceptEmpty).join(','),
          'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
        },
      }).json();

      // We'll keep a map of URIs -> container object
      const nonEmptyByUriMap = {};
      nonEmptyContainers.forEach((container) => {
        nonEmptyByUriMap[container.uri] = container;
      });
      setNonEmptyContainersByUri(nonEmptyByUriMap);

      // Save them to allContainers as objects, but track "availableContainers" & "selectedContainers" as URIs
      setAllContainers(nonEmptyContainers);
      const containerUris = nonEmptyContainers.map((c) => c.uri);
      setAvailableContainers(containerUris);
      setSelectedContainers([]); // Initially empty selection
      setContainersFetching(false);

      // Permanent container
      const permanentContainerResponse = !isLoadAction ? await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${resourceUUID}/`).json() : null;
      setPermanentContainer(permanentContainerResponse);

      const permanentContainerBatch = permanentContainerResponse?.current_batch
        ? await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(permanentContainerResponse.current_batch)}/`).json()
        : null;

      setPermanentContainerBatch(permanentContainerBatch);

      // Fetch printer if we Load into Printer
      const printer = isLoadAction ? await api.get(`${API_RESOURCES.PRINTER}/${resourceUUID}/`).json() : null;
      setDestinationPrinter(printer);

      const printerType = printer?.printer_type && await api.get(`${API_RESOURCES.PRINTER_TYPE}/${getUuid(printer.printer_type)}/`).json();
      setDestinationPrinterType(printerType);

      const materialBatchesForDestinationPrinter = printer ? await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
        searchParams: {
          'filter[at_machine]': printer.uri,
        },
      }).json() : null;

      const destinationPrinterBatch = materialBatchesForDestinationPrinter?.resources && materialBatchesForDestinationPrinter.resources[0] || null;
      setDestinationPrinterBatch(destinationPrinterBatch);

      const destinationResource = isLoadAction ? printer : permanentContainerResponse;

      // Sub-locations
      const subLocationsUris = destinationResource && batchResponse
        ? [...new Set([destinationResource.sub_location, batchResponse.sub_location])]
        : [];
      const { resources: subLocationsData } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[uri]': subLocationsUris.join(','),
        },
      }).json();
      setSubLocations(subLocationsData);

      // Locations
      const { resources: locationsData } = await api.get(`${API_RESOURCES.LOCATION}/`, {
        searchParams: {
          'filter[uri]': destinationResource.location,
        },
      }).json();
      setLocations(locationsData);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    void getInitialData(batchUri);
  }, [batchUri]);

  // Toggle container from available to selected and vice versa
  const containerClicked = (containerUri) => {
    const updatedAvailable = [...availableContainers];
    const updatedSelected = [...selectedContainers];

    const inAvailableIdx = updatedAvailable.indexOf(containerUri);
    const inSelectedIdx = updatedSelected.indexOf(containerUri);

    // If container is in the 'available' list, move it to 'selected'
    if (inAvailableIdx !== -1) {
      updatedAvailable.splice(inAvailableIdx, 1);
      updatedSelected.push(containerUri);
    }
    // Otherwise, move it from 'selected' back to 'available'
    else if (inSelectedIdx !== -1) {
      updatedSelected.splice(inSelectedIdx, 1);
      updatedAvailable.push(containerUri);
    }

    setAvailableContainers(updatedAvailable);
    setSelectedContainers(updatedSelected);
  };

  const hasDifferentLocations = hasLocationsMismatch(
    batch?.location,
    destinationResource?.location
  );

  const fillWithEntireContainer = () => {
    const containers = selectedContainers.map((uri) => nonEmptyContainersByUri[uri]);
    const containersTotalQuantity = containers.reduce((acc, container) => acc + container.quantity, 0);
    const destinationResourceTitle = isLoadAction ? 'Printer' : 'Permanent Container';

    openActionPanel({
      title: `Confirm ${fillBtnTitle}`,
      panelId: `confirm-${getActionBtnTitle()}`,
      content: (
        <div>
          <div className="mb30">
            <p>Would you like
              to {fillBtnTitle} {selectedContainers.length} {pluralWord('container', selectedContainers)} of
              the{' '}
              <strong>Batch {getShortUuid(batch.uri)}</strong> into the {destinationResourceTitle}{' '}
              <strong>{destinationResource.name}</strong> ?
            </p>
            <div>Total amount to {fillBtnTitle}: {' '}
              <strong><ConvertedUnits
                skipTooltip quantity={containersTotalQuantity}
                units={batch?.units} />
              </strong>
            </div>
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-lg btn-primary btn-block"
              disabled={isSubmitting}
              onClick={confirmAndLoadMaterial}
            >
              <p className="d-flex align-items-center justify-content-center mb0">
                Confirm
                {isSubmitting && <Loader inline className="spacer-left" showText={false} />}
              </p>
            </button>

            <button type="button" className="btn btn-default btn-action" onClick={closeActionPanel}>
              Cancel
            </button>
          </div>

        </div>
      ),
    });
  };

  const handlePrinterLoadAction = async (allContainersSelected, partialContainersSelected, containersToSplit) => {

    if (allContainersSelected) {

      await fullLoadEmptyBatchAction(
        destinationPrinter?.uri,
        isTopOff,
        batchUri,
        destinationPrinterBatch?.uri
      );

    } else if (partialContainersSelected) {
      await loadMultipleContainersIntoDestinationAction(
        containersToSplit,
        batchUri,
        null,
        destinationPrinter?.uri
      );

    } else {
      await fullPrinterLoadAction(
        batchUri,
        firstSelectedContainer?.uri,
        destinationPrinter?.uri
      );
    }
  };

  const handleContainerLoadAction = async (
    allContainersSelected,
    partialContainersSelected,
    containersToSplit,
    shouldSieve
  ) => {

    if (allContainersSelected) {

      if (shouldSieve) {
        await sieveFullBatchAction(batchUri, batch.quantity);
        markSieveSuccess();
      }

      await fullContainerLoadAction(batchUri,
        isTopOff
          ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF
          : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
        permanentContainer?.uri
      );

    } else if (partialContainersSelected) {

      if (shouldSieve) {
        await sieveMultipleContainersAndLoadAction(
          containersToSplit,
          batchUri,
          permanentContainer?.uri,
          isTopOff,
          markSieveSuccess
        );
      } else {
        await loadMultipleContainersIntoDestinationAction(
          containersToSplit,
          batchUri,
          permanentContainer?.uri,
        );
      }


    } else {

      if (shouldSieve) {
        await sieveFullContainerAndLoad(
          batch.uri,
          // Source container -> from where
          firstContainer,
          // Quantity to load
          firstSelectedContainer?.quantity,
          // Where to actually sieve -> destination
          permanentContainer?.uri
        );
        markSieveSuccess();
      } else {
        await fullContainerLoadAction(
          batchUri,
          isTopOff
            ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF
            : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
          permanentContainer?.uri,
          firstSelectedContainer?.quantity,
          true,
          firstContainer
        );
      }
    }
  };

  const executeLoadActions = async (allContainersSelected, partialContainersSelected) => {

    const containersToSplit = selectedContainers.map(uri => ({ uri }));
    const allSelectedContainers = allContainers.filter(({ uri }) => selectedContainers.includes(uri));

    const shouldSieve = shouldWarnAboutBatchPowderQuality && !didSieveRef.current;

    if (partialContainersSelected || allContainersSelected) {
      initScannedContainers(allSelectedContainers);
    }

    if (isLoadAction) {
      return handlePrinterLoadAction(allContainersSelected, partialContainersSelected, containersToSplit, shouldSieve);
    }

    return handleContainerLoadAction(allContainersSelected, partialContainersSelected, containersToSplit, shouldSieve);
  };


  const navigateToSuccessPage = (partialContainersSelected, allContainersSelected) => {
    const queryParams = {
      action: isLoadAction ? PERMANENT_CONTAINER_ACTIONS.MACHINE_LOAD : isTopOff
        ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF
        : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
      ...(!isTopOff && !actionBatchLoad && { isFill: isFillAction }),
      ...(isLoadAction && { isLoad: true }),
      ...(initialBatchAction && { initialBatchAction: true }),
      ...(actionFromPrinter && { actionFromPrinter: true }),
      ...(isTopOff && { isTopOff: true }),
      ...(containerAction && { containerAction: true }),
      ...(actionBatchLoad && { actionBatchLoad: true }),
      ...(!partialContainersSelected || allContainersSelected && { batch: getUuid(batchUri) }),
      ...((partialContainersSelected || selectedContainers.length === 1) && { initialBatchUUID: getUuid(batchUri) }),
      ...(allContainersSelected && isLoadAction && { isFullBatchLoad: true, batch: getUuid(batchUri) }),
      ...((!allContainersSelected && !partialContainersSelected)
        && { sourceContainerUUID: getUuid(firstContainer) }),
    };

    closeActionPanel();
    navigate(
      getRouteURI(
        isLoadAction ? routes.printerSuccess : routes.permanentContainerSuccess,
        { uuid: resourceUUID },
        queryParams
      )
    );
  };


  const handleLoadWithEntireContainer = async () => {
    try {
      setSubmitting(true);

      const allContainersSelected = selectedContainers.length === allContainers.length;
      const partialContainersSelected =
        selectedContainers.length > 1 &&
        selectedContainers.length < allContainers.length;

      await executeLoadActions(allContainersSelected, partialContainersSelected);
      navigateToSuccessPage(partialContainersSelected, allContainersSelected);
    } catch (error) {
      addToast(`${error}`, { appearance: 'error' });
      closeActionPanel();
      setSubmitting(false);
      resetActionContainerState();
    } finally {
      setSubmitting(false);
    }
  };

  const hideRelocationWarningModal = () => setShowRelocateModal(false);

  const onActionRelocate = async () => {
    try {
      const locationToRelocate = isLoadAction ? destinationPrinter.location : batch.location;
      const subLocationToRelocate = isLoadAction ? destinationPrinter.sub_location : batch.sub_location;

      setSubmitting(true);

      if (isLoadAction) {
        await relocateBatchAction(locationToRelocate, subLocationToRelocate, batchUri);
      } else {
        await relocateContainerAction(locationToRelocate, subLocationToRelocate, permanentContainer?.uri, permanentContainer?.current_batch);
      }

    } catch (error_) {
      console.error(error_);
      setSubmitError(true);
      setSubmitting(false);
    }
  };

  const confirmAndLoadMaterial = async () => {
    // Check #1: Location mismatch
    if (!didRelocateRef.current && hasDifferentLocations) {
      setShowRelocateModal(true);
      return;
    }

    // Check #2: Check if we did not skip Sieve and we did not do Sieve yet + we have Powder Quality Warning

    await checkIfSieveRequiredFirst({
      shouldWarn: shouldWarnAboutBatchPowderQuality,
      action: isLoadAction
        ? PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL
        : PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
      isSubmitting,
    });

    // Reset user approval
    resetUserApproval();

    // If we reach here, all checks passed or resolved:
    await handleLoadWithEntireContainer();
  };


  const onModalConfirmRelocate = async () => {
    await onActionRelocate();
    setShowRelocateModal(false);
    setSubmitting(false);
    // Mark relocation as done immediately
    didRelocateRef.current = true;
    // Now re-run main confirm
    await confirmAndLoadMaterial();
  };


  /**
   * Click handler for the "Load All"/"Unload All" button.
   * - If not all containers are selected, select them all (move them from `availableContainers` to `selectedContainers`).
   * - If all containers are already selected, unselect them all (reset to the initial state).
   */
  const handleLoadAllClick = () => {
    // `allContainers` is an array of container objects
    // We only store URIs in selected/available states
    const allContainerUris = allContainers.map((c) => c.uri);

    // Are *all* containers selected?
    const isAllSelected = selectedContainers.length === allContainerUris.length;

    if (!isAllSelected) {
      // Not all selected -> select them all
      setSelectedContainers(allContainerUris);
      setAvailableContainers([]);
    } else {
      // All are selected -> reset to the initial state:
      setSelectedContainers([]);
      setAvailableContainers(allContainerUris);
    }
  };

  // We can use this to switch the button label & styling
  const allContainerUris = allContainers.map((c) => c.uri);
  const isAllSelected = selectedContainers.length === allContainerUris.length && allContainerUris.length > 0;
  const loadAllButtonLabel = isAllSelected ? 'Unload All' : 'Load All';


  const getCustomHeaderText = () => {
    if (isLoadAction) {
      return 'Load Printer';
    }

    if (actionBatchLoad) {
      return 'Load';
    }

    if (isLoadAction && isTopOff) {
      return 'Top Off Printer';
    }
    return null;
  };

  const renderLocationActionText = () => {
    if (isLoadAction) {
      return (
        <p>Before {fillBtnTitle}, you can relocate the entire batch now to match the
          Destination Printer location.
        </p>
      );
    } else {
      return (
        <p>
          Before {fillBtnTitle} Material, you can relocate the entire Batch using the batch page or relocate
          the Permanent Container now to match the Batch location.
        </p>
      );
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !destinationResource) {
    return <NotFound id={resourceUUID} />;
  }

  return (
    <PermanentContainerActionPageWrapper
      id={getUuid(batchUri)}
      user={user}
      httpError={error}
      customErrorText={submitError}
      customHeaderText={getCustomHeaderText()}
      action={isTopOff ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH}
      isLoading={isLoading}
    >
      <div className="mb30">
        <h2 className="header-margin">Selected Batch:</h2>
        <BatchCardPreview
          hideContainers
          shouldShowBatchLink
          batch={batch}
          allContainers={allContainers}
          containersFetching={containersFetching}
          containersFetchError={error}
          subLocation={batchSubLocation?.name}
          loadedPrinter={loadedPrinterToSelectedBatch}
        />
      </div>

      {/* Example layout splitting containers into "available" (left) and "selected" (right) */}
      <div className="row justify-content-between" style={{ padding: '1rem' }}>
        <div className="col-xs-6">
          <ul className="batch-containers-split-list">
            {availableContainers.map((uri) => {
              const container = nonEmptyContainersByUri[uri];
              return (
                <li key={uri} className="batch-container-split-list-item">
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-action batch-container-split-list-btn mb5"
                    onClick={() => containerClicked(uri)}
                  >
                    <FontAwesomeIcon icon={faCoins} className="spacer-right" />
                    {getContainerName(container)}{' '}
                    <div className={container?.disposable ? 'd-inline-block' : ''}>
                      (<ConvertedUnits skipTooltip quantity={container?.quantity} units={batch?.units} />)
                    </div>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-xs-6">
          <ul className="batch-containers-split-list">
            {selectedContainers.map((uri) => {
              const container = nonEmptyContainersByUri[uri];
              return (
                <li key={uri} className="batch-container-split-list-item">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-action batch-container-split-list-btn mb5"
                    onClick={() => containerClicked(uri)}
                  >
                    <FontAwesomeIcon icon={faCoins} className="spacer-right" />
                    {getContainerName(container)}{' '}
                    <div className={container?.disposable ? 'd-inline-block' : ''}>
                      (<ConvertedUnits skipTooltip quantity={container?.quantity} units={batch?.units} />)
                    </div>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {/*
        "Load All" button changes to "Unload All" when everything is selected,
        and toggles the selection on click.
      */}
      <button
        type="button"
        className={`btn btn-sm mb30 ${isAllSelected ? 'btn-info' : 'btn-secondary'}`}
        disabled={isSubmitting}
        onClick={handleLoadAllClick}
      >
        <p className="d-flex align-items-center justify-content-center mb0">
          {loadAllButtonLabel}
        </p>
      </button>

      <Link
        className="btn-block" to={getRouteURI(routes.permanentContainerLoadMaterialAction,
        { uuid: isLoadAction ? getUuid(firstSelectedContainer?.uri) : resourceUUID }, {
          batch: batchUri,
          ...(isLoadAction && {
            isLoad: true,
            printerUUID: resourceUUID,
            actionFromPrinter: true,
          }),
          ...(actionBatchLoad && {
            actionBatchLoad: true,
          }),
          ...(initialBatchAction && {
            initialBatchAction: true,
          }),
          ...(containerAction && { containerAction: true }),
          ...(actionFromPrinter && {
            actionFromPrinter: true,
          }),
          action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
          sourceContainerUUID: getUuid(selectedContainers[0]),
        })}
      >
        <button
          type="submit"
          className="btn btn-lg btn-primary btn-block"
          disabled={isSubmitting || !singleContainerSelected || hasUnsupportedMaterials}
        >
          <p className="d-flex align-items-center justify-content-center mb0">
            {fillBtnTitle} With Partial Container
            {isSubmitting && <Loader inline className="spacer-left" showText={false} />}
          </p>
        </button>
      </Link>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting || !selectedContainers.length || hasUnsupportedMaterials}
        onClick={fillWithEntireContainer}
      >
        <p className="d-flex align-items-center justify-content-center mb0">
          {fillBtnTitle} With Entire Container(s)
          {isSubmitting && <Loader inline className="spacer-left" showText={false} />}
        </p>
      </button>

      {hasUnsupportedMaterials && (
        <Alert
          name="qr-instructions"
          variant="warning"
          className="text-left d-flex align-items-center mt15"
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className="font-size-22 mr15" />
          <div>
            {isLoadAction ? 'Printer' : 'Container'} &#39;{destinationResource?.name}&#39; does not support&nbsp;
            {pluralWord('material', unsupportedMaterials)}&nbsp;
            <strong>{unsupportedMaterials.map((material) => material).join(', ')}</strong>
          </div>
        </Alert>
      )}

      <BatchMultipleContainersAlert sourceBatch={batch} />

      <Modal
        isOpen={showRelocateModal}
        title="Locations Mismatch"
        dismissText="Cancel"
        confirmText={`Relocate ${isLoadAction ? 'Batch' : 'Permanent Container'}`}
        onDismiss={hideRelocationWarningModal}
        onConfirm={onModalConfirmRelocate}
      >
        <LocationsMismatch
          sourceBatch={batch}
          destinationResource={destinationResource}
          bottomTextRenderer={renderLocationActionText}
          locations={locations}
          subLocations={subLocations}
          sourceType="Batch"
          destinationType={isLoadAction ? 'Printer' : 'Permanent Container'}
          sourceDisplayName={getShortUuid(batch.uri)}
          action={`${fillBtnTitle} Material`}
        />
      </Modal>
    </PermanentContainerActionPageWrapper>
  );
};

LoadMaterialBatch.propTypes = {
  user: userPropType,
};

LoadMaterialBatch.defaultProps = {
  user: null,
};

export default LoadMaterialBatch;
