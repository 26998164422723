import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'src/components/Spinner';
import { PERMANENT_CONTAINER_ACTIONS } from 'src/utils/constants';

const actionText = {
  [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: 'Load',
  [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH]: 'Top Off',
  [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: 'Top Off',
  [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: 'Split',
};

const ContainerPowderQualityWarning =
  ({
     action, proceedWithInitialActionAnyway, sieveContainerAction, isSubmitting,
   }) => (
     <div>
       <FontAwesomeIcon icon={faExclamationTriangle} className="d-block w-100 mb15 warning-blending-icon" />
       <label className="form-check-label">
         The source batch of the container(-s) you&apos;re trying to <strong>{actionText[action]}</strong> might not be
         suitable for use.
         Consider sieving the container(-s), or opt for a container with the source batch that is either New or has
         already been Sieved.
       </label>

       <div>
         <button
           type="button"
           className="btn w-100 d-block mt15 mb5 btn-primary"
           disabled={isSubmitting}
           onClick={sieveContainerAction}
         >
           {isSubmitting ? <Spinner /> : <span>Sieve</span>}
         </button>

         <button
           type="button"
           className="btn d-block w-100 mt5 mb5 btn-warning text-white position-relative"
           disabled={isSubmitting}
           onClick={proceedWithInitialActionAnyway}
         >
           {isSubmitting ? <Spinner /> : <span>{`${actionText[action]} Anyway`}</span>}
         </button>
       </div>

     </div>
  );

ContainerPowderQualityWarning.propTypes = {
  action: PropTypes.string.isRequired,
  proceedWithInitialActionAnyway: PropTypes.func.isRequired,
  sieveContainerAction: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default ContainerPowderQualityWarning;
