import { useQuery } from '@tanstack/react-query';
import { fetcher } from 'src/api/fetcher';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

export const useModule = (moduleUri, key = 'container-uri') => {
  return useQuery({
    queryKey: ['module', key, moduleUri],
    queryFn: () => fetcher(`${API_RESOURCES.MATERIAL_CONTAINER}/${getUuid(moduleUri)}`),
    enabled: !!moduleUri,
  });
};

export const useContainersUriAndStatus = (batchUri, statuses, options = {}) => {
  return useQuery({
    queryKey: ['batchContainers', 'containers-by-params', batchUri],
    queryFn: () =>
      fetcher(API_RESOURCES.MATERIAL_CONTAINER, {
        params: {
          'filter[current_batch]': batchUri,
          // statuses is now an array so we join it here
          'filter[status]': statuses.join(','),
          'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
        },
      }),
    enabled: !!batchUri,
    select: data => data.resources,
    ...options,
  });
};
