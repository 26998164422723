import { useQuery } from '@tanstack/react-query';
import { fetcher } from 'src/api/fetcher';
import { API_RESOURCES } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

export const usePostProcessor = postProcessorUUID => {
  return useQuery({
    queryKey: ['postProcessor', postProcessorUUID],
    queryFn: () => fetcher(`${API_RESOURCES.POST_PROCESSOR}/${postProcessorUUID}`),
    enabled: !!postProcessorUUID,
    refetchOnWindowFocus: false,
  });
};

export const usePostProcessorType = postProcessorTypeUri => {
  return useQuery({
    queryKey: ['postProcessorType', postProcessorTypeUri],
    queryFn: () => fetcher(`${API_RESOURCES.POST_PROCESSOR_TYPE}/${getUuid(postProcessorTypeUri)}`),
    enabled: !!postProcessorTypeUri,
  });
};
