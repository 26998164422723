import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DateField from 'src/components/date-field';
import { SHIPMENT_STATUS_LABELS } from 'src/utils/constants';

const ShipmentDetailsForm = ({ shipment, onSubmit }) => {
  return (
    <Formik
      initialValues={{
        name: shipment.name,
        estimatedShipDate: Date.parse(shipment.estimated_shipment_date),
        estimatedReceiveDate: Date.parse(shipment.estimated_delivery_date),
        actualShipDate: Date.parse(shipment.actual_shipment_date),
        actualReceiveDate: Date.parse(shipment.actual_delivery_date),
        trackingNumber: shipment.tracking_number,
        status: shipment.status,
        cost: shipment.actual_cost,
      }}
      onSubmit={form => onSubmit(form)}
    >
      {({ isSubmitting, handleChange }) => {
        return (
          <Form>
            <label className='text-left d-block' htmlFor='name'>
              Name: *
            </label>
            <Field required className='form-control mb-4' id='name' name='name' as='Input' />
            <label className='text-left d-block' htmlFor='status'>
              status: *
            </label>
            <Field
              required
              component='select'
              id='status'
              name='status'
              className='form-control mb-4'
              onChange={e => {
                handleChange(e);
              }}
            >
              {Object.entries(SHIPMENT_STATUS_LABELS).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Field>
            <Row>
              <Col xs='12' sm='6'>
                <label className='text-left d-block' htmlFor='estimatedShipDate'>
                  Estimated Ship Date:
                </label>
                <DateField isRequiredAfterValue name='estimatedShipDate' />
              </Col>
              <Col xs='12' sm='6'>
                <label className='text-left d-block' htmlFor='estimatedReceiveDate'>
                  Estimated Receive Date:
                </label>
                <DateField isRequiredAfterValue name='estimatedReceiveDate' />
              </Col>
            </Row>
            <Row>
              <Col xs='12' sm='6'>
                <label className='text-left d-block' htmlFor='actualShipDate'>
                  Actual Ship Date:
                </label>
                <DateField isRequiredAfterValue name='actualShipDate' />
              </Col>
              <Col xs='12' sm='6'>
                <label className='text-left d-block' htmlFor='actualReceiveDate'>
                  Actual Receive Date:
                </label>
                <DateField isRequiredAfterValue name='actualReceiveDate' />
              </Col>
            </Row>
            <label className='text-left d-block' htmlFor='cost'>
              Cost:
            </label>
            <Field
              className='form-control mb-4'
              id='cost'
              name='cost'
              type='number'
              min='0'
              step='0.01'
              as='Input'
            />
            <label className='text-left d-block' htmlFor='trackingNumber'>
              Shipping/Tracking #:
            </label>
            <Field
              className='form-control mb-4'
              id='trackingNumber'
              name='trackingNumber'
              as='Input'
            />
            <button
              type='submit'
              className='btn btn-lg btn-primary btn-block'
              disabled={isSubmitting}
            >
              Save
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};

ShipmentDetailsForm.propTypes = {
  shipment: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ShipmentDetailsForm;
