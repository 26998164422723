import _keyBy from 'lodash/keyBy';
import _uniq from 'lodash/uniq';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import CommentsContainer from 'src/components/comments/comments-container';
import Header from 'src/components/header';
import NotFound from 'src/components/not-found';
import FeaturesContext from 'src/context/FeaturesContext';
import { api, apiWithContext, getAllByFilter, upload } from 'src/utils/api';
import { groupBy, keyArrayBy, unique } from 'src/utils/array';
import config from 'src/utils/config';
import {
  API_RESOURCES, EXPORT_CONTROL_LABEL_NAMES,
  NCR_FLAG,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  PRINT_ERROR_STATUSES,
  PRINT_STATUSES,
  PRINT_TYPES,
  REFERENCE_TABLE_NAMES,
  RUN_STATUS,
  TIME_ENTRY_CATEGORIES, TIME_ENTRY_RELATED_TABLE_NAME,
  WORK_INSTRUCTION_DOCUMENT_TYPES,
  WORK_INSTRUCTION_FILE_TYPES,
  WORK_INSTRUCTION_TYPES,
} from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import Sentry from 'src/utils/sentry';
import { getEnabledTimeEntryCategories } from 'src/utils/timeEntry';
import { getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { getChecklistsForPrintsByPrintUri, includeReportDocuments } from 'src/utils/work-instructions';

import GroupedPrints from './sections/_grouped-prints';
import NextRunsForPrints from './sections/_next-runs-for-prints';
import RunHeader from './sections/_run-header';
import RunMenu from './sections/_run-menu';
import RunOperations from './sections/_run-operations';

function parseBooleanFromString(input) {
  if (typeof input === 'string') {
    if (input.toLowerCase() === 'true') {
      return true;
    } else if (input.toLowerCase() === 'false') {
      return false;
    }
  }

  return input;
}

const getNextRunForWorkstation = async (api, workstation) => {
  if (!workstation || !workstation.queue.length) {
    return null;
  }
  // Queue array is ordered according to schedule on the backend
  return await api.get(`${API_RESOURCES.RUN}/${getUuid(workstation.queue[0])}/`).json();
};

const getActiveRunForWorkstationUri = async (api, run) => {
  const workstationUri = run.printer || run.post_processor;
  if (!workstationUri) {
    return null;
  }

  const workstationType = run.printer ? 'printer' : 'post_processor';
  const { resources: activeRuns } = await api.get(`${API_RESOURCES.RUN}/`, {
    searchParams: {
      'filter[status]': RUN_STATUS.inProgress,
      'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
      [`filter[${workstationType}]`]: workstationUri,
    },
  }).json();
  return activeRuns[0];
};

const getModelsForLineItems = async (api, lineItems, isPowderWorkflow) => {
  if (isPowderWorkflow) {
    return [];
  }
  const modelUris = unique(lineItems.map((lineItem) => lineItem.additive.model));
  if (!modelUris.length) {
    return [];
  }
  return await api.getAllByFilter(
    modelUris,
    `${API_RESOURCES.MODEL}/`,
    { searchParams: { 'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT } },
  );
};

const getLineItems = async (api, prints) => {
  const lineItemUris = unique(prints.map((print) => print.line_item));
  if (!lineItemUris.length) {
    return [];
  }
  // Some line items may already be deleted
  return await api.getAllByFilter(
    lineItemUris,
    `${API_RESOURCES.LINE_ITEM}/`,
    {
      searchParams: {
        // By default, only `product` type is returned
        'filter[type]': [PRINT_TYPES.PRODUCT, PRINT_TYPES.SPECIMEN].join(','),
      },
    },
  );
};

const getPrints = async (api, run) => {
  const { resources: prints } = await api.get(
    `${API_RESOURCES.PRINT}/`,
    {
      searchParams: {
        'filter[run]': run.uri,
        // `!` is added to get negative filter `filter[status]!=...`
        'filter[status]!': PRINT_STATUSES.cancelled,
        'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
      },
    },
  ).json();
  return prints;
};

const getToolingStockDataByRun = async (api, run, toolingStockFeature) => {
  if (!run || !toolingStockFeature) {
    return null;
  }

  try {
    // Fetch tooling stock data
    const toolLinksData = await api.get(`${API_RESOURCES.TOOL_LINK}/`, {
      searchParams: {
        'filter[run]': run.uri,
      },
    }).json();

    // Only look at the first tool, for now
    const toolLink = toolLinksData?.resources?.[0];
    if (!toolLink?.tool) {
      return null;
    }

    const toolingStockData = await api.get(`${API_RESOURCES.TOOLING_STOCK}/`, {
      searchParams: {
        'filter[uri]': toolLink.tool,
      },
    }).json();

    const toolingStock = toolingStockData?.resources?.[0];
    if (!toolingStock) {
      return null;
    }

    // Fetch tooling stock location, sub-location, and tooling type in parallel
    const [
      toolingStockLocation,
      toolingStockSubLocation,
      toolingType,
    ] = await Promise.all([
      api.get(`${API_RESOURCES.LOCATION}/${getUuid(toolingStock.location)}/`).json(),
      toolingStock.sub_location ? api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(toolingStock.sub_location)}/`).json() : null,
      api.get(`${API_RESOURCES.TOOLING_TYPE}/${getUuid(toolingStock.type)}/`).json(),
    ]);

    return {
      toolingStock,
      toolingType,
      toolingStockLocation: toolingStockLocation?.name,
      toolingStockSubLocation: toolingStockSubLocation?.name,
    };
  } catch (error) {
    console.error('Error fetching tool data:', error);
    return null;
  }
};


const getPiecesByPrintUri = async (api, prints) => {
  const pieceUris = prints.map((print) => print.piece);
  if (!pieceUris.length) {
    return {};
  }
  const pieces = await api.getAllByFilter(
    pieceUris,
    `${API_RESOURCES.PIECE}/`,
    { searchParams: { 'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT } },
  );
  const piecesByPrintUri = {};
  prints.forEach((print) => {
    piecesByPrintUri[print.uri] = pieces.find((piece) => piece.uri === print.piece);
  });
  return piecesByPrintUri;
};

const getPublicGroupsByUri = async (api, accessInfo) => {
  const publicGroupUris = accessInfo
    ? unique(
      accessInfo.actions
        // Ignore group uris for allowed actions
        .filter((action) => !action.allowed)
        // disallow_by_edit_groups is a list of public group URIs
        .flatMap((action) => action.disallow_by_edit_groups),
    )
    : [];
  if (!publicGroupUris.length) {
    return {};
  }
  const publicGroups = await api.getAllByFilter(
    publicGroupUris,
    `${API_RESOURCES.PUBLIC_GROUP}/`,
    { searchParams: { 'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT } },
  );

  return keyArrayBy(publicGroups, 'uri');
};

const getRunTimeEntries = async (api, run, timeEntryCategories) => {
  if (!timeEntryCategories.length) {
    return [];
  }
  const { resources: runTimeEntries } = await api.get(`${API_RESOURCES.TIME_ENTRY}/`, {
      searchParams: {
        'filter[related_table_name]': TIME_ENTRY_RELATED_TABLE_NAME.RUN,
        'filter[related_uuid]': getUuid(run.uri),
        'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
        'filter[category]': timeEntryCategories.join(','),
        'sort': 'created',
      },
    },
  ).json();
  return runTimeEntries;
};

const getCreatedAttendTimeEntryUser = async (api, timeEntry) => {
  if (!timeEntry) {
    return null;
  }
  const user = await api.get(timeEntry.created_by, { prefixUrl: false }).json();

  if (!user || !user.name) {
    return null;
  }

  return user;
};

const getRunTransformation = async (api, run) => {
  const transformation = await api.get(`${API_RESOURCES.RUN_TRANSFORMATION}/`, {
      searchParams: {
        'filter[type]': NCR_FLAG,
        'filter[source_run]': run.uri,
        'page[limit]': 5000,
        'sort': '-updated',
      },
    },
  ).json();

  return transformation?.resources;
};

const getWorkstation = async (api, run) => {
  const workstationUri = run.printer || run.post_processor;
  if (!workstationUri) {
    return null;
  }
  return await api.get(workstationUri, { prefixUrl: false }).json();
};

const getBatchMaterialInfo = async (api, run) => {
  const printerUUID = getUuid(run.printer);
  if (!printerUUID) {
    return;
  }
  const printer = await api.get(`${API_RESOURCES.PRINTER}/${printerUUID}/`).json();

  const { resources: materialBatchesAtMachine } = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
    searchParams: {
      'filter[at_machine]': printer.uri,
    },
  }).json();

  const materialBatch = materialBatchesAtMachine[0];
  // TODO: Change when UI for multiple lots is ready  getUuid(materialBatch?.material);
  const materialUUID = getUuid(materialBatch?.materials[0].uri);

  const material = await getMaterialInfo(api, materialUUID);
  return {
    materialBatch,
    material,
  };
};

const getMaterialInfo = async (api, materialUUID) => {
  if (!materialUUID) {
    return {};
  }
  return await api.get(`${API_RESOURCES.MATERIAL}/${materialUUID}/`).json();
};

const getShipmentByRun = async (api, run) => {
  const { resources: shipment } = await api.get(`${API_RESOURCES.SHIPMENT}/`, {
    searchParams: {
      'filter[run]': run.uri,
    },
  }).json();
  return shipment[0];
}

const getScheduledRuns = async (api, run) => {
  const { resources: scheduledRuns } = await api.get(`${API_RESOURCES.SCHEDULE_RUNS}/`, {
    searchParams: {
      'filter[run]': run.uri,
    },
  }).json();
  return scheduledRuns;
};

const getAccessInfoForRun = async (api, run) => {
  const { resources: accessInfoList } = await api.get(
    `${API_RESOURCES.ACCESS_INFO_FOR_RESOURCE}/`,
    {
      searchParams: {
        'filter[target_uri]': run.uri,
      },
    },
  ).json();
  // Assuming there is no more than 1 access info item for Run resource
  return accessInfoList[0];
};

const getNextRunsByPrintUri = async (api, prints) => {
  const pieceUris = prints.map((print) => print['piece']);
  if (!pieceUris.length) {
    return {};
  }
  const allPrintsOfPieces = await api.getAllByFilter(
    pieceUris,
    `${API_RESOURCES.PRINT}/`,
    { searchParams: { 'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT } },
    'piece'
  );

  // TODO: Logic copy-pasted from Rapidfab (for Run page).
  //  Might need to be refactored in future
  const nextPrints = prints.map((print) => {
    const nextProcessStepPosition = print.process_step_position + 1;

    // Find all next prints for next process step position
    // We may have multiple prints with the same process_step_position
    // for example, when print is remanufactured
    const bestCandidates = allPrintsOfPieces.filter((p) =>
      p.piece === print.piece && p.process_step_position === nextProcessStepPosition,
    );

    return (
      // Try to find first non-error print
      bestCandidates.find((p) => !PRINT_ERROR_STATUSES.includes(p.status))
      // OR use just first print (if all statuses are incomplete)
      || bestCandidates[0]
    );
  }).filter(Boolean);

  const runUris = nextPrints.map((print) => print.run).filter(Boolean);
  if (!runUris.length) {
    return {};
  }

  const nextRuns = await api.getAllByFilter(
    runUris,
    `${API_RESOURCES.RUN}/`,
    { searchParams: { 'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT } },
  );

  const nextPrintsByPieceUri = _keyBy(nextPrints, 'piece');
  const nextRunsByUri = _keyBy(nextRuns, 'uri');

  const nextRunsByPrintUri = {};
  prints.forEach((print) => {
    const nextPrint = nextPrintsByPieceUri[print.piece];
    const nextRun = nextRunsByUri[nextPrint?.run];
    if (nextRun?.status === RUN_STATUS.cancelled) {
      return;
    }
    nextRunsByPrintUri[print.uri] = nextRun;
  });
  return nextRunsByPrintUri;
};

const getWorkstationsByUri = async (api) => {
  // Loading all workstations without limit, since it is expected that there will be not much workstations
  const { resources: printers } = await api.get(`${API_RESOURCES.PRINTER}/`, {
    searchParams: {
      'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
    },
  }).json();
  const { resources: postProcessors } = await api.get(`${API_RESOURCES.POST_PROCESSOR}/`, {
    searchParams: {
      'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
    },
  }).json();
  return {
    ..._keyBy(printers, 'uri'),
    ..._keyBy(postProcessors, 'uri'),
  };
};

const getWorkInstructionReportsByPrintUri = async (api, prints, reportTypes) => {
  const printUris = prints.map((print) => print['uri']);

  if (!printUris.length) {
    return {};
  }

  const workInstructionReports = await api.getAllByFilter(
    printUris,
    `${API_RESOURCES.WORK_INSTRUCTION_REPORT}/`,
    { searchParams: { 'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT } },
    'print'
  );

  const reportWithDocumentUuids = Object.values(workInstructionReports).map((report) => {
    const { work_instruction: instructionUuid, report: value } = report;
    const type = reportTypes[instructionUuid];

    if (WORK_INSTRUCTION_DOCUMENT_TYPES.includes(type) && value) {
      return instructionUuid;
    }

    return false;
  }).filter(Boolean);

  let documents = [];

  if (reportWithDocumentUuids.length > 0) {
    documents = await api.getAllByFilter(
      reportWithDocumentUuids,
      `${API_RESOURCES.DOCUMENT}/`,
      {
        searchParams: {
          'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
          'filter[related_table_name]': REFERENCE_TABLE_NAMES.WORK_INSTRUCTION_REPORT,
        },
      },
      'related_uuid',
    );
  }

  const reportsByPrintUri = groupBy(workInstructionReports, 'print');
  const documentContentsByUUID = keyArrayBy(documents, ({ uri }) => getUuid(uri));

  const workInstructionReportsByPrintUri = {};
  for (const print of prints) {
    const printReports = reportsByPrintUri[print.uri] || [];
    workInstructionReportsByPrintUri[print.uri] = includeReportDocuments(printReports, documentContentsByUUID, reportTypes);
  }
  return workInstructionReportsByPrintUri;
};

const getPostProcessorsByRun = async (api, run) => {
  if (run.post_processor && run.post_processor_type) {
    const { resources } = await api.get(`${API_RESOURCES.POST_PROCESSOR}/`, {
      searchParams: {
        'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
        'filter[post_processor_type]': run.post_processor_type,
      },
    }).json();
    return resources || [];
  }
  return [];
};

const getPrintersByRun = async (api, run) => {
  if (run.printer && run.printer_type) {
    const { resources } = await api.get(`${API_RESOURCES.PRINTER}/`, {
      searchParams: {
        'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
        'filter[printer_type]': run.printer_type,
      },
    }).json();
    return resources || [];
  }
  return [];
};

const getLabelsByPieces = async (pieceUris) => {
  const labelsByPiece = {};

  if (!pieceUris.length) return labelsByPiece;

  const labelRelationships = await getAllByFilter(
    [...pieceUris], `${API_RESOURCES.LABEL_RELATIONSHIP}/`, {}, 'target_uri'
  );
  const labels = labelRelationships.length ?
    await getAllByFilter(labelRelationships.map((item) => item.label), `${API_RESOURCES.LABEL}/`) : [];

  labelRelationships.forEach((relationship) => {
    const label = labels.find((label) => relationship.label === label.uri);
    if (label && label?.name !== EXPORT_CONTROL_LABEL_NAMES.NO_EXPORT_CONTROL) {
      labelsByPiece[relationship.target_uri] = label;
    }
  });
  return labelsByPiece;
};

export const GROUP_STATES = {
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded',
  NONE: 'none',
};

const Run = ({ user }) => {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  const [run, setRun] = useState({});
  const [workstation, setWorkstation] = useState({});
  const [workstationNextRun, setWorkstationNextRun] = useState({});
  const [prints, setPrints] = useState([]);
  const currentPrint = prints[0]?.uri;
  const [checklistsByPrintUri, setChecklistsByPrintUri] = useState({});
  const checklist = checklistsByPrintUri[currentPrint];

  const [accessInfo, setAccessInfo] = useState();
  const [publicGroupsByUri, setPublicGroupsByUri] = useState();
  const [labelsByPiece, setLabelsByPiece] = useState({});
  const [error, setError] = useState();
  const [lineItemsByUri, setLineItemsByUri] = useState();
  const [modelsByUri, setModelsByUri] = useState();
  const [scheduledRun, setScheduledRun] = useState();
  const [workstationActiveRun, setWorkstationActiveRun] = useState();

  const [workInstructionReportsByPrintUri, setWorkInstructionReportsByPrintUri] = useState();
  const [alreadySubmittedWorkInstructions, setAlreadySubmittedWorkInstructions] = useState([]);

  const [nextRunsByPrintUri, setNextRunsByPrintUri] = useState();
  const [workstationsByUri, setWorkstationsByUri] = useState();
  const [piecesByPrintUri, setPiecesByPrintUri] = useState();
  const [materialData, setMaterialData] = useState();
  const [toolingStockData, setToolingStockData] = useState();
  const [postProcessors, setPostProcessors] = useState([]);
  const [printers, setPrinters] = useState([]);
  const [runTransformation, setRunTransformation] = useState([]);
  const [shipment, setShipment] = useState([]);

  const { features } = useContext(FeaturesContext);

  const { uuid } = useParams();

  const isPowderWorkflowFeatureEnabled = isFeatureEnabled(features, FEATURES.POWDER_WORKFLOW);
  const isToolingStockTypeFeatureEnabled = isFeatureEnabled(features, FEATURES.TOOLING_STOCK);

  const fetchInitialData = async (api, runUuid) => {
    const [run, workstationsByUri] = await Promise.all([
      api.get(`${API_RESOURCES.RUN}/${runUuid}/`).json(),
      getWorkstationsByUri(api),
    ]);

    return { run, workstationsByUri };
  };

  const fetchSecondaryData = async (api, run, isMaterialManagementEnabled, timeEntryCategories) => {
    const [
      prints,
      runTimeEntries,
      scheduledRuns,
      workstation,
      toolingStockData,
      workstationActiveRun,
      accessInfo,
      materialData,
      postProcessors,
      printers,
      runTransformation,
      shipment,
    ] = await Promise.all([
      getPrints(api, run),
      getRunTimeEntries(api, run, timeEntryCategories),
      getScheduledRuns(api, run),
      getWorkstation(api, run),
      getToolingStockDataByRun(api, run, isToolingStockTypeFeatureEnabled),
      getActiveRunForWorkstationUri(api, run),
      getAccessInfoForRun(api, run),
      isMaterialManagementEnabled ? getBatchMaterialInfo(api, run) : null,
      getPostProcessorsByRun(api, run),
      getPrintersByRun(api, run),
      getRunTransformation(api, run),
      getShipmentByRun(api, run),
    ]);

    return {
      prints,
      runTimeEntries,
      scheduledRuns,
      workstation,
      toolingStockData,
      workstationActiveRun,
      accessInfo,
      materialData,
      postProcessors,
      printers,
      runTransformation,
      shipment,
    };
  };

  const fetchTertiaryData = async (api, prints, latestAttendTimeEntry, accessInfo, workstation) => {
    const [
      checklistsByPrintUri,
      nextRunsByPrintUri,
      lineItems,
      piecesByPrintUri,
      workstationNextRun,
      publicGroupsByUri,
      timeEntryAttendUser,
      labelsByPiece,
    ] = await Promise.all([
      getChecklistsForPrintsByPrintUri(api, prints),
      getNextRunsByPrintUri(api, prints),
      getLineItems(api, prints),
      getPiecesByPrintUri(api, prints),
      getNextRunForWorkstation(api, workstation),
      getPublicGroupsByUri(api, accessInfo),
      getCreatedAttendTimeEntryUser(api, latestAttendTimeEntry),
      getLabelsByPieces(prints.map((print) => print.piece)),
    ]);

    return {
      checklistsByPrintUri,
      nextRunsByPrintUri,
      lineItems,
      piecesByPrintUri,
      workstationNextRun,
      publicGroupsByUri,
      timeEntryAttendUser,
      labelsByPiece,
    };
  };


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const runUuid = window.location.pathname.split('/').pop();
      const api = apiWithContext({ features });
      const timeEntryCategories = getEnabledTimeEntryCategories(features);
      const isMaterialManagementEnabled = isFeatureEnabled(features, FEATURES.MATERIAL_MANAGEMENT);

      try {
        const { run, workstationsByUri } = await fetchInitialData(api, runUuid);

        // Load resources by Run
        const {
          prints,
          runTimeEntries,
          scheduledRuns,
          workstation,
          toolingStockData,
          workstationActiveRun,
          accessInfo,
          materialData,
          postProcessors,
          printers,
          runTransformation,
          shipment,
        } = await fetchSecondaryData(api, run, isMaterialManagementEnabled, timeEntryCategories);

        const attendTimeEntries = runTimeEntries.filter((initialRunTimeEntry) => initialRunTimeEntry.category === TIME_ENTRY_CATEGORIES.ATTENDING);
        const latestAttendTimeEntry = attendTimeEntries.length ? attendTimeEntries[attendTimeEntries.length - 1] : null;
        // Load resources by Prints, Workstation and Access Info
        const {
          checklistsByPrintUri,
          nextRunsByPrintUri,
          lineItems,
          piecesByPrintUri,
          workstationNextRun,
          publicGroupsByUri,
          timeEntryAttendUser,
          labelsByPiece,
        } = await fetchTertiaryData(api, prints, latestAttendTimeEntry, accessInfo, workstation);

        const workInstructions = Object.values(checklistsByPrintUri).map((checklist) => checklist.work_instructions).flat();
        const reportTypes = keyArrayBy(workInstructions, 'uuid', 'report_type');

        // Load all other resources by Line items and Checklists
        const [
          models,
          workInstructionReportsByPrintUri,
        ] = await Promise.all([
          getModelsForLineItems(api, lineItems, isPowderWorkflowFeatureEnabled),
          getWorkInstructionReportsByPrintUri(api, prints, reportTypes),
        ]);

        const data = {
          run,
          workstationsByUri,
          prints,
          lineItemsByUri: keyArrayBy(lineItems, 'uri'),
          modelsByUri: keyArrayBy(models, 'uri'),
          checklistsByPrintUri,
          workInstructionReportsByPrintUri,
          scheduledRun: scheduledRuns.length ? scheduledRuns[0] : null,
          workstation,
          toolingStockData,
          workstationActiveRun,
          workstationNextRun,
          runTimeEntries,
          nextRunsByPrintUri,
          piecesByPrintUri,
          materialData,
          postProcessors,
          printers,
          latestAttendTimeEntry,
          timeEntryAttendUser,
          labelsByPiece,
          runTransformation,
          publicGroupsByUri,
          shipment,
        };
        setRun(data.run);
        setWorkstation(data.workstation);
        setToolingStockData(data.toolingStockData);
        setWorkstationNextRun(data.workstationNextRun);
        setWorkstationActiveRun(data.workstationActiveRun);
        setScheduledRun(data.scheduledRun);
        setPrints(data.prints);
        setLineItemsByUri(data.lineItemsByUri);
        setModelsByUri(data.modelsByUri);
        setChecklistsByPrintUri(data.checklistsByPrintUri);
        setWorkInstructionReportsByPrintUri(data.workInstructionReportsByPrintUri);

        setLatestAttendTimeTrackingEntry(data.latestAttendTimeEntry);

        setNextRunsByPrintUri(data.nextRunsByPrintUri);
        setWorkstationsByUri(data.workstationsByUri);
        setPiecesByPrintUri(data.piecesByPrintUri);
        setMaterialData(data.materialData);
        setPostProcessors(data.postProcessors);
        setPrinters(data.printers);
        setAttendTimeEntryUser(data.timeEntryAttendUser);
        setRunTransformation(data.runTransformation);
        setPublicGroupsByUri(data.publicGroupsByUri);
        setShipment(data.shipment);

        setAccessInfo(data.accessInfo);
        setLabelsByPiece(data.labelsByPiece);

        const uniqueWorkInstructions = _uniq(
          Object.values(data.workInstructionReportsByPrintUri || {})
            .flatMap(arr => arr.map(item => item.work_instruction))
        );


        setAlreadySubmittedWorkInstructions(uniqueWorkInstructions);

      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }

    };

    fetchData();
  }, [uuid]);


  const [runMachineTimeEntries, setRunMachineTimeEntries] = useState([]);
  const [latestAttendTimeTrackingEntry, setLatestAttendTimeTrackingEntry] = useState([]);

  const [attendTimeEntryUser, setAttendTimeEntryUser] = useState(null);

  const [groupsState, setGroupsState] = useState(GROUP_STATES.NONE);

  const handleSetReportsByPrintUri = () => {
    const initialReportValues = {};
    if (!workInstructionReportsByPrintUri) {
      return {};
    }

    Object.keys(workInstructionReportsByPrintUri).forEach((printUri) => {
      initialReportValues[printUri] = {};
      workInstructionReportsByPrintUri[printUri]
        .sort((a, b) => new Date(a.completed) - new Date(b.completed))
        .forEach((report) => {
          initialReportValues[printUri][report.work_instruction] = report.report;
        });
    });

    return initialReportValues;
  };

  const [reportsByPrintUri, setReportsByPrintUri] = useState(() => handleSetReportsByPrintUri());
  const [filteredReportData, setFilteredReportData] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);

  // Get all report values by print uri.
  const reportFilesPrintURI = groupBy(filteredReportData, 'print');

  useEffect(() => {
    setReportsByPrintUri(handleSetReportsByPrintUri());
    // Filter workInstructionReportsByPrintUri to have the only values with report_file.
    setFilteredReportData(
      workInstructionReportsByPrintUri ?
        Object
          .values(workInstructionReportsByPrintUri)
          .flat()
          .sort((a, b) =>
            new Date(a.completed) - new Date(b.completed))
          .filter((report) => report.report_file) :
        [],
    );
  }, [JSON.stringify(workInstructionReportsByPrintUri)]);

  // All reports returned from server are saved ones
  const [savedReportsByPrintUri, setSavedReportsByPrintUri] = useState(reportsByPrintUri);

  const [uploadingStateByPrintUri, setUploadingStateByPrintUri] = useState(() => {
    const result = {};
    if (!run.prints) {
      return result;
    }
    run.prints.forEach((printUri) => {
      result[printUri] = false;
    });
    return result;
  });
  const [isAbleToCompleteRun, setIsAbleToCompleteRun] = useState(true);

  if (error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={run?.id} />
        </main>
      </>
    );
  }

  const handleStepInput = (printUris, workInstructionUUID, value) => {
    // Instruction will be the same since printUris are from the same WorkStep
    const instruction = checklistsByPrintUri[printUris[0]].work_instructions.find((workInstruction) => {
      return workInstruction.uuid === workInstructionUUID;
    });
    const { report_type: type } = instruction;

    if (WORK_INSTRUCTION_FILE_TYPES.includes(type)) {
      // In case new file was uploaded, need to clear saved file value for proper check on submit
      const updatedSavedReportsByPrintUri = { ...savedReportsByPrintUri };
      printUris.forEach((printUri) => {
        updatedSavedReportsByPrintUri[printUri] = {
          ...updatedSavedReportsByPrintUri[printUri],
          [workInstructionUUID]: false,
        };
      });
      setSavedReportsByPrintUri(updatedSavedReportsByPrintUri);
    }

    const updatedReportsByPrintUri = { ...reportsByPrintUri };
    printUris.forEach((printUri) => {
      updatedReportsByPrintUri[printUri] = {
        ...updatedReportsByPrintUri[printUri],
        [workInstructionUUID]: value,
      };
    });
    setReportsByPrintUri(updatedReportsByPrintUri);
  };

  const uploadFile = async (printUri, instructionUuid, file) => {
    setIsFileUploading(true);
    setUploadingStateByPrintUri({
      ...uploadingStateByPrintUri,
      [printUri]: {
        instruction: instructionUuid,
        progress: 0,
        file,
      },
    });

    const reportResponse = await api.post(`${API_RESOURCES.WORK_INSTRUCTION_REPORT}/`, {
      json: {
        print: printUri,
        // eslint-disable-next-line camelcase
        work_instruction: instructionUuid,
      },
      onErrorToastCallback: addToast,
    });

    setUploadingStateByPrintUri((data) => ({
      ...data,
      [printUri]: {
        ...data[printUri],
        progress: 5,
      },
    }));

    const reportUri = reportResponse.headers.get('location');
    const reportUuid = getUuid(reportUri);

    const documentResponse = await api.post(`${API_RESOURCES.DOCUMENT}/`, {
      json: {
        name: file.name,
        /* eslint-disable camelcase */
        related_uuid: reportUuid,
        related_table_name: 'work_instruction_report',
        /* eslint-enable camelcase */
      },
      onErrorToastCallback: addToast,
    });

    setUploadingStateByPrintUri((data) => ({
      ...data,
      [printUri]: {
        ...data[printUri],
        progress: 10,
      },
    }));

    const documentUri = documentResponse.headers.get('location');
    const documentUuid = getUuid(documentUri);
    const uploadLocation = documentResponse.headers.get('x-upload-location');

    await upload(uploadLocation, {
      // Using `octet-stream` as a fallback, since files like STL, etc. do not have type specified
      contentType: file.type || 'application/octet-stream',
      body: file,
      onUploadProgress: (percent) => {
        const convertedPercent = (percent * 90) + 10;
        setUploadingStateByPrintUri((data) => ({
          ...data,
          [printUri]: {
            ...data[printUri],
            progress: convertedPercent,
          },
        }));
      },
      onErrorToastCallback: addToast,
    });

    const reportUpdate = await api.put(`${API_RESOURCES.WORK_INSTRUCTION_REPORT}/${reportUuid}/`, {
      json: {
        report: documentUuid,
      },
    });

    /* On each new file upload, we need get the updated list of the
       report values by the requested print URI.
     */

    const { resources: workInstructionReport } = await api.get(`${API_RESOURCES.WORK_INSTRUCTION_REPORT}/`, {
      searchParams: {
        'filter[print]': printUri, // FIXME Split into multiple?
        'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
      },
    }).json();

    // Refresh the local list of reports by the requested print URI.
    setWorkInstructionReportsByPrintUri((previous) => ({
      ...previous,
      [printUri]: workInstructionReport,
    }));

    setIsFileUploading(false);
    setUploadingStateByPrintUri(false);
    return reportUpdate;
  };

  const sendNCReviewForPiece = async (prints, run, splitPieceSchedules = true) => {
    const ncReviewResult = await api.post(`${API_RESOURCES.RUN_TRANSFORMATION}/`, {
        json: {
          prints,
          // eslint-disable-next-line camelcase
          source_run: run,
          type: NCR_FLAG,
          // eslint-disable-next-line camelcase
          split_piece_schedules: parseBooleanFromString(splitPieceSchedules),
        },
      },
    ).json();

    return ncReviewResult;
  };

  function submitInstructionReport(printUris, instruction) {
    const { report_type: type, uuid } = instruction;

    const updatedSavedReportsByPrintUri = { ...savedReportsByPrintUri };
    const metadata = [];
    printUris.forEach(async (printUri) => {
      const value = reportsByPrintUri && reportsByPrintUri[printUri][uuid];
      const savedValue = savedReportsByPrintUri && savedReportsByPrintUri[printUri] && savedReportsByPrintUri[printUri][uuid];

      if (WORK_INSTRUCTION_FILE_TYPES.includes(type)) {
        if (savedValue) {
          // If there is a saved value, then no new file was selected
          return;
        }
        if (value instanceof File) {
          try {
            await uploadFile(printUri, uuid, value);
            updatedSavedReportsByPrintUri[printUri] = {
              ...updatedSavedReportsByPrintUri[printUri],
              [uuid]: true,
            };
          } catch (error) {
            Sentry.captureException(error);
            // No need to proceed in case upload failed
            return;
          }
        }

        // Not a file means there is nothing to do
        return;
      }

      let reportValue = value;
      const safeToSubmitTypes = [
        WORK_INSTRUCTION_TYPES.SINGLE_SELECT_DROPDOWN,
        WORK_INSTRUCTION_TYPES.TEXT,
        WORK_INSTRUCTION_TYPES.NUMBER,
        WORK_INSTRUCTION_TYPES.OUTPUT_AT_LOCATION,
      ];
      if (!safeToSubmitTypes.includes(type)) {
        // Needed to convert all values except text, number and files to json string to accommodate text field storage in DB
        // Text type is already ready to submit
        reportValue = JSON.stringify(reportValue);
      }

      if (savedValue === value) {
        // value was not changed. No need to proceed
        return;
      }

      metadata.push({
        print: printUri,
        report: reportValue,
        // eslint-disable-next-line camelcase
        work_instruction: `${config.apiHost}/${API_RESOURCES.WORK_INSTRUCTION}/${uuid}/`,
      });

      updatedSavedReportsByPrintUri[printUri] = {
        ...updatedSavedReportsByPrintUri[printUri],
        [uuid]: value,
      };
    });

    setSavedReportsByPrintUri(updatedSavedReportsByPrintUri);
    setAlreadySubmittedWorkInstructions(previous => {
      if (previous.includes(uuid)) {
        return previous;
      }

      return [...previous, uuid];
    });

    if (!metadata.length) {
      // Skip if empty metadata as nothing to create
      return;
    }

    return api.post(`${API_RESOURCES.WORK_INSTRUCTION_REPORT_ACTION}/`, {
      json: {
        'action_type': 'create',
        'metadata': metadata,
      },
      onErrorToastCallback: addToast,
    }).catch(
      (error) => {
        Sentry.captureException(error);
      },
    );
  }

  function handleStepChange(printUris, previousStepIndex) {
    // Instruction will be the same since printUris are from the same WorkStep
    const previousInstruction = checklistsByPrintUri[printUris[0]].work_instructions[previousStepIndex];
    submitInstructionReport(printUris, previousInstruction);
  }

  async function updateRunData(updatedRun, updatedRunTimeEntries = null) {
    const workstationWithUpdatedQueue = await getWorkstation(api, updatedRun);
    await handleRunCompletion(updatedRun, workstationWithUpdatedQueue);
    setWorkstation(workstationWithUpdatedQueue);
    setRun(updatedRun);

    if (updatedRunTimeEntries && updatedRunTimeEntries.length) {
      processUpdatedRunTimeEntries(updatedRunTimeEntries);
    }
  }

  async function handleRunCompletion(updatedRun, workstationWithUpdatedQueue) {
    if (updatedRun.status === RUN_STATUS.complete && run.status !== RUN_STATUS.complete) {
      const newNextWorkstationRun = await getNextRunForWorkstation(api, workstationWithUpdatedQueue);
      setWorkstationNextRun(newNextWorkstationRun);
    }
  }

  function processUpdatedRunTimeEntries(updatedRunTimeEntries) {
    setRunMachineTimeEntries(getMachineRunningTimeEntries(updatedRunTimeEntries));
    const updatedAttendTimeEntries = getAttendingTimeEntries(updatedRunTimeEntries);
    updateLatestAttendTimeTrackingEntry(updatedAttendTimeEntries);
  }

  function getMachineRunningTimeEntries(updatedRunTimeEntries) {
    return updatedRunTimeEntries.filter(
      (updatedRunTimeEntry) => updatedRunTimeEntry.category === TIME_ENTRY_CATEGORIES.MACHINE_RUNNING,
    );
  }

  function getAttendingTimeEntries(updatedRunTimeEntries) {
    return updatedRunTimeEntries.filter(
      (updatedRunTimeEntry) => updatedRunTimeEntry.category === TIME_ENTRY_CATEGORIES.ATTENDING,
    );
  }

  async function updateLatestAttendTimeTrackingEntry(updatedAttendTimeEntries) {
    const latestUpdatedAttendTimeEntry = updatedAttendTimeEntries.length
      ? updatedAttendTimeEntries[updatedAttendTimeEntries.length - 1]
      : null;
    setLatestAttendTimeTrackingEntry(latestUpdatedAttendTimeEntry);
    const latestAttendUser = latestUpdatedAttendTimeEntry ? await getCreatedAttendTimeEntryUser(api, latestUpdatedAttendTimeEntry) : null;
    setAttendTimeEntryUser(latestAttendUser);
  }


  async function onRefreshRunAndWorkstationData() {
    const runId = window.location.pathname.split('/').pop();
    const run = await api.get(`${API_RESOURCES.RUN}/${runId}/`).json();
    setRun(run);
    const workstationWithUpdatedQueue = await getWorkstation(api, run);
    setWorkstation(workstationWithUpdatedQueue);
    const newNextWorkstationRun = await getNextRunForWorkstation(api, workstationWithUpdatedQueue);
    setWorkstationNextRun(newNextWorkstationRun);
  }

  const showNextPrintsInfo = run.status === RUN_STATUS.complete && !!Object.values(nextRunsByPrintUri || {}).length;

  if (loading) {
    return (
      <>
        <Header title="Loading Run..." user={user} />
        <main role="main" className="text-center">
          <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
          Loading...
        </main>
      </>
    );
  }

  return (
    <>
      <Header title={run.name} user={user} />
      <main role="main" className="text-center">
        <RunMenu
          run={run}
          checklist={checklist}
          accessInfo={accessInfo}
          publicGroupsByUri={publicGroupsByUri}
          setGroupsState={setGroupsState}
          postProcessors={postProcessors}
          printers={printers}
          onRunUpdate={updateRunData}
          onRefreshRunAndWorkstationData={onRefreshRunAndWorkstationData}
        />
        <RunHeader
          attendTimeEntryUser={attendTimeEntryUser}
          workstation={workstation}
          run={run}
          materialInfo={materialData}
          scheduledRun={scheduledRun}
          workstationNextRun={workstationNextRun}
          runMachineTimeEntries={runMachineTimeEntries}
          latestAttendTimeTrackingEntry={latestAttendTimeTrackingEntry}
          labelsByPiece={labelsByPiece}
          toolingStockData={toolingStockData}
          isToolingStockTypeFeatureEnabled={isToolingStockTypeFeatureEnabled}
        />
        <GroupedPrints
          reportsByPrintUri={reportsByPrintUri}
          uploadingStateByPrintUri={uploadingStateByPrintUri}
          modelsByUri={modelsByUri}
          checklistsByPrintUri={checklistsByPrintUri}
          lineItemsByUri={lineItemsByUri}
          prints={prints}
          handleStepInput={handleStepInput}
          handleStepChange={handleStepChange}
          groupsState={groupsState}
          setGroupsState={setGroupsState}
          piecesByPrintUri={piecesByPrintUri}
          sendNCReviewForPiece={sendNCReviewForPiece}
          runTransformation={runTransformation}
          savedReportsByPrintUri={savedReportsByPrintUri}
          setIsAbleToCompleteRun={setIsAbleToCompleteRun}
          reportFilesPrintURI={reportFilesPrintURI}
          isFileUploading={isFileUploading}
          isPowderWorkflow={isPowderWorkflowFeatureEnabled}
          labelsByPiece={labelsByPiece}
        />
        {showNextPrintsInfo && (
          <>
            <hr />
            <NextRunsForPrints
              prints={prints}
              nextRunsByPrintUri={nextRunsByPrintUri}
              workstationsByUri={workstationsByUri}
              piecesByPrintUri={piecesByPrintUri}
            />
          </>
        )}
        <RunOperations
          workstation={workstation}
          run={run}
          accessInfo={accessInfo}
          publicGroupsByUri={publicGroupsByUri}
          latestAttendTimeTrackingEntry={latestAttendTimeTrackingEntry}
          alreadySubmittedWorkInstructions={alreadySubmittedWorkInstructions}
          workstationActiveRun={workstationActiveRun}
          checklistsByPrintUri={checklistsByPrintUri}
          reportsByPrintUri={reportsByPrintUri}
          submitInstructionReport={submitInstructionReport}
          materialData={materialData}
          isAbleToCompleteRun={isAbleToCompleteRun}
          shipment={shipment}
          onRunUpdate={updateRunData}
        />
      </main>
      <CommentsContainer user={user} run={run} piecesByPrintUri={piecesByPrintUri} />
    </>
  );
};

Run.propTypes = {
  user: userPropType.isRequired,
};

export default Run;
