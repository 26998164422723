import PropTypes from "prop-types";
import React from "react";

const DockBtn = ({ onDock }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" width="42" height="42"
      viewBox="0 0 32 32" fill="none"
      className="lps-module-card-action-btn"
      onClick={onDock}
    >
      <path
        d="M16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68688 27.3137 4.6863C24.3131 1.68571 20.2435 3.53948e-06 16 2.79753e-06C11.7565 2.05558e-06 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 -2.05558e-06 11.7565 -2.79753e-06 16C-3.53948e-06 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM24.0625 14.4375C24.65 15.025 24.65 15.975 24.0625 16.5563C23.475 17.1375 22.525 17.1438 21.9437 16.5563L17.5062 12.1188L17.5062 23.5C17.5062 24.3313 16.8375 25 16.0062 25C15.175 25 14.5062 24.3313 14.5062 23.5L14.5062 12.1188L10.0687 16.5563C9.48125 17.1438 8.53125 17.1438 7.95 16.5563C7.36875 15.9688 7.3625 15.0188 7.95 14.4375L14.9375 7.4375C15.525 6.85 16.475 6.85 17.0562 7.4375L24.0625 14.4375Z"
        fill="white" />
    </svg>
  );
};

DockBtn.propTypes = {
  onDock: PropTypes.func.isRequired,
};


export default DockBtn;
