import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  formatUnloadQuantityInMachineNumber,
  renderRemainingValueClass,
} from 'src/pages/material-batch/[uuid]/action/machine-unload-hopper';
import { formatThreeDecimalsNumber } from 'src/utils/conversions';

export const formatConvertedUnits = (quantity, units) => (
  <span className='formatted-converted-units'>
    {quantity} {units}
  </span>
);

export const renderItemWithTooltip = (text, value, icon) => {
  return (
    <p className='mb0 truncated-materials-success-page'>
      <OverlayTrigger placement='top' overlay={<Tooltip>{text}</Tooltip>}>
        <FontAwesomeIcon className='spacer-right' icon={icon} />
      </OverlayTrigger>
      <span>{value}</span>
    </p>
  );
};

export const renderTotalQuantityTitle = (
  remainingQuantity,
  remainingQuantityConverted,
  batchUnits,
  batchUnitsConverted,
  isConverted,
  title = 'Total Quantity in Machine'
) => {
  if (isNaN(remainingQuantity) || !batchUnits) return null;
  return (
    <>
      <p className='font-bold'>
        {title}:&nbsp;
        <span className={renderRemainingValueClass(remainingQuantity)}>
          {formatUnloadQuantityInMachineNumber(remainingQuantity)} ({batchUnits})&nbsp;
        </span>
        <span>
          {isConverted &&
            formatConvertedUnits(
              formatThreeDecimalsNumber(remainingQuantityConverted),
              batchUnitsConverted
            )}
        </span>
      </p>
      <hr className='flex-grow-1' />
    </>
  );
};
