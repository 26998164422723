import { PERMANENT_CONTAINER_ACTIONS } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

// Configuration-driven query builder for Permanent Container actions
export const permanentContainerActionConfig = {
  [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: {
    page: 'load-material',
    params: ({
      batch,
      action,
      isFill,
      actionFromPrinter,
      actionBatchLoad,
      initialBatchAction,
      isLoad,
      containerAction,
      printer,
      sourceContainerUUID,
    }) => ({
      action,
      ...(batch && { batch }),
      ...(isFill && { isFill }),
      ...(containerAction && { containerAction }),
      ...(actionFromPrinter && { actionFromPrinter }),
      ...(initialBatchAction && { initialBatchAction }),
      ...(actionBatchLoad && { actionBatchLoad }),
      ...(isLoad && { isLoad }),
      ...(printer && { printerUUID: getUuid(printer) }),
      ...(isLoad && sourceContainerUUID && { sourceContainerUUID }),
    }),
  },
  [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH]: {
    page: 'load-material-batch',
    params: ({
      batch,
      action,
      isFill,
      actionBatchLoad,
      initialBatchAction,
      containerAction,
      actionFromPrinter,
      batchAction,
    }) => ({
      action,
      ...(batch && { batch }),
      ...(isFill && { isFill }),
      ...(containerAction && { containerAction }),
      ...(batchAction && { batchAction }),
      ...(actionBatchLoad && { actionBatchLoad }),
      ...(actionFromPrinter && { actionFromPrinter }),
      ...(initialBatchAction && { initialBatchAction }),
    }),
  },
  [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: {
    page: 'top-off',
    params: ({ batch, containerAction }) => ({
      ...(batch && { batch }),
      ...(containerAction && { containerAction }),
    }),
  },
  [PERMANENT_CONTAINER_ACTIONS.SCRAP]: {
    page: 'scrap-container',
    params: ({ batchAction }) => ({ ...(batchAction && { batchAction }) }),
  },
  [PERMANENT_CONTAINER_ACTIONS.TRANSFER_MATERIAL]: {
    page: 'transfer-material',
    params: ({ batch }) => ({ ...(batch && { batch }) }),
  },
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL]: {
    page: 'action-unload-to-container',
    params: ({ printer, batch, batchAction, actionFromPrinter }) => ({
      printer,
      ...(batch && { batch }),
      ...(actionFromPrinter && { actionFromPrinter }),
      ...(batchAction && { batchAction }),
      initialAction: PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL,
    }),
  },
  [PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH]: {
    page: 'action-unload-to-container',
    params: ({ batch, sieveQuantity, initialSieveAction, batchAction }) => ({
      ...(batch && { batch }),
      ...(sieveQuantity && { sieveQuantity }),
      ...(batchAction && { batchAction }),
      ...(initialSieveAction && { initialSieveAction }),
      initialAction: PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH,
    }),
  },
  [PERMANENT_CONTAINER_ACTIONS.SIEVE]: {
    page: 'action-container-unload',
    params: ({
      container,
      printer,
      sieveQuantity,
      containerAction,
      initialSieveAction,
      sieveIntoPrinter,
    }) => ({
      ...(container && { container }),
      ...(printer && { printer }),
      ...(sieveQuantity && { sieveQuantity }),
      ...(containerAction && { containerAction }),
      ...(initialSieveAction && { initialSieveAction }),
      ...(sieveIntoPrinter && { sieveIntoPrinter }),
      initialAction: PERMANENT_CONTAINER_ACTIONS.SIEVE,
    }),
  },
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: {
    page: 'action-unload-to-container',
    params: ({ printer, batch, batchAction, actionFromPrinter }) => ({
      printer,
      ...(batch && { batch }),
      ...(actionFromPrinter && { actionFromPrinter }),
      ...(batchAction && { batchAction }),
      initialAction: PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL,
    }),
  },
  [PERMANENT_CONTAINER_ACTIONS.RELOCATE]: {
    page: 'relocate',
    params: () => ({}),
  },
};
