/**
 * Converts a duration from seconds to days.
 * @param {number} duration - The duration in seconds.
 * @return {number} The duration in days.
 */
export function convertToDays(duration) {
  return duration / (24 * 60 * 60);
}

export const getPostProcessorDurationInSpecialFormat = duration => {
  if (duration === undefined) {
    return '0h 0m';
  }
  if (duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.round((duration % 3600) / 60);
    // Format hours and minutes, including "0h" if no hours
    const hoursAsString = `${hours}h`;
    const minutesAsString = minutes > 0 ? `${minutes}m` : '0m';
    return `${hoursAsString} ${minutesAsString}`;
  }
  return '0h 0m';
};
