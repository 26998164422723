import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const CircleProgressBar = ({ currentStep, totalSteps, hiddenByScroll }) => {
  const progressPercentage = (currentStep / totalSteps) * 100;
  const strokeDasharray = `${progressPercentage} 100`;

  const classes = classNames('progress-circle-container', {
    'action-progress-bar-smaller': hiddenByScroll,
  });

  return (
    <div className={classes}>
      <svg className='progress-circle' viewBox='0 0 36 36'>
        <circle className='progress-circle-background' cx='18' cy='18' r='16' />
        <circle
          className='progress-circle-progress'
          cx='18'
          cy='18'
          r='16'
          style={{ strokeDasharray }}
        />
      </svg>
      {hiddenByScroll ? (
        <div className='progress-circle-text'>{currentStep}</div>
      ) : (
        <div className='progress-circle-text'>
          {currentStep} of {totalSteps}
        </div>
      )}
    </div>
  );
};

CircleProgressBar.defaultProps = {
  hiddenByScroll: false,
};

CircleProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  hiddenByScroll: PropTypes.bool,
};

export default CircleProgressBar;
