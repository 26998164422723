import PropTypes from 'prop-types';
import React from 'react';

const LocationsMismatch = ({
  sourceBatch,
  destinationResource,
  locations,
  subLocations,
  bottomTextRenderer,
  sourceType = 'Container',
  destinationType = 'Container',
  sourceDisplayName,
  action,
}) => {
  // If any required data is missing, don't render anything.
  if (!sourceBatch || !destinationResource || !locations || !subLocations) {
    return null;
  }

  const batchSubLocation = subLocations.find(
    subLocation => subLocation.uri === sourceBatch.sub_location
  );
  const destinationSubLocation = subLocations.find(
    subLocation => subLocation.uri === destinationResource.sub_location
  );
  const destinationLocation = locations.find(
    location => location.uri === destinationResource.location
  );

  // Helper: if the sub-location has a name, wrap it in parentheses; otherwise, show [Archived].
  const formatSubLocationName = subLocation => {
    return subLocation && subLocation.name ? `(${subLocation.name})` : <strong>[Archived]</strong>;
  };

  const batchSubLocationName = formatSubLocationName(batchSubLocation);
  const destinationSubLocationName = formatSubLocationName(destinationSubLocation);

  return (
    <div>
      <p>
        You are trying to <strong>{action}</strong> but there is a Location mismatch.
      </p>
      <p>
        The {destinationType} <strong>{destinationResource.name}</strong> is in&nbsp;
        <strong>{destinationLocation?.name}</strong> /&nbsp;
        <strong>{destinationSubLocationName}.</strong> And the {sourceType}&nbsp;
        <strong>{sourceDisplayName}</strong> is in&nbsp;
        <strong>{sourceBatch.location_name}</strong> / <strong>{batchSubLocationName}.</strong>
      </p>
      {bottomTextRenderer ? (
        bottomTextRenderer()
      ) : (
        <p>
          Before {action}, you can relocate the {sourceType} now to match the {destinationType}
          &nbsp;location.
        </p>
      )}
    </div>
  );
};

LocationsMismatch.propTypes = {
  /* eslint-disable camelcase */
  // Required data objects.
  sourceBatch: PropTypes.shape({
    sub_location: PropTypes.string.isRequired,
    location_name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  destinationResource: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sub_location: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  subLocations: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,

  // Customization options.
  action: PropTypes.string,
  sourceType: PropTypes.string,
  destinationType: PropTypes.string,
  bottomTextRenderer: PropTypes.func,
  sourceDisplayName: PropTypes.string,
};

export default LocationsMismatch;
