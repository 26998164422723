import { faList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'src/utils/routes';
import { getRouteURI } from 'src/utils/url';
import { getUuid } from 'src/utils/url';

const ViewShipmentListButton = ({ shipmentUri }) => {
  if (!shipmentUri) return null;

  return (
    <div className='ml-4 mr-4 mt-4'>
      <Link
        to={getRouteURI(routes.shipment, {
          uuid: getUuid(shipmentUri),
        })}
      >
        <button type='button' className='btn btn-outline-success btn-action'>
          <FontAwesomeIcon icon={faList} size='3x' className='d-block m-auto' />
          View List
        </button>
      </Link>
    </div>
  );
};

ViewShipmentListButton.propTypes = {
  shipmentUri: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
};

export default ViewShipmentListButton;
