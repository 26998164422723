import { faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'src/components/Tooltip';
import TruncatedTitle from 'src/components/TruncatedTitle';

const TooltipItem = ({ items, icon }) => (
  <p className='truncated-items-tooltip-overlay mb0'>
    {items.map((item, index) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <span key={`${item}-${index}`} data-cy='hidden_items'>
        {icon && <FontAwesomeIcon className='spacer-right' icon={icon} />}
        <strong>
          &quot;{item}&quot;{index < items.length - 1 ? ', ' : ''}
        </strong>
      </span>
    ))}
  </p>
);

TooltipItem.defaultProps = {
  icon: null,
};

TooltipItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.shape({}),
};

const TruncatedItemsList = ({
  data,
  showCount,
  icon,
  showVisibleIcon,
  titleClassName,
  iconClassName,
}) => {
  const visibleItems = data.slice(0, showCount);
  const hiddenItems = data.slice(showCount);

  return (
    <div className='d-flex align-items-center'>
      <div>
        {visibleItems.map((item, index) => (
          <div
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${item}-${index}`}
            className='d-flex align-items-baseline'
            data-cy='hidden_items'
          >
            {showVisibleIcon && (
              <FontAwesomeIcon className={`spacer-right ${iconClassName}`} icon={faObjectGroup} />
            )}
            <span>
              <TruncatedTitle
                inline
                title={item}
                titleClassName={`mb0 ${titleClassName}`}
                maxTextLength={25}
                maxTextLengthMobile={20}
              />
              {index < visibleItems.length - 1 ? ',' : ''}
            </span>
          </div>
        ))}
      </div>

      {hiddenItems.length > 0 && (
        <Tooltip
          id='batch_materials_tooptip'
          placement='bottom'
          trigger={
            <div data-cy='rest-labels' className='ml5 font-weight-500'>
              {`+${hiddenItems.length}`}
            </div>
          }
        >
          <TooltipItem items={hiddenItems} icon={icon} />
        </Tooltip>
      )}
    </div>
  );
};

TruncatedItemsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  showCount: PropTypes.number,
  icon: PropTypes.shape({}),
  showVisibleIcon: PropTypes.bool,
  titleClassName: PropTypes.oneOfType([PropTypes.string, undefined]),
  iconClassName: PropTypes.oneOfType([PropTypes.string, undefined]),
};

TruncatedItemsList.defaultProps = {
  showCount: 3,
  icon: null,
  showVisibleIcon: false,
  titleClassName: '',
  iconClassName: '',
};

export default TruncatedItemsList;
