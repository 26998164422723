import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { MLINE_MODULE_TYPES } from 'src/constants/m-line';
import { fetchModuleWorkstation } from 'src/utils/api';
import { API_RESOURCES } from 'src/utils/constants';

export const isModuleEmpty = (modules = [], type) => {
  if (_isEmpty(modules)) {
    return true;
  }
  return modules.some(({ slot, module }) => slot === type && !module);
};

export const renderDynamicContent = (
  isLoading,
  content,
  loadingComponent = <Spinner animation='border' size='sm' />,
  fallback = null
) => {
  if (isLoading) {
    return loadingComponent;
  }
  if (!content) return fallback;

  return content;
};

/**
 * Checks for unsupported materials for the build and overflow modules based on the dose batch.
 *
 * For each module (build and overflow), this function compares the dose batch's materials with the module's
 * allowed materials (provided in `material_restrictions`). If the module has restrictions (i.e. its
 * `material_restrictions` array is non-empty), the function filters out dose batch materials whose URI is not
 * included in the allowed list. It returns an object mapping module types (e.g., BUILD, OVERFLOW) to an array
 * of unsupported material names.
 *
 * For example, if:
 *  - doseBatch.materials is:
 *      [ { name: "Material 1", uri: "uri1" }, { name: "Material 2", uri: "uri2" } ]
 *  - buildModule.material_restrictions is [] (which means all materials are allowed)
 *  - overflowModule.material_restrictions is [ "uri1", "uri3" ]
 *
 * then the result will be:
 *    {
 *      [MLINE_MODULE_TYPES.OVERFLOW]: ["Material 2"]
 *    }
 *
 * @param {Object} doseBatch - The dose batch object, expected to contain a `materials` array.
 * @param {Object} buildModule - The build module object, expected to contain a `material_restrictions` array.
 * @param {Object} overflowModule - The overflow module object, expected to contain a `material_restrictions` array.
 * @returns {Object} An object mapping module types (e.g., BUILD, OVERFLOW) to an array of unsupported material names.
 */
export const checkNotAllowedMaterialsForDoseModule = (doseBatch, buildModule, overflowModule) => {
  // Return an empty object if any required data is missing.
  if (!doseBatch || !buildModule || !overflowModule) return {};

  // Extract the dose batch materials, defaulting to an empty array if not provided.
  const doseMaterials = doseBatch.materials ?? [];

  // Retrieve allowed materials for the build and overflow modules.
  // An empty array means the module allows all materials.
  const buildAllowedMaterials = buildModule.material_restrictions ?? [];
  const overflowAllowedMaterials = overflowModule.material_restrictions ?? [];

  // For the build module:
  // If there are restrictions, filter out any dose material whose URI is not in the allowed list,
  // and then map to the material's name.
  const buildUnsupportedMaterials = !_isEmpty(buildAllowedMaterials)
    ? doseMaterials
        .filter(material => !buildAllowedMaterials.includes(material.uri))
        .map(material => material.name)
    : [];

  // For the overflow module:
  // Similarly, if restrictions are present, filter and map the unsupported materials.
  const overflowUnsupportedMaterials = !_isEmpty(overflowAllowedMaterials)
    ? doseMaterials
        .filter(material => !overflowAllowedMaterials.includes(material.uri))
        .map(material => material.name)
    : [];

  // Build the result object mapping module types to the names of unsupported materials.
  const unsupportedMaterials = {};
  if (!_isEmpty(buildUnsupportedMaterials)) {
    unsupportedMaterials[MLINE_MODULE_TYPES.BUILD] = buildUnsupportedMaterials;
  }
  if (!_isEmpty(overflowUnsupportedMaterials)) {
    unsupportedMaterials[MLINE_MODULE_TYPES.OVERFLOW] = overflowUnsupportedMaterials;
  }

  return unsupportedMaterials;
};

export const getMachineTypeFromUrl = urlString => {
  try {
    const url = new URL(urlString);
    const pathSegments = url.pathname.split('/').filter(Boolean);
    return pathSegments[0]; // 'printer' or 'post-processor'
  } catch (error) {
    console.error('Invalid URL', error);
    return null;
  }
};

export const checkIfModuleAlreadyDocked = async (
  module,
  currentMachineType,
  currentMachineName
) => {
  if (!module || !currentMachineType || !currentMachineName) return null;
  const isModuleAlreadyDocked = !!module.workstation_uri && module.is_docked;
  const machineModuleAlreadyDockedTo = isModuleAlreadyDocked
    ? await fetchModuleWorkstation(module.workstation_uri)
    : null;

  if (!_isEmpty(machineModuleAlreadyDockedTo)) {
    const machineName = getMachineTypeFromUrl(module.workstation_uri);
    const machineType = machineName === API_RESOURCES.PRINTER ? 'LPS' : 'MHS';
    return `This module is currently docked at ${machineType} ${machineModuleAlreadyDockedTo.name}.
      Please undock the module before docking it to ${currentMachineType} ${currentMachineName}.`;
  }

  return null;
};
