import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faChevronUp, faFastBackward } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import ActionContext from 'src/components/action-context';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import MaterialBatchSuccessCardDetails from 'src/components/material-batch-success-card-details';
import NotFound from 'src/components/not-found';
import ScanButton from 'src/components/scan-button';
import { ActionLink } from 'src/pages/material-batch/[uuid]/success';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import { getBatchApiUrl } from 'src/utils/actionsAPIUtils';
import { api } from 'src/utils/api';
import {
  API_RESOURCES, PERMANENT_CONTAINER_ACTIONS, PRINTER_ACTIONS,
} from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getContainerType } from 'src/utils/stringUtils';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

const PrinterSuccessPage = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: printerUUID } = useParams();
  const {
    batch: batchUUID,
    action,
    sourceContainerUUID,
    isLoad: isLoadAction,
    isFullBatchLoad,
    isTopOff,
    initialBatchUUID,
  } = Object.fromEntries(searchParams.entries()) ?? {};
  const { isLoading, setLoading } = useActionLoadingStore();
  const [pageState, setPageState] = useState({
    id: batchUUID,
    batch: null,
    batchLoadedToPrinter: null,
    fetchedContainers: [],
    fetchedContainersSubLocations: [],
    fetchedPermanentContainersLocations: [],
    sourcePermanentContainer: null,
    printer: null,
    error: null,
  });

  const [showEmptiedContainers, setShowEmptiedContainers] = useState(false);

  const {
    containerActionData: { permanentContainersActionState, scannedContainers },
  } = useContext(ActionContext);

  const LOAD_ACTIONS = [
    PERMANENT_CONTAINER_ACTIONS.MACHINE_LOAD,
    PRINTER_ACTIONS.LOAD_MATERIAL,
  ];

  const isPrinterLoadActions = LOAD_ACTIONS.includes(action);

  const isPrinterTopOffAction = isPrinterLoadActions && isTopOff;

  const actionTitles = {
    [PERMANENT_CONTAINER_ACTIONS.MACHINE_LOAD]: isPrinterTopOffAction ? 'Top Off' : 'Load',
    [PRINTER_ACTIONS.LOAD_MATERIAL]: isPrinterTopOffAction ? 'Top Off' : 'Load',
  };

  const actionTitle = actionTitles[action] || 'Printer';

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const printer = await api.get(`${API_RESOURCES.PRINTER}/${printerUUID}/`).json();
      const sourcePermanentContainer = sourceContainerUUID ? await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${sourceContainerUUID}/`).json() : null;
      const batchUrl = getBatchApiUrl(sourcePermanentContainer, batchUUID, initialBatchUUID);
      const batch = batchUrl ? await api.get(batchUrl).json() : null;

      const printerBatches = printer ? await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
        searchParams: {
          'filter[at_machine]': printer.uri,
        },
      }).json() : null;

      const batchLoadedToPrinter = printerBatches?.resources?.[0] || null;

      const permanentContainersScanned = isLoadAction
        ? scannedContainers
        : Object.values(permanentContainersActionState.containersScanned);

      const fetchedContainers = isLoadAction && permanentContainersScanned?.length ?
        await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
          searchParams: {
            'filter[uri]': permanentContainersScanned.map((container) => container.uri).join(','),
          },
        }).json() :
        null;

      const fetchedContainersResources = fetchedContainers?.resources;

      const fetchedContainersBatches = fetchedContainersResources?.length
        && fetchedContainersResources.map((container) => container.current_batch).filter(Boolean);

      const scannedContainerBatches = fetchedContainersBatches?.length ?
        await api.get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
          searchParams: {
            'filter[uri]': fetchedContainersBatches.join(','),
          },
        }).json() : null;

      const hasFetchedContainers = fetchedContainers?.resources?.length;

      const fetchedContainersLocations = hasFetchedContainers ?
        fetchedContainers.resources.map((container) => container.location).filter(Boolean)
        : [];

      const fetchedPermanentContainersLocations = !_isEmpty(fetchedContainersLocations) ?
        await api.get(`${API_RESOURCES.LOCATION}/`, {
          searchParams: {
            'filter[uri]': fetchedContainersLocations.join(','),
          },
        }).json() : null;

      const fetchedContainersSubLocations = hasFetchedContainers ?
        fetchedContainers.resources.map((container) => container.sub_location).filter(Boolean)
        : [];

      const fetchedPermanentContainersSubLocations = !_isEmpty(fetchedContainersSubLocations) ?
        await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
          searchParams: {
            'filter[uri]': fetchedContainersSubLocations.join(','),
          },
        }).json() : null;

      const batchSubLocationToFetch = batch ? batch.sub_location : batchLoadedToPrinter?.sub_location;

      const batchSubLocation = batchSubLocationToFetch ? await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batchSubLocationToFetch)}/`).json() : null;

      setPageState({
        id: printerUUID,
        batch,
        batchLoadedToPrinter,
        printer,
        sourcePermanentContainer,
        fetchedContainers: fetchedContainers?.resources || [],
        scannedContainerBatches: scannedContainerBatches?.resources || [],
        fetchedContainersSubLocations: fetchedPermanentContainersSubLocations?.resources || null,
        fetchedPermanentContainersLocations: fetchedPermanentContainersLocations?.resources || null,
        action,
        subLocation: batchSubLocation?.name,
        error: null,
      });
    } catch (error) {
      if (error.name === 'HTTPError') {
        setPageState((prevState) => ({
          ...prevState,
          id: batchUUID,
          error: {
            name: error.name,
            status: error.response && error.response.status,
          },
        }));
      }

    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, [printerUUID]);

  const getBatchLink = (batch = pageState.batch) => batch
    ? getRouteURI(routes.materialContainer, {}, { batch: getUuid(batch.uri) })
    : null;

  const getPermanentContainerLink = (permanentContainer = pageState.permanentContainer) => permanentContainer
    ? getRouteURI(routes.permanentContainerDetails, { uuid: getUuid(permanentContainer.uri) })
    : null;

  const getContainerName = (container = pageState.permanentContainer) => {
    if (!container) return null;
    return container.disposable ? getShortUuid(container.uri) : container.name;
  };

  const renderLoadActionUI = () => {
    const loadedWording = isPrinterTopOffAction ? 'topped up' : 'loaded';
    if (_isEmpty(scannedContainers)) {
      return (
        <p className="success-action-text">
          {batchUUID && (!isLoadAction || isFullBatchLoad) ? (
            <>
              Batch
              <ActionLink
                text={getShortUuid(batchUUID)}
                link={getBatchLink()}
              />
            </>
          ) : getContainerType(pageState.sourcePermanentContainer)}
          <ActionLink
            text={getContainerName(pageState.sourcePermanentContainer)}
            link={getPermanentContainerLink(pageState.sourcePermanentContainer)}
          />

          was successfully {loadedWording} into the&nbsp;

          <span>printer</span>
          <ActionLink
            text={pageState.printer.name}
            link={getPrinterUrl()}
          />
        </p>
      );
    }

    return (
      <p className="success-action-text">
        {scannedContainers.length} containers
        were successfully {loadedWording} into the printer
        <ActionLink
          text={pageState.printer.name}
          link={getPrinterUrl()}
        />
      </p>
    );
  };

  const ACTION_SUCCESS_MESSAGES = {
    [PERMANENT_CONTAINER_ACTIONS.MACHINE_LOAD]: () => renderLoadActionUI(),
    [PRINTER_ACTIONS.LOAD_MATERIAL]: () => renderLoadActionUI(),
  };

  const createMaterialBatchConfig = (title, batchProp, subLocationProp) => ({
    title,
    batch: pageState[batchProp],
    subLocation: pageState[subLocationProp],
  });

  const createMaterialBatchSuccessCardDetails = (config) => (
    <MaterialBatchSuccessCardDetails key={config.title || config?.batch?.uri} {...config} />
  );

  const PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG = {
    LOAD_PRINTER: [
      printerUUID ? {
        printer: pageState.printer,
        batch: pageState.batchLoadedToPrinter,
        subLocation: pageState.subLocation,
      } : {},
      sourceContainerUUID ? {
        containerTitle: 'Source Container',
        permanentContainer: pageState.sourcePermanentContainer,
        batch: pageState.batch,
        customQuantity: String(pageState.sourcePermanentContainer?.quantity),
        hideLocation: true,
        containerOnly: true,
      } : {},
      !_isEmpty(scannedContainers) ? createMaterialBatchConfig('Selected Batch', 'batch', 'subLocation') : {},
      batchUUID && (!isLoadAction || isFullBatchLoad) && _isEmpty(scannedContainers) ? createMaterialBatchConfig('Batch', 'batch', 'subLocation') : {},
    ],
    DEFAULT: createMaterialBatchConfig('Batch', 'batch', 'subLocation'),
  };

  const renderCards = (config) => {
    return config.map(createMaterialBatchSuccessCardDetails);
  };

  const ACTION_SUCCESS_DATA = {
    [PERMANENT_CONTAINER_ACTIONS.MACHINE_LOAD]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.LOAD_PRINTER),
    [PRINTER_ACTIONS.LOAD_MATERIAL]: () => renderCards(PERMANENT_CONTAINER_ACTION_SUCCESS_CONFIG.LOAD_PRINTER),
  };

  const toggleEmptiedContainers = () => {
    setShowEmptiedContainers((prev) => !prev);
  };

  if (pageState.error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={pageState.id} />
        </main>
      </>
    );
  }

  const getPrinterUrl = () => {
    return `/printer/${printerUUID}/material`;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !pageState.printer) {
    return <NotFound id={printerUUID} />;
  }

  const renderBottomButtons = () => (
    <div className="success-buttons-bottom">
      <Link
        to={getPrinterUrl()}
      >
        <div className="btn btn-outline-primary btn-action mt-4">
          <FontAwesomeIcon icon={faFastBackward} size="3x" className="d-block m-auto" />
          View Printer
        </div>
      </Link>
      <ScanButton />
    </div>
  );

  const renderEmptiedContainers = () => {
    if (isPrinterLoadActions && !_isEmpty(scannedContainers)) {
      return (
        <div>
          <button
            type="submit"
            className="btn btn-lg btn-primary mb5"
            onClick={toggleEmptiedContainers}
          >
            <FontAwesomeIcon icon={showEmptiedContainers ? faChevronUp : faChevronDown} className="spacer-right" />
            Emptied Containers
          </button>
          {showEmptiedContainers && (
            <div className="mt15">
              {pageState.fetchedContainers.map((container, index) => (
                <p key={container.uri} className="mb0">
                  {index + 1}.
                  {container.disposable
                    ? getShortUuid(container.uri)
                    : (
                      <ActionLink
                        text={getContainerName(container)}
                        link={getPermanentContainerLink(container)}
                      />
                    )}
                </p>
              ))}
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Header
        title={isPrinterTopOffAction ? 'Top Off Success' : 'Load Printer Success'}
        user={user} />
      <main role="main" className="text-center">
        <div className="success-main">
          <div className="success-animation">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
          <p className="success-text">{actionTitle} Action Complete</p>

          <div className="success-main-content">
            {ACTION_SUCCESS_MESSAGES?.[action] && ACTION_SUCCESS_MESSAGES[action]()}

            <hr className="success-hr-line" />

            {ACTION_SUCCESS_DATA?.[action] && ACTION_SUCCESS_DATA[action]()}
            {renderEmptiedContainers()}
            {renderBottomButtons()}
          </div>
        </div>
      </main>
    </>
  );

};

PrinterSuccessPage.propTypes = {
  user: userPropType,
};

PrinterSuccessPage.defaultProps = {
  user: null,
};

export default PrinterSuccessPage;
