import create from 'zustand';

const useScanStore = create(set => ({
  urlError: '',
  successfulScan: false,

  triggerScanError: errorText =>
    set({
      urlError: new Error(errorText),
      successfulScan: false,
    }),

  setUrlError: errorText =>
    set({
      urlError: errorText,
    }),

  setSuccessfulScan: isSuccessful =>
    set({
      successfulScan: isSuccessful,
    }),

  cleanupScan: () =>
    set({
      urlError: '',
      successfulScan: false,
    }),
}));

export default useScanStore;
